import img from "./img/img.png"

export const questions = {
    "gameType": "Poll",
    "glo": [
        {
            "ident": "q0",
            "content": "Czy sklepy Inspiration Store to dawne sklepy eSmoking World?",
            "type": "oneWithInput",
            img: img,
            "correctAnswer": 0,
            "answers": [
                {
                    "ident": "a",
                    "content": "Tak"
                },
                {
                    "ident": "b",
                    "content": "Nie"
                },
            ]
        },
        {
            "ident": "q1",
            "content": "Które z niżej wymienionych produktów są dostępne w Inspiration Store:",
            "type": "oneWithInput",
            img: img,
            "correctAnswer": 3,
            "answers": [
                {
                    "ident": "a",
                    "content": "glo™ Hyper X2 i glo™ Hyper X2 Air"
                },
                {
                    "ident": "b",
                    "content": "Vuse Go i Vuse ePod"
                },
                {
                    "ident": "c",
                    "content": "Wkłady tytoniowe neo™ i saszetki nikotynowe Velo"
                },
                {
                    "ident": "d",
                    "content": "Wszystkie odpowiedzi są poprawne"
                },
            ]
        },
        {
            "ident": "q2",
            "content": "Sklepy Inspiration Store znajdziesz:",
            "type": "oneWithInput",
            "correctAnswer": 2,
            img: img,
            "answers": [
                {
                    "ident": "a",
                    "content": "Tylko w Warszawie"
                },
                {
                    "ident": "b",
                    "content": "Jedynie w miastach wojewódzkich"
                },
                {
                    "ident": "c",
                    "content": "W 38 miastach na terenie całej Polski"
                },
            ]
        },
        {
            "ident": "q3",
            "content": "Czy na wyspach Inspiration Store jest możliwość założenia subskrypcji?",
            "type": "oneWithInput",
            img: img,
            "correctAnswer": 0,
            "answers": [
                {
                    "ident": "a",
                    "content": "Tak"
                },
                {
                    "ident": "b",
                    "content": "Nie"
                },
            ]
        },
        {
            "ident": "q4",
            "content": "Na wyspach Inspiration Store możesz skorzystać z dedykowanych ofert dla konsumenta?",
            "type": "oneWithInput",
            img: img,
            "correctAnswer": 0,
            "answers": [
                {
                    "ident": "a",
                    "content": "Tak"
                },
                {
                    "ident": "b",
                    "content": "Nie"
                },
            ]
        },
        {
            "ident": "q5",
            "content": "Czy z systemu lojalnościowego eSmoking Club można korzystać w Inspiration Store?",
            "type": "oneWithInput",
            img: img,
            "correctAnswer": 0,
            "answers": [
                {
                    "ident": "a",
                    "content": "Tak"
                },
                {
                    "ident": "b",
                    "content": "Nie"
                },
            ]
        },
    ]
}