import img from "./img/Main.jpg"

export const questions = {
  "gameType": "Poll",
  "glo": [
    {
      "ident": "q0",
      "content": "Jakie imprezy wchodzą w skład Xperience Your Way?",
      "type": "oneWithInput",
      "img": img,
      "answers": [
        {
          "ident": "a",
          "content": "Truck Tour"
        },
        {
          "ident": "b",
          "content": "Ambient"
        },
        {
          "ident": "c",
          "content": "Strefy Festiwalowe",
        },
        {
          "ident": "d",
          "content": "Wszystkie wymienione"
        },
      ]
    },
    {
      "ident": "q1",
      "content": "Na jakich festiwalach będziesz mógł bawić się w strefie glo™?",
      "type": "oneWithInput",
      "img": img,
      "answers": [
        {
          "ident": "a",
          "content": "SBM FFestival"
        },
        {
          "ident": "b",
          "content": "Sunrise Festival, Sun Festival"
        },
        {
          "ident": "c",
          "content": "OFF Festival, SBM FFestival, Sun Festival"
        },
        {
          "ident": "d",
          "content": "SBM FFestival, Sunrise Festival, Sun Festival, OFF Festival"
        },
      ]
    },
    {
      "ident": "q2",
      "content": "Na jakim wydarzeniu możesz wygrawerować swój podgrzewacz glo™?",
      "type": "oneWithInput",
      "img": img,
      "answers": [
        {
          "ident": "a",
          "content": "Ambient"
        },
        {
          "ident": "b",
          "content": "Truck Tour"
        },
        {
          "ident": "c",
          "content": "Strefa festiwalowa"
        },
        {
          "ident": "d",
          "content": "Na wszystkich trzech wydarzeniach"
        },
      ]
    },
  ]
}