import _1_1 from "./img/1_1.png"
import _1_2 from "./img/1_2.png"
import _1_3 from "./img/1_3.png"
import _1_4 from "./img/1_4.png"
import _2_1 from "./img/2_1.png"
import _2_2 from "./img/2_2.png"
import _2_3 from "./img/2_3.png"
import _2_4 from "./img/2_4.png"
import _3_1 from "./img/3_1.png"
import _3_2 from "./img/3_2.png"
import _3_3 from "./img/3_3.png"
import _3_4 from "./img/3_4.png"
import _4_1 from "./img/4_1.png"
import _4_2 from "./img/4_2.png"
import _4_3 from "./img/4_3.png"
import _4_4 from "./img/4_4.png"

export const questions = {
    "gameType": "PickOne",
    "glo": [
        {
            "ident": "q0",
            "content": "Który kolor nie pasuje?",
            fontSize: "18px",
            "type": "img",
            "answers": [
                {
                    "ident": "a",
                    content: _1_1
                },
                {
                    "ident": "b",
                    content: _1_2
                },
                {
                    "ident": "c",
                    content: _1_3
                },
                {
                    "ident": "d",
                    content: _1_4
                },
            ]
        },
        {
            "ident": "q1",
            "content": "Które urządzenie nie pasuje?",
            fontSize: "18px",
            "type": "img",
            "answers": [
                {
                    "ident": "a",
                    content: _2_1
                },
                {
                    "ident": "b",
                    content: _2_2
                },
                {
                    "ident": "c",
                    content: _2_3
                },
                {
                    "ident": "d",
                    content: _2_4
                },
            ]
        },
        {
            "ident": "q2",
            "content": "Które urządzenie nie pasuje?",
            fontSize: "18px",
            "type": "img",
            "answers": [
                {
                    "ident": "a",
                    content: _3_1
                },
                {
                    "ident": "b",
                    content: _3_2
                },
                {
                    "ident": "c",
                    content: _3_3
                },
                {
                    "ident": "d",
                    content: _3_4
                },
            ]
        },
        // {
        //     "ident": "q3",
        //     "content": "Które urządzenie nie pasuje?",
        //     fontSize: "18px",
        //     "type": "img",
        //     "answers": [
        //         {
        //             "ident": "a",
        //             content: _4_1
        //         },
        //         {
        //             "ident": "b",
        //             content: _4_2
        //         },
        //         {
        //             "ident": "c",
        //             content: _4_3
        //         },
        //         {
        //             "ident": "d",
        //             content: _4_4
        //         },
        //     ]
        // },
    ]
}