import React, { useState } from 'react'
import Modal from "../Modal/Modal"
import Button from "../Button/Button"
import Send from "../../../img/send.svg"
import "./style.scss"

export default function PushNotificationModal({ showModal, setShowModal }) {

  return (
    <>
      <Modal isOpen={showModal.show} buttonClose={false} modalClass={"narrow"}>
        <div className="push-notification-modal">
          <div className="upper-bar">
            <img src={Send} alt="" />
            <button onClick={() => setShowModal(prev => ({...prev , show: !prev.show}))}>
              <div className="exit" />
            </button>
          </div>
          <div className="text">
            <h4>{showModal.enableInSettings ? "Chcesz włączyć powiadomienia?" :"Chcesz zrezygnować z powiadomień?"}</h4>
            <p>{showModal.enableInSettings ? "Notyfikacje z Alter Zone włączysz w ustawieniach swojej przeglądarki." : "Notyfikacje z Alter Zone wyłączysz w ustawieniach swojej przeglądarki."}</p>
          </div>
          <div className="button-wrapper">
            <Button action={() => setShowModal(prev => ({...prev , show: !prev.show}))}>OK</Button>
          </div>
        </div>
      </Modal>
    </>
  )
}
