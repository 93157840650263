import slowhop from "./img/ic_slowhop.svg"
import pufa from "./img/ic_puffa.svg"

export const konkurs2_kwiecien = {
    rewards: [
        {
            img: slowhop,
            place: "1",
            content: "Voucher Slowhop na relaksujący wyjazd (1500zł)",
        },
        {
            img: slowhop,
            place: "2-3",
            content: "Voucher Slowhop na relaksujący wyjazd (1000zł)"
        },
        {
            img: pufa,
            place: "4-10",
            content: "Puf Sakoo do odpoczywania",
        },
    ],
}