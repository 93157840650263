import pack1 from "./img/1.png"
import pack2 from "./img/2.png"
import pack3 from "./img/3.png"
import main from "./img/main.png"

export const questions = {
    "gameType": "ScratchReveal",
    "glo": [
        {
            "ident": "q0",
            "content": "Odkryj wygląd paczki wkładów KENT do glo™, przesuwając kursorem po opakowaniu",
            "name": "Royal Blue",
            "sub": "Bogaty wariant tytoniowy z kremowymi nutami.",
            img: main,
            "answers": [
                {
                    "ident": null,
                    "pack": pack1
                },
            ]
        },
        {
            "ident": "q1",
            "content": "Odkryj wygląd paczki wkładów KENT do glo™, przesuwając kursorem po opakowaniu",
            "name": "Cloud Blue",
            "sub": "Zbalansowany wariant tytoniowy",
            img: main,
            "answers": [
                {
                    "ident": null,
                    "pack": pack2
                },
            ]
        },
        {
            "ident": "q2",
            "content": "Odkryj wygląd paczki wkładów KENT do glo™, przesuwając kursorem po opakowaniu",
            "name": "Emerald Green Click",
            "sub": "Intensywne miętowe doświadczenie z kapsułą.",
            img: main,
            "answers": [
                {
                    "ident": null,
                    "pack": pack3
                },
            ]
        },
    ]
}