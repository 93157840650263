export const questions = {
    "gameType": "PickOne",
    "glo": [
        {
            "ident": "q8",
            "content": "Ile znajdziesz wariantów w ofercie wkładów neo™?",
            "fontSizeMobile": "24px",
            "fontSizeDesktop": "24px",
            "type": "text",
            "correctAnswer": "b",
            "answers": [
                {
                    "ident": "a",
                    "content": "6"
                },
                {
                    "ident": "b",
                    "content": "12"
                },
            ]
        },
        {
            "ident": "q9",
            "content": "Który wariant jest Twoim ulubionym? (wybierz 1)",
            "fontSizeMobile": "24px",
            "fontSizeDesktop": "24px",
            type: "dropdowns",
            "copys": [
                ""
            ],
            "answers": [
                {
                    ident: "a",
                    copy: "neo™ Ice Click",
                },
                {
                    ident: "b",
                    copy: "neo™ Scarlet Click",
                },
                {
                    ident: "c",
                    copy: "neo™ Summer Yellow Click",
                },
                {
                    ident: "d",
                    copy: "neo™ Terracotta Tobacco",
                },
                {
                    ident: "e",
                    copy: "neo™ Arctic Blue Click",
                },
                {
                    ident: "f",
                    copy: "neo™ Gold Tobacco",
                },
                {
                    ident: "g",
                    copy: "neo™ Green Click",
                },
                {
                    ident: "h",
                    copy: "neo™ Sunset Click",
                },
                {
                    ident: "i",
                    copy: "neo™ Violet Click",
                },
                {
                    ident: "j",
                    copy: "neo™ Rounded Tabacco",
                },
                {
                    ident: "k",
                    copy: "neo™ Foam Click",
                },
                {
                    ident: "l",
                    copy: "neo™ Melt Click",
                },
            ]
        },
    ]
}