export const data = [
      {
          name: "Joanna",
          content: "Ognisko, fajka pokoju na jeziorze Titicaca z Indianami wysp Uros"
      },
      {
        name: "Marcin",
        content: "Szkocja Edynburg wschód słońca szum morza odpływ odprężony na falochronie"
    },
    {
        name: "Monika",
        content: "Wracasz z Egiptu na nieważnej wizie o 4 rano cichaczem!"
    },
    {
        name: "Gosia",
        content: "Zostanie rodziną zastępczą dla noworodka nie mogąc mieć swoich dzieci"
    },
    {
        name: "Janusz",
        content: "Miłość do kuchni, program telewizyjny, wygrana, rozgłos, własna restauracja, spełnienie"
    },
    {
        name: "Bartek",
        content: "Nietuzinkowy spęd kamratów przesiąknięty wulkanicznym gejzerem energii wybitnie mocno pozytywnej."
    },
    {
        name: "Dominika",
        content: "Lekarze z Czech powiedzieli, że jednak mogę mieć dzieci!"
    },
    {
        name: "Mateusz",
        content: "Mecz Polaków stadion Narodowy dreszcze radość wygrana kibicowanie szczęście duma"
    },
    {
        name: "Mateusz",
        content: "Pomysł, plecak, samolot, bach Meksyk! Wędrówka, kartelu góra, ucieczka, żyję!"
    },
    {
        name: "Marcin",
        content: "Żona. Codziennie nowe doświadczenie. Mimo tego finał zawsze ten sam."
    },
    {
        name: "Kamil",
        content: "Granie w ampfutbol czyli piłkę nożną niepełnosprawnych to styl życia"
    },
    {
        name: "Viktor",
        content: "COVID, wojna, ale my razem"
    },
    {
        name: "Iryna",
        content: "Przeprowadzka z Ukrainy do Polski. Najlepszy kraj na świecie. Love."
    },
    {
        name: "Aleksandra",
        content: "Spanie dwa dni na chodniku przed koncertem Harry'ego Stylesa"
    },
    {
        name: "Aleksander",
        content: "Wyruszyłem rankiem z Palenicy. Wieczór przywitałem widokiem z Ciemnosmreczyńskiej Grani."
    },
    {
        name: "Dawid",
        content: "Euforystyczny blogostan nad pięknym Adriatykiem owiany nutką nieprzemyślanych zdarzeń życiowych."
    },
    {
        name: "Lucyna",
        content: "Wejście na ETNĘ, wrażenie bycia na KSIĘŻYCU."
    },
    {
        name: "Wojciech",
        content: "Sky is the limit! Skoczyłem ze spadachronem z 6000 metrów!"
    },
    {
        name: "Patrycja",
        content: "Najlepsze doświadczenie mojego życia to macierzyństwo.nie ma nic lepszego."
    },
    {
        name: "Maciej",
        content: "Najcudowniejsze uczucie to pierwszy raz trzymać w ramionach wnuka Juliana"
    },
    {
        name: "Mateusz",
        content: "WYŚCIGI F1 PRĘDKOŚĆ FERRARI TOR DRIFT RYWALIZACJA 4KÓŁKA ADRENALINA gAZdOdECHY"
    },
    {
        name: "Mateusz",
        content: "Zostanę tatą w tym roku. Wreszcie!"
    },
    {
        name: "Wadim",
        content: "Długa podróż od otrzymania Karty Polaka do uzyskania Obywatelstwa Polski!"
    },
    {
        name: "Sebastian",
        content: "Praca 750 metrów pod ziemią. Nie ma takiego samego dnia..."
    },
    {
        name: "Leszek",
        content: "Europa. Miesiąc na rowerze. Sam. 2200 kilometrów. Niewyobrażalne poczucie wolności."
    },
    {
        name: "Monika",
        content: "Ognisko, przysięga wojskowa, randka, wspólne mieszkanie, narzeczeństwo, ślub, dziecko. Życie!"
    },
    {
        name: "Magdalena",
        content: "Spontan Bangkok, 4h przed odlotem, 4tygodnie mega przygody"
    },
    {
        name: "Justyna",
        content: "Grześ, Rakoń,Wołowiec-pierwszy i najlepszy wypad w Tatry Zachodnie"
    },
    {
        name: "San",
        content: "Zagubienie przyjaciół na chińskim markecie w Szanghaju podczas zakupów."
    },
    {
        name: "Dawid",
        content: "Strzelić bramkę w lidze orlikowej to jest naprawdę fantastyczne uczucie"
    },
    {
        name: "Adam",
        content: "Samodzielny lot helikopterem nad korytem rzeki"
    },
    {
        name: "Leszek",
        content: "Pierwsze zarobione pieniądze, byłem wtedy królem życia :)"
    },
    {
        name: "Katarzyna",
        content: "Rok temu rzuciłam pracę w korporacji i wyjechałam do Francji"
    },
    {
        name: "Kamila",
        content: "Norweskie fiordy, fiński spokój, belgijska czekolada, zatłoczony Mediolan, nostalgiczna Ryga."
    },
    {
        name: "Rafał",
        content: "Moim najlepszym doświadczeniem była podróż Trabantem do Rumunii 3400 km"
    },
    {
        name: "Anita",
        content: "Włoskie wakacje w Dolomitach. Zdobyłam Marmoladę VIA Ferrata przez lodowiec"
    },
    {
        name: "Michał",
        content: "Przejazd na rowerach z Mediolanu do Napoli i potem Sycylia"
    },
    {
        name: "Matian",
        content: "Jazda STOPEM po Europie - 8 krajów odwiedzonych!"
    },
    {
        name: "Dawid",
        content: "Jarocin 1993, sam, bez pieniedzy ogorki i woda, muzyka!"
    },
    {
        name: "Monika",
        content: "Podarowanie jeszcze dwóch lat godnego życia staremu adoptowanemu psu."
    },
  ]