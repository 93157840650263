import Basket from "./img/basket.svg"
import GoPro from "./img/gopro.svg"
import Scooter from "./img/scooter.svg"

const konkurs1_luty_2023 = {
    rewards: [
        {
            img: Scooter,
            place: "1",
            content: "Hulajnoga elektryczna XIAOMI 4 Pro",
        },
        {
            img: GoPro,
            place: "2-3",
            content: "Kamera sportowa GoPro HERO9 Black"
        },
        {
            img: Basket,
            place: "4-10",
            content: "Kosz piknikowy"
        },
    ],
}

export default konkurs1_luty_2023