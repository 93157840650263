import React, { useEffect, useState } from "react"
import "./style.scss"
import Close from "./img/close.svg"
import Arrow from "./img/arrow.svg"
import { Link } from 'react-router-dom'

export default function MigrationBar({ setWednesdayBar }) {


  return (
    <div className="crazy-wednesday-bar">
        <>
          <div className="container">
            <p><strong>Program Alter Zone zostanie oficjalnie zamknięty 27 grudnia 2023 roku. </strong></p>
          </div>
        </>
    </div>
  );
}
