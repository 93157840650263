export const questions = {
    "gameType": "PickOne",
    "glo": [
        {
            "ident": "q0",
            "content": "Które z poniższych urządzeń miało ostatnio swoją premierę?",
            "fontSizeMobile": "18px",
            "fontSizeDesktop": "24px",
            "type": "text",
            "answers": [
                {
                    "ident": "a",
                    "content": "glo™ hyper+"
                },
                {
                    "ident": "b",
                    "content": "glo™ hyper + UNIQ"
                },
                {
                    "ident": "c",
                    "content": "glo™ hyper x2"
                },
                {
                    "ident": "d",
                    "content": "Żadne z powyższych"
                }
            ]
        },
        {
            "ident": "q1",
            "content": "Ile wariantów smakowych wkładów neo™ możesz kupić obecnie?",
            "fontSizeMobile": "18px",
            "fontSizeDesktop": "24px",
            "type": "text",
            "answers": [
                {
                    "ident": "a",
                    "content": "3"
                },
                {
                    "ident": "b",
                    "content": "6"
                },
                {
                    "ident": "c",
                    "content": "9"
                },
                {
                    "ident": "d",
                    "content": "12"
                }
            ]
        },
        {
            "ident": "q2",
            "content": "Ile urządzeń glo™ pozwala na personalizacje wyglądu za pomocą wymiennych paneli bocznych?",
            "fontSizeMobile": "18px",
            "fontSizeDesktop": "24px",
            "type": "text",
            "answers": [
                {
                    "ident": "a",
                    "content": "1"
                },
                {
                    "ident": "b",
                    "content": "2"
                },
                {
                    "ident": "c",
                    "content": "3"
                },
                {
                    "ident": "d",
                    "content": "4"
                }
            ]
        },
        {
            "ident": "q3",
            "content": "Ile punktów otrzymasz za każde skuteczne polecenie swojego pełnoletniego palącego znajomego w Alter Zone?",
            "fontSizeMobile": "18px",
            "fontSizeDesktop": "24px",
            "type": "text",
            "answers": [
                {
                    "ident": "d",
                    "content": "15"
                },
                {
                    "ident": "b",
                    "content": "50"
                },
                {
                    "ident": "c",
                    "content": "100"
                },
                {
                    "ident": "a",
                    "content": "150"
                }
            ]
        },
    ]
}