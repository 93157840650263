import sunset from "./img/sunset.png"
import scarlet from "./img/scarlet.png"
import summer from "./img/summer.png"
import violet from "./img/violet.png"
import arctic from "./img/arctic.png"
import ice from "./img/ice.png"
import newPack2 from "./img/newPack2.png"
import newPack3 from "./img/newPack3.png"
import newPack4 from "./img/newPack4.png"
import newPack5 from "./img/newPack5.png"
import newPack6 from "./img/newPack6.png"
import newPack7 from "./img/newPack7.png"
import img from "./img/img.png"

export const questions = {
    "gameType": "PickAndShow",
    "glo": [
        {
            "ident": "q0",
            "content": "neo™ Summer Yellow Click",
            img: img,
            "answers": [
                {
                    ident: "a",
                    name: "Ice Click",
                    copy: "Intensywne miętowe doświadczenie",
                    "pack": ice,
                    "newPack": newPack2,
                },
                {
                    ident: "b",
                    name: "Summer Yellow Click",
                    copy: "Nuty słodkiego melona z cytrusami i nuty tropikalnych owoców",
                    "pack": summer,
                    "newPack": newPack3,

                },
                {
                    ident: "c",
                    name: "Arctic Click",
                    copy: "Doświadczenie intensywnego mentolu oraz słodka miętowa nuta",
                    "pack": arctic,
                    "newPack": newPack4,
                },
                {
                    ident: "d",
                    name: "Scarlett Click",
                    copy: "Słodki posmak czerwonych owoców",
                    "pack": scarlet,
                    "newPack": newPack5,
                },
                {
                    ident: "e",
                    name: "Violet Click",
                    copy: "Słodki i świeży jagodowy aromat",
                    "pack": violet,
                    "newPack": newPack6,
                },
                {
                    ident: "f",
                    name: "Sunset Click",
                    copy: "Tropikalne świeże i słodkie nuty ananasa",
                    "pack": sunset,
                    "newPack": newPack7,
                },
            ],
        },
        {
            "ident": "q1",
            "content": "neo™ Scarlet Click",
            img: img,
            "answers": [
            ]
        },
    ]
}