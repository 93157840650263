import img from "./img/img.png"

export const questions = {
    "gameType": "Poll",
    "glo": [
        {
            "ident": "q0",
            "content": "Program Inspiration Club to połączenie obecnie istniejących programów lojalnościowych. Jakich?",
            "type": "oneWithInput",
            img: img,
            "correctAnswer": 3,
            "answers": [
                {
                    "ident": "a",
                    "content": "Alter Zone"
                },
                {
                    "ident": "b",
                    "content": "Alter Cloud"
                },
                {
                    "ident": "c",
                    "content": "eSmoking Club"
                },
                {
                    "ident": "d",
                    "content": "Wszystkich powyższych"
                },
            ]
        },
        {
            "ident": "q1",
            "content": "Czy w Inspiration Club będzie można zdobywać punkty za zakupy?",
            "type": "oneWithInput",
            img: img,
            "correctAnswer": 1,
            "answers": [
                {
                    "ident": "a",
                    "content": "Tak, ale tylko w weekendy"
                },
                {
                    "ident": "b",
                    "content": "Tak, w sklepach internetowych discloverglo.pl, velo.com oraz w punktach Inspiration Store i eSmoking World"
                },
                {
                    "ident": "c",
                    "content": "Nie, zakupy nie liczą się do programu"
                },
                {
                    "ident": "d",
                    "content": "Nie, tylko za aktywności"
                },
            ]
        },
        {
            "ident": "q2",
            "content": "Co się dzieje podczas przenoszenia konta z obecnej platformy do nowego programu Inspiration Club?",
            "type": "oneWithInput",
            "correctAnswer": 3,
            img: img,
            "answers": [
                {
                    "ident": "a",
                    "content": "Posiadane punkty są konwertowane i przenoszone"
                },
                {
                    "ident": "b",
                    "content": "Konto na obecnej platformie zostaje skasowane"
                },
                {
                    "ident": "c",
                    "content": "Niezrealizowane kupony w momencie przenoszenia są wysłane na maila"
                },
                {
                    "ident": "d",
                    "content": "Wszystkie odpowiedzi są prawidłowe"
                },
            ]
        },
        {
            "ident": "q3",
            "content": "Ile punktów otrzymuje się za pre-rejestrację w Inspiration Club?",
            "type": "oneWithInput",
            img: img,
            "correctAnswer": 1,
            "answers": [
                {
                    "ident": "a",
                    "content": "10 punktów"
                },
                {
                    "ident": "b",
                    "content": "25 punktów"
                },
                {
                    "ident": "c",
                    "content": "50 punktów"
                },
                {
                    "ident": "d",
                    "content": "100 punktów"
                },
            ]
        },
    ]
}