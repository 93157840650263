import ans1_1 from "./img/1.svg"
import ans1_2 from "./img/2.svg"
import ans1_3 from "./img/3.svg"

import ans2_1 from "./img/4.svg"
import ans2_2 from "./img/5.svg"
import ans2_3 from "./img/6.svg"

import pack1 from "./img/pack1.png"
import pack2 from "./img/pack2.png"

export const questions = {
    "gameType": "TitlesQuiz",
    "glo": [
        {
            "ident": "q0",
            "content": "Wybierz i zaznacz pasujące kafelki do paczki neo™ Lounge Click",
            "type": "image multi-select",
            "correctAnswers": ["a","b"],
            "img": pack1,
            "answers": [
                {
                    "ident": "a",
                    "content": "neo™ Terrazza Click",
                    img: ans1_1
                },
                {
                    "ident": "b",
                    "content": "neo™ Lounge Click",
                    img: ans1_2
                },
                {
                    "ident": "c",
                    "content": "neo™ Terrazza Click",
                    img: ans1_3
                },
            ]
        },
        {
            "ident": "q1",
            "content": "Wybierz i zaznacz pasujące kafelki do paczki neo™ Terrazza Click",
            "type": "image multi-select",
            "correctAnswers": ["b"],
            "img": pack2,
            "answers": [
                {
                    "ident": "a",
                    "content": "neo™ Terrazza Click",
                    img: ans2_1
                },
                {
                    "ident": "b",
                    "content": "neo™ Lounge Click",
                    img: ans2_2
                },
                {
                    "ident": "c",
                    "content": "neo™ Terrazza Click",
                    img: ans2_3
                },
            ]
        },

    ]
}