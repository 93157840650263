export const questions = {
    "gameType": "Poll",
    "glo": [
        {
            "ident": "q1",
            "content": "Co skłoniło Cię do dołączenia do programu Alter Zone?",
            "sub": "Wybierz jedną odpowiedź",
            "type": "oneWithInput",
            "answers": [
                {
                    "ident": "0",
                    "content": "Kod zniżkowy na glo"
                },
                {
                    "ident": "1",
                    "content": "Kody promocyjne na wkłady neo™"
                },
                {
                    "ident": "2",
                    "content": "Nagrody partnerskie",
                    "sub": "np. voucher Wyjątkowy prezent"
                },
                {
                    "ident": "3",
                    "content": "Chęć udziału w quizach i zadaniach"
                },
                {
                    "ident": "4",
                    "content": "Konkursy z nagrodami"
                },
                {
                    "ident": "5",
                    "content": "Inne",
                    "other": "Napisz, co Cię skłoniło"
                }
            ]
        },
        {
            "ident": "q2",
            "content": "Co najbardziej podoba Ci się w Alter Zone?",
            "sub": "Proszę zaznacz maksymalnie 3 najlepiej pasujące odpowiedzi: ",
            "type": "muliAnswer",
            "answers": [
                {
                    "ident": "0",
                    "content": "Możliwość zdobycia kodów zniżkowych na wkłady neo™"
                },
                {
                    "ident": "1",
                    "content": "Możliwość zdobycia nagród partnerskich"
                },
                {
                    "ident": "2",
                    "content": "Ciekawe zadania"
                },
                {
                    "ident": "3",
                    "content": "Duży wybór nagród"
                },
                {
                    "ident": "4",
                    "content": "Jasne zasady programu"
                },
                {
                    "ident": "5",
                    "content": "Łatwo osiągalne nagrody"
                },
                {
                    "ident": "6",
                    "content": "Benefity za polecanie glo™ pelnoletnim znajomym"
                },
                {
                    "ident": "7",
                    "content": "Inne",
                    "other": "Napisz, co Ci się podoba"
                },
            ]
        },
        {
            "ident": "q3",
            "content": "Czy bierzesz aktywny udział w innych programach lojalnościowych?",
            "type": "boolean",
            "answers": [
                {
                    "ident": "0",
                    "content": "Tak"
                },
                {
                    "ident": "1",
                    "content": "Nie"
                },
            ]
        },
        {
            "ident": "q4",
            "content": "Korzystając ze skali od zera do dziesięciu, na ile prawdopodobne jest, że polecisz program lojalnościowy Alter Zone swojemu znajomemu?",
            "sub": "0 oznacza, że na pewno nie polecisz, a 10 oznacza, że na pewno polecisz.",
            "type": "range",
            "answers": [
            ]
        },
        {
            "ident": "q5",
            "content": "Jakie kategorie nagród są dla Ciebie interesujące:",
            "type": "oneWithInput",
            "answers": [
                {
                    "ident": "0",
                    "content": "Zniżki na transport"
                },
                {
                    "ident": "1",
                    "content": "Rozrywka"
                },
                {
                    "ident": "2",
                    "content": "Zniżki na odzież"
                },
                {
                    "ident": "3",
                    "content": "Zniżki turystyczne"
                },
                {
                    "ident": "4",
                    "content": "Elektronika"
                },
                {
                    "ident": "5",
                    "content": "Kawiarnia/gastronomia"
                },
                {
                    "ident": "6",
                    "content": "Inne",
                    "other": "Jaka kategoria"
                },
            ]
        },
        {
            "ident": "q6",
            "content": "Jaka częstotliwość publikacji nowych punktowanych zadań byłaby dla Ciebie optymalna?",
            "type": "oneWithInput",
            "answers": [
                {
                    "ident": "0",
                    "content": "Raz na tydzień"
                },
                {
                    "ident": "1",
                    "content": "Raz na dwa tygodnie"
                },
                {
                    "ident": "2",
                    "content": "Raz na miesiąc"
                },
                {
                    "ident": "3",
                    "content": "Inne",
                    "other": "Napisz częstotliwość"
                },
            ]
        }
    ]
}