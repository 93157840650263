export const questions = {
    "gameType": "TrueFalsePickOneLayout",
    "glo": [
        {
            "ident": "q0",
            "content": "Jedyną różnicą między glo™ hyper+ UNIQ a glo™ hyper X2 to brak wymiennych paneli bocznych.",
            "type": "text",
            "answers": [
                {
                    "ident": "a",
                    "content": "Prawda"
                },
                {
                    "ident": "b",
                    "content": "Fałsz"
                }
            ]
        },
        {
            "ident": "q1",
            "content": "W glo™ hyper X2 pojawił się osobny przycisk BOOST z boku urządzenia, dzięki czemu możesz łatwo przełączać się między trybem podgrzewania.",
            "type": "text",
            "answers": [
                {
                    "ident": "a",
                    "content": "Prawda"
                },
                {
                    "ident": "b",
                    "content": "Fałsz"
                }
            ]
        },
        {
            "ident": "q2",
            "content": "Urządzenie glo™ hyper X2 znajdziesz w gamie 6 nowych kolorów, dzięki czemu dopasujesz go do swojego stylu.",
            "type": "text",
            "answers": [
                {
                    "ident": "a",
                    "content": "Prawda"
                },
                {
                    "ident": "b",
                    "content": "Fałsz"
                }
            ]
        }
    ]
}