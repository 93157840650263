import muzyka from "./img/muzyka.svg"
import moda from "./img/moda.svg"
import design from "./img/design.svg"

const konkurs5_lipiec_2023 = {
    design: [
        {
            img: design,
            place: "glo™ Hyper X2",
            content: "ze wzorem Design",
        },
    ],
    moda: [
        {
            img: moda,
            place: "glo™ Hyper X2",
            content: "ze wzorem Moda",
        },
    ],
    muzyka: [
        {
            img: muzyka,
            place: "glo™ Hyper X2",
            content: "ze wzorem Muzyka",
        },
    ],
}

export default konkurs5_lipiec_2023