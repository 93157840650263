import outdoor1 from "./img/outdoor1.png"
import outdoor2 from "./img/outdoor2.png"
import outdoor3 from "./img/outdoor3.png"
import portret1 from "./img/portret1.jpg"
import portret2 from "./img/portret2.jpg"
import portret3 from "./img/portret3.jpg"
import lifestyle1 from "./img/lifestyle1.png"
import lifestyle2 from "./img/lifestyle2.png"
import lifestyle3 from "./img/lifestyle3.png"

export const data = {
    outdoor: [
        [
            {
                name: "Sebastian J.",
                content: outdoor1,
            },
            {
                name: "Natalia K.",
                content: outdoor2,
            },
            {
                name: "Paulina S.",
                content: outdoor3,
            },
        ],
    ],
    portret: [
        [
            {
                name: "Stanisława M.",
                content: portret1,
            },
            {
                name: "Renia N.",
                content: portret2,
            },
            {
                name: "Ewelina T.",
                content: portret3,
            },
        ],
    ],
    lifestyle: [
        [
            {
                name: "Andrii B.",
                content: lifestyle1,
            },
            {
                name: "Anna J.",
                content: lifestyle2,
            },
            {
                name: "Adam B.",
                content: lifestyle3,
            },
        ],
    ],
}