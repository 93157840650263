import movie from "./img/movie.svg"
import bag from "./img/bag.svg"
import vacation from "./img/vacation.svg"

const konkurs3_czerwiec_2023 = {
    Outdoor: [
        {
            img: vacation,
            place: "1",
            content: "Voucher Wakacje.pl o wartości 1000 zł",
        },
        {
            img: vacation,
            place: "2-3",
            content: "Voucher Wakacje.pl o wartości 500 zł"
        },
    ],
    Lifestyle: [
        {
            img: movie,
            place: "1",
            content: "Dwie karty Unlimited Cinema City ",
        },
        {
            img: movie,
            place: "2-3",
            content: "Jedna karty Unlimited Cinema City "
        },
    ],
    glo: [
        {
            img: bag,
            place: "1",
            content: "Karta Edenred o wartości 1000 zł",
        },
        {
            img: bag,
            place: "2-3",
            content: "Karta Edenred o wartości 500 zł"
        },
    ],
}

export default konkurs3_czerwiec_2023