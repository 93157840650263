import Mobile from "./img/bg-mobile.jpg"
import Desktop from "./img/bg-desktop.jpg"
import { questions } from "./questions"

export const blizsze_poznanie = {
    header: "Letnie wyczucie",
    backgroundMobile: Mobile,
    backgroundDesktop: Desktop,
    title: "Letnie wyczucie",
    subtitle: "Sprawdźmy to.",
    message: "Odpowiedz na 3 pytania, wybierając poprawną odpowiedź.",
    points: 15,
    statute: "/assets/Regulamin Aktywności_Bliższe_Poznanie_14.02.pdf",

    ident: "sierpien1_2022",
    gameType: "SummerGame",
    questions: questions,

    correct: {
        title: "Bezbłędnie!",
        content: [
            "Widać, że jesteście ze sobą blisko \ni znasz glo™ jak własną kieszeń. Dlaczego by tego nie wykorzystać \ni zdobyć kolejne punkty?",
            "Poleć glo™ pełnoletniemu, palącemu znajomemu",
        ]
    },
    wrong: {
        title: "Małe potknięcie",
        content: [
            "Nie martw się. Cały czas masz szansę zdobyć 15 punktów. Wróć do aktywności ponownie i spróbuj raz jeszcze.?",
            // "Chcesz skorzystać z małej pomocy?",
        ]
    }
}