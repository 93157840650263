import pack1 from "./img/pack1.png"
import pack2 from "./img/pack2.png"
import pack3 from "./img/pack3.png"
import pack4 from "./img/pack4.png"
import pack5 from "./img/pack5.png"
import pack6 from "./img/pack6.png"
import pack7 from "./img/pack7.png"
import newPack1 from "./img/newPack1.png"
import newPack2 from "./img/newPack2.png"
import newPack3 from "./img/newPack3.png"
import newPack4 from "./img/newPack4.png"
import newPack5 from "./img/newPack5.png"
import newPack6 from "./img/newPack6.png"
import newPack7 from "./img/newPack7.png"

export const questions = {
    "gameType": "PickAndReveal",
    "glo": [
        {
            "ident": "q0",
            "content": "neo™ Summer Yellow Click",
            "answers": [
                {
                    ident: "a",
                    copy: "Green Click",
                    "pack": pack1,
                    "newPack": newPack1,
                },
                {
                    ident: "b",
                    copy: "Ice Click",
                    "pack": pack2,
                    "newPack": newPack2,
                },
                {
                    ident: "c",
                    copy: "Summer Yellow Click",
                    "pack": pack3,
                    "newPack": newPack3,

                },
                {
                    ident: "d",
                    copy: "Arctic Click",
                    "pack": pack4,
                    "newPack": newPack4,
                },
                {
                    ident: "e",
                    copy: "Scarlett Click",
                    "pack": pack5,
                    "newPack": newPack5,
                },
                {
                    ident: "f",
                    copy: "Violet Click",
                    "pack": pack6,
                    "newPack": newPack6,
                },
                {
                    ident: "g",
                    copy: "Sunset Click",
                    "pack": pack7,
                    "newPack": newPack7,
                },
            ],
        },
        {
            "ident": "q1",
            "content": "neo™ Scarlet Click",
            "answers": [
            ]
        },
        {
            "ident": "q2",
            "content": "neo™ Ice Click",
            "answers": [
            ]
        },
    ]
}