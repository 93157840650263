import React, { useEffect, useState } from 'react'
import Button from '../../components/Button/Button'
import { useUserState } from '../../UserContext'
import InputMask from 'react-input-mask'
import _ from 'lodash'
import { showErrorPopup, translateError } from '../../components/ErrorHandling'
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Grid } from "swiper/core";
import SwipeImg from "./img/swipe.svg"
import FinishSvg from "./img/finish-svg.svg"
import Arrow from "./img/oragne-arrow.svg"
import FormImg from "./img/form-img.jpg"
import DateImg from "./img/date-img.jpg"
import FinishImg from "./img/finish-img.jpg"
import "swiper/css";
import "swiper/css/grid";
import "./style.scss"
import { ReactComponent as Close } from '../../components/Popup/img/close.svg';
import { animated, useTransition } from '@react-spring/web'
import { usePopper } from 'react-popper'
import { useWindowSize } from '../../Helpers'


SwiperCore.use([Grid]);

export default function SubscriptionForm() {
  const { userDetails } = useUserState()

  const preparedPhoneNumber = userDetails.mobilePhone ? userDetails.mobilePhone.includes("+48") ?
    userDetails.mobilePhone.replace("+48", "") : userDetails.mobilePhone.slice(0, 2) === "48" ?
      userDetails.mobilePhone.slice(2,) : userDetails.mobilePhone : ""

  const [firstName, setFirstName] = useState(userDetails.firstName ?? "")
  const [lastName, setLastName] = useState(userDetails.lastName ?? "")
  const [mobilePhone, setMobilePhone] = useState(preparedPhoneNumber)
  const [emailAddress, setEmailAddress] = useState(userDetails.emailAddress ?? "")
  const [street, setStreet] = useState("")
  const [buildingNumber, setBuildingNumber] = useState("")
  const [apartmentNumber, setApartmentNumber] = useState("")
  const [city, setCity] = useState("Warszawa")
  const [postalCode, setPostalCode] = useState("")
  const [postalCodePrefix, setPostalCodePrefix] = useState("")
  const [errors, setErrors] = useState({})
  const [loosedFocus, setLoosedFocus] = useState({})
  const [currentStep, setCurrentStep] = useState("form")
  const [slots, setSlots] = useState([])
  const [selectedSlot, setSelectedSlot] = useState({})
  const [selectedSlotUUID, setSelectedSlotUUID] = useState("")

  const [popupOpen, setPopupOpen] = useState(true)
  const [popup, setPopup] = useState(null)
  const [box, setBox] = useState(null)

  const { width } = useWindowSize()

  const { styles, attributes } = usePopper(box, popup, {
    placement: 'top',
    modifiers: [
      {
        name: "offset",
        options: {
          offset: [width < 1020 ? 0 : width < 1280 ? 40 : 110, -40]
        }
      },
      {
        name: "eventListeners",
        enabled: popupOpen
      },
      {
        name: "preventOverflow",
        options: {
          altAxis: true,
          padding: { top: 100, left: width < 1020 ? 24 : 460, right: 0 }
        }
      }
    ]
  });

  const transitionPopup = useTransition(popupOpen, {
    from: {
      opacity: 0,
      scale: 0,
    },
    enter: {
      opacity: 1,
      width: "100%",
      scale: 1,
    },
    leave: {
      opacity: 0,
      scale: 0,
    }
  })

  const closePopup = () => {
    setPopupOpen(false)
  }

  useEffect(() => {
    if ([firstName, lastName, emailAddress].some(e => e === "") && Object.keys(userDetails).length !== 0 && currentStep === "form") {
      setFirstName(userDetails.firstName)
      setLastName(userDetails.lastName)

      const preparedPhoneNumber = userDetails.mobilePhone ? userDetails.mobilePhone.includes("+48") ?
        userDetails.mobilePhone.replace("+48", "") : userDetails.mobilePhone.slice(0, 2) === "48" ?
          userDetails.mobilePhone.slice(2,) : userDetails.mobilePhone : ""

      setMobilePhone(preparedPhoneNumber)
      setEmailAddress(userDetails.emailAddress)
    }

    if (userDetails.firstName && currentStep === "date") {
      getSlots()
    }

  }, [userDetails, currentStep])

  const focus = _.debounce((name) => {
    setTimeout(() => {
      document.querySelector("*[name='" + name + "']")?.scrollIntoView({ behavior: "smooth", block: "center" })
      setTimeout(() => {
        document.querySelector("*[name='" + name + "']")?.focus()
      }, 500)
    }, 300)
  }, 200)

  const scrollTop = () => {
    document.querySelector(".app").scrollTo({ top: 0, left: 0, behavior: 'smooth' })
  }

  const inlineValidate = (e) => {
    const target = e.target
    const targetName = target.name
    let focus = loosedFocus
    focus[targetName] = true
    validateInput(e.target)
    setLoosedFocus(focus)
  }

  const validateInput = (i) => {
    const input = i
    const name = input.name
    const value = input.type === "checkbox" || input.type === "radio" ? input.checked : input.value
    const regex = input.dataset?.regex || false
    const comment = input.dataset?.regexcomment || false
    const emptyComment = input.dataset?.emptycomment || false
    const lengthComment = input.dataset?.lengthcomment || false
    const minLength = input.dataset?.minlength || 0
    const checkSpaces = input.dataset?.checkspaces || false
    const checkSpacesComment = input.dataset?.checkspacescomment || false

    if (![name] || value === "" || value === false || value === "false") {
      let err = errors
      err[name] = i.required ? emptyComment ? emptyComment : input.type === "checkbox" ? "Zgoda wymagana" : "Pole wymagane" : ""
      setErrors({ ...err })
      // focus()
      return false
    } else if (checkSpaces && value.indexOf(" ") !== -1) {
      let err = errors
      err[name] = checkSpacesComment ? checkSpacesComment : "Nie może zawierać spacji"
      setErrors({ ...err })
      // focus()
      return false
    } else if (regex && !new RegExp(regex).test(value)) {
      let err = errors
      err[name] = comment ? comment : "Nieprawidłowa wartość"
      setErrors({ ...err })
      // focus()
      return false
    } else if (input.type === "date") {
      let date = new Date(JSON.parse(window.sessionStorage.getItem("token")).expires.date.toString().replace(" ", "T"))
      let selectedDate = new Date(value)
      if (date < selectedDate) {
        let err = errors
        err[name] = "Podana data nie może być późniejsza niż data dzisiejsza. Wybierz poprawną datę."
        setErrors({ ...err })
        return false
      }
      let err = errors
      err[name] = false
      setErrors({ ...err })
      return true
    } else {
      let err = errors
      err[name] = false
      setErrors({ ...err })
      return true
    }
  }

  const validateForm = (e, callback, formClass = "") => {
    e.preventDefault()
    let wrongCounter = 0
    const inputs = document.querySelectorAll("form" + (formClass ? "." + formClass : "") + " *:required")

    if (inputs && inputs.length > 0) {
      for (let i = inputs.length - 1; i >= 0; i--) {
        const input = inputs[i];
        if (!validateInput(input)) {
          wrongCounter++
        }
      }

      const focusArray = [...inputs].filter(e => errors[e.name] ?? false)

      if (focusArray?.[0]) {
        focus(focusArray[0]?.name)
      }

      if (wrongCounter === 0) {
        callback()
        scrollTop()
      }
    }
  }

  function getSlots() {
    fetch(process.env.REACT_APP_API_URL + "/alterzone/subscription/slots", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + JSON.parse(sessionStorage.getItem('token'))?.uuid,
        'X-Mode-Local': sessionStorage.getItem('devParams') ? true : false,
      },
      body: JSON.stringify({})
    })
      .then((response) => {
        return response.json()
      })
      .then(
        (result) => {
          if (result.status.success) {
            setSlots(result.data.slice(0, 14))
          } else {
            showErrorPopup(translateError(result.data.error), result.meta.ts)
          }
        },
        (error) => {
          showErrorPopup(translateError("generic"))
          console.log(error)
        }
      )
  }

  const sendForm = () => {
    const payload = {
      "subscription": {
        "slot": {
          "uuid": selectedSlotUUID
        }
      },
      "consumer": {
        firstName,
        lastName,
        mobilePhone,
        street,
        buildingNumber,
        apartmentNumber,
        city,
        postalCode: `${postalCodePrefix}-${postalCode}`,
      }
    }

    fetch(process.env.REACT_APP_API_URL + "/alterzone/subscription/send", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + JSON.parse(sessionStorage.getItem('token'))?.uuid,
        'X-Mode-Local': sessionStorage.getItem('devParams') ? true : false,
      },
      body: JSON.stringify(payload)
    })
      .then((response) => {
        return response.json()
      })
      .then(
        (result) => {
          if (result.status.success) {
            setCurrentStep("finish")
          } else {
            showErrorPopup(translateError(result.data.error), result.meta.ts)
            getSlots()
          }
        },
        (error) => {
          showErrorPopup(translateError("generic"))
          getSlots()
          console.log(error)
        }
      )
  }

  return (
    <section className={`subscription-form-page ${currentStep}`}>
      <div className="header">
        <button>
          <div className="inner">
            <svg width="14" height="24" viewBox="0 0 14 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M11.981 5.05634e-05C12.3345 5.05634e-05 12.7137 0.126438 12.9921 0.404895C13.548 0.960799 13.548 1.84551 12.9921 2.40142L3.41247 12.0069L12.9921 21.5866C13.548 22.1425 13.548 23.0272 12.9921 23.5831C12.4362 24.139 11.5515 24.139 10.9956 23.5831L0.404845 13.0178C0.126388 12.7394 0 12.3859 0 12.0067C0 11.6276 0.15206 11.2741 0.404845 10.9956L10.9956 0.404845C11.2741 0.126388 11.6276 0 11.981 0V5.05634e-05Z" fill="#ED8002" />
            </svg>
          </div>
        </button>
        <h2>Subskrypcje</h2>
      </div>
      <div className="desktop-layout">
        <div className="side">
          <ul>
            <li>
              <div className="number">
                <div className={`inner ${currentStep === "form" ? "active" : ""}`}>
                  <span>1</span>
                </div>
              </div>
              <div className={`text ${currentStep === "form" ? "active" : ""}`}>
                <h4>Dane adresowe</h4>
                <p>Podaj dane i wskaż miejsce spotkania z&nbsp;kurierem.</p>
              </div>
            </li>
            <li>
              <div className="number">
                <div className={`inner ${currentStep !== "form" ? "active" : ""}`}>
                  <span>2</span>
                </div>
              </div>
              <div className={`text ${currentStep !== "form" ? "active" : ""}`}>
                <h4>Wybierz termin</h4>
                <p>Umów się na konkretny dzień i&nbsp;dogodny przedział czasowy.</p>
              </div>
            </li>
          </ul>
          <img src={currentStep === "form" ? FormImg : currentStep === "date" ? DateImg : FinishImg} alt="" />
        </div>
        {currentStep === "form" && <div className="content">
          <h2>Dane adresowe</h2>
          <form method='POST'>
            <label htmlFor="firstName">
              <p>Imię</p>
              <input type="text" maxLength={40} value={firstName} onChange={(e) => { if (loosedFocus["firstName"]) inlineValidate(e); setFirstName(e.target.value); }} onBlur={(e) => { if (!loosedFocus["firstName"]) inlineValidate(e) }} id="firstName" name='firstName' required />
              {errors['firstName'] ? <p className="error">{errors['firstName']}</p> : null}
            </label>
            <label htmlFor="lastName">
              <p>Nazwisko</p>
              <input type="text" maxLength={40} value={lastName} onChange={(e) => { if (loosedFocus["lastName"]) inlineValidate(e); setLastName(e.target.value); }} onBlur={(e) => { if (!loosedFocus["lastName"]) inlineValidate(e) }} id="lastName" name="lastName" required />
              {errors['lastName'] ? <p className="error">{errors['lastName']}</p> : null}
            </label>
            <label htmlFor="mobilePhone">
              <p>Numer telefonu</p>
              <input type="text" maxLength={40} value={mobilePhone} onChange={(e) => { if (loosedFocus["mobilePhone"]) inlineValidate(e); if (e.target.value === '' || /^[0-9\b]+$/.test(e.target.value)) setMobilePhone(e.target.value); }} onBlur={(e) => { if (!loosedFocus["mobilePhone"]) inlineValidate(e) }} id="mobilePhone" name="mobilePhone" placeholder='np. 600 123 456' required />
              {errors['mobilePhone'] ? <p className="error">{errors['mobilePhone']}</p> : null}
            </label>
            <label htmlFor="emailAddress">
              <p>Adres e-mail</p>
              <input type="text" maxLength={40} value={emailAddress} onChange={(e) => { if (loosedFocus["emailAddress"]) inlineValidate(e); setEmailAddress(e.target.value); }} onBlur={(e) => { if (!loosedFocus["emailAddress"]) inlineValidate(e) }} id="emailAddress" name="emailAddress" required />
              {errors['emailAddress'] ? <p className="error">{errors['emailAddress']}</p> : null}
            </label>
            <label htmlFor="street">
              <p>Ulica</p>
              <input type="text" maxLength={40} value={street} onChange={(e) => { if (loosedFocus["street"]) inlineValidate(e); setStreet(e.target.value); }} onBlur={(e) => { if (!loosedFocus["street"]) inlineValidate(e) }} id='street' name='street' placeholder='np. Warszawska' required />
              {errors['street'] ? <p className="error">{errors['street']}</p> : null}
            </label>
            <div className="input-flex">
              <label htmlFor="buildingNumber">
                <p>Nr domu</p>
                <input type="text" maxLength={5} value={buildingNumber} onChange={(e) => { if (loosedFocus["buildingNumber"]) inlineValidate(e); setBuildingNumber(e.target.value); }} onBlur={(e) => { if (!loosedFocus["buildingNumber"]) inlineValidate(e) }} id="buildingNumber" name="buildingNumber" placeholder='np. 1' data-regex="^\d+(?:[a-zA-Z]+)?" required />
                {errors['buildingNumber'] ? <p className="error">{errors['buildingNumber']}</p> : null}
              </label>
              <label htmlFor="apartmentNumber">
                <p>Nr mieszkania</p>
                <input type="text" maxLength={5} value={apartmentNumber} onChange={(e) => { if (loosedFocus["apartmentNumber"]) inlineValidate(e); setApartmentNumber(e.target.value); }} onBlur={(e) => { if (!loosedFocus["apartmentNumber"]) { inlineValidate(e) } if (apartmentNumber.length === 0) { let newErrors = { ...errors }; delete newErrors.apartmentNumber; setErrors(newErrors) } }} id='apartmentNumber' name='apartmentNumber' placeholder='np. 7' data-regex="^\d+(?:[a-zA-Z]+)?" />
                {errors['apartmentNumber'] ? <p className="error">{errors['apartmentNumber']}</p> : null}
              </label>
            </div>
            <div className="input-flex">
              <label htmlFor="postalCode" ref={setBox}>
                <p>Kod pocztowy</p>
                <div className="input-wrapper">
                  <select id="postalCodePrefix" name="postalCodePrefix" value={postalCodePrefix} onChange={e => { if (loosedFocus["postalCodePrefix"]) inlineValidate(e); setPostalCodePrefix(e.target.value) }} onBlur={(e) => { if (!loosedFocus["postalCodePrefix"]) inlineValidate(e) }} data-regex="^[0-9][1-9]" disabled={!!popupOpen} required>
                    <option value="00">00</option>
                    <option value="01">01</option>
                    <option value="02">02</option>
                    <option value="03">03</option>
                    <option value="04">04</option>
                    <option value="05">05</option>
                  </select>
                  <input type="text" maxLength={3} minLength={3} value={postalCode} onChange={e => { if (loosedFocus["postalCode"]) inlineValidate(e); if (e.target.value === '' || /^[0-9\b]+$/.test(e.target.value)) setPostalCode(e.target.value); }} onBlur={(e) => { if (!loosedFocus["postalCode"]) inlineValidate(e) }} id="postalCode" name="postalCode" placeholder='np.234' disabled={!!popupOpen} required />
                </div>
                {errors['postalCode'] || errors['postalCodePrefix']  ? <p className="error">{errors['postalCode'] || errors['postalCodePrefix']}</p> : null}
              </label>
              <label htmlFor="city">
                <p>Miasto</p>
                <input maxLength={40} type="text" value={city} onChange={() => { }} id='city' required />
                <button className='popup-open-btn' onClick={e => { e.preventDefault(); setPopupOpen(prev => !prev) }}>
                  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="8" cy="8" r="8" fill="#ED8002" />
                    <path d="M7.16867 6.03919H8.82467V4.61719H7.16867V6.03919ZM7.15967 11.5022H8.83367V6.64219H7.15967V11.5022Z" fill="white" />
                  </svg>
                </button>

                {/* {errors['firstName'] ? <p className="error">{errors['firstName']}</p> : null} */}
              </label>
            </div>
            {transitionPopup((style, item) => (
              item ?
                <animated.div className='popup-window' ref={setPopup}  {...attributes} style={{ ...style, ...styles.popper }}>
                  <div className="popup-inner">
                    <div className="top">
                      <p className="popup-p"></p>
                      <button onClick={e => { e.preventDefault(); closePopup(e) }} className="popup-close">
                        <Close />
                      </button>
                    </div>
                    <div className="popup-wrapper">
                      <p className="popup-p">Pilotażowa akcja odbywa się tylko na terenie <strong>Warszawy</strong>, wkrótce będzie można zamawiać subskrypcje na terenie całego kraju.</p>
                    </div>
                  </div>
                </animated.div>
                : ""
            ))}
            <Button action={e => { validateForm(e, () => setCurrentStep("date")) }}>Przejdź dalej</Button>
          </form>
        </div>}
        {currentStep === "date" && <div className="content">
          <div className="title-flex">
            <h2>Wybierz termin</h2>
            <img src={SwipeImg} alt="" />
          </div>
          <Swiper className="subscription-slot-swiper"
            spaceBetween={16}
            slidesPerView="auto"
            grid={{
              rows: 2,
              fill: "row",
            }}
            modules={[Grid]}

          //  breakpoints={{
          //    720: {
          //      slidesPerView: 2
          //    },
          //    1280: {
          //      slidesPerView: 1
          //    }
          //  }}

          //  initialSlide={plan}
          //  onSlideChange={index => handlePlanChange(index.snapIndex)}
          //  ref={swiperRef}
          >
            {
              slots.map((element, index) => {
                const date = new Date(element.date.replace(" ", "T"))
                const dayOfWeek = ["nd", "pon", "wt", "śr", "czw", "pt", "so"][date.getDay()]
                const dateString = date.toLocaleDateString("pl-PL", {
                  month: "2-digit",
                  day: "2-digit",
                })
                return (
                  <SwiperSlide key={index}>
                    <button className={selectedSlot === element ? "selected" : ""} onClick={() => { setSelectedSlot(element); setSelectedSlotUUID("") }} disabled={!element.available}>
                      <div className="inner">
                        <h4>{dateString}</h4>
                        <p>{dayOfWeek}</p>
                      </div>
                    </button>
                  </SwiperSlide>
                )
              })
            }
          </Swiper>
          <div className="title-flex">
            <h3>Wybierz przedział czasowy</h3>
            <img src={SwipeImg} alt="" />
          </div>
          <Swiper className="subscription-slot-swiper slot-hour"
            spaceBetween={16}
            slidesPerView="auto"

          //  breakpoints={{
          //    720: {
          //      slidesPerView: 2
          //    },
          //    1280: {
          //      slidesPerView: 1
          //    }
          //  }}

          //  initialSlide={plan}
          //  onSlideChange={index => handlePlanChange(index.snapIndex)}
          //  ref={swiperRef}
          >
            {
              selectedSlot.slots && selectedSlot.slots.map((element, index) => {
                const dayTime = element.hours.includes("10") || element.hours.includes("12") ? "Rano" : "Wieczorem"

                return (
                  <SwiperSlide key={index}>
                    <button className={selectedSlotUUID === element.uuid ? "selected" : ""} onClick={() => setSelectedSlotUUID(element.uuid)} disabled={!element.available}>
                      <div className="inner">
                        <h4>{element.hours.replace(" ", "")}</h4>
                        <p>{dayTime}</p>
                      </div>
                    </button>
                  </SwiperSlide>
                )
              })
            }
          </Swiper>
          <Button action={() => sendForm()} disabled={Object.keys(selectedSlot).length === 0 || selectedSlotUUID.length === 0}>Umów się</Button>
        </div>}
        {currentStep === "finish" && <div className="content">
          <img src={FinishSvg} alt="" />
          <h2>Super!</h2>
          <p className="info">Umawiamy Cię z kurierem</p>
          <div className="next">
            <h4>Co dalej?</h4>
            <div className="flex-wrapper">
              <div>
                <p>Na Twój adres e-mail otrzymasz <strong>potwierdzenie terminu</strong> spotkania z&nbsp;kurierem.</p>
              </div>
              <img src={Arrow} alt="" />
              <div>
                <p>W jego obecności <strong>wybierzesz rodzaj pakietu oraz dowolne wkłady neo™,</strong> a&nbsp;następnie podpiszesz umowę.</p>
              </div>
            </div>
          </div>
          <h4 className='remind'>Pamiętaj!</h4>
          <p>Jeśli chcesz zdobyć <strong>punkty w Alter Zone</strong> podczas podpisywania umowy podaj swój <strong>adres e-mail</strong>, którego używasz do logowania.</p>
          <Button href="/">Wróć na stronę głowną</Button>
        </div>}
      </div>
    </section>
  )
}
