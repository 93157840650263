import React, { useEffect, useState } from "react"
import useCountDown from 'react-countdown-hook';
// import { useNavigate } from "react-router";
import { useUserDispatch, useUserState, logout, refreshToken } from '../../UserContext'
import "./style.scss"

const Timer = () => {
  const [timeLeft, { start }] = useCountDown(30000, 1000);

  useEffect(() => {
    start();
    //eslint-disable-next-line
  }, []);

  return (
    <p>Sesja wygaśnie za: <span>{(timeLeft / 1000).toFixed(0)}</span> sekund.</p>
  )
}

export default function LogoutWarning () {
  // const navigate = useNavigate()
  const { token } = useUserState()
  const dispatch = useUserDispatch()
  const [logoutWarning, setLogoutWarning] = useState(false)

  const handleLogout = () => {
    // navigate("/wylogowano", { replace: true })
    logout(dispatch)
  }

  let tokenTimer = null
  let warningTimer = null
  let timeTimer = null

  const checkToken = () => {
    const tokenTimestamp = (token?.expires?.timestamp)*1000

    if(tokenTimestamp) {
      const tokenValidTo = new Date(tokenTimestamp).getTime()
      const now = new Date().getTime()
      const tokenExpiresIn = parseInt((tokenValidTo - now))

      tokenTimer = setTimeout(() => {
        handleLogout()
      }, tokenExpiresIn-5000)

      tokenTimer = setTimeout(() => {
        if(localStorage.getItem("rememberMe") && localStorage.getItem("refreshToken")) {
          refreshToken(dispatch, localStorage.getItem("refreshToken"))
        } else {
          setLogoutWarning(true)
        }
      }, tokenExpiresIn-35000)
    } else {
      clearTimeout(tokenTimer)
      clearTimeout(warningTimer)
      clearInterval(timeTimer)
      setLogoutWarning(false)
    }
  }

  useEffect(() => {
    checkToken()
    // eslint-disable-next-line
  }, [token.uuid])

  return (
    <>
      { logoutWarning ? <div className="logout-warning"><Timer/></div> : null }
    </>
  )
}
