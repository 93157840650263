import Img0 from "./img/img0.jpg"
import Img0Desktop from "./img/img0-desktop.jpg"
import Img1 from "./img/img1.jpg"
import Img1Desktop from "./img/img1-desktop.jpg"
import BackgroundDesktop from "./img/background.jpg"

export const questions = {
    "gameType": "FindPacks",
    "glo": [
        {
            "ident": "q0",
            "content": "Wkłady neo™ to szeroka oferta wariantów, dostępna w kilku smakowych kategoriach",
            "sub": "Jakie to?",
            "type": "question",
            "backgroundDesktop": BackgroundDesktop,
            "answers": [
                {
                    "ident": "a",
                    "content": "Tytoniowe"
                },
                {
                    "ident": "b",
                    "content": "Z podwójną kapsułą, Tytoniowe"
                },
                {
                    "ident": "c",
                    "content": "Mentolowe, Owocowe"
                },
                {
                    "ident": "d",
                    "content": "Z podwójną kapsułką, Tytoniowe, Mentolowe, Owocowe"
                },
            ]
        },
        {
            "ident": "q1",
            "content": "Znajdź wszystkie paczki neo™ na zdjęciu",
            "type": "find",
            "imgMobile": Img0,
            "imgDesktop": Img0Desktop,
            "answers": [
                {
                    "ident": "ce83e305-b808-413d-8ff2-167ef10c635b",
                    "clipPathMobile": "circle(5% at 46.8% 86%)",
                    "clipPathDesktop": "circle(4.1% at 47% 74%)"
                },
                {
                    "ident": "cd2bb32d-a042-4546-b720-abbb33bffd4d",
                    "clipPathMobile": "circle(5% at 44.6% 72.9%)",
                    "clipPathDesktop": "circle(4.1% at 74% 86%)"
                },
                {
                    "ident": "2bfd3ea4-1f73-44dc-8dce-ec1ca06d2787",
                    "clipPathMobile": "circle(5% at 94.4% 82.4%)",
                    "clipPathDesktop": "circle(4.1% at 48.3% 90.7%)"
                },
            ]
        },
        {
            "ident": "q2",
            "content": "Znajdź wszystkie paczki neo™ na zdjęciu",
            "type": "find",
            "imgMobile": Img1,
            "imgDesktop": Img1Desktop,
            "answers": [
                {
                    "ident": "5ab86fa2-b83e-4efb-ade5-98c948794dcf",
                    "clipPathMobile": "circle(5% at 25% 96%)",
                    "clipPathDesktop": "circle(4.1% at 43% 96%)"
                },
                {
                    "ident": "f02f8b11-6a25-44d5-b7d3-bbc9aa4df6c5",
                    "clipPathMobile": "circle(5% at 91.5% 74%)",
                    "clipPathDesktop": "circle(4.1% at 73% 73%)"
                },
            ]
        }
    ]
}