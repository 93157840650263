export const questions = {
    "gameType": "PickOne",
    "glo": [
        {
            "ident": "q0",
            "content": "Otwierasz swoje świeżo kupione urządzenie. Co zawiera zestaw startowy glo™ hyper X2?",
            fontSize: "18px",
            "type": "checkbox",
            "answers": [
                {
                    "ident": "a",
                    content: "Podgrzewacz wkładów tytoniowych"
                },
                {
                    "ident": "b",
                    content: "Kabel USB-C"
                },
                {
                    "ident": "c",
                    content: "Szczoteczka do czyszczenia"
                },
                {
                    "ident": "d",
                    content: "Wszystkie trzy rzeczy"
                },
            ]
        },
        {
            "ident": "q1",
            "content": "Podgrzewacz wkładów tytoniowych glo™ hyper X2 posiada dwa tryby podgrzewania: STANDARD i BOOST. Przez ile sekund należy przytrzymać przycisk, aby rozpocząć sesję podgrzewania?",
            fontSize: "18px",
            "type": "checkbox",
            "answers": [
                {
                    "ident": "a",
                    content: "2 sekundy"
                },
                {
                    "ident": "b",
                    content: "3 sekundy"
                },
                {
                    "ident": "c",
                    content: "4 sekundy"
                },
                {
                    "ident": "d",
                    content: "5 sekund"
                },
            ]
        },
        {
            "ident": "q2",
            "content": "Udało Ci się włączyć wybrany tryb i rozpocząć proces nagrzewania. Kiedy możesz zacząć korzystać z urządzenia?",
            fontSize: "18px",
            "type": "checkbox",
            "answers": [
                {
                    "ident": "a",
                    content: "Po minucie od wybrania trybu"
                },
                {
                    "ident": "b",
                    content: "Gdy wszystkie światełka LED zaświecą się, a glo™ zawibruje jeden raz"
                },
                {
                    "ident": "c",
                    content: "Gdy glo™ zacznie stale wibrować przez 10 sekund"
                },
                {
                    "ident": "d",
                    content: "LED zapali się na kolor zielony"
                },
            ]
        },
        {
            "ident": "q3",
            "content": "Podczas korzystania z glo™ hyper X2 urządzenie zawibrowało, a wyświetlacz LED zapulsował. Co to może oznaczać?",
            fontSize: "18px",
            "type": "checkbox",
            "answers": [
                {
                    "ident": "a",
                    content: "Temperatura baterii przekroczyła maksymalny limit"
                },
                {
                    "ident": "b",
                    content: "Niski poziom baterii"
                },
                {
                    "ident": "c",
                    content: "Do końca sesji pozostało 10 sekund"
                },
                {
                    "ident": "d",
                    content: "Urządzenie należy wyczyścić"
                },
            ]
        },
    ]
}