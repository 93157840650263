export const questions = {
    "gameType": "TrueFalsePickOneLayout",
    "glo": [
        {
            "ident": "q0",
            "content": "We wszystkich punktach sieci własnej eSmoking World znajdziesz specjalne kosze, do których możesz wrzucić swój podgrzewacz glo™.",
            "type": "text",
            "answers": [
                {
                    "ident": "a",
                    "content": "Prawda"
                },
                {
                    "ident": "b",
                    "content": "Fałsz"
                }
            ]
        },
        {
            "ident": "q1",
            "content": "Elektroniczne odpady muszą być poddawane recyklingowi w odpowiedni sposób, tak aby toksyczne substancje nie trafiły do naszego środowiska.",
            "type": "text",
            "answers": [
                {
                    "ident": "a",
                    "content": "Prawda"
                },
                {
                    "ident": "b",
                    "content": "Fałsz"
                }
            ]
        },
        {
            "ident": "q2",
            "content": "Twoje urządzenie musi być starsze niż 3 lata, aby móc oddać je do recyklingu.",
            "type": "text",
            "answers": [
                {
                    "ident": "a",
                    "content": "Prawda"
                },
                {
                    "ident": "b",
                    "content": "Fałsz"
                }
            ]
        }
    ]
}