import { useEffect, useState } from "react"
import Logo from "../../components/Header/img/alterzone-logo-white.svg"
import { getUrlParam, parseUrlParams } from "../../UrlParamsHelper"
import { Link, useNavigate, useParams } from 'react-router-dom'
import "./style.scss"
import { showErrorPopup, translateError } from "../../components/ErrorHandling"
import Button from "../../components/Button/Button"
import { Swiper, SwiperSlide } from "swiper/react";
import Valid from "../LoginPage/img/valid.svg"
import NotValid from "../LoginPage/img/not-valid.svg"
import MaszWybor from "./img/maszwybor-logo.svg"
import Present from "./img/present.svg"
import _ from 'lodash'
import { useReadOTP } from "react-read-otp";
import useCountDown from 'react-countdown-hook';

import InputMask from "react-input-mask";

import "swiper/css";

import "swiper/css/pagination"
import "./style.scss"

import SwiperCore, {
  Autoplay, Pagination, Navigation
} from 'swiper';
import { leadingZero, sendFloodEvent } from "../../Helpers"

SwiperCore.use([Autoplay, Pagination, Navigation]);

const Timer = ({onFinished}) => {
  const [timeLeft, { start, reset }] = useCountDown(60000, 1000);

  useEffect(() => {
    start();
    //eslint-disable-next-line
  }, []);

  return (
    <>
      { timeLeft > 0 ?
        <p className='wait-until'>Wyślij ponownie za {" "}
          <span className="time"> {((timeLeft / 1000).toFixed(0))}</span> s.
        </p>
      :
        <Button action={() => { reset(); start(); onFinished() }}>Wyślij kod ponownie</Button>
      }
    </>
  )
}

const OTPCode = (inputProps) => {
  const setCode = (index, value) => {
      inputProps.setOtp(inputProps.otp.map( (item, key) => key === index ? value : item ))
  }

  const keyUp = (e) => {
      if(e.keyCode === 13) {
          inputProps.callback()
      } else if(e.keyCode === 8) {
          const inputs = document.querySelectorAll(".code-input input")
          let inputIndex = null
          for (let i = 0; i < inputs.length; i++) {
          const element = inputs[i];
          if(element === document.activeElement) {
              inputIndex = i
          }
          }
          if(inputIndex > 0) {
          inputs[inputIndex-1].focus()
          inputs[inputIndex-1].click()
          }
      }
  }

  useEffect(() => {
      document.addEventListener("keyup", keyUp)

      return () => {
          document.removeEventListener("keyup", keyUp)
      }
      //eslint-disable-next-line
  }, [])

  let inputs = []

  for (let i = 0; i < inputProps.length; i++) {
      inputs.push(<div key={i} className="code-input"><input name={"code-"+(i+1)} onClick={(e) => { 
          setCode(i, "")
          }} onChange={(e) => {
          setCode(i, e.target.value)
          let inputs = document.querySelectorAll(".code-input input")
          for (let i = 0; i < inputs.length; i++) {
              const element = inputs[i];
              if(element.value === "") {
              element.focus()
              break
              }
          }
          }} maxLength="1" value={inputProps.otp[i] || ""}/></div>)
  }

  return (
      <div className="phone-number otp">
        <label htmlFor="otp">Kod SMS</label>
        <input maxLength={4} placeholder="np. 1234" type="text" id="otp" className="hidden" value={inputProps.otp.join("")} onChange={(e) => { inputProps.setOtp(e.target.value.split("")) }} autoComplete="one-time-code"/>
      </div>
  )
}

const SelectDatepickerNew = ({selectedDate, onDateChange, onDateChangeParams}) => {
  const getDaysInMonth = (month, year) => {
    let date = new Date(parseInt(year), parseInt(month), 1);
    let days = [];

    while (date.getMonth() === parseInt(month)) {
      let d = new Date(date)
      days.push(d.getDate());
      date.setDate(date.getDate() + 1);
    }

    return days;
  }

  const isValidDate = (d) => {
    return d instanceof Date && !isNaN(d);
  }

  const initialDate = new Date(2023, 0, 1, 1, 0, 0, 0)
  const [days, setDays] = useState(getDaysInMonth(selectedDate ? selectedDate.getMonth() : initialDate.getMonth(), selectedDate ? selectedDate.getFullYear() :initialDate.getFullYear()))
  const months = [
    "Styczeń",
    "Luty",
    "Marzec",
    "Kwiecień",
    "Maj",
    "Czerwiec",
    "Lipiec",
    "Sierpień",
    "Wrzesień",
    "Październik",
    "Listopad",
    "Grudzień"
  ]
  const years = _.range(initialDate.getFullYear(), 1900);
  const [day, setDay] = useState(selectedDate ? selectedDate.getDate() : "null")
  const [month, setMonth] = useState(selectedDate ? selectedDate.getMonth() : "null")
  const [year, setYear] = useState(selectedDate ? selectedDate.getFullYear() : "null")

  useEffect(() => {
    const newDays = getDaysInMonth(month !== "null" ? month : selectedDate ? selectedDate.getMonth() : initialDate.getMonth(), year !== "null" ? year : selectedDate ? selectedDate.getFullYear() : initialDate.getFullYear())
    setDays(newDays)
    if(!newDays.includes(day)) {
      setDay("null")
    }
  }, [month, year])

  useEffect(() => {
    if(day === "" || month === "" || year === "") {
      setDay("null")
      setMonth("null")
      setYear("null")
    } else {
      let d = new Date(year, month, day, 0, 0, 0, 0)
      if(isValidDate(d)) {
        onDateChange(d)
      } else {
        onDateChange(null)
      }
      onDateChangeParams(day, month, year)
    }
  }, [day, month, year])

  return (
    <div className="select-datepicker">
      <select className="day" value={day} onChange={(e) => { setDay(e.target.value === "null" ? "" : parseInt(e.target.value)) }}>
        <option value="null">Dzień</option>
        {days.map((day, i) => {
          return <option  key={i} value={day}>{day}</option>
        })}
      </select>
      <select className="month" value={month} onChange={(e) => { setMonth(e.target.value === "null" ? "" : parseInt(e.target.value)) }}>
        <option value="null">Miesiąc</option>
        {months.map((item, i) => {
          return <option key={i} value={i}>{item}</option>
        })}
      </select>
      <select className="year" value={year} onChange={(e) => { setYear(e.target.value === "null" ? "" : parseInt(e.target.value)) }}>
        <option value="null">Rok</option>
        {years.map((item, i) => {
          return <option key={i} value={item}>{item}</option>
        })}
      </select>
    </div>
  )
}

const ReCaptcha = () => {
  useEffect(() => {
    const script = document.createElement("script")
    script.src = "https://www.google.com/recaptcha/api.js?render=6LfENLkkAAAAAMtD12K4JhTnPgIpRgz68PTDTehu"
    document.body.appendChild(script)
  }, [])
  
  return (
    <div
      className="g-recaptcha"
      data-sitekey="6LfENLkkAAAAAMtD12K4JhTnPgIpRgz68PTDTehu"
      data-size="invisible"
    ></div>
  )
}

const RegisterPage = () => {
  const navigate = useNavigate()
  const { referral } = useParams()
  const [step, setStep] = useState(referral ? "referral" : "organic")
  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [emailAddress, setEmailAddress] = useState("")
  const [mobilePhone, setMobilePhone] = useState("")
  const [birthDate, setBirthDate] = useState(null)
  const [day, setDay] = useState(null)
  const [month, setMonth] = useState(null)
  const [year, setYear] = useState(null)
  const [billingPostalCode, setBillingPostalCode] = useState("")
  const [otpSent, setOtpSent] = useState(false)
  const [otp, setOtp] = useState([null, null, null, null])
  const [waitOtp, setWaitOtp] = useState(false)
  const [otpVerified, setOtpVerified] = useState(false)
  const [waitVerifyOtp, setWaitVerifyOtp] = useState(false)
  const [codeEmpty, setCodeEmpty] = useState(false)
  const [ageVerified, setAgeVerified] = useState(false)
  const [consentAgreement, setConsentAgreement] = useState(false)
  const [consentData, setConsentData] = useState(false)
  const [errors, setErrors] = useState({})
  const [preloader, setPreloader] = useState(false)
  const [loosedFocus, setLoosedFocus] = useState({})
  const [bgBlur, setBgBlur] = useState(false)
  const [additionalConsents, setAdditionalConsents] = useState({
    "newsletterCommercialAuthorization": false,
    "mobileOptIn": false,
    "socialMediaOptIn": false
  })

  const enableOtpRead = otpSent

  const parseOtp = (val) => {
    setOtp(val.split(""))
  }

  useReadOTP(parseOtp, {
    enableOtpRead
  });

  useEffect(() => {
    sendFloodEvent('DC-11110757/maszw0/lpg_a0+standard')

    const documentHeight = () => {
      const obj = document.querySelector(".register-page")
      setTimeout(() => obj.style.setProperty('--height', obj.scrollHeight > window.innerHeight ? `auto` : `${Math.max(window.innerHeight, 660)}px`), 200)
    }
    window.addEventListener("resize", documentHeight)
    documentHeight()

    return () => {
      window.removeEventListener("resize", documentHeight)
    }
  }, [step])

  const getRecaptchaToken = () => {
    window.grecaptcha.ready(_ => {
      console.log("ready")
      window.grecaptcha
        .execute("6LfENLkkAAAAAMtD12K4JhTnPgIpRgz68PTDTehu", { action: "register" })
        .then(token => {
          register(token)
        })
    })
  }

  const sendSMS = () => {
    setWaitOtp(true)
    let params = {
      consumer: {
        mobilePhone: "+48" + mobilePhone,
      },
      otp: {
        domain: `${window.origin}`
      }
    }

    fetch(process.env.REACT_APP_API_URL + "/consumer/otp-send", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(params)
    })
      .then((response) => {
        return response.json()
      })
      .then(
        (result) => {
          if (result.status.success) {
            setWaitOtp(false)
            setOtpSent(true)
          } else {
            showErrorPopup(translateError(result.data.error), result.meta.ts)
            setWaitOtp(false)
          }
        },
        (error) => {
          showErrorPopup(translateError("generic"))
          setWaitOtp(false)
          console.log(error)
        }
      )
  }

  const verifyOtp = () => {
    setWaitVerifyOtp(true)
    let params = {
      consumer: {
        mobilePhone: "+48" + mobilePhone,
        otp: otp.join('')
      }
    }

    fetch(process.env.REACT_APP_API_URL + "/consumer/otp-refresh", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(params)
    })
      .then((response) => {
        return response.json()
      })
      .then(
        (result) => {
          if (result.status.success) {
            setWaitVerifyOtp(false)
            setOtpVerified(true)
            setCodeEmpty(false)
          } else {
            showErrorPopup(translateError(result.data.error), result.meta.ts)
            setWaitVerifyOtp(false)
            setCodeEmpty(true)
            setOtpVerified(false)
          }
        },
        (error) => {
          showErrorPopup(translateError("generic"))
          setWaitVerifyOtp(false)
          console.log(error)
        }
      )
  }

  const register = (recaptchaToken) => {
    setPreloader(true)
    let params = {
      consumer: {
        emailAddress: emailAddress.trim(),
        firstName: firstName.trim(),
        lastName: lastName.trim(),
        ageVerified: ageVerified,
        consentAgreement: consentAgreement,
        source: "alterzone",
        newsletterCommercialAuthorization: additionalConsents.newsletterCommercialAuthorization,
        mobileOptIn: additionalConsents.mobileOptIn,
        socialMediaOptIn: additionalConsents.socialMediaOptIn,
        birthDate: birthDate ? birthDate?.getFullYear()+"-"+leadingZero(birthDate?.getMonth()+1)+"-"+leadingZero(birthDate?.getDate()) : null,
        billingPostalCode: billingPostalCode
      },
      confirmation: {
        recaptcha: recaptchaToken,
        return: {
          url: `${window.origin}/haslo/ustaw${parseUrlParams() ? parseUrlParams() + "&" : "?"}token=`
        }
      }
    }

    if(mobilePhone) {
      params.consumer.mobilePhone = "+48"+mobilePhone
      params.consumer.otp = otp.join('')
    }

    if (referral) {
      params.referral = { code: referral }
    }

    const cookieOptions = JSON.parse(window.localStorage.getItem("cookieOptions"))

    if (getUrlParam("utm_source")) {
      params.consumer.joinSource = `${getUrlParam("utm_source")}_${cookieOptions.performance ? "t" : "f"}_${cookieOptions.communication ? "t" : "f"}`;
    } else {
      params.consumer.joinSource = `null_${cookieOptions.performance ? "t" : "f"}_${cookieOptions.communication ? "t" : "f"}`
    }

    fetch(process.env.REACT_APP_API_URL + "/consumer/register", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        'X-Mode-Local': sessionStorage.getItem('devParams') ? true : false,
      },
      body: JSON.stringify(params)
    })
      .then((response) => {
        return response.json()
      })
      .then(
        (result) => {
          if (result.status.success) {
            setPreloader(false)
            setStep("thankyou")
            sendFloodEvent('DC-11110757/maszw000/reg_a0+standard')
            if (window.dataLayer) {
              window.dataLayer.push({
                event: "Registration",
                step: "Step 1",
                status: "Success"
              });
            }
          } else {
            showErrorPopup(translateError(result.data.error), result.meta.ts)
            if (result.data.error === "error_m2_already_registered") {
              if (window.dataLayer) {
                window.dataLayer.push({
                  event: "Registration",
                  step: "Step 1",
                  status: "Failure - used mail"
                });
              }
            }
            setPreloader(false)
            setStep("form")
            setOtpSent(false)
            setCodeEmpty(true)
            setOtpVerified(false)
          }
        },
        (error) => {
          showErrorPopup(translateError("generic"))
          setPreloader(false)
          console.log(error)
        }
      )
  }

  const handleMobilePhoneChange = (e) => {
    const reg = /^[0-9\b]+$/;
    if (e.target.value === '' || reg.test(e.target.value)) {
      setMobilePhone(e.target.value)
    }
  }

  const validateInput = (i) => {
    const input = i
    const name = input.name
    const req = input.required ? true : false
    const value = input.type === "checkbox" ? input.checked : input.value
    const regex = input.dataset?.regex || false
    const comment = input.dataset?.regexcomment || false
    const emptyComment = input.dataset?.emptycomment || false
    const lengthComment = input.dataset?.lengthcomment || false
    const minLength = input.dataset?.minlength || 0
    const checkSpaces = input.dataset?.checkspaces || false
    const checkSpacesComment = input.dataset?.checkspacescomment || false

    if ((![name] || value === "" || value === false || value === "false") && req) {
      let err = errors
      err[name] = emptyComment ? emptyComment : input.type === "checkbox" ? "Zgoda wymagana" : "Pole wymagane"
      setErrors({ ...err })
      // focus()
      return false
    } else if (checkSpaces && value.indexOf(" ") !== -1) {
      let err = errors
      err[name] = checkSpacesComment ? checkSpacesComment : "Nie może zawierać spacji"
      setErrors({ ...err })
      // focus()
      return false
    } else if (value !== "" && (regex && !new RegExp(regex).test(value))) {
      let err = errors
      err[name] = comment ? comment : "Nieprawidłowa wartość"
      setErrors({ ...err })
      // focus()
      return false
    } else if (value.length < minLength && value.length > 0) {
      let err = errors
      err[name] = lengthComment ? lengthComment : "Minimalna długość, to 2 znaki"
      setErrors({ ...err })
      // focus()
      return false
    } else {
      let err = errors
      delete err[name]
      setErrors({ ...err })
      return true
    }
  }

  const isEmpty = (obj) => {
    return Object.keys(obj).length === 0;
  }

  const validateForm = (e, callback, formClass = "") => {
    e.preventDefault()
    let wrongCounter = 0
    const inputs = document.querySelectorAll("form" + (formClass ? "." + formClass : "") + " input:required")
    let birthError = false

    if (inputs && inputs.length > 0) {
      for (let i = inputs.length - 1; i >= 0; i--) {
        const input = inputs[i];
        if (!validateInput(input)) {
          wrongCounter++
        }
      }
    }

    if(birthDate === null && (day !== "null" || month !== "null" || year !== "null")) {
      let err = errors
      err["birthDate"] = "Podaj pełną datę urodzenia (dzień, miesiąc, rok)"
      setErrors({ ...err })
      birthError = true
    }

    if(!otpVerified) {
      setCodeEmpty(true)
    } else {
      setCodeEmpty(false)
    }

    if (wrongCounter === 0 && isEmpty(errors) && !birthError && otpVerified) {
      callback()
    }
  }

  const inlineValidate = (e) => {
    const target = e.target
    const targetName = target.name
    let focus = loosedFocus
    focus[targetName] = true
    validateInput(e.target)
    setLoosedFocus(focus)
  }

  const handleBlur = () => {
    if(Object.values(errors).some(e => Boolean(e))) {
      setBgBlur(true)
    } else {
      setBgBlur(false)
    }
  }

  const today = new Date()
  today.setHours(0)
  today.setMinutes(0)
  today.setSeconds(0)
  today.setFullYear(today.getFullYear() - 18)

  const handleDateChange = (date) => {
    if(date) {
      if(date.getTime() > today.getTime()) {
        let err = errors
        err["birthDate"] = "Nie ukończyłeś(-aś) 18 roku życia."
        setErrors({ ...err })
      } else {
        let err = errors
        delete err["birthDate"]
        setErrors({ ...err })
      }
      setBirthDate(date)
    } else {
      let err = errors
      delete err["birthDate"]
      setErrors({ ...err })
      setBirthDate(null)
    }
  }

  const view = () => {
    switch (step) {
      case "referral":
        return (
          <div className="referral">
            <Swiper
              pagination={true}
              navigation={true}
              autoplay={{
                delay: 6000,
                disableOnInteraction: false,
              }}
              className="swiper-referral" slidesPerView={1} >
              <SwiperSlide>
                <h2>Wejdź do Alter Zone!</h2>
                <div className="wrapper">
                  <div className="icon">
                    <img src={Present} alt="" />
                  </div>
                  <h3>Twój przyjaciel zaprosił Cię <br />do naszej społeczności. <br />Sprawdź, co czeka na Ciebie <br />po dołączeniu. </h3>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <h2>Nagroda do odebrania</h2>
                <div className="wrapper">
                  <div className="icon">
                    <img src={Present} alt="" />
                  </div>
                  <h3>Kupon rabatowy <span>30 zł</span> na zakup <br />urządzenia</h3>
                </div>
              </SwiperSlide>
            </Swiper>
            <div className="button-wrapper">
              <Button action={() => { setStep("form") }}>Wchodzę w to</Button>
            </div>
          </div>
        )

      case "form":
        return (
          <div className="wrapper">
            <form method="POST" className="register-form">
              <div className="selector">
                <div className="login">
                  <Link to="/login">Zaloguj się</Link>
                </div>
                <div className="register selected">
                  Zarejestruj się
                </div>
              </div>
              <div className="text">
                <div className="shapes">
                  <div className="rect"></div>
                  <div className="oval"></div>
                </div>
                <h3>Dołącz do naszej społeczności!</h3>
                <div className="wrapper">
                  <h4>Tych samych danych użyjesz do logowania w:</h4>
                  <div className="logos">
                    <img src={MaszWybor} alt="Masz Wybor logo" />
                  </div>
                </div>
              </div>
              <div className="user-data">
                <div className="input">
                  <label>Imię</label>
                  <input name="firstName" maxLength={40} type="text" value={firstName} onChange={(e) => { if (loosedFocus["firstName"]) inlineValidate(e); setFirstName(e.target.value); }} onFocus={() => setBgBlur(true)} onBlur={(e) => { if (!loosedFocus["firstName"]) inlineValidate(e); handleBlur() }} placeholder="np. Jan" data-regex="^[a-zA-ZśŚęĘżźŹńŃćĆłŁąĄńŃóÓżŻ`'-]{0,40}$" data-regexcomment="Imię nie może zawierać znaków specjalnych i cyfr" data-emptycomment="Podaj swoje Imię" data-lengthcomment="Imię jest zbyt krótkie (min. to 2 znaki)" required />
                  {errors["firstName"] !== undefined ? <img src={!errors["firstName"] ? Valid : NotValid} alt="" className="valid-icon" /> : null}
                  {errors['firstName'] ? <p className="error">{errors['firstName']}</p> : null}
                </div>
                <div className="input">
                  <label>Nazwisko</label>
                  <input name="lastName" maxLength={80} type="text" value={lastName} onChange={(e) => { if (loosedFocus["lastName"]) inlineValidate(e); setLastName(e.target.value) }} onFocus={() => setBgBlur(true)} onBlur={(e) => { if (!loosedFocus["lastNamed"]) inlineValidate(e); handleBlur() }} placeholder="np. Kowalski" data-regex="^[a-zA-ZśŚęĘżźŹńŃćĆłŁąĄńŃóÓżŻ`'-]{0,80}$" data-regexcomment="Nazwisko nie może zawierać znaków specjalnych i cyfr" data-emptycomment="Podaj swoje Nazwisko" data-lengthcomment="Nazwisko jest zbyt krótkie (min. to 2 znaki)" required />
                  {errors["lastName"] !== undefined ? <img src={!errors["lastName"] ? Valid : NotValid} alt="" className="valid-icon" /> : null}
                  {errors['lastName'] ? <p className="error">{errors['lastName']}</p> : null}
                </div>
                <div className="input">
                  <label>Adres e-mail</label>
                  <input name="emailAddress" type="email" value={emailAddress} onChange={(e) => { if (loosedFocus["emailAddress"]) inlineValidate(e); setEmailAddress(e.target.value) }} onFocus={() => setBgBlur(true)} onBlur={(e) => { if (!loosedFocus["emailAddress"]) inlineValidate(e); handleBlur() }} placeholder="np. jan.kowalski@gmail.com" data-regex='^(([a-zA-Z0-9-_.]+(\.[a-zA-Z0-9-_.]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$' data-regexcomment="Nieprawidłowy format. Wprowadź poprawny adres e-mail" data-emptycomment="Wpisz adres e-mail" data-lengthcomment="Email za krótki (min. to 2 znaki)" data-checkspaces={true} data-checkspacescomment="Usuń spację z adresu e-mail." required />
                  {errors["emailAddress"] !== undefined ? <img src={!errors["emailAddress"] ? Valid : NotValid} alt="" className="valid-icon" /> : null}
                  {errors['emailAddress'] ? <p className={`error ${errors?.emailAddress?.length > 20 ? "long" : ""}`}>{errors['emailAddress']}</p> : null}
                </div>
                <div className="input group">
                  <div className="input phone">
                    <label>Numer telefonu</label>
                    <input name="mobilePhone" maxLength={9} data-minLength={9} type="text" value={mobilePhone} onChange={(e) => { if (loosedFocus["mobilePhone"]) inlineValidate(e); handleMobilePhoneChange(e) }} onFocus={() => setBgBlur(true)} onBlur={(e) => { if (!loosedFocus["mobilePhone"]) inlineValidate(e); handleBlur() }} placeholder="np. 501 234 567" data-regex="^[0-9\b]+$" data-regexcomment="Numer telefonu może zawierać tylko cyfry" data-emptycomment="Podaj numer telefonu" data-lengthcomment="Numer telefonu zbyt krótki (min. to 9 cyfr)" required/>
                    {errors["mobilePhone"] !== undefined ? <img src={!errors["mobilePhone"] ? Valid : NotValid} alt="" className="valid-icon" /> : null}
                    {errors['mobilePhone'] ? <p className="error">{errors['mobilePhone']}</p> : null}
                    { (codeEmpty && mobilePhone?.length === 9 && !otpSent) && <p className="error">Wyślij sms, aby zweryfikować podany numer</p> }
                  </div>
                  { mobilePhone?.length === 9 || otpSent ? <div className="send-otp">
                    { waitOtp ? <Button disabled={true}>Proszę czekać...</Button> : otpSent ? <Timer onFinished={() => { sendSMS() }}/> : <Button action={() => { sendSMS() }}>Wyślij kod SMS</Button> }
                  </div> : null }
                </div>
                { otpSent && <div className="input group">
                  <div className="input phone">
                    <OTPCode otp={otp} setOtp={setOtp} length={4} callback={() => { document.getElementById("submit").click() }}/>
                    { codeEmpty && <p className="error">Zweryfikuj kod, aby móc przejść dalej</p> }
                    { otpVerified && <p className="success"><img src={Valid}/><span>Kod poprawny</span></p> }
                  </div>
                  { otpSent ? <div className="verify-otp">
                    { waitVerifyOtp ? <Button disabled={true}>Proszę czekać...</Button> : <Button action={() => { verifyOtp() }}>Zweryfikuj kod</Button> }
                  </div> : null }
                </div> }
                <div className="input birthdate">
                  <label>Data urodzenia</label>
                  <SelectDatepickerNew selectedDate={birthDate} onDateChange={(d) => { handleDateChange(d) }} onDateChangeParams={(day, month, year) => {
                    setDay(day)
                    setMonth(month)
                    setYear(year)
                  }}/>
                  {errors['birthDate'] ? <p className="error">{errors['birthDate']}</p> : null}
                </div>
                <div className="input postalcode">
                    <label>Kod pocztowy</label>
                    <InputMask name="billingPostalCode" mask="99-999" onChange={(e) => { if (loosedFocus["billingPostalCode"]) inlineValidate(e); setBillingPostalCode(e.target.value) }} onFocus={() => setBgBlur(true)} onBlur={(e) => { if (!loosedFocus["billingPostalCode"]) inlineValidate(e); handleBlur() }} value={billingPostalCode} maskChar={null} placeholder={"00-000"} data-regex="^[0-9]{2}-[0-9]{3}$" data-regexcomment="Wpisz prawidłowy kod pocztowy w formacie 00-000" minLength={0}/>
                    {errors['billingPostalCode'] ? <p className="error">{errors['billingPostalCode']}</p> : null}
                </div>
                <div className="consents" style={{ marginTop: "24px" }}>
                  <div className="checkbox">
                    <label htmlFor="consentAgreement">Oświadczam, że znam i akceptuję <a href={process.env.REACT_APP_API_URL + "/alterzone/terms/latest"} target="_blank" rel="noopener noreferrer">Regulamin Alter Zone</a> oraz <a href="/assets/polityka_prywatnosci_AZ.pdf" target="_blank" rel="noopener noreferrer">Politykę prywatności</a>*</label>
                    <div className="switch">
                      <input id="consentAgreement" name="consentAgreement" type="checkbox" value={consentAgreement} onChange={(e) => { inlineValidate(e); setConsentAgreement(e.target.checked); handleBlur() }} checked={consentAgreement} required />
                      <span className="slider"></span>
                    </div>
                  </div>
                  {errors['consentAgreement'] && !consentAgreement ? <p className="error">{errors['consentAgreement']}</p> : null}
                  <div className="checkbox">
                    <label htmlFor="ageVerified">Oświadczam, że jestem pełnoletnim użytkownikiem wyrobów nikotynowych BAT Polska Trading sp.&nbsp;z&nbsp;o.o. i/lub wyrobów powiązanych.*</label>
                    <div className="switch">
                      <input id="ageVerified" name="ageVerified" type="checkbox" value={ageVerified} onChange={(e) => { inlineValidate(e); setAgeVerified(e.target.checked); handleBlur() }} checked={ageVerified} required />
                      <span className="slider"></span>
                    </div>
                  </div>
                  {errors['ageVerified'] && !ageVerified ? <p className="error">{errors['ageVerified']}</p> : null}
                  <div className="checkbox">
                    <label htmlFor="consentData">Wyrażam zgodę na przetwarzanie moich danych osobowych podanych podczas rejestracji, przez BAT Polska Trading sp. z o.o oraz CHIC sp. z o. o. z siedzibą w Ostrzeszowie jako współadministratorów, w celu utworzenia konta.*</label>
                    <div className="switch">
                      <input id="consentData" name="consentData" type="checkbox" value={consentData} onChange={(e) => { inlineValidate(e); setConsentData(e.target.checked); handleBlur() }} checked={consentData} required />
                      <span className="slider"></span>
                    </div>
                  </div>
                  {errors['consentData'] && !consentData ? <p className="error">{errors['consentData']}</p> : null}
                  <p className="consent-required"><strong>* Zgoda wymagana</strong></p>
                  <p className="info" style={{ textAlign: "center" }}>Pamiętaj, że zawsze możesz wycofać powyższe zgody na przetwarzanie Twoich danych osobowych. Wycofanie zgody nie będzie miało jednak wpływu na zgodność z prawem naszych działań podjętych przed jej wycofaniem. Aby wycofać swoją zgodę skontaktuj się z nami pod adresem: <a href="mailto:daneosobowe@bat.com.pl">daneosobowe@bat.com.pl</a>. Masz także prawo do uzyskania dostępu do treści Twoich danych, prawo ich poprawiania, prawo do ich przenoszenia, prawo do żądania ich usunięcia oraz prawo ograniczenia ich przetwarzania. Jeśli uznasz, że przetwarzamy Twoje dane osobowe niezgodnie z prawem, możesz wnieść skargę do Prezesa Urzędu Ochrony Danych Osobowych. <br/>Więcej informacji w <a href="/assets/polityka_prywatnosci_AZ.pdf" target="_blank" rel="noopener noreferrer">Polityce prywatności</a>.</p>
                </div>
              </div>
              <div className="button-wrapper">
                <Button id="submit" action={(e) => { validateForm(e, () => { setStep("consents"); setBgBlur(false) }, "register-form") }} disabled={preloader}>{preloader ? "Proszę czekać..." : "Dalej"}</Button>
              </div>
            </form>
          </div>
        )

      case "consents":
        return (
          <div className="consents">
            <form method="POST" className="register-form">
              <div className="selector">
                <div className="login">
                  <Link to="/login">Zaloguj się</Link>
                </div>
                <div className="register selected">
                  Zarejestruj się
                </div>
              </div>
              <div className="shapes">
                <div className="oval"></div>
                <div className="rect"></div>
              </div>
              <h3>Super, jeszcze tylko jeden krok.</h3>
              <div className="user-data">
                <p className="info" style={{ textAlign: "center" }}><strong>Wyrażam zgodę na przetwarzanie podanych przeze mnie danych osobowych przez BAT Polska Trading sp. z o.o. z&nbsp;siedzibą w&nbsp;Warszawie oraz CHIC spółkę z&nbsp;ograniczoną odpowiedzialnością z&nbsp;siedzibą w&nbsp;Ostrzeszowie jako współadministratorów, w&nbsp;celu prowadzenia przez te podmioty niepublicznych działań o&nbsp;charakterze marketingu bezpośredniego, z&nbsp;wykorzystaniem profilowania moich danych osobowych oraz badania moich decyzji zakupowych. Działania te będą obejmowały prezentowanie mi informacji o&nbsp;produktach, usługach, konkursach i&nbsp;innych inicjatywach grupy BAT, w&nbsp;tym prezentowanie spersonalizowanych informacji oraz ofert marketingowych:</strong></p>
                <div className="checkbox">
                  <label htmlFor="newsletterCommercialAuthorization" >za pośrednictwem środków komunikacji elektronicznej, tj.: poczty elektronicznej (e-mail) oraz wiadomości tekstowych SMS</label>
                  <div className="switch">
                    <input id="newsletterCommercialAuthorization" type="checkbox" onChange={(e) => setAdditionalConsents(prev => ({ ...prev, newsletterCommercialAuthorization: !prev.newsletterCommercialAuthorization }))} checked={additionalConsents.newsletterCommercialAuthorization} />
                    <span className="slider"></span>
                  </div>
                </div>
                <div className="checkbox">
                  <label htmlFor="mobileOptIn" >poprzez kontakt telefoniczny, w&nbsp;tym połączenia głosowe</label>
                  <div className="switch">
                    <input id="mobileOptIn" type="checkbox" onChange={(e) => setAdditionalConsents(prev => ({ ...prev, mobileOptIn: !prev.mobileOptIn }))} checked={additionalConsents.mobileOptIn} />
                    <span className="slider"></span>
                  </div>
                </div>
                <div className="checkbox">
                  <label htmlFor="socialMediaOptIn" >w&nbsp;sekcjach reklamowych na portalach społecznościowych (np. Facebook, Instagram i&nbsp;podobne)</label>
                  <div className="switch">
                    <input id="socialMediaOptIn" type="checkbox" onChange={(e) => setAdditionalConsents(prev => ({ ...prev, socialMediaOptIn: !prev.socialMediaOptIn }))} checked={additionalConsents.socialMediaOptIn} />
                    <span className="slider"></span>
                  </div>
                </div>
                <p className="info" style={{ textAlign: "center" }}>Pamiętaj, że zawsze możesz wycofać powyższe zgody na przetwarzanie Twoich danych osobowych. Wycofanie zgody nie będzie miało jednak wpływu na zgodność z prawem naszych działań podjętych przed jej wycofaniem. Aby wycofać swoją zgodę skontaktuj się z nami pod adresem: <a href="mailto:daneosobowe@bat.com.pl">daneosobowe@bat.com.pl</a>. Masz także prawo do uzyskania dostępu do treści Twoich danych, prawo ich poprawiania, prawo do ich przenoszenia, prawo do żądania ich usunięcia oraz prawo ograniczenia ich przetwarzania. Jeśli uznasz, że przetwarzamy Twoje dane osobowe niezgodnie z prawem, możesz wnieść skargę do Prezesa Urzędu Ochrony Danych Osobowych. <br/>Więcej informacji w <a href="/assets/polityka_prywatnosci_AZ.pdf" target="_blank" rel="noopener noreferrer">Polityce prywatności</a>.</p>
              </div>
              <div className="button-wrapper">
                <Button action={(e) => { validateForm(e, () => {getRecaptchaToken(); handleBlur()}, "register-form") }} disabled={preloader}>{preloader ? "Proszę czekać..." : "Zarejestruj się"}</Button>
              </div>
            </form>
          </div>
        )

      case "thankyou":
        return (
          <div className="thankyou">
            <div className="selector">
              <div className="login">
                <Link to="/login">Zaloguj się</Link>
              </div>
              <div className="register selected">
                Zarejestruj się
              </div>
            </div>
            <h3>Dziękujemy za dokonanie <br />rejestracji.</h3>
            <p>Na podany adres e-mail<br />
              <strong>wysłaliśmy link weryfikacyjny.</strong>
            </p>
            <p>Link ważny jest przez 24 h. <br />Po kliknięciu w link, ustawisz hasło <br /> logowania do serwisu.</p>
            <p className="small">Nie widzisz potwierdzenia rejestracji? <br />Sprawdź czy wiadomość nie trafiła do folderu SPAM w Twojej <br />skrzynce mailowej.</p>
            <div className="button-wrapper">
              <Button action={() => { navigate("/login") }}>Gotowe</Button>
            </div>
          </div>
        )

      default: // organic
        return (
          <div className="organic">
            <div className="welcome">
              <h1>Dla tych, którzy</h1>
              <h1 className="orange">Chcą więcej</h1>
            </div>
            <h3>Wejdź do społeczności <br/>przeznaczonej wyłącznie dla <br/>pełnoletnich użytkowników wyrobów nikotynowych <br/>BAT Polska Trading sp. z o.o.</h3>
            <div className="button-wrapper">
              <Button action={() => { setStep("form") }}>Wchodzę w to</Button>
            </div>
          </div>
        )
    }
  }

  return (
    <div className="register-page">
      <div className={`blur ${bgBlur ? "active" : ""}`}>
        <div className="register-page-inner">
          <div className="logo">
            <img src={Logo} alt="" />
          </div>
          <div className="main">
            {view()}
          </div>
        </div>
      </div>
      <ReCaptcha/>
    </div>
  )
}

export default RegisterPage
