import _1 from "./img/1.jpg"
import _2 from "./img/2.jpg"
import _3 from "./img/3.jpg"
import _4 from "./img/4.jpg"
import _5 from "./img/5.jpg"
import _6 from "./img/6.jpg"
import _7 from "./img/7.jpg"
import _8 from "./img/8.jpg"
import _9 from "./img/9.jpg"
import _10 from "./img/10.jpg"
import _11 from "./img/11.jpg"
import _12 from "./img/12.jpg"

export const questions = {
    "gameType": "HedonisciSwiper",
    "glo": [
        {
            "ident": "q0",
            "content": "Kto rozmawiał z zaproszonymi gośćmi w 3 sezonie Oświeconych Hedonistów?",
            "type": "multiSelect",
            "answers": [
                {
                    "ident": "a",
                    "content": "Agnieszka Woźniak-Starak"
                },
                {
                    "ident": "b",
                    "content": "Maja Sablewska"
                },
                {
                    "ident": "c",
                    "content": "Mishon"
                },
                {
                    "ident": "d",
                    "content": "Gabi Drzewiecka"
                },
            ]
        },
        {
            "ident": "q1",
            "content": "Ile odcinków pojawiło się w 3 sezonie Oświeconych Hedonistów?",
            "type": "text",
            "answers": [
                {
                    "ident": "a",
                    "content": "5"
                },
                {
                    "ident": "b",
                    "content": "8"
                },
                {
                    "ident": "c",
                    "content": "12"
                },
                {
                    "ident": "d",
                    "content": "24"
                },
            ]
        },
        {
            "ident": "q2",
            "content": "Kto był pierwszym gościem w 3 sezonie Oświeconych Hedonistów?",
            "type": "text",
            "answers": [
                {
                    "ident": "a",
                    "content": "Piotr Czaykowski"
                },
                {
                    "ident": "b",
                    "content": "Artur Rojek"
                },
                {
                    "ident": "c",
                    "content": "Sara Boruc"
                },
                {
                    "ident": "d",
                    "content": "Natalia Nykiel"
                },
            ]
        },
        {
            "ident": "q3",
            "content": "Która rozmowa z 3 sezonu Oświeconych Hedonistów jest Twoją ulubioną?",
            "type": "swiper",
            "answers": [
                {
                    "ident": "a",
                    "content": "Agnieszka Woźniak-Starak\nx Karolina Naji",
                    "img": _1 
                },
                {
                    "ident": "b",
                    "content": "Agnieszka Woźniak-Starak\nx Sara Boruc",
                    "img": _2 
                },
                {
                    "ident": "c",
                    "content": "Agnieszka Woźniak-Starak\nx Marieta Żukowska",
                    "img": _3 
                },
                {
                    "ident": "d",
                    "content": "Agnieszka Woźniak-Starak\nx Karla Gruszecka",
                    "img": _4 
                },
                {
                    "ident": "e",
                    "content": "Agnieszka Woźniak-Starak\nx Marcin Tyszka",
                    "img": _5 
                },
                {
                    "ident": "f",
                    "content": "Agnieszka Woźniak-Starak\nx Nikodem Rozbicki",
                    "img": _6 
                },
                {
                    "ident": "g",
                    "content": "Gabi Drzewiecka x Avi",
                    "img": _7 
                },
                {
                    "ident": "h",
                    "content": "Gabi Drzewiecka \nx Natalia Nykiel",
                    "img": _8 
                },
                {
                    "ident": "i",
                    "content": "Gabi Drzewiecka \nx Ofelia Iga Krefft",
                    "img": _9 
                },
                {
                    "ident": "j",
                    "content": "Gabi Drzewiecka \nx Artur Rojek",
                    "img": _10
                },
                {
                    "ident": "k",
                    "content": "Gabi Drzewiecka \nx Piotr Czaykowski",
                    "img": _11
                },
                {
                    "ident": "l",
                    "content": "Gabi Drzewiecka \nx Natasza Urbańska",
                    "img": _12
                },
            ]
        },
    ]
}