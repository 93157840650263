import _1 from "./img/1.jpg"
import _2 from "./img/2.jpg"
import _3 from "./img/3.jpg"
import _4 from "./img/4.jpg"
import _5 from "./img/5.jpg"
import _6 from "./img/6.jpg"
import pack1 from "./img/pack1.jpg"
import pack2 from "./img/pack2.jpg"
import pack3 from "./img/pack3.jpg"
import pack4 from "./img/pack4.jpg"
import pack5 from "./img/pack5.jpg"
import pack6 from "./img/pack6.jpg"

export const questions = {
    "gameType": "ScratchReveal",
    "glo": [
        {
            "ident": "q0",
            "content": "neo™ Summer Yellow Click",
            "correctAnswer": "a",
            "answers": [
                {
                    "ident": "a",
                    "img": _1,
                    "pack": pack1
                },
                {
                    "ident": "b",
                    "img": _2,
                    "pack": pack2
                },
                {
                    "ident": "c",
                    "img": _3,
                    "pack": pack3
                },
                {
                    "ident": "d",
                    "img": _4,
                    "pack": pack4
                },
                {
                    "ident": "e",
                    "img": _5,
                    "pack": pack5
                },
                {
                    "ident": "f",
                    "img": _6,
                    "pack": pack6
                },
            ]
        },
        {
            "ident": "q1",
            "content": "neo™ Scarlet Click",
            "correctAnswer": "b",
            "answers": [
                {
                    "ident": "a",
                    "img": _1,
                    "pack": pack1
                },
                {
                    "ident": "b",
                    "img": _2,
                    "pack": pack2
                },
                {
                    "ident": "c",
                    "img": _3,
                    "pack": pack3
                },
                {
                    "ident": "d",
                    "img": _4,
                    "pack": pack4
                },
                {
                    "ident": "e",
                    "img": _5,
                    "pack": pack5
                },
                {
                    "ident": "f",
                    "img": _6,
                    "pack": pack6
                },
            ]
        },
        {
            "ident": "q2",
            "content": "neo™ Ice Click",
            "correctAnswer": "f",
            "answers": [
                {
                    "ident": "a",
                    "img": _1,
                    "pack": pack1
                },
                {
                    "ident": "b",
                    "img": _2,
                    "pack": pack2
                },
                {
                    "ident": "c",
                    "img": _3,
                    "pack": pack3
                },
                {
                    "ident": "d",
                    "img": _4,
                    "pack": pack4
                },
                {
                    "ident": "e",
                    "img": _5,
                    "pack": pack5
                },
                {
                    "ident": "f",
                    "img": _6,
                    "pack": pack6
                },
            ]
        },
        {
            "ident": "q3",
            "content": "neo™ Violet Click",
            "correctAnswer": "d",
            "answers": [
                {
                    "ident": "a",
                    "img": _1,
                    "pack": pack1
                },
                {
                    "ident": "b",
                    "img": _2,
                    "pack": pack2
                },
                {
                    "ident": "c",
                    "img": _3,
                    "pack": pack3
                },
                {
                    "ident": "d",
                    "img": _4,
                    "pack": pack4
                },
                {
                    "ident": "e",
                    "img": _5,
                    "pack": pack5
                },
                {
                    "ident": "f",
                    "img": _6,
                    "pack": pack6
                },
            ]
        },
    ]
}