import Mobile from "./img/bg-mobile.jpg"
import Desktop from "./img/bg-desktop.jpg"
import { questions } from "./questions"

export const fakty_o_glo_hyper_uniq = {
    header: "Fakty o glo™ hyper+ UNIQ",
    backgroundMobile: Mobile,
    backgroundDesktop: Desktop,
    title: "Premiera glo ™ hyper+ UNIQ może nieść za sobą plotki i różne niepotwierdzone informacje.",
    subtitle: "Czy udało Ci się już zapoznać na tyle z nowym urządzeniem, by obalić ewentualne mity? ",
    message: "Oceń, które z podanych stwierdzeń jest prawdziwe lub fałszywe, Wybierz „Prawda” - jeśli zdanie jest prawdziwe, albo „Fałsz” – jeśli jest fałszywe.",
    points: 15,
    statute: "/assets/Regulamin Aktywności_Fakty_o_glo_hyper_uniq_14.02.pdf",

    ident: "luty2_2022",
    gameType: "TrueFalse",
    questions: questions,

    correct: {
        title: "Super!",
        content: [
            "Znasz fakty i obalasz mity. Tak trzymaj! Nowoczesny, kolorowy i różnorodny – takie jest nowy glo™ hyper+ UNIQ Unikalność i szeroki wybór - \n15 wymiennych paneli bocznych Dopasuj urządzenie do Twojego stylu życia i okazji.",
            "Wszystkie dostępne panele znajdziesz w sklepie online oraz w punktach eSmoking World.",
        ]
    },
    wrong: {
        title: "Upss…",
        content: [
            "Chyba wiemy, w czym tkwi problem. Nie znasz jeszcze w pełni glo™ hyper+ UNIQ. Zapoznaj się nowością i powtórz zadanie raz jeszcze, jeśli chcesz zgarnąć 15 punktów.",
            "Nasze nowe urządzenie przedstawiliśmy w newsie",
        ]
    },
}