import img1 from "./img/Music.png"
import img2 from "./img/Moda.png"
import img3 from "./img/Art.png"

export const questions = {
    "gameType": "WordSearch",
    "glo": [
        {
            "ident": "q0",
            "content": "Znajdź 1 dowolne słowo i dowiedz się, która limitowana edycja glo™ hyper X2 powinna wylądować w Twoich rękach",
            "type": "board",
            "board": ["m", "u", "z", "y", "k", "a", "k", "a", "r", "y", "o", "d", "j", "d", "e", "s", "i", "g", "n", "p", "r", "p", "o", "s", "f", "y", "w", "k", "l", "c", "y", "q", "a", "t", "u", "v", "b", "n", "t", "s", "t", "m", "n", "m", "o", "d", "a", "s", "d", "t", "m", "t", "p", "x", "v", "i", "j", "h", "g", "y", "a", "e", "r", "q", "t", "y", "k", "n", "z", "l", "g", "u", "d", "p", "o", "w", "m", "a", "n", "x", "w", "z", "o", "r", "n", "i", "c", "t", "w", "o", "r", "y", "t", "y", "g", "z", "f", "a", "k", "z"],
            "correctAnswers": ["muzyka", "rytm", "moda", "styl", "wzornictwo", "design"]
        },
        {
            "ident": "q1",
            "content": "Twoja limitowana edycja glo™ hyper X2:",
            "type": "show",
            "answers": [
                { "ident": "muzyka", "img": img1, "sub": "glo™ hyper X2 Black/Red Music", },
                { "ident": "rytm", "img": img1, "sub": "glo™ hyper X2 Black/Red Music", },
                { "ident": "moda", "img": img2, "sub": "glo™ hyper X2 Green/Pine Fashion", },
                { "ident": "styl", "img": img2, "sub": "glo™ hyper X2 Green/Pine Fashion", },
                { "ident": "wzornictwo", "img": img3, "sub": "glo™ hyper X2 White/Gold Art", },
                { "ident": "design", "img": img3, "sub": "glo™ hyper X2 White/Gold Art", },
            ],
        },
    ]
}