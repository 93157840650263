import { useState, useEffect,useRef } from 'react';
import { addUrlParam, getUrlParam, parseUrlParams } from './UrlParamsHelper';

export function useWindowSize() {
  const isClient = typeof window === 'object';

  const [windowSize, setWindowSize] = useState({
    width: isClient ? window.innerWidth : undefined,
    height: isClient ? window.innerHeight : undefined
  });

  useEffect(() => {
    const isClient = typeof window === 'object';
    
    if (!isClient) {
      return false;
    }

    function handleResize() {
      setWindowSize({
        width: isClient ? window.innerWidth : undefined,
        height: isClient ? window.innerHeight : undefined
      });
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowSize;
}

export function sendEvent(event, payload) {
    const data = {
        "event": {
            "ident": event,
            "payload": payload || null
        }
    }

    fetch(process.env.REACT_APP_API_URL + "/consumer/event-create", {
        method: "POST",
        headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + JSON.parse(sessionStorage.getItem('token')).uuid,
        'X-Mode-Local': sessionStorage.getItem('devParams') ? true : false,
        },
        body: JSON.stringify(data)
    })
    .then((response) => {
        return response.json()
    })
    .then(
        (result) => {
            
        },
        (error) => {
            console.log(error)
        }
    )
}

export function leadingZero(day) {
  let d = parseInt(day);
  if (d < 10) {
    return "0" + d;
  } else {
    return "" + d;
  }
}

export function parseDate(d, hours, showSeconds, addDays) {
  let date
  try {
    date = new Date(d.replace(" ", "T"));
  } catch (error) {
    return "n/d"
  }
  let seconds = ":" + leadingZero(date.getSeconds());

  if (showSeconds === false) {
    seconds = "";
  }

  if (addDays) {
    date.setDate(date.getDate() + addDays)
  }

  if (hours) {
    return (
      leadingZero(date.getDate()) +
      "/" +
      leadingZero(date.getMonth() + 1) +
      "/" +
      date.getFullYear() +
      ", " +
      leadingZero(date.getHours()) +
      ":" +
      leadingZero(date.getMinutes()) +
      seconds
    );
  } else {
    return (
      leadingZero(date.getDate()) +
      "." +
      leadingZero(date.getMonth() + 1) +
      "." +
      date.getFullYear()
    );
  }
}

export function validateDates(vlalidTo, serverTime, daysAdded = 0) {
  const date1 = new Date(vlalidTo?.includes("T") ? vlalidTo : vlalidTo?.replace(" ", "T"))
  if(daysAdded) {
    // add days and change hour to 23:59:59
    date1.setDate(date1.getDate() + daysAdded)
    date1.setHours(23,59,59)
  }
  const date2 = new Date(serverTime?.includes("T") ? serverTime : serverTime?.replace(" ", "T"))

  if([date1.toString(), date2.toString()].includes("Invalid Date")) {
    console.warn("wrong dates")
    // true is disabled ?? lol
    return true
  }

  return date1 <= date2
}

export function localeString(string) {
  const date = new Date(string)
  return date.toLocaleDateString("pl-PL", { day: "numeric", month: 'long', year: "numeric" })
}

export function validToBreakpoint(created, validToFromServer, disabledValidation = false, serverTime = null, forceChcek = false) {
  let date = new Date(created)
  const magicDate = new Date("2022-03-15 00:00")

    if (date < magicDate) {
      return disabledValidation ? validateDates(validToFromServer, serverTime) : parseDate(validToFromServer)
    }
  
    if (forceChcek) {
      return disabledValidation ? validateDates(validToFromServer, serverTime) : parseDate(validToFromServer)
    }
  
    return disabledValidation ? validateDates(created, serverTime, 30) : parseDate(created, false, false, 30)
}

export function getCookie(sName) {
  var oCrumbles = document.cookie.split(';');
  for (var i = 0; i < oCrumbles.length; i++) {
    var oPair = oCrumbles[i].split('=');
    var sKey = decodeURIComponent(oPair[0].trim());
    var sValue = oPair.length > 1 ? oPair[1] : '';
    if (sKey === sName) {
      return decodeURIComponent(sValue);
    }
  }
  return '';
}

export function setCookie(sName, sValue, options) {
  var sCookie = encodeURIComponent(sName) + '=' + encodeURIComponent(sValue);
  if (options && options instanceof Date) {
      options = {
          expires: options
      };
  }
  if (options && typeof options === 'object') {
      if (options.expires) {
          sCookie += '; expires=' + options.expires.toGMTString();
      }
      if (options.path) {
          sCookie += '; path=' + options.path.toString();
      }
      if (options.domain) {
          sCookie += '; domain=' + options.domain.toString();
      }
      if (options.secure) {
          sCookie += '; secure';
      }
  }
  document.cookie= sCookie;
}

export function removeCookie(sName, options) {
  var opt = options || {};
  opt.expires = new Date();
  this.setCookie(sName, '', opt);
}

export function sendFloodEvent(sendTo, path) {
  if(window.gtag) {
    window.gtag('event', 'conversion', path ? {
      'allow_custom_scripts': true,
      'u1': path,
      'send_to': sendTo
    } : {
      'allow_custom_scripts': true,
      'send_to': sendTo
    });
  }
}


export const useInterval = (callback, delay) => {
  const savedCallback = useRef();

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    const tick = () => {
      savedCallback.current();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
};

export const utmParamLogin = () => {
  if(getUrlParam("utm_source")) {
    addUrlParam("logged_in", "true")
    document.location.search = parseUrlParams()
  }
}


export function useSessionStorage(key, initialValue) {
  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState(() => {
      if (window)
          try {
              // Get from local storage by key
              const item = window.sessionStorage.getItem(key);
              // Parse stored json or if none return initialValue
              return item ? JSON.parse(item) : initialValue;
          } catch (error) {
              // If error also return initialValue
              console.log(error);
              return initialValue;
          }
  });
  if (typeof window === "undefined") {
      return initialValue;
  }
  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.
  const setValue = (value) => {
      try {
          // Allow value to be a function so we have same API as useState
          const valueToStore =
              value instanceof Function ? value(storedValue) : value;
          // Save state
          setStoredValue(valueToStore);
          // Save to local storage
          window.sessionStorage.setItem(key, JSON.stringify(valueToStore));
          window.dispatchEvent(new Event("storage"));
      } catch (error) {
          // A more advanced implementation would handle the error case
          console.log(error);
      }
  };
  return [storedValue, setValue];
}

export function useLocalStorage(key, initialValue) {
  const [storedValue, setStoredValue] = useState(() => {
    if (window)
      try {
        const item = window.localStorage.getItem(key);
        return item ? JSON.parse(item) : initialValue;
      } catch (error) {
        console.log(error);
        return initialValue;
      }
  });
  if (typeof window === "undefined") {
    return initialValue;
  }
  const setValue = (value) => {
    try {
      const valueToStore =
        value instanceof Function ? value(storedValue) : value;
      setStoredValue(valueToStore);
      window.localStorage.setItem(key, JSON.stringify(valueToStore));
    } catch (error) {
      console.log(error);
    }
  };
  return [storedValue, setValue];
}