
export const questions = {
    "gameType": "revealImage",
    "glo": [
        {
            "ident": "q0",
            "content": "",
            "answers": [
                "322506ef-e11a-45fc-ba64-96c9ea58c55b",
                "498267ca-919a-41e1-9bef-3d7d14997ca2",
                "5b5f7d1e-c178-4d79-8a50-914bb512ef76",
                "3122d407-8cbb-458d-ba86-599343a30b1f",
                "2704fb97-67c8-4597-80f3-275c15f8acc1",
                "585a4735-e59a-45be-a935-d447473b4daa",
                "946de9cb-4815-41f7-9286-32cac34178ff",
                "c1776522-6dfc-4ba1-ab02-d90807135674",
                "f48bd589-4aef-4bab-8eeb-2022ea641306"
            ],
        },
        {
            "ident": "q1",
            "content": "W punktach eSmoking World znajdziesz wszystkie dostępne warianty smakowe wkładów neo™ oraz pełną gamę kolorystyczną glo™ hyper+ UNIQ. ",
            "answers": [
                {
                    "ident": "a",
                    "content": "Prawda",
                },
                {
                    "ident": "b",
                    "content": "Fałsz"
                }
            ],
            "correct": "a"
        },
        {
            "ident": "q2",
            "content": "W punktach eSmoking World nie podpiszesz umowy na pakiet subskrypcji wkładów neo™.",
            "answers": [
                {
                    "ident": "a",
                    "content": "Prawda"
                },
                {
                    "ident": "b",
                    "content": "Fałsz"
                }
            ],
            "correct": "b"
        },
        {
            "ident": "q3",
            "content": "W całej Polsce znajdziesz około 400 punktów sprzedaży eSmoking World.",
            "answers": [
                {
                    "ident": "a",
                    "content": "Prawda"
                },
                {
                    "ident": "b",
                    "content": "Fałsz"
                }
            ],
            "correct": "a"
        },
        {
            "ident": "q4",
            "content": "Doradcy eSmoking World nie pomogą Ci w kwestiach technicznych urządzenia, ale za to dopasują wariant smakowy neo™ do Twoich preferencji.",
            "answers": [
                {
                    "ident": "a",
                    "content": "Prawda"
                },
                {
                    "ident": "b",
                    "content": "Fałsz"
                }
            ],
            "correct": "b"
        },
    ]
}