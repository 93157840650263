import Mobile from "./img/bg-mobile.jpg"
import Desktop from "./img/bg-desktop.jpg"
import DesktopSide from "./img/desktop-side.jpg"
import reward0 from "./img/reward0.jpg"
import reward1 from "./img/reward1.jpg"
import reward2 from "./img/reward2.jpg"
import Pack0 from "./img/pack0.jpg"
import Pack1 from "./img/pack1.jpg"
import Pack2 from "./img/pack2.jpg"
import Pack3 from "./img/pack3.jpg"
import Pack4 from "./img/pack4.jpg"
import Pack5 from "./img/pack5.jpg"
import Pack6 from "./img/pack6.jpg"
import Pack7 from "./img/pack7.jpg"
import Pack8 from "./img/pack8.jpg"
import Pack9 from "./img/pack9.jpg"
import Pack10 from "./img/pack10.jpg"
import Pack11 from "./img/pack11.jpg"

export const konkurs1_czerwiec = {
    backgroundMobile: Mobile,
    backgroundDesktop: Desktop,
    desktopSide: DesktopSide,
    title: "Słuchajcie! Ostatnio glo™ wróciło \nz niesamowitej podróży, ale zapomniało o robieniu fotek.",
    subtitle: "Tu wkraczacie WY! Pokażcie nam, gdzie zawędrowało glo™ oczami Waszej wyobraźni i kamery. ",
    message: "",
    points: 20,
    statute: "/assets/Regulamin Konkurs_glo_AlterZone_14.02.pdf",
    header: "Atrakcyjny weekend z neo™",
    ident: "konkurs1_czerwiec",
    gameType: "Contest",

    rewards: [
        {
            img: reward0,
            place: "1-3",
            content: "Mi Electric Scooter Pro 2 Mercedes-AMG Petronas",
            sub: "(F1 Team Edition)"
        },
        {
            img: reward1,
            place: "4-10",
            content: "Voucher 250€ na Ryanair"
        },
        {
            img: reward2,
            place: "11-20",
            content: "JBL Clip 4",
            sub: "(czarny)"
        },
    ],

    questions: [
        {
            content: "Jak spędzisz wolny weekend?",
            answers: [
                "Nad morzem",
                "City Tour",
                "W górach"
            ],
        },
        {
            content: "Wybierz <span>3 paczki neo™</span>, które ze sobą zabierzesz:",
            answers: [
                Pack0,
                Pack1,
                Pack2,
                Pack3,
                Pack4,
                Pack5,
                Pack6,
                Pack7,
                Pack8,
                Pack9,
                Pack10,
                Pack11,

            ],
        },
        {
            content: "Scena jest Twoja! Pora się rozmarzyć.",
            sub: "Napisz 3-dniowy plan wydarzenia, które zorganizujesz (festiwal, domówka – Ty decydujesz). Pamiętaj, tylko żeby daną imprezę osadzić w wybranym przez Ciebie miejscu.",
            inputs: [
                "Nazwa wydarzenia",
                "Atrakcja 1. dnia",
                "Atrakcja 2. dnia",
                "Atrakcja 3. dnia"
            ]
        }
    ],

    correct: {
        title: "Wow! Szykuje się mega wydarzenie",
        content: [
            "Z takimi pomysłami czeka nas prawdziwa moc atrakcji.",
            "Zwycięzców w konkursie będzie dwudziestu, ale już teraz trzymamy za Ciebie kciuki.",
            "W oczekiwaniu na wyniki Twoje konto zasiliło:"
        ]
    },
    wrong: {
        title: "Ups... cos poszło nie tak",
        content: [
            "Twój plan nie wypalił. Spróbuj raz jeszcze rozpisać atrakcje na Twoim wymarzonym wydarzeniu."
        ]
    },
}