import Part0 from "./img/piece0.jpg"
import Part1 from "./img/piece1.jpg"
import Part2 from "./img/piece2.jpg"
import Part3 from "./img/piece3.jpg"
import Part4 from "./img/piece4.jpg"
import Part5 from "./img/piece5.jpg"
import Part6 from "./img/piece6.jpg"
import Part7 from "./img/piece7.jpg"
import Part8 from "./img/piece8.jpg"
import NeoFoamClick from "./img/Neo_foam_click.png"
import NeoMeltClick from "./img/Neo_melt_click.png"

export const questions = {
    "gameType": "JigsawRelaxNeo",
    "glo": [
        {
            "ident": "q0",
            "content": "Dopasuj opis aromatu do konkretnego wkładu neo™",
            "sub": "“Połączenie słodkich i miętowych nut.”",
            "answers": [
                {
                    "ident": "a",
                    "content": "neo™ Foam Click",
                    "img": NeoFoamClick
                },
                {
                    "ident": "b",
                    "content": "neo™ Melt Click",
                    "img": NeoMeltClick
                },
            ]
        },
        {
            "ident": "q1",
            "content": "Dopasuj opis aromatu  do konkretnego wkładu neo™.",
            "sub": "“Bogata kremowa nuta z dodatkiem karmelu.”",
            "answers": [
                {
                    "ident": "a",
                    "content": "neo™ Foam Click",
                    "img": NeoFoamClick
                },
                {
                    "ident": "b",
                    "content": "neo™ Melt Click",
                    "img": NeoMeltClick
                },
            ]
        },
        {
            "ident": "q2",
            "content": "Odpręż się przy układance.",
            "sub": "Zaznacz dwa sąsiadujące fragmenty, aby je przesunąć. Kafelki mogą poruszać się tylko w pionie i poziomie.",
            "answers": [
                {
                    "ident": "d95b92a3-64f6-40b1-ace1-1d0d4703cb8e",
                    "content": Part0
                }, 
                {
                    "ident": "5af74029-1023-4a09-ab31-8e7334a4da76",
                    "content": Part1
                }, 
                {
                    "ident": "d90e3ffc-c152-4200-ad2f-8fbcc3a7c668",
                    "content": Part2
                }, 
                {
                    "ident": "5153cefb-65d1-4e77-ba6f-c72d4c47d2dd",
                    "content": Part3
                }, 
                {
                    "ident": "ea1ef602-2ff3-4acd-a961-a190865d5eb3",
                    "content": Part4
                }, 
                {
                    "ident": "d05afa58-de4d-4f04-8c5e-5084cc6c6fb2",
                    "content": Part5
                }, 
                {
                    "ident": "327de6c1-3b39-446f-afb3-8f649d1dccf9",
                    "content": Part6
                }, 
                {
                    "ident": "6dbc7d27-38e9-4f1b-b8f6-e8c4907d0b57",
                    "content": Part7
                }, 
                {
                    "ident": "f4b5f27d-18ff-4b0c-adb0-7052ff141a3f",
                    "content": Part8
                },
            ]
        },
    ]
}