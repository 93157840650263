import arctic from "./img/arctic.webp"
import foam from "./img/foam.webp"
import gold from "./img/gold.webp"
import green from "./img/green.webp"
import ice from "./img/ice.webp"
import melt from "./img/melt.webp"
import rounded from "./img/rounded.webp"
import scarlet from "./img/scarlet.webp"
import summer from "./img/summer.webp"
import sunset from "./img/sunset.webp"
import terracotta from "./img/terracotta.webp"
import violet from "./img/violet.webp"
import bracelet from "./img/bracelet.webp"
import brush from "./img/brush.webp"
import gloCase from "./img/case.webp"
import panels from "./img/panels.webp"
import transparentCase from "./img/transparent-case.webp"
import uniqBlack from "./img/uniq-black.webp"
import uniqBlue from "./img/uniq-blue.webp"
import uniqGold from "./img/uniq-gold.webp"
import uniqWhite from "./img/uniq-white.webp"
import usbc from "./img/usbc.webp"
import x2Black from "./img/x2-black.webp"
import x2Blue from "./img/x2-blue.webp"
import x2Gold from "./img/x2-gold.webp"
import x2Orange from "./img/x2-orange.webp"
import x2Red from "./img/x2-red.webp"
import x2White from "./img/x2-white.webp"


export const questions = {
    "gameType": "X2Poll",
    "glo": [
        {
            "ident": "q1",
            "content": "Który wariant smakowych neo™ jest Twoim ulubionym?",
            "sub": "Wybierz maksymalnie 3 warianty",
            "type": "image",
            "multiSelect": true,
            columns: 4,
            "answers": [
                {
                    "ident": "0",
                    "content": "neo™ Melt\nClick",
                    img: melt
                },
                {
                    "ident": "1",
                    "content": "neo™ Foam\nClick",
                    img: foam
                },
                {
                    "ident": "2",
                    "content": "neo™ Gold\nTobacco",
                    img: gold
                },
                {
                    "ident": "3",
                    "content": "neo™ Rounded\nTobacco",
                    img: rounded
                },
                {
                    "ident": "4",
                    "content": "neo™ Terracotta\nTobacco",
                    img: terracotta
                },
                {
                    "ident": "5",
                    "content": "neo™ Ice\nClick",
                    img: ice
                },
                {
                    "ident": "6",
                    "content": "neo™ Green\nClick",
                    img: green
                },
                {
                    "ident": "7",
                    "content": "neo™ Scarlet\nClick",
                    img: scarlet
                },
                {
                    "ident": "8",
                    "content": "neo™ Sunset\nClick",
                    img: sunset
                },
                {
                    "ident": "9",
                    "content": "neo™ Violet\nClick",
                    img: violet
                },
                {
                    "ident": "10",
                    "content": "neo™ Arctic\nBlue Click",
                    img: arctic
                },
                {
                    "ident": "11",
                    "content": "neo™ Summer\nYellow Click",
                    img: summer
                },
            ]
        },
        {
            "ident": "q2",
            "content": "Wybierz najlepszy podgrzewacz tytoniu glo™",
            "type": "image",
            columns: 2,
            "answers": [
                {
                    "ident": "0",
                    "content": "glo™ hyper\nX2",
                    img: x2Black,
                    nextIndex: 2
                },
                {
                    "ident": "1",
                    "content": "glo™ hyper+\nUNIQ",
                    img: uniqBlack,
                    nextIndex: 3
                },
            ]
        },
        {
            "ident": "q3_1",
            "content": "Który kolor glo™ hyper X2 jest Twoim ulubionym?",
            "type": "image",
            columns: 3,
            nextIndex: 4,
            "answers": [
                {
                    "ident": "0",
                    "content": "Black",
                    img: x2Black
                },
                {
                    "ident": "1",
                    "content": "Black/Red",
                    img: x2Red
                },
                {
                    "ident": "2",
                    "content": "White/Gold",
                    img: x2Gold
                },
                {
                    "ident": "3",
                    "content": "Green/Pine",
                    img: x2White
                },
                {
                    "ident": "4",
                    "content": "Blue",
                    img: x2Blue
                },
                {
                    "ident": "5",
                    "content": "Orange",
                    img: x2Orange
                },
            ]
        },
        {
            "ident": "q3_2",
            "content": "Który kolor glo™ hyper+ UNIQ jest Twoim ulubionym?",
            "type": "image",
            columns: 4,
            nextIndex: 4,
            "answers": [
                {
                    "ident": "0",
                    "content": "Black",
                    img: uniqBlack
                },
                {
                    "ident": "1",
                    "content": "Gold",
                    img: uniqGold
                },
                {
                    "ident": "2",
                    "content": "Blue",
                    img: uniqBlue
                },
                {
                    "ident": "3",
                    "content": "White",
                    img: uniqWhite
                },
            ]
        },
        {
            "ident": "q4",
            "content": "Wybierz najlepsze akcesoria",
            "sub": "Wybierz maksymalnie 3 warianty",
            "type": "image",
            "multiSelect": true,
            columns: 3,
            "answers": [
                {
                    "ident": "0",
                    "content": "Etui dla\nglo™",
                    img: gloCase
                },
                {
                    "ident": "1",
                    "content": "Przezroczyste\netui dla glo™",
                    img: transparentCase
                },
                {
                    "ident": "2",
                    "content": "Bransoletka USB do\nładowania glo™",
                    img: bracelet
                },
                {
                    "ident": "3",
                    "content": "Kabel USB-C do\nglo™",
                    img: usbc
                },
                {
                    "ident": "4",
                    "content": "Szczoteczka do\nczyszczenia glo™",
                    img: brush
                },
                {
                    "ident": "5",
                    "content": "Panel wymienny do\nglo™ hyper+ UNIQ",
                    img: panels
                },
            ]
        },
    ]
}