export const errorDefinitions = [
  {
    ident: "error_otp_please_wait",
    title: "Kod został już wysłany",
    desc: "Sprawdź wiadomości SMS i przepisz kod.",
    button: "Zamknij",
    returnUrl: null,
    disableClose: false,
    updateUserData: false
  },
  {
    ident: "error_wrong_otp",
    title: "Nieprawidłowy kod SMS",
    desc: "Sprawdź czy poprawnie wpisałeś kod z wiadomości SMS",
    button: "Zamknij",
    returnUrl: null,
    disableClose: false,
    updateUserData: false
  },
  {
    ident: "error_catalogue_not_found",
    title: "Ta nagroda jest niedostępna.",
    desc: "Chociaż jak tu jesteś, to może zerkniesz na katalog nagród? <br/>Wybierz coś fajnego dla siebie!",
    button: "Katalog nagród",
    returnUrl: "/nagrody/katalog",
    disableClose: true,
    updateUserData: false
  },
  {
    ident: "error_wrong_password",
    title: "Wprowadzone hasło jest błędne.",
    desc: "Upewnij się, że wpisujesz poprawne hasło i spróbuj ponownie.",
    button: "Ok",
    returnUrl: null,
    disableClose: false,
    updateUserData: false
  },
  {
    ident: "error_email_change_please_wait",
    title: "Nie tak szybko.",
    desc: "Jesteś już w trakcie zmiany adresu e-mail. Sprawdź swój mail i dokończ proces.",
    button: "Ok",
    returnUrl: null,
    disableClose: false,
    updateUserData: false
  },
  {
    ident: "error_mobile_change_please_wait",
    title: "Nie tak szybko.",
    desc: "Jesteś już w trakcie zmiany numeru telefonu. Sprawdź wiadomości SMS i dokończ proces.",
    button: "Ok",
    returnUrl: null,
    disableClose: false,
    updateUserData: false
  },
  {
    ident: "error_mobile_phone_taken",
    title: "Nie możemy dokonać zmiany.",
    desc: "Podany numer telefonu jest już przypisany do innego konta. Sprawdź, czy wpisujesz poprawny numer telefonu. Jeśli problem się pojawia, skontaktuj się z nami pod adresem daneosobowe@bat.com.pl.",
    button: "Ok",
    returnUrl: null,
    disableClose: false,
    updateUserData: false
  },
  {
    ident: "error_email_taken",
    title: "Nie możemy dokonać zmiany.",
    desc: "Konto o podanym adresie e-mail już istnieje. Jeśli nie masz dostępu do poprzedniego konta, spróbuj zresetować hasło lub skontaktuj się z nami pod adresem: daneosobowe@bat.com.pl.",
    button: "Ok",
    returnUrl: null,
    disableClose: false,
    updateUserData: false
  },
  {
    ident: "error_voucher_please_wait",
    title: "Nie tak szybko - SMS jest już w drodze",
    desc: "SMS z linkiem do odbioru kuponu został wysłany przy poprzedniej próbie i jest już w drodze na podany poprzednio numer. Jeżeli nie otrzymasz go w ciągu 5 minut sprawdź wpisywany numer telefonu i spróbuj ponownie.",
    button: "Zamknij",
    returnUrl: "/login",
    disableClose: false,
    updateUserData: false
  },
  {
    ident: "error_wrong_user_status",
    title: "Twoje konto jest już aktywne - czas się zalogować.",
    desc: "Jeżeli nie pamiętasz swojego hasła, skorzystaj z opcji „Nie pamiętam hasła\", by ustawić nowe.",
    button: "Ok",
    returnUrl: "/login",
    disableClose: false,
    updateUserData: false
  },
  {
    ident: "error_token_invalid",
    title: "Twój link stracił ważność lub jest niepoprawny",
    desc: "Skorzystaj z opcji „Nie pamiętam hasła\", by uzyskać nowy link ustawienia hasła.",
    button: "Ok",
    returnUrl: "/login",
    disableClose: false,
    updateUserData: false
  },
  {
    ident: "error_unauthorized",
    title: "Wylogowano lub sesja wygasła.",
    desc: "Przejdź do strony logowania.",
    button: "Zaloguj się ponownie",
    returnUrl: null,
    disableClose: false,
    updateUserData: false,
    logout: true
  },
  {
    ident: "error_account_temporary_blocked",
    title: "Twoje konto zostało tymczasowo zablokowane.",
    desc: "<p>Wykryliśmy nietypową aktywność na Twoim koncie. Aktualnie weryfikujemy tę sytuację i wkrótce skontaktujemy się z Tobą.</p><p>Jeśli potrzebujesz pomocy, napisz na adres: <a href=\"mailto:kontakt@alterzone.pl\">kontakt@alterzone.pl</a><br/>Przepraszamy za niedogodności.</p>",
    button: "Zamknij",
    returnUrl: null,
    disableClose: false,
    updateUserData: false
  },
  {
    ident: "error_account_blocked",
    title: "Twoje konto zostało tymczasowo zablokowane.",
    desc: "<p>Wykryliśmy nietypową aktywność na Twoim koncie. Aktualnie weryfikujemy tę sytuację i wkrótce skontaktujemy się z Tobą.</p><p>Jeśli potrzebujesz pomocy, napisz na adres: <a href=\"mailto:kontakt@alterzone.pl\">kontakt@alterzone.pl</a><br/>Przepraszamy za niedogodności.</p>",
    button: "Zamknij",
    returnUrl: null,
    disableClose: false,
    updateUserData: false
  },
  {
    ident: "error_m2_already_registered",
    title:"Konto o podanym adresie już istnieje.",
    desc:"Jeśli posiadasz konto w sklepie internetowym discoverglo.com lub na platformie Masz Wybór użyj tych samych danych logowania. <br/>Spróbuj się zalogować lub kliknij „Nie pamiętam hasła”, aby je zresetować.",
    button: "Zmień hasło",
    returnUrl: "/login/reset",
    disableClose: false,
    updateUserData: false
  },
  {
    ident: "error_m2_wrong_credentials_or_disabled",
    title:"Podany e-mail lub hasło są nieprawidłowe.",
    desc:"Upewnij się, że poprawnie wpisujesz dane. Spróbuj zalogować się raz jeszcze.",
    button: "Zaloguj się ponownie",
    returnUrl: null,
    disableClose: false,
    updateUserData: false
  },
  {
    ident: "error_m2_empty_field",
    title: "Wystąpił błąd.",
    desc: " Spróbuj jeszcze raz.",
    button: "Zamknij",
    returnUrl: null,
    disableClose: false,
    updateUserData: false
  },
  {
    ident: "error_token_has_been_already_used",
    title: "Wystąpił błąd.",
    desc: "Przejdź do resetu hasła.",
    button: "Zaloguj się ponownie",
    returnUrl: null,
    disableClose: true,
    updateUserData: false
  },
  {
    ident: "error_customer_not_found_by_email",
    title: "Wystąpił błąd.",
    desc: "Zacznij od początku.",
    button: "Zamknij",
    returnUrl: null,
    disableClose: false,
    updateUserData: false
  },
  {
    ident: "error_customer_not_found_by_token",
    title: "Wystąpił błąd.",
    desc: "Zacznij od początku.",
    button: "Zaloguj się ponownie",
    returnUrl: null,
    disableClose: true,
    updateUserData: false
  },
  {
    ident: "error_already_answered",
    title: "Wystąpił błąd.",
    desc: "Odpowiedź na pytanie konkursowe została już przesłana.",
    button: "Zamknij",
    returnUrl: null,
    disableClose: false,
    updateUserData: false
  },
  {
    ident: "error_max_limit_of_solutions_exceeded",
    title: "Wystąpił błąd.",
    desc: "Wykonałeś już to zadanie. Sprawdź pozostałe aktywności.",
    button: "Zamknij",
    returnUrl: "/aktywnosci",
    disableClose: false,
    updateUserData: false
  },
  {
    ident: "error_token_expired",
    title: "Twój link stracił już ważność.",
    desc: 'Skorzystaj z opcji „Nie pamiętam hasła", by uzyskać nowy link ustawienia hasła.',
    button: "Ok",
    returnUrl: null,
    disableClose: true,
    updateUserData: false
  },
  {
    ident: "error_voucher_already_assigned",
    title: "Wystąpił błąd.",
    desc: "Podany numer telefonu istnieje już w bazie, spróbuj ponownie podając nowy numer telefonu",
    button: "Zamknij",
    returnUrl: null,
    disableClose: false,
    updateUserData: false
  },
  {
    ident: "error_verification_required",
    title: "Ograniczyliśmy dostęp do Twojego konta.",
    desc: '<p>Wykryliśmy nietypową aktywność na koncie, do którego próbujesz się zalogować.* Ze względów bezpieczeństwa i w celu zweryfikowania informacji tymczasowo zablokowaliśmy do niego dostęp.</p><p>Aby odblokować konto, postępuj zgodnie z instrukcją, którą wysłaliśmy na Twój adres e-mail.</p><p class="disclaimer">*Blokada konta pojawia się, jeśli wykryjemy naruszenie zasad korzystania z platformy Masz Wybór. <a href="/assets/regulamin_serwisu_maszwybor.pdf" target="_blank">Dowiedz się więcej</a></p>',
    button: "Zamknij",
    returnUrl: null,
    disableClose: false,
    updateUserData: false
  },
  {
    ident: "error_m2_token_error",
    title: "Ups, coś poszło nie tak.",
    desc: '<p>Skorzystaj z opcji "nie pamiętam hasła", by ustawić nowe hasło.</p>',
    button: "Zamknij",
    returnUrl: null,
    disableClose: false,
    updateUserData: false
  },
  {
    ident: "first_voucher",
    title: "Cześć!",
    desc: "<p><strong>Przy wymianie ponad 200 punktów w danym miesiącu na nagrody, potrzebne będzie uzupełnienie dodatkowych danych koniecznych do wystawienia Ci PIT-11.</strong></p><p>Przy próbie pobrania nagrody skontaktujemy się z Tobą mailowo w celu zebrania niezbędnych danych, a następnie przekażemy Ci Twoją nagrodę.</p>",
    button: "Zamknij",
    returnUrl: null,
    disableClose: false,
    updateUserData: false
  },
  {
    ident: "error_monthly_limit_exceeded", // Po kliknięciu "Dalej" docelowo pokazujemy tax form, przydałby sie projekt. Do uzupełnienia opis poniżej.
    title: "Wystąpił błąd.",
    desc: "<p><strong>Aktualnie nie można odebrać nagrody, przepraszamy za problemy techniczne.</strong></p>",
    button: "Dalej",
    returnUrl: null,
    disableClose: false,
    updateUserData: false,
    customAction: "showTaxForm" // Ident of custom action.
  },
  {
    ident: "error_prize_exception",
    title: "Wystąpił błąd.",
    desc: "<p><strong>Aktualnie nie można odebrać nagrody, przepraszamy za problemy techniczne.</strong></p>",
    button: "Zamknij",
    returnUrl: null,
    disableClose: false,
    updateUserData: false
  },
  {
    ident: "generic",
    title: "Ups, coś poszło nie tak.",
    desc: "Wystąpił błąd. Spróbuj jeszcze raz.",
    button: "Zamknij",
    returnUrl: null,
    disableClose: false,
    updateUserData: false
  },
  {
    ident: "error_contest_closed",
    title: "Wystąpił błąd.",
    desc: 'Konkurs stracił ważność.',
    button: "Zamknij",
    returnUrl: null,
    disableClose: true,
    updateUserData: false
  },
  {
    ident: "error_slot_chosen",
    title: "Wystąpił błąd.",
    desc: 'Miejsce na lot zostało już zarezerwowane.',
    button: "Zamknij",
    returnUrl: "null",
    disableClose: false,
    updateUserData: false
  },
  {
    ident: "error_slot_taken",
    title: "Wystąpił błąd.",
    desc: 'Wybrana data i godina jest już niedostępna',
    button: "Zamknij",
    returnUrl: "null",
    disableClose: false,
    updateUserData: false
  },
  {
    ident: "error_account_restricted",
    title: "Ograniczenie dostępu",
    desc: 'Wykryliśmy nietypową aktywność na koncie niezgodną z regulaminem programu lojalnościowego Alter Zone. Ze względów bezpieczeństwa  ograniczyliśmy dostęp do niektórych funkcjonalności konta.',
    button: "Zamknij",
    returnUrl: "null",
    disableClose: false,
    updateUserData: false
  },
  {
    ident: "error_monthlyBonus_locked",
    title: "Ta nagroda jest niedostępna.",
    desc: '',
    button: "Zamknij",
    returnUrl: "/",
    disableClose: false,
    updateUserData: false
  },
  {
    ident: "error_monthlyBonus_already_purchased",
    title: "Nagroda została już odebrana",
    desc: '',
    button: "Zamknij",
    returnUrl: "/",
    disableClose: false,
    updateUserData: false
  },
  {
    ident: "error_subscription_slot_not_found",
    title: "Wybrany termin jest już niedostępny.",
    desc: 'Spróbuj wybrać inny.',
    button: "Zamknij",
    returnUrl: null,
    disableClose: false,
    updateUserData: false
  },
  {
    ident: "error_subscription_slot_already_used",
    title: "Wybrany termin jest już niedostępny.",
    desc: 'Spróbuj wybrać inny.',
    button: "Zamknij",
    returnUrl: null,
    disableClose: false,
    updateUserData: false
  },
  {
    ident: "error_subscription_slot_not_available",
    title: "Wybrany termin jest już niedostępny.",
    desc: 'Spróbuj wybrać inny.',
    button: "Zamknij",
    returnUrl: null,
    disableClose: false,
    updateUserData: false
  },
  {
    ident: "error_subscription_form_limit_exceeded",
    title: "Przekroczono limit rezerwacji.",
    desc: 'Każdy użytkownik może dokonać maksymalnie trzech rezerwacji terminu.',
    button: "Ok",
    returnUrl: null,
    disableClose: false,
    updateUserData: false
  },
  {
    ident: "error_daily_limit_exceeded",
    type: "small_popup_random",
    title: "",
    desc: "",
    randomCopy: [
      {
        title: "To Twoja ulubiona nagroda?",
        desc: "Widzimy, że trafiła ona już dziś do Ciebie. Wróć jutro, aby odebrać ją ponownie lub przejrzyj katalog i&nbsp;wybierz coś równie interesującego!"
      },
      {
        title: "Co jest grane?",
        desc: "Ahh… kolejna taka sama nagroda. Jeżeli tak bardzo Ci na niej zależy, to wróć po nią jutro. Chociaż może zerkniesz na katalog? Są tam równie interesujące nagrody. "
      },
      {
        title: "Uwielbiasz tę nagrodę?",
        desc: "Widzimy, że trafiła ona już dziś do Ciebie. Wróć jutro, aby odebrać ją ponownie lub przejrzyj katalog i&nbsp;dokonaj kolejnego wyboru! "
      },
      {
        title: "Dajesz czadu!",
        desc: "Pozwól sobie na chwilę radości z poprzednią nagrodą. Jutro odbierzesz ją ponownie. A&nbsp;jeśli nie chcesz czekać z&nbsp;założonymi rękami – zajrzyj do katalogu po inne fajne kupony."
      },
      {
        title: "Wyszło na jaw, że pociąga Cię ta nagroda.",
        desc: "Widzimy, że trafiła ona już dziś do Ciebie. Poczekaj do jutra, aby ponownie się z nią spotkać. Chociaż po co czekać, jeśli można wydać punkty na inną nagrodę."
      }
    ],
    button: "Katalog nagród",
    returnUrl: "/nagrody/katalog",
    disableClose: false,
    updateUserData: false
  }
]

export const customActions = {
  showTaxForm: () => { console.log("showTaxForm") }
}