import React, { useState, useEffect } from 'react'
import Modal from "../Modal/Modal"
import Button from "../Button/Button"
import Send from "../../../img/send.svg"
import "./style.scss"
import * as serviceWorkerRegistration from '../../../serviceWorkerRegistration';

export default function PushNotificationModal({notify, setNotify}) {
  const [buttonClickCount, setButtonClickCount] = useState(0)

  const handleAllowButton = () => {
    serviceWorkerRegistration.unregister()
    setButtonClickCount(prev => prev + 1)
    setTimeout(() => {
      document.querySelector(".sp_notify_prompt").click()
      if (buttonClickCount === 3 || notify.permission === "granted") {
        setNotify({ permission: "prompt", showModal: false })
      }
    }, 500)
  }

  return (
    <>
      <Modal isOpen={notify?.showModal && notify?.permission === "prompt"} buttonClose={false} modalClass={"narrow"}>
        <div className="push-notification-modal">
          <div className="upper-bar">
            <img src={Send} alt="" />
            <button onClick={() => setNotify({ permission: "prompt", showModal: false })}>
              <div className="exit" />
            </button>
          </div>
          <div className="text">
            <h4>Bądź na bieżąco z nowymi aktywnościami, newsami oraz nagrodami</h4>
            <p>Zezwól stronie Alter Zone na wysyłkę powiadomień.</p>
          </div>
          <div className="button-wrapper">
            <Button action={() => setNotify({ permission: "prompt", showModal: false })} btnClass="white">Odrzuć</Button>
            <Button action={handleAllowButton}>Zezwól</Button>
          </div>
        </div>
      </Modal>
    </>
  )
}
