import Book from "./img/book.png"
import Cup from "./img/cup.png"
import Headphones from "./img/headphones.png"
import Laptop from "./img/laptop.png"
import Phone from "./img/phone.png"
import X2 from "./img/x2.png"

export const questions = {
    "gameType": "AirWeight",
    "glo": [
        {
            "answers": [
                {

                    ident: "cf34a9f1-78d4-404f-9b31-1f1b18523dcd",
                    img: X2,
                    weight: "100 g",
                    content: {
                        title: "glo Hyper X2:",
                        desc: "glo™ hyper X2 air jest o 30% lżejsze"
                    }
                },
                {

                    ident: "b542a653-a6c7-4a57-a96c-e0ba8ed7bb01",
                    img: Phone,
                    weight: "200 g",
                    content: {
                        title: "Smartfon:",
                        desc: "glo™ hyper X2 air jest o 60% lżejsze"
                    }
                },
                {

                    ident: "e5ff66dc-73b9-456c-8443-df980e1577fd",
                    img: Book,
                    weight: "300 g",
                    content: {
                        title: "Książka:",
                        desc: "glo™ hyper X2 air jest o 75% lżejsze"
                    }
                },
                {
                    ident: "2d28ee28-7aae-4943-9e8c-fcca4ec581a3",
                    img: Laptop,
                    weight: "1500 g",
                    content: {
                        title: "Laptop:",
                        desc: "glo™ hyper X2 air jest o 95% lżejsze"
                    }
                },
                {

                    ident: "2247915a-f7cb-4e94-8770-e21160ffd67d",
                    img: Headphones,
                    weight: "200 g",
                    content: {
                        title: "Słuchawki nauszne:",
                        desc: "glo™ hyper X2 air jest o 60% lżejsze"
                    }
                },
                {
                    ident: "deaa3bfc-e96f-44d8-a7eb-63a1413caa72",
                    img: Cup,
                    weight: "350 g",
                    content: {
                        title: "Kubek termiczny:",
                        desc: "glo™ hyper X2 air jest o 80% lżejsze"
                    }
                },
            ],
        },
    ]
}