import React, { useState } from 'react'
import { ReactComponent as Egg } from './img/pack.svg';
import { usePopper } from 'react-popper';
import EasterPopup from './EasterPopup';
import { animated, useTransition } from '@react-spring/web'
import "./style.scss"
import { sendEvent, useSessionStorage } from '../../Helpers';

const PackMap = new Map([
  [1, { uuid: "12a6bc45-9afd-47d2-904b-dd5c0c897e68", img: require("./img/1.jpg") }],
  [2, { uuid: "18be3fe5-e704-43a7-bfbc-71154db5f236", img: require("./img/2.jpg") }],
  [3, { uuid: "f7cce9a9-b143-484e-8880-877f40331502", img: require("./img/3.jpg") }],
  [4, { uuid: "523fd7b2-256b-4db4-8e5d-1c5bafa65b15", img: require("./img/4.jpg") }],
  [5, { uuid: "9f5140bb-9da9-4aac-8823-e5176c597895", img: require("./img/5.jpg") }],
  [6, { uuid: "3302c50b-653a-4a56-bdef-c8fda66aca88", img: require("./img/6.jpg") }],
  [7, { uuid: "a2feba69-03a4-4582-af9c-e7cb42aa643e", img: require("./img/7.jpg") }],
])

export const getEasterEggArray = () => {
  try {
    return JSON.parse(window.sessionStorage.getItem("foundEasterEggs")) ?? []

  } catch (e) {
    return []
  }
}

export default function EasterEgg({ type, style }) {
  const [popupOpen, setPopupOpen] = useState(false)
  const [foundEasterEggs, setFoundEasterEggs] = useSessionStorage("foundEasterEggs", [])
  const easterGameFinished = JSON.parse(window.sessionStorage.getItem("easterFinished")) ?? false
  const [clicked, setClicked] = useState(false)
  const [box, setBox] = useState(null)
  const [popup, setPopup] = useState(null)

  const { styles, attributes } = usePopper(box, popup, {
    placement: 'top',
    modifiers: [
      {
        name: "offset",
        options: {
          offset: [0, 8]
        }
      },
      {
        name: "eventListeners",
        enabled: popupOpen
      },
      {
        name: "preventOverflow",
        options: {
          altAxis: true,
          padding: { top: 100, left: 10 }
        }
      }
    ]
  });

  // const translateArr = styles.popper.transform?.match(/(-\d+|\d+)(,\d+)*(\.\d+)*/g).map(Number) ?? []

  // console.log(translateArr)

  // console.log(`translate(${translateArr[0]/2}px, 0px)`)

  const transitionPopup = useTransition(foundEasterEggs.includes(PackMap.get(type).uuid) && popupOpen, {
    from: {
      opacity: 0,
      scale: 0,
    },
    enter: {
      opacity: 1,
      width: "266px",
      scale: 1,
    },
    leave: {
      opacity: 0,
      scale: 0,
    }
  })

  const transitionEgg = useTransition(!foundEasterEggs.includes(PackMap.get(type).uuid) || popupOpen, {
    enter: {
      opacity: 1,
    },
    leave: {
      opacity: 0,
    }
  })

  const onEggClick = e => {
    if (clicked) {
      return
    }
    e.preventDefault()
    setPopupOpen(true)
    setClicked(true)
    window.sessionStorage.setItem("hideEasterCounter", false)
    window.dispatchEvent(new Event("storage"));
    if (foundEasterEggs.length === 0) {
      setFoundEasterEggs([PackMap.get(type).uuid])
      sendEvent("kwiecien1_2023_START")
    } else {
      setFoundEasterEggs(prev => [...prev, PackMap.get(type).uuid])
    }
  }

  if (easterGameFinished) {
    return <></>
  }

  return (
    <div className='easter-egg' style={style}>
      {transitionEgg((style, item) => (
        item ? <animated.div style={{ ...style }}><img src={PackMap.get(type).img} alt="" onClick={e => onEggClick(e)} ref={setBox} /></animated.div> : ""
      ))
      }
      {transitionPopup((style, item) => (
        item ? <EasterPopup popupOpen={popupOpen} attributes={attributes} setPopup={setPopup} setPopupOpen={setPopupOpen} styles={{ ...styles.popper, ...style }} />
          : ""
      ))}
    </div>
  )
}
