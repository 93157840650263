export const questions = {
    "gameType": "SubscriptionRange",
    "glo": [
        {
            "ident": "q0",
            "content": "Stwórz pakiet, w którym<br/><span>oszczędzasz 120,40 zł w miesiącu.</span>",
            "values": {
                "packNames": [
                    {
                        "ident": "a",
                        "value": "Silver"
                    },
                    {
                        "ident": "b",
                        "value": "Gold"
                    },
                    {
                        "ident": "c",
                        "value": "Platinum"
                    },
                    {
                        "ident": "d",
                        "value": "Diamond"
                    },
                ],
                "pricePerPack": [
                    {
                        "ident": "a",
                        "value": "8,49 zł"
                    },
                    {
                        "ident": "b",
                        "value": "8,99 zł"
                    },
                    {
                        "ident": "c",
                        "value": "9,49 zł"
                    },
                    {
                        "ident": "d",
                        "value": "9,99 zł"
                    },
                ],
                "packCount": [
                    {
                        "ident": "a",
                        "value": "15"
                    },
                    {
                        "ident": "b",
                        "value": "20"
                    },
                    {
                        "ident": "c",
                        "value": "30"
                    },
                    {
                        "ident": "d",
                        "value": "40"
                    },
                ],
                "packagePrice": [
                    {
                        "ident": "a",
                        "value": "149,85 zł"
                    },
                    {
                        "ident": "b",
                        "value": "189,80 zł"
                    },
                    {
                        "ident": "c",
                        "value": "269,70 zł"
                    },
                    {
                        "ident": "d",
                        "value": "339,60 zł"
                    },
                ]
            },
        },
        {
            "ident": "q1",
            "content": "Stwórz pakiet, w którym<br/><span>oszczędzasz 75,30 zł w miesiącu.</span>",
            "values": {
                "packNames": [
                    {
                        "ident": "a",
                        "value": "Silver"
                    },
                    {
                        "ident": "b",
                        "value": "Gold"
                    },
                    {
                        "ident": "c",
                        "value": "Platinum"
                    },
                    {
                        "ident": "d",
                        "value": "Diamond"
                    },
                ],
                "pricePerPack": [
                    {
                        "ident": "a",
                        "value": "8,49 zł"
                    },
                    {
                        "ident": "b",
                        "value": "8,99 zł"
                    },
                    {
                        "ident": "c",
                        "value": "9,49 zł"
                    },
                    {
                        "ident": "d",
                        "value": "9,99 zł"
                    },
                ],
                "packCount": [
                    {
                        "ident": "a",
                        "value": "15"
                    },
                    {
                        "ident": "b",
                        "value": "20"
                    },
                    {
                        "ident": "c",
                        "value": "30"
                    },
                    {
                        "ident": "d",
                        "value": "40"
                    },
                ],
                "packagePrice": [
                    {
                        "ident": "a",
                        "value": "149,85 zł"
                    },
                    {
                        "ident": "b",
                        "value": "189,80 zł"
                    },
                    {
                        "ident": "c",
                        "value": "269,70 zł"
                    },
                    {
                        "ident": "d",
                        "value": "339,60 zł"
                    },
                ]
            },
        },
        {
            "ident": "q2",
            "content": "Stwórz pakiet, w którym<br/><span>oszczędzasz 40,20 zł w miesiącu.</span>",
            "values": {
                "packNames": [
                    {
                        "ident": "a",
                        "value": "Silver"
                    },
                    {
                        "ident": "b",
                        "value": "Gold"
                    },
                    {
                        "ident": "c",
                        "value": "Platinum"
                    },
                    {
                        "ident": "d",
                        "value": "Diamond"
                    },
                ],
                "pricePerPack": [
                    {
                        "ident": "a",
                        "value": "8,49 zł"
                    },
                    {
                        "ident": "b",
                        "value": "8,99 zł"
                    },
                    {
                        "ident": "c",
                        "value": "9,49 zł"
                    },
                    {
                        "ident": "d",
                        "value": "9,99 zł"
                    },
                ],
                "packCount": [
                    {
                        "ident": "a",
                        "value": "15"
                    },
                    {
                        "ident": "b",
                        "value": "20"
                    },
                    {
                        "ident": "c",
                        "value": "30"
                    },
                    {
                        "ident": "d",
                        "value": "40"
                    },
                ],
                "packagePrice": [
                    {
                        "ident": "a",
                        "value": "149,85 zł"
                    },
                    {
                        "ident": "b",
                        "value": "189,80 zł"
                    },
                    {
                        "ident": "c",
                        "value": "269,70 zł"
                    },
                    {
                        "ident": "d",
                        "value": "339,60 zł"
                    },
                ]
            },
        },
        {
            "ident": "q3",
            "content": "Stwórz pakiet, w którym<br/><span>oszczędzasz 22,65 zł w miesiącu.</span>",
            "values": {
                "packNames": [
                    {
                        "ident": "a",
                        "value": "Silver"
                    },
                    {
                        "ident": "b",
                        "value": "Gold"
                    },
                    {
                        "ident": "c",
                        "value": "Platinum"
                    },
                    {
                        "ident": "d",
                        "value": "Diamond"
                    },
                ],
                "pricePerPack": [
                    {
                        "ident": "a",
                        "value": "8,49 zł"
                    },
                    {
                        "ident": "b",
                        "value": "8,99 zł"
                    },
                    {
                        "ident": "c",
                        "value": "9,49 zł"
                    },
                    {
                        "ident": "d",
                        "value": "9,99 zł"
                    },
                ],
                "packCount": [
                    {
                        "ident": "a",
                        "value": "15"
                    },
                    {
                        "ident": "b",
                        "value": "20"
                    },
                    {
                        "ident": "c",
                        "value": "30"
                    },
                    {
                        "ident": "d",
                        "value": "40"
                    },
                ],
                "packagePrice": [
                    {
                        "ident": "a",
                        "value": "149,85 zł"
                    },
                    {
                        "ident": "b",
                        "value": "189,80 zł"
                    },
                    {
                        "ident": "c",
                        "value": "269,70 zł"
                    },
                    {
                        "ident": "d",
                        "value": "339,60 zł"
                    },
                ]
            },
        }
    ]
}