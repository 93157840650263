import glo0 from "./img/glo0.png"
import glo1 from "./img/glo1.png"
import glo2 from "./img/glo2.png"
import glo3 from "./img/glo3.png"
import glo4 from "./img/glo4.png"
import glo5 from "./img/glo5.png"

export const questions = {
    "gameType": "TrueFalse",
    "glo": [
        {
            "ident": "q0",
            "content": "Aromat: Tropikalna nuta ananasa",
            "type": "img",
            "answers": [
                {
                    "ident": "a",
                    "content": glo0,
                    "name": "Sunset Click"
                },
                {
                    "ident": "b",
                    "content": glo1,
                    "name": "Summer Yellow Click"
                }
            ]
        },
        {
            "ident": "q1",
            "content": "Aromat: Pikantne nuty drzewne",
            "type": "img",
            "answers": [
                {
                    "ident": "a",
                    "content": glo3,
                    "name": "Dark Tobacco"
                },
                {
                    "ident": "b",
                    "content": glo2,
                    "name": "Terracotta Tobacco"
                }
            ]
        },
        {
            "ident": "q2",
            "content": "Mroźny mentol i słodka miętowa nuta",
            "type": "img",
            "answers": [
                {
                    "ident": "a",
                    "content": glo4,
                    "name": "Violet Click"
                },
                {
                    "ident": "b",
                    "content": glo5,
                    "name": "Arctic Blue Click"
                }
            ]
        }
    ]
}