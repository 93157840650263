export const questions = {
  gameType: "ArAnkieta",
  glo: [
    {
      ident: "q1",
      content: "Czy korzystałeś(-aś) ze skanera Zone+?",
      sub: "",
      type: "boolean",
      answers: [
        {
          ident: "0",
          content: "Tak",
          nextQuestion: "q2_1",
        },
        {
          ident: "1",
          content: "Nie",
          nextQuestion: "q2",
        },
      ],
    },
    {
      ident: "q2",
      content:
        "Czy zdarzyło Ci się korzystać ze skanerów i technologii rozszerzonej rzeczywistości w innych aplikacjach? ",
      type: "boolean",
      answers: [
        {
          ident: "0",
          content: "Tak",
          nextQuestion: "q3",
        },
        {
          ident: "1",
          content: "Nie",
          nextQuestion: "q3",
        },
      ],
    },
    {
      ident: "q2_1",
      content:
        "Czy udało Ci się odebrać nagrodę za pierwsze skanowanie (30 punktów)? ",
      sub: "",
      type: "boolean",
      answers: [
        {
          ident: "0",
          content: "Tak",
          nextQuestion: "q3_1",
        },
        {
          ident: "1",
          content: "Nie",
          nextQuestion: "q3_2",
        },
      ],
    },
    {
      ident: "q3",

      type: "booleanMulti",
      content: "Jaki jest powód nieuruchomienia przez Ciebie skanera Zone+? ",
      answers: [
        {
          ident: "0",
          content: "Nie wiedziałem(-am) o takiej funkcjonalności",
          nextQuestion: "q4",
        },
        {
          ident: "1",
          content: "Nie interesuje mnie skanowanie",
          nextQuestion: "q4",
        },
        {
          ident: "2",
          content: "Nagrody nie są zachęcajace",
          nextQuestion: "q4",
        },
        {
          ident: "3",
          content: "Ograniczenie skanowania w lokalizacjach tylko do Warszawy",
          nextQuestion: "q4",
        },
      ],
    },
    {
      ident: "q3_1",
      type: "booleanMulti",
      content: "Oceń atrakcyjność nagrody w postaci 30 punktów: ",
      answers: [
        {
          ident: "0",
          content: "Bardzo nieatrakcyjna",
          nextQuestion: "q4_1",
        },
        {
          ident: "1",
          content: "Nieatrakcyjna",
          nextQuestion: "q4_1",
        },
        {
          ident: "2",
          content: "Trudno powiedzieć",
          nextQuestion: "q4_1",
        },
        {
          ident: "3",
          content: "Atrakcyjna",
          nextQuestion: "q4_1",
        },
        {
          ident: "4",
          content: "Bardzo atrakcyjna",
             nextQuestion: "q4_1",
        },
      ],
    },
    {
      ident: "q3_2",
      type: "booleanMulti",
      content: "Dlaczego nie udało Ci się jeszcze odebrać nagrody (30 punktów) za pierwsze skanowanie? ",
      answers: [
        {
          ident: "0",
          content: "Nie próbowałem(-am) skanować Nie wiedziałem(-am) co należy zeskanować",
          nextQuestion: "finish",
        },
        {
          ident: "1",
          content: "Nie wiedziałem(-am) co należy zeskanować",
          nextQuestion: "finish",
        },
        {
          ident: "2",
          content: "Nie wiedziałem(-am) co należy zeskanować ",
          nextQuestion: "finish",
        },
        {
          ident: "3",
          content: "Obawiam się zezwalania dostępu do aparatu",
          nextQuestion: "finish",
        },
        {
          ident: "4",
          content: "Przez problemy techniczne skanera",
          nextQuestion: "finish",
        },
      
      ],
    },
    {
      ident: "q4",
      type: "booleanMulti",
      content: "Co skłoniłoby Cię do uruchomienia skanera Zone+?   ",
      answers: [
        {
          ident: "0",
          content: "Więcej punktów za pierwsze skanowanie ",
          nextQuestion: "finish",
        },
        {
          ident: "1",
          content: "Bardziej atrakcyjny kupon na paczki neo™",
          nextQuestion: "finish",
        },
        {
          ident: "2",
          content: "Film z wyjaśnieniem zasad działania skanera Zone+ ",
          nextQuestion: "finish",
        },
        {
          ident: "3",
          content:
            "Dostępność skanowania w lokalizacjach (barach, restauracjach) poza Warszawą",
          nextQuestion: "q5",
        },
      ],
    },
    {
      ident: "q4_1",
      type: "boolean",
      content: "Czy udało Ci się odebrać nagrodę (3 paczki neo™ w cenie 1 + 2 zł) za skanowanie w jednej z lokalizacji w Warszawie? ",
      answers: [
        {
          ident: "0",
          content: "Tak",
          nextQuestion: "q5_1",
        },
        {
          ident: "1",
          content: "Nie",
          nextQuestion: "q5_2",
        },
      ],
    },
    {
      ident: "q5",
      type: "location",
      content: "W jakim mieście chciałbyś(-ałabyś), aby pojawiła się możliwość skanowania w lokalizacjach?",
      answers: [
        {
          ident: "0",
          nextQuestion: "finish",
        }
      ],
      nextQuestion: "finish",
    },
    {
      ident: "q6_2",
      type: "location",
      content: "W jakim mieście chciałbyś(-ałabyś), aby pojawiła się możliwość skanowania w lokalizacjach?",
      answers: [{
        ident: "0",
        nextQuestion: "q7_1",
      }],
      nextQuestion: "q7_1",
    },
    {
      ident: "q5_2",
      type: "booleanMulti",
      content: "Dlaczego nie udało Ci się jeszcze odebrać nagrody (3 paczki neo™ w cenie 1 + 2 zł) za skanowanie w jednej z dostępnych lokalizacji?",
      answers: [
        {
          ident: "0",
          content: "W moim mieście nie ma żadnej lokalizacji (jestem spoza Warszawy)",
          nextQuestion: "q6_2",
        },
        {
          ident: "1",
          content: "Nie odwiedziłem(-am) jeszcze żadnego z dostępnych barów i restauracji ",
          nextQuestion: "q7_1",
        },
        {
          ident: "2",
          content: "Przez problemy techniczne skanera ",
          nextQuestion: "q7_1",
        },
        {
          ident: "3",
          content:
            "Obawiam się zezwalania dostępu do lokalizacji",
            nextQuestion: "q7_1",
        },
      ],
    },
    {
      ident: "q7_1",
      type: "booleanMulti",
      content: "Jak oceniasz łatwość użycia i poruszania się po skanerze Zone+?",
      answers: [
        {
          ident: "0",
          content: "Bardzo trudno",
          nextQuestion: "q8_1",
        },
        {
          ident: "1",
          content: "Trudno ",
          nextQuestion: "q8_1",
        },
        {
          ident: "2",
          content:
            "Trudno powiedzieć",
            nextQuestion: "q8_1",
        },
        {
          ident: "3",
          content:
            "Łatwo",
            nextQuestion: "q8_1",
        },
        {
          ident: "4",
          content:
            "Bardzo łatwo",
            nextQuestion: "q8_1",
        },
      ],
    },
    {
      ident: "q8_1",
      type: "booleanMulti",
      content: "Jaka jest Twoja ogólna satysfakcja z działania skanera Zone+?",
      answers: [
        {
          ident: "0",
          content: "Bardzo niska",
          nextQuestion: "q9_1",
        },
        {
          ident: "1",
          content: "Niska",
          nextQuestion: "q9_1",
        },
        {
          ident: "2",
          content:
            "Trudno powiedzieć",
            nextQuestion: "q9_1",
        },
        {
          ident: "3",
          content:
            "Wysoka",
            nextQuestion: "q9_1",
        },
        {
          ident: "4",
          content:
            "Bardzo wysoka",
            nextQuestion: "q9_1",
        },
      ],
    },
    {
      ident: "q5_1",
      type: "booleanMulti",
      content: "Oceń atrakcyjność nagrody w postaci kuponu na 3 paczki neo™ w cenie 1 + 2 zł: ",
      answers: [
        {
          ident: "0",
          content: "Bardzo nieatrakcyjna ",
          nextQuestion: "q6_1",
        },
        {
          ident: "1",
          content: "Nieatrakcyjna",
          nextQuestion: "q6_1",
        },
        {
          ident: "2",
          content: "Trudno powiedzieć ",
          nextQuestion: "q6_1",
        },
        {
          ident: "3",
          content:
            "Atrakcyjna",
            nextQuestion: "q6_1",
        },
        {
          ident: "4",
          content:
            "Bardzo atrakcyjna",
            nextQuestion: "q6_1",
        },
        
      ],
    },
    {
      ident: "q6_1",
      type: "booleanMulti",
      content: "Na ile prawdopodobne jest to, że w ciągu kolejnych tygodni dokonasz ponownego skanowania w jednej z lokalizacji i odbierzesz kolejny kupon?  ",
      answers: [
        {
          ident: "0",
          content: "Bardzo nieprawdopodobne ",
          nextQuestion: "q7_1",
        },
        {
          ident: "1",
          content: "Nieprawdopodobne",
          nextQuestion: "q7_1",
        },
        {
          ident: "2",
          content: "Trudno powiedzieć",
          nextQuestion: "q7_1",
        },
        {
          ident: "3",
          content:
            "Prawdopodobne",
            nextQuestion: "q7_1",
        },
        {
          ident: "3",
          content:
            "Bardzo prawdopodobne",
            nextQuestion: "q7_1",
        },
        
      ],
    },
      {
      ident: "q9_1",
      content:
        "Na ile prawdopodobne jest, że polecisz korzystanie ze skanera Zone+ swojemu przyjacielowi lub znajomemu? Użytkownik ma skalę od 1 do 10",
      sub: "",
      type: "range",
      answers: [],
    },
    
    
    // {
    //   ident: "q4",
    //   content:
    //     "Korzystając ze skali od zera do dziesięciu, na ile prawdopodobne jest, że polecisz program lojalnościowy Alter Zone swojemu znajomemu?",
    //   sub: "0 oznacza, że na pewno nie polecisz, a 10 oznacza, że na pewno polecisz.",
    //   type: "range",
    //   answers: [],
    // },
    // {
    //   ident: "q5",
    //   content: "Jakie kategorie nagród są dla Ciebie interesujące:",
    //   type: "oneWithInput",
    //   answers: [
    //     {
    //       ident: "0",
    //       content: "Zniżki na transport",
    //     },
    //     {
    //       ident: "1",
    //       content: "Rozrywka",
    //     },
    //     {
    //       ident: "2",
    //       content: "Zniżki na odzież",
    //     },
    //     {
    //       ident: "3",
    //       content: "Zniżki turystyczne",
    //     },
    //     {
    //       ident: "4",
    //       content: "Elektronika",
    //     },
    //     {
    //       ident: "5",
    //       content: "Kawiarnia/gastronomia",
    //     },
    //     {
    //       ident: "6",
    //       content: "Inne",
    //       other: "Jaka kategoria",
    //     },
    //   ],
    // },
      {
      ident: "q10_1",
      content: "Wskaż proszę co wpłynęło na Twoją ocenę?",
      type: "muliAnswer",
      sub: "Można zaznaczyć kilka odpowiedzi.",
      answers: [
        {
          nextQuestion: "finish",
          ident: "0",
          content: "Mała liczba dostępnych nagród za skanowanie ",
        },
        {
          nextQuestion: "finish",
          ident: "1",
          content: "Brak różnych animacji (rozszerzonej rzeczywistości) po skanowaniu",
        },
        {
          nextQuestion: "finish",
          ident: "2",
          content: "Za mało dostępnych lokalizacji (barów, restauracji) w Polsce ",
        },
        {
          nextQuestion: "finish",
          ident: "3",
          content: "Możliwość zdobycia nowego kuponu na paczki neo™ tylko raz w tygodniu",
        },
        {
          nextQuestion: "finish",
          ident: "4",
          content: "Problemy w działaniu skanera ",
        },
        
        {
          nextQuestion: "finish",
          ident: "5",
          content: "Inny powód",
          other: "napisz proszę jaki?",
        },
      ],
    },
    {
      ident: "q10_2",
      content: "Chcemy, aby skaner Zone+ był coraz lepszy. Wskaż proszę co powinno być dla nas priorytetem żeby następnym razem otrzymać jeszcze wyższą ocenę?",
      type: "muliAnswer",
      sub: "Można zaznaczyć kilka odpowiedzi.",
      answers: [
        {
          nextQuestion: "finish",
          ident: "0",
          content: "Więcej atrakcyjnych nagród",
        },
        {
          nextQuestion: "finish",
          ident: "1",
          content: "Możliwość zdobywania innych nagród poza lokalizacją niż tylko punkty w Alter Zone",
        },
        {
          nextQuestion: "finish",
          ident: "2",
          content: "Więcej dostępnych lokalizacji (barów, restauracji) w Polsce",
        },
        {
          nextQuestion: "finish",
          ident: "3",
          content: "Dodanie możliwości skanowania również w punktach sprzedażych",
        },
        {
          nextQuestion: "finish",
          ident: "4",
          content: "Więcej różnorodnych animacji (rozszerzonej rzeczywistości) po zeskanowaniu",
        },
        {
          nextQuestion: "finish",
          ident: "5",
          content: "Wyszukiwanie najbliższych barów lub restauracji po lokalizacji",
        },
        {
          nextQuestion: "finish",
          ident: "6",
          content: "Inny powód",
          other: "napisz proszę jaki?",
        },
      ],
    },
    {
      ident: "q10_3",
      content: "Cieszymy się że możemy liczyć na Twoje polecenie naszego skanera. Wskaż proszę co głównie wpłynęło na Twoją ocenę?",
      type: "muliAnswer",
      sub: "Można zaznaczyć kilka odpowiedzi.",
      answers: [
        {
          
          nextQuestion: "finish",
          ident: "0",
          content: "Atrakcyjne nagrody",
        },
        {
          nextQuestion: "finish",
          ident: "1",
          content: "Pomysł na zdobywanie nagród poprzez skanowanie",
        },
        {
          nextQuestion: "finish",
          ident: "2",
          content: "Możliwość zdobywania co tydzień nowego kuponu na paczki neo™",
        },
        {
          nextQuestion: "finish",
          ident: "3",
          content: "Rozszerzona rzeczywistość po zeskanowaniu",
        },
        {
          nextQuestion: "finish",
          ident: "4",
          content: "Dostępność skanowania w różnych lokalizacjach (barach, restauracjach)",
        },
       
        {
          nextQuestion: "finish",
          ident: "5",
          content: "Inny powód",
          other: "napisz proszę jaki?",
        },
      ],
    },
   
  ],
};
