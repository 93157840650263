import Img0 from "./img/img_mobile1.jpg"
import Img0Desktop from "./img/img_desktop1.jpg"
import Img1 from "./img/img_mobile2.jpg"
import Img1Desktop from "./img/img_desktop2.jpg"
import pack1 from "./img/pack1.jpg"
import pack2 from "./img/pack2.jpg"
import pack3 from "./img/pack3.jpg"
import pack4 from "./img/pack4.jpg"

export const questions = {
    "gameType": "FindPacks",
    "glo": [
        // {
        //     "ident": "q0",
        //     "content": "Wkłady neo™ to szeroka oferta wariantów, dostępna w kilku smakowych kategoriach",
        //     "sub": "Jakie to?",
        //     "type": "question",
        //     "backgroundDesktop": BackgroundDesktop,
        //     "answers": [
        //         {
        //             "ident": "a",
        //             "content": "Tytoniowe"
        //         },
        //         {
        //             "ident": "b",
        //             "content": "Z podwójną kapsułą, Tytoniowe"
        //         },
        //         {
        //             "ident": "c",
        //             "content": "Mentolowe, Owocowe"
        //         },
        //         {
        //             "ident": "d",
        //             "content": "Z podwójną kapsułką, Tytoniowe, Mentolowe, Owocowe"
        //         },
        //     ]
        // },
        {
            "ident": "q0",
            "content": "Które warinaty neo™ zmieniają swoje opakowania oraz formułę?",
            "type": "image",
            "answers": [
                {
                    "ident": "a",
                    "content": "Gold Tobacco &\n Dark Tobacco",
                    img: pack1
                },
                {
                    "ident": "b",
                    "content": "Gold Tobacco &\n Terracotta Tobacco",
                    img: pack2
                },
                {
                    "ident": "c",
                    "content": "Terracotta Tobacco &\n Dark Tobacco",
                    img: pack3
                },
                {
                    "ident": "d",
                    "content": "Ice Click &\n Green Click",
                    img: pack4
                },
            ]
        },
        {
            "ident": "q1",
            "content": "Znajdź wszystkie nowe paczki neo™ na zdjęciu",
            "type": "find",
            "imgMobile": Img0,
            "imgDesktop": Img0Desktop,
            "answers": [
                {
                    "ident": "30ab92ee-b8e1-426c-948e-f74c5a71e12a",
                    "clipPathMobile": "circle(5.0% at 61% 68%)",
                    "clipPathDesktop": "circle(3.0% at 55% 69%)"
                },
                {
                    "ident": "138b633b-acd6-47f7-8d22-24ae1df29f3b",
                    "clipPathMobile": "circle(5.0% at 80% 76%)",
                    "clipPathDesktop": "circle(3.0% at 63% 76%)"
                },
            ]
        },
        {
            "ident": "q2",
            "content": "Znajdź wszystkie nowe paczki neo™ na zdjęciu",
            "type": "find",
            "imgMobile": Img1,
            "imgDesktop": Img1Desktop,
            "answers": [
                {
                    "ident": "6e98c4ce-dbd3-4d7d-b1f9-d3e979f0f983",
                    "clipPathMobile": "circle(5% at 26% 82%)",
                    "clipPathDesktop": "circle(3% at 65% 57%)"
                },
                {
                    "ident": "46e8c776-c66a-4f26-a956-9a02a70d4778",
                    "clipPathMobile": "circle(5% at 85% 57%)",
                    "clipPathDesktop": "circle(3% at 39% 82%)"
                },
            ]
        }
    ]
}