import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router";
import _ from 'lodash';
import Back from "../img/back.svg"
import BackOrange from "../img/back-orange.svg"
import "./style.scss";
import { parseDate, useWindowSize } from "../../../Helpers";
import Button from "../../../components/Button/Button.js";
import { showErrorPopup, translateError } from "../../../components/ErrorHandling";
import Countdown from "react-countdown";
import { useArticleDispatch, useArticleState, getArticles } from "../../../ArticlesContext";
import { Swiper, SwiperSlide } from "swiper/react";
import { ReactComponent as Arrow } from "../../HomePage/img/arrow.svg"

import "swiper/css";
import "swiper/css/pagination"

import SwiperCore, {
  Mousewheel, Pagination, Navigation
} from 'swiper';
import { getUserDetails, useUserDispatch, useUserState } from "../../../UserContext";
import { Link } from "react-router-dom";
import LikeButton from "../../../components/LikeButton"
import { sendICPreregister } from "../../../components/ICPreregister";

SwiperCore.use([Mousewheel, Pagination, Navigation]);

const Timer = ({from, to}) => {
  const dateFrom = new Date(from)
  const dateTo = new Date(to)
  const dateNow = new Date()

  const counterTime = dateNow.getTime() < dateFrom.getTime() ? dateFrom : dateTo

  const renderer = ({ days, hours, minutes, seconds }) => {
    return <div className="timer-inner">{days}<span>d.</span>{hours}<span>godz.</span>{minutes}<span>min.</span>{seconds}<span>sek.</span></div>;
  };

  return (
    <div className="article-timer">
      { dateNow.getTime() < dateFrom.getTime() ? <p className="timer-title">Start za:</p> : <p className="timer-title">Do końca:</p> }
      <Countdown date={ new Date(counterTime) } renderer={renderer}/>
    </div>
  )
}

const CyclicTimer = () => {
  const [complete, setComplete] = useState(0)
  const today = new Date()
  let dateNow = new Date()
  let fridayFivePM = new Date(dateNow.getTime())

  if(dateNow.getDay() !== 5 || dateNow.getHours() > 16) {
    fridayFivePM = new Date(
      dateNow.setDate(
        dateNow.getDate() + ((7 - dateNow.getDay() + 5) % 7 || 7)
      )
    )
    dateNow = new Date()
  }

  fridayFivePM.setHours(17)
  fridayFivePM.setMinutes(0)
  fridayFivePM.setSeconds(0)

  let mondayEightAM = new Date(dateNow.getTime())
  
  if(dateNow.getDay() !== 1 || dateNow.getHours() > 7) {
    mondayEightAM = new Date(
      dateNow.setDate(
        dateNow.getDate() + ((7 - dateNow.getDay() + 1) % 7 || 7)
      )
    )
    dateNow = new Date()
  }

  mondayEightAM.setHours(8)
  mondayEightAM.setMinutes(0)
  mondayEightAM.setSeconds(0)

  const counterTime = today.getTime() < mondayEightAM.getTime() && mondayEightAM.getTime() < fridayFivePM.getTime() ? new Date(mondayEightAM.getTime()) : new Date(fridayFivePM.getTime())

  const renderer = ({ days, hours, minutes, seconds }) => {
    return <div className="timer-inner">{days}<span>d.</span>{hours}<span>godz.</span>{minutes}<span>min.</span>{seconds}<span>sek.</span></div>;
  };

  return (
    <div className="article-timer">
      { today.getTime() < mondayEightAM.getTime() && mondayEightAM.getTime() < fridayFivePM.getTime() ? <p className="timer-title">Do końca:</p> : <p className="timer-title">Start za:</p> }
      <Countdown key={complete} date={ new Date(counterTime) } onComplete={() => { setComplete(1) }} renderer={renderer}/>
    </div>
  )
}

const ArticlePage = ({setIcPreregisterModal}) => {
  const { width } = useWindowSize()
  const SCREEN_XGA = 1020;
  const { slug } = useParams()
  const navigate = useNavigate()
  const [article, setArticle] = useState({})
  const dispatch = useArticleDispatch()
  const userDetalisDispatch = useUserDispatch()
  const { articles } = useArticleState()
  const { userDetails } = useUserState()
  const [loading, setLoading] = useState(false)

  const getArticle = () => {
    const params = {
      "article": {
          "slug": slug
      }
    }

    fetch(process.env.REACT_APP_API_URL + "/alterzone/article/describe", {
        method: "POST",
        headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + JSON.parse(sessionStorage.getItem('token'))?.uuid,
        'X-Mode-Local': sessionStorage.getItem('devParams') ? true : false,
        },
        body: JSON.stringify(params)
    })
    .then((response) => {
        return response.json()
    })
    .then(
        (result) => {
          if(result.status.success) {
            const isExternal = _.filter(result.data.content?.modules, { type: "external_url" })[0] ? true : false
            if(isExternal) {
              window.location.replace(_.filter(result.data.content?.modules, { type: "external_url" })[0].custom)
            } else {
              setArticle(result.data)
            } 
          } else {
            showErrorPopup(translateError(result.data.error), result.meta.ts)
          }
        },
        (error) => {
          showErrorPopup(translateError("generic"))
            console.log(error)
        }
    )
  }

  useEffect(() => {
    setTimeout(() => { if ((!article.content) && userDetails.consentAlterzoneParticipate) getArticle() }, 300)
    //eslint-disable-next-line
  }, [userDetails])

  const articleImage = _.filter(article.content?.modules, { type: "hero_image" })[0]
  const articleImageMobile = _.filter(article.content?.modules, { type: "thumbnail" })[0]
  const articleIntro = _.filter(article.content?.modules, { type: "intro" })[0]?.custom || ""
  const articleModules = _.filter(article.content?.modules, (mod) => {
    return mod.type !== "hero_image" && mod.type !== "thumbnail" && mod.type !== "intro"
  })

  const getArt = async () => {
    try {
      let response = await getArticles(dispatch)
      if (!response) return
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    setTimeout(() => { if ((!articles || articles.length === 0) && userDetails.consentAlterzoneParticipate) getArt() }, 300)
    //eslint-disable-next-line
  }, [userDetails])

  let sortedArticles = _.orderBy(articles, ['hero'], 'desc').filter(e => !e.external_url)
  let index = false
  for (let i = 0; i < sortedArticles.length; i++) {
    const element = sortedArticles[i];
    if(element.slug === slug) {
      index = i
    }
  }
  if(index !== false) {
    sortedArticles.splice(index, 1)
  }
  sortedArticles.splice(4)

  console.log(sortedArticles)

  return (
    <section className="article-page">
      <div className="article-top" style={{ backgroundImage: "url(" + (width < SCREEN_XGA ? (articleImageMobile ? articleImageMobile?.file?.url : articleImage?.file?.url) : articleImage?.file?.url) + ")" }}>
        <div className="image-cover"></div>
        <div className="article-container">
          <div className="go-back" onClick={() => navigate(-1)}><img src={ BackOrange } alt="Powrót"/></div>
          <div className="article-data">
            <div className="article-category">{ article.categoryName }</div>
            <div className="article-publication">{ article.created ? parseDate(article.created?.date) : "..." }</div>
          </div>
          <h1 dangerouslySetInnerHTML={{ __html: article.content?.title }}></h1>
          <LikeButton article={article} setArticle={setArticle}/>
          {/* <div className={`like-wrapper ${likeButtonClicked ? "clicked" : ""} `}>
              <button className={`like-button ${article?.liked ? "liked" : ""}`} 
                onClick={() => { 
                  likeArticle(); 
                  setLikeButtonClicked(true)
                  }} disabled={article.liked ?? true}>
                  {console.log(article.liked)}
                    <div className="inner">
                      <div className="icon">
                        <img src={Like} alt="" />
                      </div>
                      <div className="animation">
                      <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100">
                        <circle cx="50" cy="50" r="47" fill="none" stroke="#ED8002" stroke-width="6" stroke-dasharray="0,30" stroke-linecap="round" className="circle-bigger" />
                        <circle cx="50" cy="50" r="47" fill="none" stroke="#ED8002" stroke-width="6" stroke-dasharray="0,30" stroke-linecap="round" className="circle-smaller" />
                      </svg>
                    </div>
                  </div>
              </button>
              <div className="like-count">
                {article.likes ?? 0}
              </div>
          </div> */}
        </div>
      </div>
      <div className="article-container">
        <div className="article-head">
          <div className="intro-text" dangerouslySetInnerHTML={{ __html: articleIntro }}></div>
        </div>
        <div className="article-content">
          { articleModules.map((module, i) => {
              const forceMobile = module?.metadata?.forceMobile ? "force-mobile" : ""
              const highlightType = module?.metadata?.highlighted === "border" ? "with-border" : module?.metadata?.highlighted === "background" ? "highlighted" : ""

              switch (module.type) {
                case 'text':
                  return <div key={i} className={ "article-text " + highlightType } dangerouslySetInnerHTML={{ __html: module.custom }}></div>
                case 'text-cta':
                  return <div key={i} className={ module.metadata?.highlighted ? "article-text-cta highlighted" : "article-text-cta" }>
                    <div className="article-text-cta-text" dangerouslySetInnerHTML={{ __html: module.custom }}></div>
                    <div className="article-text-cta-buttons">
                      {module.metadata?.name_1 && <Button {...(slug === "wstepna-rejestracja-otwarta-inspiration-club-jest-juz-blisko" ? {action: () => sendICPreregister(() => {getUserDetails(userDetalisDispatch); setIcPreregisterModal(prev => ({...prev, open: true, step: 1}))}, setLoading)} : {href: module.metadata?.name_1 })} disabled={loading || userDetails?.preRegistration} btnClass={ module.metadata?.color_1 === "orange" ? "orange" : "white" }>{!userDetails?.preRegistration ? module.metadata?.url_1 : "Jesteś już na liście"}</Button>}
                      {module.metadata?.name_2 && <Button {...(slug === "wstepna-rejestracja-otwarta-inspiration-club-jest-juz-blisko" ? {action: () => sendICPreregister(() => {getUserDetails(userDetalisDispatch); setIcPreregisterModal(prev => ({...prev, open: true, step: 1}))}, setLoading)} : {href: module.metadata?.name_2 })} disabled={loading || userDetails?.preRegistration} btnClass={ module.metadata?.color_2 === "orange" ? "orange" : "white" }>{!userDetails?.preRegistration ? module.metadata?.url_2 : "Jesteś już na liście"}</Button>}
                      {module.metadata?.name_3 && <Button {...(slug === "wstepna-rejestracja-otwarta-inspiration-club-jest-juz-blisko" ? {action: () => sendICPreregister(() => {getUserDetails(userDetalisDispatch); setIcPreregisterModal(prev => ({...prev, open: true, step: 1}))}, setLoading)} : {href: module.metadata?.name_3 })} disabled={loading || userDetails?.preRegistration} btnClass={ module.metadata?.color_3 === "orange" ? "orange" : "white" }>{!userDetails?.preRegistration ? module.metadata?.url_3 : "Jesteś już na liście"}</Button>}
                    </div>
                  </div>
                case 'image-text':
                  return (
                    <div key={i} className={"article-columns with-image " + (module.metadata?.layout === 'left' ? "text-left " : "text-right ") + (module.metadata?.highlighted ? "highlighted" : "")}>
                      <div className="article-column-image"><img src={ module.file?.url } alt={ module.metadata?.alt }/><p className="article-image-description" dangerouslySetInnerHTML={{ __html: module.metadata?.imgDesc }}></p></div>
                      <div className="article-column-text" dangerouslySetInnerHTML={{ __html: module.custom}}></div>
                    </div>
                  )
                case 'gallery':
                  return (
                    <div key={i}>
                      { module.custom && <h3 className="gallery-title">{ module.custom }</h3> }
                      <div className={ (module.metadata?.images?.length > 2 ? "article-gallery highlighted " : "article-gallery ") + (module.metadata?.layout === "left" ? "from-left" : "from-right")}>
                        { module.metadata?.images?.map((img, j) => {
                          return (
                            <div key={j} className="article-gallery-image"><img src={ img.url } alt={ "" }/></div>
                          )
                        }) }
                      </div>
                    </div>
                  )
                case 'logo-list':
                  return (
                    <div key={i}>
                      { module.custom && <h3 className="logo-title">{ module.custom }</h3> }
                      <div className={ "logo-list" }>
                        { module.metadata?.images?.map((img, j) => {
                          return (
                            <div key={j} className="logo-image"><img src={ img.url } alt={ "" }/></div>
                          )
                        }) }
                      </div>
                    </div>
                  )
                case 'award':
                  return (
                    <div key={i} className={"award " + (module.metadata?.layout === 'left' ? "text-left" : "text-right")}>
                      <div className="award-column-image"><img src={ module.file?.url } alt={ module.metadata?.alt }/><p className="award-image-description" dangerouslySetInnerHTML={{ __html: module.metadata?.imgDesc }}></p></div>
                      <div className="award-column-text">
                        <div dangerouslySetInnerHTML={{ __html: module.custom}}></div>
                        {module.metadata?.buttonUrl && <Button href={ module.metadata?.buttonUrl } btnClass={ "transparent" }>{ module.metadata?.buttonCopy }</Button>}
                      </div>
                    </div>
                  )
                case 'timer-image-text':
                  return (
                    <div key={i} className={"article-columns with-image highlighted " + (module.metadata?.layout === 'left' ? "text-left " : "text-right ")}>
                      <div className="article-column-image"><img src={ module.file?.url } alt={ module.metadata?.alt }/><p className="article-image-description" dangerouslySetInnerHTML={{ __html: module.metadata?.imgDesc }}></p></div>
                      <div className="article-column-text">
                        <div dangerouslySetInnerHTML={{ __html: module.custom}}></div>
                        <Timer from={module.metadata.dateFrom} to={module.metadata.dateTo} />
                      </div>
                    </div>
                  )
                case 'cyclic-timer-image-text':
                  return (
                    <div key={i} className={"article-columns with-image highlighted " + (module.metadata?.layout === 'left' ? "text-left " : "text-right ")}>
                      <div className="article-column-image"><img src={ module.file?.url } alt={ module.metadata?.alt }/><p className="article-image-description" dangerouslySetInnerHTML={{ __html: module.metadata?.imgDesc }}></p></div>
                      <div className="article-column-text">
                        <div dangerouslySetInnerHTML={{ __html: module.custom}}></div>
                        <CyclicTimer/>
                      </div>
                    </div>
                  )
                case 'image':
                  return (
                    <div key={i} className="article-image">
                      <img src={ module.file?.url } alt={ module.metadata?.alt }/>
                      <p className="article-image-description" dangerouslySetInnerHTML={{ __html: module.metadata?.imgDesc }}></p>
                    </div>
                  )
                case 'columns':
                  return (
                    <div key={i} className={ "article-columns " + highlightType + " " + forceMobile }>
                      <div className="article-column" dangerouslySetInnerHTML={{ __html: module.columns[0].custom }}></div>
                      <div className="article-column" dangerouslySetInnerHTML={{ __html: module.columns[1].custom }}></div>
                    </div>
                  )
                case 'columns-3':
                  return (
                    <div key={i} className={ "article-columns-3 " + highlightType + " " + forceMobile }>
                      <div className="article-column" dangerouslySetInnerHTML={{ __html: module.columns[0].custom }}></div>
                      <div className="article-column" dangerouslySetInnerHTML={{ __html: module.columns[1].custom }}></div>
                      <div className="article-column" dangerouslySetInnerHTML={{ __html: module.columns[2].custom }}></div>
                    </div>
                  )
                case 'button':
                  return (
                    <div key={i} className="article-button">
                      <Button {...(slug === "wstepna-rejestracja-otwarta-inspiration-club-jest-juz-blisko" ? {action: () => sendICPreregister(() => {getUserDetails(userDetalisDispatch); setIcPreregisterModal(prev => ({...prev, open: true, step: 1}))}, setLoading)} : {href: module.custom })} disabled={loading || userDetails?.preRegistration}>{ !userDetails?.preRegistration ? module.metadata?.name : "Jesteś już na liście" }</Button>
                    </div>
                  )
                case 'video':
                  return (
                    <div key={i} className={ "article-video " + (module.metadata?.highlighted ? "highlighted" : "")}>
                      <div className="wrapper">
                        <video width={720} controls>
                          <source src={module.file?.url} type="video/mp4"/>
                          Twoja przeglądarka nie obsługuje odtwarzania wideo.
                        </video>
                      </div>
                    </div>
                  )
                case 'video-src':
                  return (
                    <div key={i} className={ "article-video " + (module.metadata?.highlighted ? "highlighted" : "")}>
                      <div className="wrapper">
                        <video width={720} controls>
                          <source src={module?.metadata?.altSrc} type="video/mp4"/>
                          Twoja przeglądarka nie obsługuje odtwarzania wideo.
                        </video>
                      </div>
                    </div>
                  )
                default:
                  return null
              }
            })}
        </div>
      </div>
      <div className="read-more">
        <div className="container">
          <LikeButton article={article} setArticle={setArticle}/>
          <h3>Czytaj dalej</h3>
          <Swiper pagination={false} navigation={false} className={ "swiper-awards visible" }
            breakpoints={{
              0: {
                spaceBetween: 16,
                slidesPerView: "auto"
              },
              1280: {
                spaceBetween: 16,
                slidesPerView: 4
              }
            }}
          >
            {sortedArticles.length > 0 && sortedArticles?.map((element, index) => (
              <SwiperSlide width={320} key={index}>
                <Link key={index} className={`award`} to={`/co-nowego/${element.slug}`}>
                  <div className="img" style={{ backgroundImage: `url( ${(element?.thumbnail?.file?.url ? element?.thumbnail?.file?.url : element?.hero_image?.file?.url)} )` }}>
                  </div>
                  <div className="content">
                    <div className="article-data">
                      <div className="article-category">{ element.categoryName }</div>
                      <div className="article-publication">{ element.created ? parseDate(element.created?.date) : "..." }</div>
                    </div>
                    <div className="title">
                      {element.content?.title}
                    </div>
                    <div className="arrow">
                      <Arrow />
                    </div>
                  </div>
                </Link>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </section>
  )
}

export default ArticlePage