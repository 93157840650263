import ticket from "./img/ticket.svg"

const konkurs4_czerwiec_2023 = {
    sun: [
        {
            img: ticket,
            place: "1",
            content: "10 x podwójne 3-dniowe karnety na Sun Festival",
        },
    ],
    sunrise: [
        {
            img: ticket,
            place: "1",
            content: "10 x podwójne 3-dniowe karnety na Sunrise Festival",
        },
    ],
}

export default konkurs4_czerwiec_2023