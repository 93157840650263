export const questions = {
    "gameType": "PickOne",
    "glo": [
        {
            "ident": "q0",
            "content": "Balonem Clouds Fest lądujemy w Chorzowie. W którym województwie?",
            "fontSizeMobile": "18px",
            "fontSizeDesktop": "24px",
            "type": "text",
            "answers": [
                {
                    "ident": "a",
                    "content": "opolskim"
                },
                {
                    "ident": "b",
                    "content": "świętokrzyskim"
                },
                {
                    "ident": "c",
                    "content": "śląskim"
                },
                {
                    "ident": "d",
                    "content": "małopolskim"
                }
            ]
        },
        {
            "ident": "q1",
            "content": "W jakiej lokalizacji odbywa się koncert Cloud Fest w Chorzowie?",
            "fontSizeMobile": "18px",
            "fontSizeDesktop": "24px",
            "type": "text",
            "answers": [
                {
                    "ident": "a",
                    "content": "Szyb Prezydenta w kompleksie Sztygarka"
                },
                {
                    "ident": "b",
                    "content": "Stadion Śląski"
                },
                {
                    "ident": "c",
                    "content": "Park Śląski"
                },
                {
                    "ident": "d",
                    "content": "Duży Krąg Taneczny"
                }
            ]
        },
        {
            "ident": "q2",
            "content": "To jeden z największych takich obiektów w Europie i największy w Polsce. Oprócz koncertu Clouds Fest może być Twoim celem, aby przedłużyć weekend w Chorzowie. O czym mowa?",
            "fontSizeMobile": "15px",
            "fontSizeDesktop": "18px",
            "type": "text",
            "answers": [
                {
                    "ident": "a",
                    "content": "Park Śląski"
                },
                {
                    "ident": "b",
                    "content": "Śląski Ogród Zoologiczny"
                },
                {
                    "ident": "c",
                    "content": "Legendia Śląskie Wesołe Miasteczko"
                },
                {
                    "ident": "d",
                    "content": "Stadion Śląski"
                }
            ]
        },
    ]
}