import React, { useState, useEffect } from "react";
import { useWindowSize } from '../../Helpers';
import "./style.scss"
import Button from "../../components/Button/Button";
import logo from "./img/logo.svg"

export default function CoverPage({ newX2 }) {
  const { width } = useWindowSize();
  const SCREEN_XGA = 1020;


  return (
    <section className={"cover-page"}>
      <div>
        <img alt="logo" src={logo} />
      </div>
      <div className="inner">
        <h1>Szukasz </h1>
        <h2>Alter zone?</h2>
        <p>Przepraszamy, ale program został zamknięty.<span> Nie martw się. Fantastyczne oferty rabatowe i niezliczone korzyści czekają na Ciebie w nowym programie -</span>  Inspiration Club.</p>
        <p>Dołącz już teraz!</p>
        <div className="button">
          <Button href="https://inspirationclub.pl/"> Przejdź do Inspiration Club</Button>
        </div>
      </div>
    </section >
  );
}
