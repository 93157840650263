import reward0 from "./img/diner.png"
import reward1 from "./img/prosto.png"

export const konkurs2_sierpien = {
    rewards: [
        {
            img: reward0,
            place: "1",
            content: "Kolacja z Piotrem Czaykowskim",
            points: 200
        },
        {
            img: reward1,
            place: "2",
            content: "Voucher Prosto wear (100 zł)",
            points: 200
        },
        {
            img: reward1,
            place: "3",
            content: "Voucher Prosto wear (100 zł)",
            points: 200
        },
    ],

    questions: [
        {
            question: "Z jakim przedmiotem w swoim domu Artur Rojek jest szczególnie związany?",
            answers: [
                "Statuetka Grammy", "Adapter", "Płyta z autografem Coldplay", "Gitara"
            ],
            correct: "1",
            link: "https://youtu.be/18lB4Ra53V8"
        },
        {
            question: "Wymarzone wakacje Ofelii Igi Kreft?",
            answers: [
                "Kalifornia", "Malediwy", "Kenia", "Australia"
            ],
            correct: "0",
            link: "https://youtu.be/LWE363pMCQ8"
        },
        {
            question: "Gdzie pierwszy raz wystąpiła Natalia Nykiel?",
            answers: [
                "Ambona w kościele", "Amfiteatr w Ustce", "Na scenie w Talent Show", "Krupówki w Zakopanem"
            ],
            correct: "0",
            link: "https://youtu.be/W6Qb_jKumqU"
        },
        {
            question: "Gwiazda filmowa, którą chciałaby ubrać Karla Gruszecka, to?",
            answers: [
                "Kate Moss", "Leonardo Di Caprio", "Madonna", "Tilda Swinston"
            ],
            correct: "3",
            link: "https://youtu.be/jIuTqz8rAlY"
        },
        {
            question: "Ukochany film Mariety Żukowskiej?",
            answers: [
                "Spider-Man", "Avatar", "Pożegnanie z Afryką", "Dźwięk muzyki"
            ],
            correct: "2",
            link: "https://youtu.be/rVxoO8h6hLE"
        },

    ],
}