import _1 from "./img/1.jpg"
import _2 from "./img/2.jpg"
import _3 from "./img/3.jpg"
import _4 from "./img/4.jpg"
import _5 from "./img/5.jpg"
import _6 from "./img/6.jpg"
import pack1 from "./img/pack1.png"
import pack2 from "./img/pack2.png"
import pack3 from "./img/pack3.png"
import pack4 from "./img/pack4.png"
import pack5 from "./img/pack5.png"
import pack6 from "./img/pack6.png"

export const questions = {
    "gameType": "ScratchReveal",
    "glo": [
        {
            "ident": "q0",
            "content": "Limitowaną edycję wariantów smakowych z serii Moment Range znajdziesz wyłącznie w punktach eSmoking World.",
            "type": "text",
            "answers": [
                {
                    "ident": "a",
                    "content": "Prawda"
                },
                {
                    "ident": "b",
                    "content": "Fałsz"
                },
            ]
        },
        {
            "ident": "q1",
            "content": "Wybierz i nałóż odpowiedni kolor na paczkę <span>neo™ Melt Click</span>, przesuwając kursorem/palcem po opakowaniu.",
            "answers": [
                {
                    "ident": "a",
                    "img": _1,
                    "pack": pack1
                },
                {
                    "ident": "b",
                    "img": _2,
                    "pack": pack2
                },
                {
                    "ident": "c",
                    "img": _3,
                    "pack": pack3
                },
                {
                    "ident": "d",
                    "img": _4,
                    "pack": pack4
                },
                {
                    "ident": "e",
                    "img": _5,
                    "pack": pack5
                },
                {
                    "ident": "f",
                    "img": _6,
                    "pack": pack6
                },
            ]
        },
        {
            "ident": "q2",
            "content": "Wybierz i nałóż odpowiedni kolor na paczkę <span>neo™ Foam Click</span>, przesuwając kursorem/palcem po opakowaniu.",
            "answers": [
                {
                    "ident": "a",
                    "img": _1,
                    "pack": pack1
                },
                {
                    "ident": "b",
                    "img": _2,
                    "pack": pack2
                },
                {
                    "ident": "c",
                    "img": _3,
                    "pack": pack3
                },
                {
                    "ident": "d",
                    "img": _4,
                    "pack": pack4
                },
                {
                    "ident": "e",
                    "img": _5,
                    "pack": pack5
                },
                {
                    "ident": "f",
                    "img": _6,
                    "pack": pack6
                },
            ]
        },
    ]
}