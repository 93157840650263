import img from "./img/img.png"

export const questions = {
    "gameType": "TrueFalsePickOneLayout",
    "glo": [
        {
            "ident": "q0",
            "content": "Paczki neo™ są obecne w 36 krajach na całym świecie",
            fontSizeMobile: "15px",
            fontSizeDesktop: "15px",
            img: img,
            "type": "text",
            "answers": [
                {
                    "ident": "a",
                    "content": "Prawda"
                },
                {
                    "ident": "b",
                    "content": "Fałsz"
                }
            ]
        },
        {
            "ident": "q1",
            "content": "Odpoczywając w Hiszpanii lub Portugalii znajdziesz tam paczki wkładów neo™.",
            fontSizeMobile: "15px",
            fontSizeDesktop: "15px",
            img: img,
            "type": "text",
            "answers": [
                {
                    "ident": "a",
                    "content": "Prawda"
                },
                {
                    "ident": "b",
                    "content": "Fałsz"
                }
            ]
        },
        {
            "ident": "q2",
            "content": `Paczki neo™ są dostępne tylko w Europie.`,
            fontSizeMobile: "15px",
            fontSizeDesktop: "15px",
            img: img,
            "type": "text",
            "answers": [
                {
                    "ident": "a",
                    "content": "Prawda"
                },
                {
                    "ident": "b",
                    "content": "Fałsz"
                }
            ]
        },
        {
            "ident": "q3",
            "content": `Paczek neo™ nie znajdziesz w żadnym kraju w Azji.`,
            fontSizeMobile: "15px",
            fontSizeDesktop: "15px",
            img: img,
            "type": "text",
            "answers": [
                {
                    "ident": "a",
                    "content": "Prawda"
                },
                {
                    "ident": "b",
                    "content": "Fałsz"
                }
            ]
        },
    ]
}