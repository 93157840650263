import { useEffect, useState } from "react"
import Logo from "../../components/Header/img/alterzone-logo-white.svg"
import { Link, useNavigate } from 'react-router-dom'
import { useUserState, useUserDispatch, login, refreshToken } from '../../UserContext'
import "./style.scss"
import { useLocation } from "react-router"
import Button from "../../components/Button/Button"
import Modal from "../../components/Modal/Modal"
import { showErrorPopup, translateError } from "../../components/ErrorHandling"
// import Bat from "./img/bat.svg"
import DiscoverGlo from "./img/discover-glo.svg"
import MaszWybor from "./img/maszwybor-logo.svg"

import Password from "../../../img/password.svg"
import Exit from "../../components/Modal/img/exit.svg"

import Vouchery from "./img/vouchery.svg"
import Wyzwania from "./img/wyzwania.svg"
import Odrycia from "./img/odkrycia.svg"
import Switch from "./img/switch.svg"
import Bottom from "./img/bottom.svg"

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import SwiperCore, { Autoplay } from 'swiper';
import { getUrlParam } from "../../UrlParamsHelper"
SwiperCore.use([Autoplay]);

const Counter = ({ callback, stopCounter }) => {
  const [timer, setTimer] = useState(30)
  useEffect(() => {
    let tm = setTimeout(() => {
      if (timer > 0 && !stopCounter) {
        setTimer(timer - 1)
      } else if (!stopCounter) {
        callback()
      }
    }, 1000)

    return () => {
      clearTimeout(tm)
    }

    //eslint-disable-next-line
  }, [timer])

  return <span>{timer}</span>
}

const LoginPage = ({ showCookieModal }) => {
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const [name, setName] = useState("")
  const [passwd, setPasswd] = useState("")
  const [remindEmail, setRemindEmail] = useState("")
  const dispatch = useUserDispatch()
  const { loading } = useUserState()
  const [errors, setErrors] = useState({})
  const [remindPasswordPopup, setRemind] = useState(pathname === "/login/reset" ? true : false)
  const [remindSuccess, setRemindSuccess] = useState(false)
  const [preloader, setPreloader] = useState(false)
  const [rememberMe, setRememberMe] = useState(false)
  const [loosedFocus, setLoosedFocus] = useState({})
  const [showPasswd, setShowPasswd] = useState(false);
  const [bgBlur, setBgBlur] = useState(false)
  const [customPopup, setCustomPopup] = useState(false)
  const [customPopupLoader, setCustomPopupLoader] = useState(false)

  const getPWADisplayMode = () => {
    const isStandalone = window.matchMedia('(display-mode: standalone)').matches;
    if (document.referrer.startsWith('android-app://')) {
      return 'twa';
    } else if (navigator.standalone || isStandalone) {
      return 'standalone';
    }
    return 'browser';
  }

  const isPWA = getPWADisplayMode() === "standalone" ? true : false

  // useEffect(() => {
  //   document.querySelector("input[name='name']").scrollIntoView({ behavior: "smooth", block: "center", inline: "center" })
  //   setTimeout(() => {
  //     if (document.querySelector("input[name='name']")) document.querySelector("input[name='name']").focus()
  //   }, 700)
  // }, [])

  useEffect(() => {
    const documentHeight = () => {
      const obj = document.querySelector(".login-page")
      obj.style.setProperty('--height', `${window.innerHeight}px`)
    }

    window.addEventListener("resize", documentHeight)
    documentHeight()

    return () => {
      window.removeEventListener("resize", documentHeight)
    }
  }, [])

  const handleLogin = async () => {
    const payload = {
      consumer: {
        emailAddress: name.trim(),
        password: passwd,
        remember: rememberMe
      }
    }

    //bb

    try {
      let response = await login(dispatch, payload)
      if (!response) return
      if(response.status.success) {
        if (pathname === "/login") navigate("/", { replace: true })
      } else if(response.data.error === "error_m2_not_found_in_salesforce") {
        setCustomPopup(true)
      } else {
        showErrorPopup(translateError(response.data.error), response.meta.ts)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const toggleRememberMe = () => {
    if (rememberMe) {
      setRememberMe(false)
      localStorage.removeItem("rememberMe")
    } else {
      setRememberMe(true)
      localStorage.setItem("rememberMe", true)
    }
  }

  const tokenRefresh = async () => {
    try {
      let response = await refreshToken(dispatch, localStorage.getItem("refreshToken"))
      if (!response) {
        setRememberMe(false)
        return
      }
      if (pathname === "/login") navigate("/", { replace: true })
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    setTimeout(() => {
      if (localStorage.getItem("rememberMe") && localStorage.getItem("refreshToken") && !sessionStorage.getItem("token")) {
        setRememberMe(true)
        tokenRefresh()
      } else {
        localStorage.removeItem("rememberMe")
      }
    }, 500)

    //eslint-disable-next-line
  }, [])

  const remindPassword = () => {
    setPreloader(true)
    const params = {
      consumer: {
        emailAddress: remindEmail,
        source: "alterzone"
      },
      reset: {
        return: {
          url: `${window.origin}/haslo/reset?token=`
        }
      }
    }

    fetch(process.env.REACT_APP_API_URL + "/consumer/reset-password-token", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        'X-Mode-Local': sessionStorage.getItem('devParams') ? true : false,
      },
      body: JSON.stringify(params)
    })
      .then((response) => {
        return response.json()
      })
      .then(
        (result) => {
          if (result.status.success) {
            setPreloader(false)
            setRemindSuccess(true)
          } else {
            showErrorPopup(translateError(result.data.error), result.meta.ts)
            setPreloader(false)
            setRemind(false)
          }
          setLoosedFocus({})
          setErrors({})
        },
        (error) => {
          showErrorPopup(translateError("generic"))
          setPreloader(false)
          setRemind(false)
          setLoosedFocus({})
          setErrors({})
          console.log(error)
        }
      )
  }

  const validateInput = (i) => {
    const input = i
    const name = input.name
    const value = input.type === "checkbox" ? input.checked : input.value
    const regex = input.dataset?.regex || false
    const comment = input.dataset?.regexcomment || false
    const emptyComment = input.dataset?.emptycomment || false
    const lengthComment = input.dataset?.lengthcomment || false
    const minLength = input.dataset?.minlength || 0
    const checkSpaces = input.dataset?.checkspaces || false
    const checkSpacesComment = input.dataset?.checkspacescomment || false

    // const focus = _.debounce(() => {
    //   setTimeout(() => {
    //     document.querySelector("input[name='" + name + "']")?.scrollIntoView({ behavior: "smooth", block: "center", inline: "center" })
    //     // setTimeout(() => {
    //     //   document.querySelector("input[name='" + name + "']")?.focus()
    //     // }, 200)
    //     // setTimeout(() => {
    //     //   document.querySelector("input[name='" + name + "']")?.classList.add("error-single")
    //     //   setTimeout(() => {
    //     //     document.querySelector("input[name='" + name + "']")?.classList.remove("error-single")
    //     //   }, 3000)
    //     // }, 300)
    //   }, 300)
    // }, 500)

    if (![name] || value === "" || value === false || value === "false") {
      let err = errors
      err[name] = emptyComment ? emptyComment : "Pole wymagane"
      setErrors({ ...err })
      // focus()
      return false
    } else if (checkSpaces && value.indexOf(" ") !== -1) {
      let err = errors
      err[name] = checkSpacesComment ? checkSpacesComment : "Nie może zawierać spacji"
      setErrors({ ...err })
      // focus()
      return false
    } else if (regex && !new RegExp(regex).test(value)) {
      let err = errors
      err[name] = comment ? comment : "Nieprawidłowa wartość"
      setErrors({ ...err })
      // focus()
      return false
    } else if (value.length < 2 || value.length < minLength) {
      let err = errors
      err[name] = lengthComment ? lengthComment : "Minimalna długość, to 2 znaki"
      setErrors({ ...err })
      // focus()
      return false
    } else {
      let err = errors
      err[name] = false
      setErrors({ ...err })
      return true
    }
  }

  const validateForm = (e, callback, formClass = "") => {
    e.preventDefault()
    let wrongCounter = 0
    const inputs = document.querySelectorAll("form" + (formClass ? "." + formClass : "") + " input:required")

    if (inputs && inputs.length > 0) {
      for (let i = inputs.length - 1; i >= 0; i--) {
        const input = inputs[i];
        if (!validateInput(input)) {
          wrongCounter++
        }
      }

      if (wrongCounter === 0) {
        callback()
      }
    }
  }

  const inlineValidate = (e) => {
    const target = e.target
    const targetName = target.name
    let focus = loosedFocus
    focus[targetName] = true
    validateInput(e.target)
    setLoosedFocus(focus)
  }

  return (
    <div className="login-page">
      <div className="login-background">
        <div className={`login-page-inner ${bgBlur ? "blur" : ""}`}>
          <div className="logo">
            {/* <img src={Bat} alt="" /> */}
            <img src={Logo} alt="" />
          </div>
          {/* <div className="main"> */}
          <form method="POST" className="login-form" autoComplete="off">
            {/* <h3>Witaj, zaloguj się</h3> */}
            {/* <p>Po zalogowaniu zobaczysz opcje produktowe British American Tobacco.</p> */}
            <div className="selector">
              <div className="login selected">
                Zaloguj się
              </div>
              {/* <div className="register">
                <Link to="/rejestracja">Zarejestruj się</Link>
              </div> */}
            </div>
            <div className="text">
              <div className="welcome">
                <h3>Miło CIĘ</h3>
                <h3>widzieć!</h3>
              </div>
              <div className="wrapper">
                <h4>Użyj tych samych danych logowania,</h4>
                <div className="flex">
                  <h4>jeśli masz konto w:</h4>
                  <img src={MaszWybor} alt="Masz Wybor logo" />
                </div>
                {/* <div className="logos"> */}
                {/* <img src={MaszWybor} alt="Masz Wybor logo" /> */}
                {/* <img src={DiscoverGlo} alt="Discover Glo logo" /> */}
                {/* </div> */}
              </div>
            </div>
            <div className="user-data">
              <div className="input">
                <label>Adres e-mail</label>
                <input name="name" type="text" value={name} onChange={(e) => { if (loosedFocus["name"]) inlineValidate(e); setName(e.target.value) }} onFocus={() => setBgBlur(true)} onBlur={(e) => { if (!loosedFocus["name"]) inlineValidate(e); if (Object.values(errors).every(e => !e)) { setBgBlur(false) } }} placeholder="np. jan.kowalski@gmail.com" data-regex='^(([a-zA-Z0-9-_.]+(\.[a-zA-Z0-9-_.]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$' data-regexcomment="Nieprawidłowy format. Wprowadź poprawny adres e-mail" data-emptycomment="Wpisz adres e-mail" data-lengthcomment="Email za krótki (min. to 2 znaki)" data-checkspaces={true} data-checkspacescomment="Usuń spację z adresu e-mail." required />
                {errors["name"] ? <p className={`error ${errors?.name?.length > 20 ? "long" : ""}`}>{errors["name"]}</p> : null}
              </div>
              <div className="input">
                <label>Hasło</label>
                <input autoComplete="off" name="passwd" type={showPasswd ? "text" : "password"} value={passwd} onChange={(e) => { if (loosedFocus["passwd"]) inlineValidate(e); setPasswd(e.target.value) }} onFocus={() => setBgBlur(true)} onBlur={(e) => { if (!loosedFocus["passwd"]) inlineValidate(e); if (Object.values(errors).every(e => !e)) { setBgBlur(false) } }} placeholder="Wpisz hasło" data-emptycomment="Wpisz hasło" data-minlength={8} data-lengthcomment="Hasło za krótkie (min. to 8 znaków)" required />
                <div
                  className={showPasswd ? "view-icon disable" : "view-icon"}
                  onClick={() => {
                    setShowPasswd(!showPasswd);
                  }}
                ></div>
                {errors["passwd"] ? <p className="error">{errors["passwd"]}</p> : null}
              </div>
            </div>
            <div className="form-bottom">
              {isPWA || document.location.hostname === "localhost" ? <div className="checkbox">
                <input type="checkbox" checked={rememberMe} id="rememberMe" onChange={() => { toggleRememberMe(!rememberMe) }} />
                <label htmlFor="rememberMe">Zapamiętaj mnie</label>
              </div> : null}
              <p className="forgot"><span href="#" onClick={(e) => { e.preventDefault(); setRemind(true); setLoosedFocus({}); setErrors({}) }}>Nie pamiętam hasła</span></p>
            </div>
            <div className="buttons">
              <Button action={(e) => { validateForm(e, handleLogin, "login-form") }} disabled={loading}>{loading ? "Proszę czekać..." : "Zaloguj się"}</Button>
            </div>
            <Swiper
              autoplay={{
                delay: 3000,
                disableOnInteraction: false,
              }}
              className="swiper-login" slidesPerView={1}>
              <SwiperSlide>
                <div className="wrapper">
                  <h1>Wyzwania</h1>
                  <p>Ciekawe zadania, wyjątkowe bonusy</p>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="wrapper">
                  <h1>ODKRYCIA</h1>
                  <p> Wybieraj z szerokiego katalogu nagród</p>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="wrapper">
                  <h1>V<img src={Switch} alt="O" />UCHERY </h1>
                  <p>Niezwykłe wydarzenia - spędzaj czas tak jak chcesz</p>
                </div>
              </SwiperSlide>
            </Swiper>
            <div className="desktop-svgs">
              {/* <div className="wrapper wrapper-1">
                <h1>Wyzwania</h1>
                <p>Ciekawe zadania, wyjątkowe bonusy</p>
              </div>
              <div className="wrapper wrapper-2">
                <h1>ODKRYCIA</h1>
                <p> Wybieraj z szerokiego katalogu nagród</p>
              </div>
              <div className="wrapper wrapper-3">
                <h1>V<img src={Switch} alt="O" />UCHERY </h1>
                <p>Niezwykłe wydarzenia - spędzaj czas tak jak chcesz</p>
              </div> */}
              <img src={Bottom} alt="" />
            </div>
          </form>
        </div>
      </div>
      <Modal isOpen={remindPasswordPopup} closeModal={() => { setRemind(false); setLoosedFocus({}); setErrors({}) }} modalClass="remind-password" buttonClose={false}>
        <div className="new-modal-header">
          <img src={Password} alt="" />
          <button>
            <img src={Exit} alt="" onClick={() => setRemind(false)} />
          </button>
        </div>
        {remindSuccess ? <div className="remind-password">
          <h3>Właśnie wysłaliśmy do Ciebie <br />mail z linkiem do resetu hasła</h3>
          <p>Kliknij w&nbsp;niego i&nbsp;ustaw nowe hasło.<br />Link ważny jest przez 24 h.</p>
          <Button btnClass="orange" action={(e) => { setRemind(false) }}>Zamknij</Button>
        </div> : <div className="remind-password">
          <h3>Przypomnij hasło</h3>
          <p>Na podany adres-email zostanie wysłany link do strony pozwalającej ustawić nowe hasło.</p>
          <form method="POST" className="remind-form" autoComplete="off">
            <div className="input">
              <label>Adres e-mail</label>
              <input name="remindEmail" type="text" value={remindEmail} onChange={(e) => { if (loosedFocus["remindEmail"]) inlineValidate(e); setRemindEmail(e.target.value) }} onBlur={(e) => { if (!loosedFocus["remindEmail"]) inlineValidate(e) }} placeholder="np. jan.kowalski@gmail.com" data-regex="\S+@\S+\.\S+" data-regexcomment="Nieprawidłowy format. Wprowadź poprawny adres e-mail" data-emptycomment="Wpisz adres e-mail" data-lengthcomment="Email za krótki (min. to 2 znaki)" data-checkspaces={true} data-checkspacescomment="Usuń spację z adresu e-mail." required />
              {errors["remindEmail"] ? <p className={`error ${errors["remindEmail"].length > 20 ? "long" : ""}`}>{errors["remindEmail"]}</p> : null}
            </div>
            <Button btnClass="orange" action={(e) => { validateForm(e, remindPassword, "remind-form") }} disabled={preloader}>{preloader ? "Proszę czekać..." : "Wyślij"}</Button>
          </form>
        </div>}
      </Modal>
      <Modal isOpen={customPopup} buttonClose={false} modalClass="remind-password">
        <div className="remind-password m2-not-found">
          <h3>Super, że tu jesteś</h3>
          <p>Sprawdzamy jeszcze, czy znajdujesz się na liście gości.</p>
          <p className={ customPopupLoader ? "hide time" : "time" }>Daj nam <strong><Counter callback={() => { setCustomPopupLoader(true) }} stopCounter={customPopupLoader} /> sekund</strong>.</p>
          <p className="bottom">Za chwilę wkroczysz do świata Alter&nbsp;Zone.</p>
          <Button btnClass="orange" action={() => { if (customPopupLoader) { handleLogin(); setCustomPopupLoader(false); setCustomPopup(false) } }} disabled={!customPopupLoader}>Wejdź do alterzone</Button>
        </div>
      </Modal>
      {/* </div> */}
      {/* <p className="versionId">1.3.9</p> */}
    </div>
  )
}

export default LoginPage
