import Mobile from "./img/bg-mobile.jpg"
import Desktop from "./img/bg-desktop.jpg"
import { questions } from "./questions"


export const wiosenne_wyczucie = {
    header: "Ekspert neo™",
    backgroundMobile: Mobile,
    backgroundDesktop: Desktop,
    title: "Prawdziwy ekspert jest w stanie rozpoznać aromat patrząc tylko \nna paczkę",
    subtitle: "Linia smaków neo™ to aż 12 wariantów w różnych kategoriach. Znasz je wszystkie? Sprawdź się w naszym wyzwaniu.",
    message: "Dopasuj opis aromatu do zdjęcia paczki, wybierając odpowiedni wkład neo™.",
    points: 15,
    statute: "/assets/Regulamin Aktywności_Ekspert_neo_14.02.pdf",

    ident: "luty3_2022",
    gameType: "TrueFalse",
    questions: questions,

    correct: {
        title: "Wow!",
        content: [
            "Jesteśmy zaskoczeni! To było bezbłędne oraz imponujące. Masz swój ulubiony smak wkładów neo™?",
            // "Wkłady tytoniowe neo™ to aż 12 różnych smaków. 3 warianty tytoniowe, 9 wariantów z kapsułą. W tym:  2 warianty mentolowe, 2 warianty z podwójną kapsułą 5 wariantów owocowych",
            "Szukaj swoich ulubionych smaków \nw najbliższych punktach sprzedaży. "
        ]
    },
    wrong: {
        title: "Było ok!",
        content: [
            "Jednak to za mało by zostać Ekspertem neo™. Pamiętaj, że praktyka czyni mistrza. 15 punktów będzie na Ciebie czekać.",
            "Sprawdź wszystkie warianty neo™",
            // "Chcesz skorzystać z małej pomocy?"
        ]
    },
}