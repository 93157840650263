import React, { useState, useEffect } from "react";
import Modal from 'react-modal';
import Exit from "../Modal/img/exit.svg"
import "./style.scss"

function useLocalStorage(key, initialValue) {
  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState(() => {
    if (window)
      try {
        // Get from local storage by key
        const item = window.localStorage.getItem(key);
        // Parse stored json or if none return initialValue
        return item ? JSON.parse(item) : initialValue;
      } catch (error) {
        // If error also return initialValue
        console.log(error);
        return initialValue;
      }
  });
  if (typeof window === "undefined") {
    return initialValue;
  }
  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.
  const setValue = (value) => {
    try {
      // Allow value to be a function so we have same API as useState
      const valueToStore =
        value instanceof Function ? value(storedValue) : value;
      // Save state
      setStoredValue(valueToStore);
      // Save to local storage
      window.localStorage.setItem(key, JSON.stringify(valueToStore));
    } catch (error) {
      // A more advanced implementation would handle the error case
      console.log(error);
    }
  };
  return [storedValue, setValue];
}


const initialCookieState = {
  technical: true,
  performance: false,
  communication: false,
};

const CookiesModal = ({ showCookieModal, hideCookieModal }) => {
  Modal.setAppElement('#root')

  const tabs = ["technical", "performance", "communication"];
  const [currentTabIndex, setCurrentTabIndex] = useState(0);
  const currentTabName = tabs[currentTabIndex];
  const [expandOptions, setExpandOptions] = useState(false);
  const [value, setValue] = useLocalStorage(
    "cookieOptions",
    initialCookieState
  );
  const consentNeedAccepted =
    !value?.communication || !value?.performance;
  const [cookieOptions, setCookieOptions] = useState(value);
  const [showCookie, setShowCookies] = useState(consentNeedAccepted)
  const btnIsActive =
    value.technical || value.performance || value.communication === true;

  const toggleCookie = (name) => {
    const tabName = tabs[currentTabIndex];
    if (name !== "technical") {
      setCookieOptions((opts) => ({
        ...opts,
        [tabName]: !opts[tabName],
      }));
    }
  };

  useEffect(() => {
    setValue(cookieOptions);
    if (cookieOptions.communication && window.dataLayer) {
      window.dataLayer.push({
        event: "GTMcookieOK",
      });
    }
  }, [cookieOptions, setValue]);

  const nextCookieHandle = () => {
    if (currentTabIndex < 2) {
      setCurrentTabIndex((prev) => prev + 1);
    } else {
      setShowCookies(false);
      hideCookieModal()
    }
    if (currentTabIndex === 2 && window.fbq) {
      window.fbq("trackCustom", "Adult_confirmed");
    }
  };

  const handleAcceptAllCookies = () => {
    setCookieOptions({
      technical: true,
      performance: true,
      communication: true,
    });
    if (window.fbq) {
      window.fbq("trackCustom", "Adult_confirmed");
    }
    setShowCookies(false);
    hideCookieModal()
  };

  const handleChangeTabIndex = (index) => {
    setCurrentTabIndex(index);
  };

  return (
    <Modal
      isOpen={showCookie || showCookieModal}
      contentLabel="Modal"
      className={{
        base: "modal",
        afterOpen: "modal_after-open",
        beforeClose: "modal_before-close",
      }}
      overlayClassName={{
        base: "overlay",
        afterOpen: "overlay_after-open",
        beforeClose: "overlay_before-close",
      }}
      closeTimeoutMS={0}
    >
      <div className="popup-inner">
        <div className="cookies-modal">
          {/* <div className="button-wrapper">
            <button className='close-button'>
              <img src={Exit} alt="" onClick={() => { hideCookieModal(); setShowCookies(false) }} />
            </button>
          </div> */}
          {expandOptions || showCookieModal ? (
            <>
              <div className="cookie-modal-step">
                <div className="cookie-modal-step__header">
                  <button
                    onClick={() => handleChangeTabIndex(0)}
                    className={
                      currentTabIndex === 0
                        ? "cookie-modal-step__tab cookie-modal-step__tab--active"
                        : "cookie-modal-step__tab"
                    }
                  >
                    Techniczne
                  </button>
                  <button
                    onClick={() => handleChangeTabIndex(1)}
                    className={
                      currentTabIndex === 1
                        ? "cookie-modal-step__tab cookie-modal-step__tab--active"
                        : "cookie-modal-step__tab"
                    }
                  >
                    Wydajnościowe
                  </button>
                  <button
                    onClick={() => handleChangeTabIndex(2)}
                    className={
                      currentTabIndex === 2
                        ? "cookie-modal-step__tab cookie-modal-step__tab--active"
                        : "cookie-modal-step__tab"
                    }
                  >
                    Śledzenie <span>i komunikacja</span>
                  </button>
                </div>
                {currentTabIndex === 0 && (
                  <div className="cookie-modal-step__body">
                    <h2 className="cookie-modal-step__title">
                      <span>Techniczne </span>pliki cookies
                    </h2>
                    <p className="cookie-modal-step__desc">
                      Są to pliki niezbędne do prawidłowego funkcjonowania strony.
                      Techniczne pliki cookies, pamiętają o tym kim jesteś, jakie
                      masz preferencje (język, ustawienia regionalne), dzięki czemu
                      możesz w wygodny sposób korzystać ze strony i jej ustawień.
                      Nie możesz z nich zrezygnować, ale możesz ustawić w
                      preferencjach przeglądarki odrzucanie wszystkich cookies. Może
                      to jednak spowodować, że strona nie będzie wyświetlana
                      prawidłowo albo niektóre funkcje nie będą działać.
                    </p>
                  </div>
                )}
                {currentTabIndex === 1 && (
                  <div className="cookie-modal-step__body">
                    <h2 className="cookie-modal-step__title">
                      <span>Wydajnościowe </span>pliki cookies
                    </h2>
                    <p className="cookie-modal-step__desc">
                      Używając tych cookies zbieramy wyłącznie anonimowe informacje.
                      Dzięki nim ustalamy, jak użytkownicy korzystają z naszej
                      strony i dowiadujemy się, czy nie ma w niej błędów i możemy
                      poprawić jej wydajność.
                    </p>
                  </div>
                )}
                {currentTabIndex === 2 && (
                  <div className="cookie-modal-step__body">
                    <h2 className="cookie-modal-step__title">
                      <span>Marketingowe </span>pliki cookies
                    </h2>
                    <p className="cookie-modal-step__desc">
                      Te pliki cookies (i inne technologie do nich podobne)
                      pozwalają śledzić historię Twojego przeglądania na tej stronie
                      oraz na innych stronach, co pozwala nam przekazywać
                      ukierunkowane informacje do odbiorcy. Na ich podstawie możemy
                      na przykład przestawiać Ci informacje o charakterze
                      promocyjnym.
                    </p>
                  </div>
                )}
              </div>
              <div className="modal-cookie-switch">
                <div className="modal-cookie-switch__container">
                  <div className="modal-cookie-switch__content">
                    <p>
                      {currentTabIndex !== 0
                        ? cookieOptions[tabs[currentTabIndex]]
                          ? "Zaakceptowane"
                          : "Niezaakceptowane"
                        : "Zawsze aktywne"}
                    </p>
                  </div>
                  <button
                    onClick={() => toggleCookie(currentTabName)}
                    className={
                        cookieOptions[tabs[currentTabIndex]]
                          ? "modal-cookie-switch__btn modal-cookie-switch__btn--active"
                          : "modal-cookie-switch__btn"
                    }
                  >
                    <span
                      className={
                        cookieOptions[tabs[currentTabIndex]]
                          ? "modal-cookie-switch__bullet modal-cookie-switch__bullet--active"
                          : "modal-cookie-switch__bullet"
                      }
                    >{console.log(cookieOptions[tabs[currentTabIndex]])}</span>
                  </button>
                </div>
              </div>

              <button
                onClick={() => nextCookieHandle()}
                disabled={!btnIsActive}
                className="cookie-modal-step__btn"
              >
                {currentTabIndex >= 2 ? "Zapisz" : "Dalej"}
              </button>
            </>
          ) : (
            <div className="cookies-modal-intro">
              <p className="cookies-modal-intro__desc">
                Na tej stronie korzystamy z plików cookie (i innych podobnych
                technologii), aby zapewnić jej prawidłowe działanie, ulepszać ją
                oraz analizować zachowania użytkowników i na tej podstawie oferować
                spersonalizowaną komunikację marketingową.
              </p>
              <p className="cookies-modal-intro__desc">
                Klikając “Akceptuję wszystkie”, zgadzasz się abyśmy korzystali ze
                wszystkich ww. rodzajów cookies. Aby samemu zdecydować, które
                rodzaje cookies akceptujesz, wybierz “Więcej opcji”. Możesz zmienić
                te ustawienia w dowolnym momencie, klikając w link widoczny w
                stopce.
              </p>
              <p className="cookies-modal-intro__desc">
                Więcej informacji w{" "}
                <a
                  className="cookies-modal-intro__link"
                  href="/assets/polityka_cookies_AZ.pdf"
                >
                  Polityce cookies
                </a>
              </p>
              <div className="cookies-modal-intro__btn-group">
                <button
                  id="accept_cookies"
                  onClick={handleAcceptAllCookies}
                  className="btn cookies-modal-intro__btn"
                >
                  Akceptuj Wszystkie
                </button>
                <button
                  id="more_cookies"
                  onClick={() => setExpandOptions(true)}
                  className="btn cookies-modal-intro__btn"
                >
                  Więcej opcji
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default CookiesModal;
