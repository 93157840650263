export const questions = {
    "gameType": "PickOne",
    "glo": [
        {
            "ident": "q0",
            "content": "Podgrzewacz glo ™ posiada indukcyjną technologię podgrzewania, dzięki czemu szybciej się nagrzewa. Czy wiesz po ilu sekundach, urządzenie jest gotowe do użycia \nw zależności od trybu pracy?",
            "type": "text",
            "answers": [
                {
                    "ident": "a",
                    "content": "STANDARD 30 sek., BOOST 25 sek."
                },
                {
                    "ident": "b",
                    "content": "STANDARD 60 sek., BOOST 45 sek."
                },
                {
                    "ident": "c",
                    "content": "STANDARD 10 sek., BOOST 5 sek."
                },
                {
                    "ident": "d",
                    "content": "STANDARD 20 sek., BOOST 15 sek."
                }
            ]
        },
        {
            "ident": "q1",
            "content": "O czym informuje dioda dookoła przycisku \nw urządzeniu glo™?",
            "type": "text",
            "answers": [
                {
                    "ident": "a",
                    "content": "Wskazuje na stan naładowania baterii"
                },
                {
                    "ident": "b",
                    "content": "Informuje o procesie trwającym procesie podgrzewania"
                },
                {
                    "ident": "c",
                    "content": "Komunikuje o błędach \nz urządzeniem"
                },
                {
                    "ident": "d",
                    "content": "Wszystkie odpowiedzi są poprawne"
                }
            ]
        },
        {
            "ident": "q2",
            "content": "Jak często trzeba czyścić urządzenie, \nby zapewnić poprawną pracę podgrzewacza glo™?",
            "type": "text",
            "answers": [
                {
                    "ident": "a",
                    "content": "Co 20 sesji"
                },
                {
                    "ident": "b",
                    "content": "Raz na 6 miesięcy"
                },
                {
                    "ident": "c",
                    "content": "Raz na rok"
                },
                {
                    "ident": "d",
                    "content": "Urządzenie nie musi być czyszczone"
                }
            ]
        }
    ]
}