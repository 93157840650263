
export const questions = {
    "gameType": "revealImage",
    "glo": [
        {
            "ident": "q0",
            "tile": 1,
            "content": "W wariantach tytoniowych znajdują się 3 aromaty neo™ (Gold Tobacco, Rounded Tobacco oraz Terracotta Tobacco).",
            "answers": [
                {
                    "ident": "a",
                    "content": "Prawda",
                },
                {
                    "ident": "b",
                    "content": "Fałsz"
                }
            ],
            "correct": "a"
        },
        {
            "ident": "q1",
            "tile": 3,
            "content": "Wkład neo™ Arctic Blue Click należy do edycji Moment Range.",
            "answers": [
                {
                    "ident": "a",
                    "content": "Prawda"
                },
                {
                    "ident": "b",
                    "content": "Fałsz"
                }
            ],
            "correct": "b"
        },
        {
            "ident": "q2",
            "tile": 5,
            "content": "Aromat neo™ Foam Click to bogata kremowa nuta z karmelowym posmakiem.",
            "answers": [
                {
                    "ident": "a",
                    "content": "Prawda"
                },
                {
                    "ident": "b",
                    "content": "Fałsz"
                }
            ],
            "correct": "a"
        },
        {
            "ident": "q3",
            "tile": 7,
            "content": "Wkłady neo™ oferują dużą różnorodność - warianty tytoniowe, mentolowe, owocowe i z podwójną kapsułą.",
            "answers": [
                {
                    "ident": "a",
                    "content": "Prawda"
                },
                {
                    "ident": "b",
                    "content": "Fałsz"
                }
            ],
            "correct": "a"
        },
        // {
        //     "ident": "q4",
        //     "tile": 7,
        //     "content": "Jednym z owocowych wariantów jest neo™ Watermelon Click.",
        //     "answers": [
        //         {
        //             "ident": "a",
        //             "content": "Prawda"
        //         },
        //         {
        //             "ident": "b",
        //             "content": "Fałsz"
        //         }
        //     ],
        //     "correct": "b"
        // },
        // {
        //     "ident": "q5",
        //     "tile": 8,
        //     "content": "Edycja limitowana wkładów neo™ dostępna jest wyłącznie w punktach eSmoking World.",
        //     "answers": [
        //         {
        //             "ident": "a",
        //             "content": "Prawda"
        //         },
        //         {
        //             "ident": "b",
        //             "content": "Fałsz"
        //         }
        //     ],
        //     "correct": "a"
        // },
    ]
}