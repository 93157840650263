import Prepaid1000 from "./img/prepaid_1000.svg"
import Prepaid500 from "./img/prepaid_500.svg"
import Prepaid250 from "./img/prepaid_250.svg"

export const konkurs9_grudzien = {
    rewards: [
        {
            img: Prepaid1000,
            place: "1-3",
            content: "2 x 1000 zł na kartach przedpłaconych",
        },
        {
            img: Prepaid500,
            place: "4-6",
            content: "2 x 500 zł na kartach przedpłaconych"
        },
        {
            img: Prepaid250,
            place: "7-10",
            content: "2 x 250 zł na kartach przedpłaconych",
        },
    ],
}