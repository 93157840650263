export const questions = {
    "gameType": "TrueFalsePickOneLayout",
    "glo": [
        {
            "ident": "q0",
            "content": "Błędem jest wymiana wkładu neo™ po przerwanej sesji. Ten sam wkład możemy podgrzać drugi raz.",
            fontSizeMobile: "24px",
            fontSizeDesktop: "24px",
            "type": "text",
            "answers": [
                {
                    "ident": "a",
                    "content": "Prawda"
                },
                {
                    "ident": "b",
                    "content": "Fałsz"
                }
            ]
        },
        {
            "ident": "q1",
            "content": "Błędem jest podgrzewanie w glo™ tradycyjnych papierosów jako zamienników wkładów neo™.",
            fontSizeMobile: "24px",
            fontSizeDesktop: "24px",
            "type": "text",
            "answers": [
                {
                    "ident": "a",
                    "content": "Prawda"
                },
                {
                    "ident": "b",
                    "content": "Fałsz"
                }
            ]
        },
        {
            "ident": "q2",
            "content": "Błędem jest regularne czyszczenie komory grzewczej w urządzeniu glo™.",
            fontSizeMobile: "24px",
            fontSizeDesktop: "24px",
            "type": "text",
            "answers": [
                {
                    "ident": "a",
                    "content": "Prawda"
                },
                {
                    "ident": "b",
                    "content": "Fałsz"
                }
            ]
        },
        {
            "ident": "q3",
            "content": "Błędem jest nieodczekanie na osiągnięcie przez wkład neo™ odpowiedniej temperatury podgrzania.",
            fontSizeMobile: "",
            fontSizeDesktop: "24px",
            "type": "text",
            "answers": [
                {
                    "ident": "a",
                    "content": "Prawda"
                },
                {
                    "ident": "b",
                    "content": "Fałsz"
                }
            ]
        }
    ]
}