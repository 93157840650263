export const questions = {
    "gameType": "ListLayout",
    "glo": [
        {
            "ident": "q1",
            "content": "Z ilu urządzeń glo™ korzystasz na co dzień?",
            fontSizeMobile: "24px",
            fontSizeDesktop: "24px",
            "type": "text",
            "answers": [
                {
                    "ident": 0,
                    "content": "1"
                },
                {
                    "ident": 1,
                    "content": "2-3"
                },
                {
                    "ident": 2,
                    "content": "4-5"
                },
                {
                    "ident": 3,
                    "content": "6 i więcej"
                }
            ]
        },
        {
            "ident": "q2",
            "content": "Czy oprócz używania podgrzewacza glo™, korzystasz także z innych opcji dostarczających nikotynę?",
            fontSizeMobile: "24px",
            fontSizeDesktop: "24px",
            "type": "array",
            "answers": [
                {
                    "ident": 0,
                    "content": "Nie, nie korzystam z innych opcji"
                },
                {
                    "ident": 1,
                    "content": "Palę tradycyjne papierosy"
                },
                {
                    "ident": 2,
                    "content": "Korzystam z e-papierosów (np. Vuse ePod 2)"
                },
                {
                    "ident": 3,
                    "content": "Korzystam z saszetek nikotynowych (np. VELO)"
                },
                {
                    "ident": 4,
                    "content": "Korzystam z jednorazowych e-papierosów (np. Vuse GO)"
                }
            ]
        },
        {
            "ident": "q3",
            "content": "Od jakiego czasu używasz glo™?",
            fontSizeMobile: "24px",
            fontSizeDesktop: "24px",
            "type": "text",
            "answers": [
                {
                    "ident": 0,
                    "content": "Krócej niż jeden miesiąc"
                },
                {
                    "ident": 1,
                    "content": "Dłużej niż 1 miesiąc, ale krócej niż 6 miesięcy"
                },
                {
                    "ident": 2,
                    "content": "Dłużej niż 6 miesięcy, ale krócej niż 1 rok"
                },
                {
                    "ident": 3,
                    "content": "Dłużej niż jednej rok"
                }
            ]
        },
        {
            "ident": "q4",
            "content": "Ile wkładów neo™ podgrzewasz dziennie?",
            fontSizeMobile: "",
            fontSizeDesktop: "24px",
            "type": "text",
            "answers": [
                {
                    "ident": 0,
                    "content": "1-5"
                },
                {
                    "ident": 1,
                    "content": "6-10"
                },
                {
                    "ident": 2,
                    "content": "11-15"
                },
                {
                    "ident": 3,
                    "content": "16-20"
                },
                {
                    "ident": 4,
                    "content": "Więcej niż 20"
                }
            ]
        },
        {
            "ident": "q5",
            "content": "Gdzie najczęściej kupujesz paczki neo™?",
            fontSizeMobile: "",
            fontSizeDesktop: "24px",
            "type": "array",
            "answers": [
                {
                    "ident": 0,
                    "content": "Punkt sprzedaży eSmoking World"
                },
                {
                    "ident": 1,
                    "content": "Sieci sklepów (np. Żabka)"
                },
                {
                    "ident": 2,
                    "content": "Salony prasowe"
                },
                {
                    "ident": 3,
                    "content": "Stacja paliw"
                },
                {
                    "ident": 4,
                    "content": "Posiadam pakiet subskrypcji wkładów neo™"
                }
            ]
        }
    ]
}