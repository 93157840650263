import Xbox from "./img/xbox.svg"
import CinemaCity from "./img/cinema-city.svg"

const konkurs1_luty_2023 = {
    rewards: [
        {
            img: Xbox,
            place: "1-3",
            content: "Konsola Xbox Series X + dodatkowy pad",
        },
        {
            img: CinemaCity,
            place: "4-10",
            content: "2x Karty Unlimited Cinema City"
        },
    ],
}

export default konkurs1_luty_2023