
export const questions = {
  "gameType": "TrueFalseTile",
  "glo": [
    {
      "ident": "q0",
      "tile": 0,
      "content": "Urządzenia glo™ hyper X2 Air oraz glo™ hyper X2 są dostępne tylko w 3 kolorach.",
      "answers": [
        {
          "ident": "a",
          "content": "Prawda",
        },
        {
          "ident": "b",
          "content": "Fałsz"
        }
      ],
      "correct": "b",
      "disclaimer": ""
    },
    {
      "ident": "q1",
      "tile": 1,
      "content": "Urządzenie glo™ hyper X2 Air posiada wykończenie metaliczne, a glo™ hyper X2 typu „soft touch”.",
      "answers": [
        {
          "ident": "a",
          "content": "Prawda"
        },
        {
          "ident": "b",
          "content": "Fałsz"
        }
      ],
      "correct": "a",
      "disclaimer": ""
    },
    {
      "ident": "q2",
      "tile": 2,
      "content": "Urządzenie glo™ hyper X2 Air posiada przesuwany system otwierania, a w glo™ hyper X2 zastosowany jest obracany system.",
      "answers": [
        {
          "ident": "a",
          "content": "Prawda"
        },
        {
          "ident": "b",
          "content": "Fałsz"
        }
      ],
      "correct": "a",
      "disclaimer": ""
    },
    {
      "ident": "q3",
      "tile": 3,
      "content": "Urządzenia glo™ hyper X2 Air oraz glo™ hyper X2 ważą tyle samo.",
      "answers": [
        {
          "ident": "a",
          "content": "Prawda"
        },
        {
          "ident": "b",
          "content": "Fałsz"
        }
      ],
      "correct": "b",
      "disclaimer": ""
    },
    {
      "ident": "q4",
      "tile": 4,
      "content": "Urządzenie glo™ hyper X2 Air oferuje możliwość podgrzewania 3 wkładów następujących po sobie, a glo™ hyper X2 daje Ci możliwość podgrzewania 2 następujących wkładów*",
      "answers": [
        {
          "ident": "a",
          "content": "Prawda"
        },
        {
          "ident": "b",
          "content": "Fałsz"
        }
      ],
      "correct": "a",
      "disclaimer": "*zależy od poziomu naładowania urządzenia i sposobu użytkowania"
    },
  ]
}