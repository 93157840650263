import React, { useEffect, useState } from 'react'
import Modal from "../Modal/Modal"
import Button from "../Button/Button"
import "./style.scss"
import { useLocation } from 'react-router'
import { getUrlParam, removeUrlParam } from '../../UrlParamsHelper'
import AgeGate1 from "./img/age-gate-1.svg"
import AgeGate2 from "./img/age-gate-2.svg"

export default function AgeGate({setAge}) {
    const { pathname } = useLocation()
    function useSessionStorage(key, initialValue) {
        // State to store our value
        // Pass initial state function to useState so logic is only executed once
        const [storedValue, setStoredValue] = useState(() => {
            if (window)
                try {
                    // Get from local storage by key
                    const item = window.sessionStorage.getItem(key);
                    // Parse stored json or if none return initialValue
                    return item ? JSON.parse(item) : initialValue;
                } catch (error) {
                    // If error also return initialValue
                    console.log(error);
                    return initialValue;
                }
        });
        if (typeof window === "undefined") {
            return initialValue;
        }
        // Return a wrapped version of useState's setter function that ...
        // ... persists the new value to localStorage.
        const setValue = (value) => {
            try {
                // Allow value to be a function so we have same API as useState
                const valueToStore =
                    value instanceof Function ? value(storedValue) : value;
                // Save state
                setStoredValue(valueToStore);
                // Save to local storage
                window.sessionStorage.setItem(key, JSON.stringify(valueToStore));
            } catch (error) {
                // A more advanced implementation would handle the error case
                console.log(error);
            }
        };
        return [storedValue, setValue];
    }

    const [value, setValue] = useSessionStorage(
        "overEighteen",
        pathname === "/twoj-kupon/odbior" ? true : false
    );
    const [error, setError] = useState(false) 

    useEffect(() => {
        if(getUrlParam("ageGate") === "false") {
            setValue(true)
            removeUrlParam("ageGate")
        }
    }, [])

    return (
        <Modal isOpen={!value && !localStorage.getItem("rememberMe")} buttonClose={false} closeModal={() => setValue(true) } modalClass="age-gate">
            <div className="age-gate">
                <div className="top">
                    <p>Witaj na stronie dla <br className='mobile' />pełnoletnich osób zainteresowanych  <br />ofertą BAT Polska Trading sp.&nbsp;z&nbsp;o.o.</p>
                    {/* <img src={AgeGate1} alt="" style={{ maxWidth: "90%", display: "block", margin: "0 auto" }}/> */}
                </div>
                <div className="over-18">
                    <h3>Potwierdzam, że mam <br className='mobile' />ukończone 18 lat</h3>
                </div>
                <div className="buttons">
                    <Button action={() => { setValue(true); setAge(true) }}>Tak, potwierdzam</Button>
                    <Button btnClass='white' action={() => setError(true)}>Nie</Button>
                </div>
                <div className="error">
                    {error && <h3> Musisz mieć ukończone 18 lat aby wejść na stronę</h3>}
                </div>
                <div className="disclaimer">
                    <p>Ta strona należy do BAT Polska Trading Sp.&nbsp;z&nbsp;o.o. Dedykowana jest dla pełnoletnich użytkowników wyrobów nikotynowych BAT Polska Trading sp.&nbsp;z&nbsp;o.o. Wszystkie wyroby należące do BAT Polska szkodzą zdrowiu i&nbsp;są uzależniające.</p>
                    {/* <img src={AgeGate2} alt="" style={{ maxWidth: "90%", display: "block", margin: "0 auto" }}/> */}
                </div>
            </div>
        </Modal>
    )
}
