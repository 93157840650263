import Part0 from "./img/part0.jpg"
import Part1 from "./img/part1.jpg"
import Part2 from "./img/part2.jpg"
import Part3 from "./img/part3.jpg"
import Part4 from "./img/part4.jpg"
import Part5 from "./img/part5.jpg"
import Part6 from "./img/part6.jpg"
import Part7 from "./img/part7.jpg"
import Part8 from "./img/part8.jpg"

export const questions = {
    "gameType": "JigsawNew",
    "glo": [
        {
            "ident": "q0",
            "content": "Dopasuj opis do wkładu neo™ Rounded Tobacco",
            type: "pickOne",
            "answers": [
                {
                    "ident": "a",
                    "content": "Złożone i intensywne doświadczenie tytoniu z pikantnymi nutami drzewnymi"
                },
                {
                    "ident": "b",
                    "content": "Zbalansowany i najłagodniejszy wariant tytoniowy"
                },
                {
                    "ident": "c",
                    "content": "Łagodny wariant tytoniowy z nutami waniliowymi"
                },
                {
                    "ident": "d",
                    "content": "Zrównoważony, bogaty wariant tytoniowy z kremowymi nutami"
                },
            ]
        },
        {
            "ident": "q1",
            "content": "Dopasuj intensywność do wkładu neo™ Rounded Tobacco",
            type: "strength",
            "answers": [
                {
                    "ident": "a",
                    "content": [true, false, false, false, false]
                },
                {
                    "ident": "b",
                    "content": [true, true, false, false, false]
                },
                {
                    "ident": "c",
                    "content": [true, true, true, false, false]
                },
                {
                    "ident": "d",
                    "content": [true, true, true, true, false]
                },
            ]
        },
        {
            "ident": "q2",
            "content": "Zaznacz dwa sąsiadujące fragmenty, aby je przesunąć. Kafelki mogą poruszać się tylko w pionie i poziomie.",
            type: "jigsaw",
            "answers": [
                {
                    "ident": "2eab2486-2a24-4f65-87ac-6ee715f512a0",
                    "content": Part0
                }, 
                {
                    "ident": "92c0011a-6d19-44db-8fa7-0f0544ac4b0b",
                    "content": Part1
                }, 
                {
                    "ident": "d43ad238-1e45-47db-bf89-f72c5fc767f4",
                    "content": Part2
                }, 
                {
                    "ident": "99bd9982-72e0-4a8c-9714-ea212c76df49",
                    "content": Part3
                }, 
                {
                    "ident": "06c860c4-4054-42ed-9922-495e853803a3",
                    "content": Part4
                }, 
                {
                    "ident": "56551f5b-b7aa-4eff-a39c-8934bc21f71d",
                    "content": Part5
                }, 
                {
                    "ident": "38669bbf-6fe6-4849-b778-efe86de45f33",
                    "content": Part6
                }, 
                {
                    "ident": "c934067e-da68-4de2-8dc7-2dbbfbc7352c",
                    "content": Part7
                }, 
                {
                    "ident": "e296d744-60df-4a75-9393-6769805ed8c6",
                    "content": Part8
                },
            ]
        },
    ]
}