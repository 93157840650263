import React from 'react'
import { Link } from 'react-router-dom';
import "./Button.scss"

// default link wrapper
// if http on begining of link use a wrapper
// if action specified use button wrapper

// add posibility of using external classes

function Button({ children, href, action = null, submit=false, disabled=false, btnClass ="orange", title, state, target, rel, id, onAnimationEnd }) {

  function validateLink(link) {
    let temp = link

    if (action === null && temp === undefined && submit === false) {
      return
    }
    if (submit === true) {
      return "submit"
    }
    if (action !== null && link === undefined) {
      return "function"
    }
    if (temp && (temp.includes("http://") || temp.includes("https://"))) {
      return "external"
    }
    return "internal"
  }

  const mode = validateLink(href)

  switch(mode) {
    case "internal":
      return (
        <Link  to={href ?? ""} className={`button ${btnClass} ${disabled ? "disabled" : ""}`} title={title} onClick={action} state={state} onAnimationEnd={onAnimationEnd}>
          {children}
        </Link>
      )

    case "external":
      return (
        <a href={href} target={ target ? target : "_blank" } rel={ rel || "noopener noreferrer" } className={`button ${btnClass} ${disabled ? "disabled" : ""}`}
          title={title} onAnimationEnd={onAnimationEnd}>
          {children}
        </a>
      )

    case "function":
      return (
        <button className={`button ${btnClass} ${disabled ? "disabled" : ""}`} onClick={action} title={title} disabled={disabled} id={id} onAnimationEnd={onAnimationEnd}>
          {children}
        </button>
      )
    
    case "submit":
      return(
        <input type="submit" value={children} className={`button ${btnClass} ${disabled ? "disabled" : ""}`} 
           disabled={disabled} title={title} onAnimationEnd={onAnimationEnd}/>
      )

    default:
      return (
        <div className={`button ${btnClass} ${disabled ? "disabled" : ""}`} title={title} onAnimationEnd={onAnimationEnd}>
          {children}
        </div>
      )
  }
}

export default Button
