import Mobile from "./img/bg-mobile.jpg"
import Desktop from "./img/bg-desktop.jpg"
// import Statute from "./Regulamin Aktywności_Ekspert_neo_14.02.pdf"
import reward0 from "./img/reward0.png"
import reward1 from "./img/reward1.png"
import reward2 from "./img/reward2.png"

export const konkurs1_luty = {
    header: "Niezapomniane chwile z glo™",
    backgroundMobile: Mobile,
    backgroundDesktop: Desktop,
    title: "Słuchajcie! Ostatnio glo™ wróciło \nz niesamowitej podróży, ale zapomniało o robieniu fotek.",
    subtitle: "Tu wkraczacie WY! Pokażcie nam, gdzie zawędrowało glo™ oczami Waszej wyobraźni i kamery. ",
    message: "",
    points: 20,
    statute: "/assets/Regulamin Konkurs_glo_AlterZone_14.02.pdf",

    ident: "konkurs1_luty",
    gameType: "Contest",

    rewards: [
        {
            img: reward0,
            place: "1-3",
            content: "Apple iPhone 13 Pro 128GB"
        },
        {
            img: reward1,
            place: "4-10",
            content: "Słuchawki Apple AirPods Pro"
        },
        {
            img: reward2,
            place: "11-20",
            content: "Aparat Fujifilm Instax Mini 11"
        },
    ],

    correct: {
        title: "Super fota!",
        content: [
            "Udało Ci się uchwycić genialny moment!",
            "Zwycięzców w konkursie będzie dwudziestu, ale już teraz trzymamy za Ciebie kciuki.",
            "W oczekiwaniu na wyniki Twoje konto zasiliło:"
        ]
    },
    wrong: {
        title: "Ups... cos poszło nie tak",
        content: [
            "Twoje zdjęcie gdzieś utknęło i nie zostało nam dostarczone. Spróbuj raz jeszcze. Jeśli problem będzie się powtarzał, sprawdź, czy format i waga zdjęcia zgadzają się z wymaganiami."
        ]
    },
}