export const questions = {
    "gameType": "Video",
    "glo": [
        {
            "ident": "q0",
            "content": "Która edycja CLOUDS FEST odbyła się w 2022 roku?",
            "answers": [
                {
                    "ident": "a",
                    "content": "Pierwsza"
                },
                {
                    "ident": "b",
                    "content": "Druga"
                },
                {
                    "ident": "c",
                    "content": "Trzecia"
                },
                {
                    "ident": "d",
                    "content": "Czwarta"
                },
            ]
        },
        {
            "ident": "q1",
            "content": "W jakich miastach w tym roku wylądował balon z koncertami CLOUDS FEST?",
            "answers": [
                {
                    "ident": "a",
                    "content": "Warszawa, Kraków, Wrocław"
                },
                {
                    "ident": "b",
                    "content": "Wrocław, Poznań, Gdańsk"
                },
                {
                    "ident": "c",
                    "content": "Wrocław, Gdańsk, Chorzów"
                },
                {
                    "ident": "d",
                    "content": "Warszawa, Gdańsk, Chorzów"
                },
            ]
        },
        {
            "ident": "q2",
            "content": "Kto zagrał na scenie CLOUDS FEST w tym roku?",
            video: require("./img/1.mp4"),
            "answers": [
                {
                    "ident": "a",
                    "content": "Daria Zawiałow, PRO8L3M"
                },
                {
                    "ident": "b",
                    "content": "Zalewski, Brodka, Sobel"
                },
                {
                    "ident": "c",
                    "content": "duet Flirtini, PRO8L3M, Natalia Szroeder"
                },
                {
                    "ident": "d",
                    "content": "Natalia Przybysz, Daria Zawiałow, PRO8L3M, duet Flirtini"
                },
            ]
        },
        {
            "ident": "q3",
            "content": "Jaka atrakcja na ternie festiwalu oprócz koncertu była na CLOUDS FEST?",
            video: require("./img/2.mp4"),
            "answers": [
                {
                    "ident": "a",
                    "content": "Ścianka wspinaczkowa"
                },
                {
                    "ident": "b",
                    "content": "Lot balonem"
                },
                {
                    "ident": "c",
                    "content": "Skok na bungee"
                },
                {
                    "ident": "d",
                    "content": "Rollercoaster"
                },
            ]
        }
    ]
}