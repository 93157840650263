import img from "./img/Main.jpg"

export const questions = {
  "gameType": "Poll",
  "glo": [
    {
      "ident": "q0",
      "content": "W ilu krajach dostępne są do kupienia urządzenia glo™ oraz paczki neo™?",
      "type": "oneWithInput",
      "img": img,
      "answers": [
        {
          "ident": "a",
          "content": "16"
        },
        {
          "ident": "b",
          "content": "24"
        },
        {
          "ident": "c",
          "content": "36",
        },
        {
          "ident": "d",
          "content": "47"
        },
      ]
    },
    {
      "ident": "q1",
      "content": "Czy można korzystać z urządzenia glo™ podczas lotów?",
      "type": "oneWithInput",
      "img": img,
      "answers": [
        {
          "ident": "a",
          "content": "Tak"
        },
        {
          "ident": "b",
          "content": "Tak, ale tylko za pozwoleniem stewardesy"
        },
        {
          "ident": "c",
          "content": "Tak, ale tylko w specjalnie wyznaczonej strefie"
        },
        {
          "ident": "d",
          "content": "Podgrzewanie podczas lotu jest zabronione"
        },
      ]
    },
    {
      "ident": "q2",
      "content": "Na ilu lotniskach w Polsce kupisz urządzenie glo™ lub paczki neo™ przed podróżą?",
      "type": "oneWithInput",
      "img": img,
      "answers": [
        {
          "ident": "a",
          "content": "2"
        },
        {
          "ident": "b",
          "content": "5"
        },
        {
          "ident": "c",
          "content": "7"
        },
        {
          "ident": "d",
          "content": "12"
        },
      ]
    },
    {
      "ident": "q3",
      "content": "Co warto sprawdzić, gdy wybierasz się na wakacje do innego kraju z glo™?",
      "type": "oneWithInput",
      "img": img,
      "answers": [
        {
          "ident": "a",
          "content": "Pogodę"
        },
        {
          "ident": "b",
          "content": "Standard gniazdek elektrycznych"
        },
        {
          "ident": "c",
          "content": "Obowiązującą walutę"
        },
        {
          "ident": "d",
          "content": "Odległość plaży od hotelu"
        },
      ]
    },
  ]
}