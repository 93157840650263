export const questions = {
    "gameType": "Subscription",
    "glo": [
        {
            "ident": "q0",
            "content": "Pakiet SILVER – 9 zł za paczkę neo™",
            "type": "text",
            "answers": [
                {
                    "ident": "a",
                    "content": "5"
                },
                {
                    "ident": "b",
                    "content": "10"
                },
                {
                    "ident": "c",
                    "content": "15"
                },
                {
                    "ident": "a",
                    "content": "30 zł"
                },
                {
                    "ident": "b",
                    "content": "70 zł"
                },
                {
                    "ident": "c",
                    "content": "135 zł"
                }
            ]
        },
        {
            "ident": "q2",
            "content": "Pakiet GOLD – 8,50 zł za paczkę neo™",
            "type": "text",
            "answers": [
                {
                    "ident": "a",
                    "content": "20"
                },
                {
                    "ident": "b",
                    "content": "30"
                },
                {
                    "ident": "c",
                    "content": "40"
                },
                {
                    "ident": "a",
                    "content": "170zł"
                },
                {
                    "ident": "b",
                    "content": "300 zł"
                },
                {
                    "ident": "c",
                    "content": "390 zł"
                }
            ]
        },
        {
            "ident": "q4",
            "content": "Pakiet PLATINUM – 8 zł za paczkę neo™",
            "type": "text",
            "answers": [
                {
                    "ident": "a",
                    "content": "30"
                },
                {
                    "ident": "b",
                    "content": "60"
                },
                {
                    "ident": "c",
                    "content": "90"
                },
                {
                    "ident": "a",
                    "content": "120 zł"
                },
                {
                    "ident": "b",
                    "content": "240 zł"
                },
                {
                    "ident": "c",
                    "content": "420 zł"
                }
            ]
        }
    ]
}