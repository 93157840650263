export const questions = {
    "gameType": "PickOne",
    "glo": [
        {
            "ident": "q0",
            "content": "Black Friday to największe święto promocji, które co roku wypada w czwarty piątek listopada.",
            "type": "text",
            fontSize: "24px",
            "answers": [
                {
                    "ident": "a",
                    "content": "Prawda"
                },
                {
                    "ident": "b",
                    "content": "Fałsz"
                }
            ]
        },
        {
            "ident": "q1",
            "content": "Black Friday swoją nazwę zawdzięcza zaćmieniu słońca, które wypadło w piątek.",
            "type": "text",
            fontSize: "24px",
            "answers": [
                {
                    "ident": "a",
                    "content": "Prawda"
                },
                {
                    "ident": "b",
                    "content": "Fałsz"
                }
            ]
        },
        {
            "ident": "q2",
            "content": "Black Friday rozpoczyna symbolicznie świąteczny sezon zakupowy.",
            "type": "text",
            fontSize: "24px",
            "answers": [
                {
                    "ident": "a",
                    "content": "Prawda"
                },
                {
                    "ident": "b",
                    "content": "Fałsz"
                }
            ]
        },
        {
            "ident": "q3",
            "content": "Już nie tylko jeden dzień w roku. Coraz więcej sklepów rozszerza swoje promocje nawet do poniedziałku. Cyber Monday to święto zakupów online połączone z obniżkami w Internecie.",
            "type": "text",
            fontSize: "18px",
            "answers": [
                {
                    "ident": "a",
                    "content": "Prawda"
                },
                {
                    "ident": "b",
                    "content": "Fałsz"
                }
            ]
        }
    ]
}