import _1 from "./img/1.jpg"
import _1_1 from "./img/1_1.jpg"
import _2 from "./img/2.jpg"
import _2_1 from "./img/2_1.jpg"
import _3 from "./img/3.jpg"
import _3_1 from "./img/3_1.jpg"

export const questions = {
    "gameType": "FindDifference",
    "glo": [
        {
            "ident": "q0",
            "content": "W ilu kolorach jest dostępne nowe urządzenie glo™ hyper X2?",
            "type": "question",
            "answers": [
                {
                    "ident": "a",
                    "content": "2"
                },
                {
                    "ident": "b",
                    "content": "4"
                },
                {
                    "ident": "c",
                    "content": "7"
                },
                {
                    "ident": "d",
                    "content": "10"
                },
            ]
        },
        {
            "ident": "q1",
            "type": "find",
            "srcImg": _1,
            "img": _1_1,
            "answers": [
                {
                    "ident": "41ae6e6a-0c77-4cec-8d89-bafa932c523c",
                    "clipPath": "circle(5.7% at 61% 64%)",
                },
                {
                    "ident": "17d2c8ff-601f-4270-9fc7-1ec4240ccb7f",
                    "clipPath": "circle(11.4% at 30% 29%)",
                },
                {
                    "ident": "d214664f-2c07-46ae-9d43-728e015ecea7",
                    "clipPath": "circle(13.9% at 8% 55%)",
                },
            ]
        },
        {
            "ident": "q2",
            "type": "find",
            "srcImg": _2,
            "img": _2_1,
            "answers": [
                {
                    "ident": "71a54fe4-0117-41f2-acd2-f701bdbf090b",
                    "clipPath": "circle(13.9% at 26% 37%)",
                },
                {
                    "ident": "36842c14-34b7-42c4-af0a-0289b03f0095",
                    "clipPath": "circle(14.4% at 56% 66%)",
                },
                {
                    "ident": "80548f4e-dd25-4fe9-8c37-4b30bd65b3e0",
                    "clipPath": "circle(10.0% at 72% 38%)",
                },
            ]
        },
        {
            "ident": "q3",
            "type": "find",
            "srcImg": _3,
            "img": _3_1,
            "answers": [
                {
                    "ident": "d734ab0e-733a-425e-b643-df08eb4b6bf3",
                    "clipPath": "circle(11.2% at 73% 43%)",
                },
                {
                    "ident": "1703af08-0d6e-46a3-bc41-6c5fd8965af1",
                    "clipPath": "circle(9.4% at 34% 83%)",
                },
                {
                    "ident": "e22bba65-d8e9-42b8-89d9-2c996f199e89",
                    "clipPath": "circle(5.4% at 52% 53%)",
                },
            ]
        }
    ]
}