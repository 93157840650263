import Part0 from "./img/piece0.jpg"
import Part1 from "./img/piece1.jpg"
import Part2 from "./img/piece2.jpg"
import Part3 from "./img/piece3.jpg"
import Part4 from "./img/piece4.jpg"
import Part5 from "./img/piece5.jpg"
import Part6 from "./img/piece6.jpg"
import Part7 from "./img/piece7.jpg"
import Part8 from "./img/piece8.jpg"

export const questions = {
    "gameType": "Jigsaw",
    "glo": [
        {
            "ident": "q0",
            "content": "W jakich miastach dotykamy chmur z koncertami Clouds Fest?",
            "answers": [
                {
                    "ident": "a",
                    "content": "Warszawa"
                },
                {
                    "ident": "b",
                    "content": "Kraków"
                },
                {
                    "ident": "c",
                    "content": "Wrocław"
                },
                {
                    "ident": "d",
                    "content": "Poznań"
                },
                {
                    "ident": "e",
                    "content": "Gdańsk"
                },
                {
                    "ident": "f",
                    "content": "Chorzów"
                },
            ]
        },
        {
            "ident": "q1",
            "content": "Kto występuje na tegorocznej scenie Clouds Fest?",
            "answers": [
                {
                    "ident": "a",
                    "content": "Natalia Przybysz"
                },
                {
                    "ident": "b",
                    "content": "Natalia Szroeder"
                },
                {
                    "ident": "c",
                    "content": "Daria Zawiałow"
                },
                {
                    "ident": "d",
                    "content": "Jimek"
                },
                {
                    "ident": "e",
                    "content": "PRO8L3M"
                },
                {
                    "ident": "f",
                    "content": "Pezet"
                },
            ]
        },
        {
            "ident": "q2",
            "content": "Ułóż zdjęcie i poznaj datę ostatniego koncertu.",
            "sub": "Zaznacz dwa sąsiadujące fragmenty, aby je przesunąć. Kafelki mogą poruszać się tylko w pionie i poziomie.",
            "answers": [
                {
                    "ident": "63c176a7-2a18-4f5b-948d-93c0576a413e",
                    "content": Part0
                }, 
                {
                    "ident": "4f116f93-e03b-4329-8388-d257ac56e66c",
                    "content": Part1
                }, 
                {
                    "ident": "c18b9ffb-5a82-42e0-bfeb-7437509b5474",
                    "content": Part2
                }, 
                {
                    "ident": "f585de49-ce66-4c8e-823e-cc2a4daa64af",
                    "content": Part3
                }, 
                {
                    "ident": "07518465-e387-4c45-b28d-b3302dc66e94",
                    "content": Part4
                }, 
                {
                    "ident": "6e0d2a32-273c-4628-8bd9-c15728e5847a",
                    "content": Part5
                }, 
                {
                    "ident": "e26e9f4a-60aa-4555-800b-8b4bc0cb0318",
                    "content": Part6
                }, 
                {
                    "ident": "4cf7ea52-bf92-42dc-9723-b85aa6ed0408",
                    "content": Part7
                }, 
                {
                    "ident": "8cea27c4-a77e-477e-b8fe-e0d042dcff71",
                    "content": Part8
                },
            ]
        },
    ]
}