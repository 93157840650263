import q1 from "./img/q1.png"
import q2 from "./img/q2.png"
import q3 from "./img/q3.png"
import q4 from "./img/q4.png"
import q5 from "./img/q5.png"
import q6 from "./img/q6.png"
import q7 from "./img/q7.png"
import q8 from "./img/q8.png"
import q9 from "./img/q9.png"

export const questions = {
    "gameType": "TrueFalseImageLayout",
    "glo": [
        {
            "ident": "q0",
            "content": q1,
            fontSizeMobile: "24px",
            fontSizeDesktop: "24px",
            "type": "text",
            "answers": [
                {
                    "ident": "a",
                    "content": "Intensywne miętowe doświadczenie",
                    correct: true
                },
                {
                    "ident": "b",
                    "content": "Słodki i świeży jagodowy aromat"
                }
            ]
        },
        {
            "ident": "q1",
            "content": q2,
            fontSizeMobile: "24px",
            fontSizeDesktop: "24px",
            "type": "text",
            "answers": [
                {
                    "ident": "a",
                    "content": "Słodki posmak czerwonych owoców"
                },
                {
                    "ident": "b",
                    "content": "Słodki i świeży jagodowy aromat",
                    correct: true
                }
            ]
        },
        {
            "ident": "q2",
            "content": q3,
            fontSizeMobile: "24px",
            fontSizeDesktop: "24px",
            "type": "text",
            "answers": [
                {
                    "ident": "a",
                    "content": "Doświadczenie intensywnego mentolu oraz słodka miętowa nuta"
                },
                {
                    "ident": "b",
                    "content": "Łagodny i słodki miętowy aromat",
                    correct: true
                }
            ]
        },
        {
            "ident": "q3",
            "content": q4,
            fontSizeMobile: "",
            fontSizeDesktop: "24px",
            "type": "text",
            "answers": [
                {
                    "ident": "a",
                    "content": "Słodki aromat czerwonych owoców",
                    correct: true
                },
                {
                    "ident": "b",
                    "content": "Tropikalne, świeże i słodkie nuty ananasa"
                }
            ]
        },
        {
            "ident": "q4",
            "content": q5,
            fontSizeMobile: "",
            fontSizeDesktop: "24px",
            "type": "text",
            "answers": [
                {
                    "ident": "a",
                    "content": "Bogata kremowa nuta z karmelowym posmakiem",
                    correct: true
                },
                {
                    "ident": "b",
                    "content": "Połączenie słodkich i miętowych nut"
                }
            ]
        },
        {
            "ident": "q5",
            "content": q6,
            fontSizeMobile: "",
            fontSizeDesktop: "24px",
            "type": "text",
            "answers": [
                {
                    "ident": "a",
                    "content": "Aromat słodkiego melona z cytrusami oraz aromatami tropikalnych owoców",
                    correct: true
                },
                {
                    "ident": "b",
                    "content": "Tropikalne, świeże i słodkie nuty ananasa"
                }
            ]
        },
        {
            "ident": "q6",
            "content": q7,
            fontSizeMobile: "",
            fontSizeDesktop: "24px",
            "type": "text",
            "answers": [
                {
                    "ident": "a",
                    "content": "Doświadczenie intensywnego mentolu oraz słodki miętowy aromat",
                    correct: true
                },
                {
                    "ident": "b",
                    "content": "Nuty słodkiego melona z cytrusami i nuty tropikalnych owoców"
                }
            ]
        },
        {
            "ident": "q7",
            "content": q8,
            fontSizeMobile: "",
            fontSizeDesktop: "24px",
            "type": "text",
            "answers": [
                {
                    "ident": "a",
                    "content": "Tropikalne, świeże i słodkie aromaty ananasa",
                    correct: true
                },
                {
                    "ident": "b",
                    "content": "Słodki posmak czerwonych owoców"
                }
            ]
        },
        {
            "ident": "q8",
            "content": q9,
            fontSizeMobile: "",
            fontSizeDesktop: "24px",
            "type": "text",
            "answers": [
                {
                    "ident": "a",
                    "content": "Połączenie słodkich i miętowych nut",
                    correct: true
                },
                {
                    "ident": "b",
                    "content": "Słodki posmak czerwonych owoców"
                }
            ]
        }
    ]
}