import Arctic from "./img/arctic.png"
import Foam from "./img/foam.png"
import Gold from "./img/gold.png"
import Green from "./img/green.png"
import Ice from "./img/ice.png"
import Melt from "./img/melt.png"
import Rounded from "./img/rounded.png"
import Scarlet from "./img/scarlet.png"
import Summer from "./img/summer.png"
import Sunset from "./img/sunset.png"
import Terracotta from "./img/terracotta.png"
import Violet from "./img/violet.png"


export const questions = {
    "gameType": "FindPacks",
    "glo": [
        {
            "ident": "q0",
            "content": "Warianty owocowe",
            "type": "image multi-select",
            "fontSizeMobile": "20px",
            "lineHeightMobile": "32px",
            "fontSizeDesktop": "24px",
            "lineHeightDesktop": "32px",
            "correctAnswers": ["c6569428-2841-4da2-81d5-0cd927239cc5", "15ca90d7-ca96-42ff-88c0-2aa00634d2b7"],
            "answers": [
                {
                    "ident": "c6569428-2841-4da2-81d5-0cd927239cc5",
                    "content": "neo™ Scarlet\nClick",
                    img: Scarlet
                },
                {
                    "ident": "80ee543f-e1f1-4ae6-bd5e-7f0059e23623",
                    "content": "neo™ Ice\nClick",
                    img: Ice
                },
                {
                    "ident": "15ca90d7-ca96-42ff-88c0-2aa00634d2b7",
                    "content": "neo™ Violet\nClick",
                    img: Violet
                },
                {
                    "ident": "cdad6a48-bad5-4dfa-902b-4f10ed83cb60",
                    "content": "neo™ Gold\nTobacco",
                    img: Gold
                },
                {
                    "ident": "f17230c9-b893-46c4-a287-6e8246767cd6",
                    "content": "neo™ Melt\nClick",
                    img: Melt
                },
                {
                    "ident": "6995b201-5695-4e4d-959b-50ea38ecefa0",
                    "content": "neo™ Green\nClick",
                    img: Green
                },
            ]
        },
        {
            "ident": "q1",
            "content": "Warianty miętowe",
            "type": "image multi-select",
            "correctAnswers": ["eb41d602-1bdf-4fb6-8b31-7afa2051ae80", "09ea5c2b-91cd-4482-8adc-b399afcba6c6", "2b5d5a9d-09df-43f8-b66b-a2fa6d435c95"],
            "answers": [
                {
                    "ident": "eb41d602-1bdf-4fb6-8b31-7afa2051ae80",
                    "content": "neo™ Ice\nClick",
                    img: Ice
                },
                {
                    "ident": "09ea5c2b-91cd-4482-8adc-b399afcba6c6",
                    "content": "neo™ Arctic\nBlue Click",
                    img: Arctic
                },
                {
                    "ident": "a34cfee7-0966-4250-814d-e0572848f004",
                    "content": "neo™ Terracotta\nTobacco",
                    img: Terracotta
                },
                {
                    "ident": "8f93aeeb-0299-4988-a784-f7778bff96de",
                    "content": "neo™ Foam\nClick",
                    img: Foam
                },
                {
                    "ident": "e38869ad-1d68-41c7-83a0-f3f09859c2ed",
                    "content": "neo™ Sunset\nClick",
                    img: Sunset
                },
                {
                    "ident": "2b5d5a9d-09df-43f8-b66b-a2fa6d435c95",
                    "content": "neo™ Green\nClick",
                    img: Green
                },
            ]
        },
        {
            "ident": "q2",
            "content": "Warianty tytoniowe",
            "type": "image multi-select",
            "correctAnswers": ["87cac88d-71a4-4c1b-b2fa-cbd8627d32df", "62d724f2-de06-4401-968d-56454392694c", "9db597f8-b655-4fce-ae15-94e70268c358"],
            "answers": [
                {
                    "ident": "4695b4a6-192d-45d7-8601-a79aca143a5b",
                    "content": "neo™ Green\nClick",
                    img: Green
                },
                {
                    "ident": "174b5a2e-6bd0-4d0a-bd64-2645d01f3ad3",
                    "content": "neo™ Summer\nYellow Click",
                    img: Summer
                },
                {
                    "ident": "303851e5-fd87-40d5-bfba-f36ba047cb08",
                    "content": "neo™ Arctic\nBlue Click",
                    img: Arctic
                },
                {
                    "ident": "87cac88d-71a4-4c1b-b2fa-cbd8627d32df",
                    "content": "neo™ Terracotta\nTobacco",
                    img: Terracotta
                },
                {
                    "ident": "62d724f2-de06-4401-968d-56454392694c",
                    "content": "neo™ Gold\nTobacco",
                    img: Gold
                },
                {
                    "ident": "9db597f8-b655-4fce-ae15-94e70268c358",
                    "content": "neo™ Rounded\nTobacco",
                    img: Rounded
                },
            ]
        },
    ]
}