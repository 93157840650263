import Mobile from "./img/bg-mobile.jpg"
import Desktop from "./img/bg-desktop.jpg"
import { questions } from "./questions"

export const subskrypcje_neo = {
    header: "Subskrybuj neo™",
    backgroundMobile: Mobile,
    backgroundDesktop: Desktop,
    title: "Chcesz więcej za mniej?",
    subtitle: "Cenisz sobie bezkompromisowe momenty przyjemności? \nDostosuj pakiet do swoich wymagań i oszczędzaj czas oraz pieniądze. Wybierz swoje ulubione smaki \ni otrzymuj wkłady neo™ \nz dostawą do domu!",
    message: "Dostosuj liczbę opakowań neo™ oraz cenę za pakiet subskrypcji, by otrzymać podany koszt 1 paczki neo™.",
    points: 15,
    statute: "/assets/Regulamin Aktywności_Subskrybuj_neo_14.02.pdf",

    ident: "luty4_2022",
    gameType: "Subscription",
    questions: questions,

    correct: {
        title: "Ekstra!",
        content: [
            "Masz już wybrany pakiet dla siebie? Trzeba przyznać, ze 8 zł za paczkę to kusząca oferta.",
            "Pakiet subskrypcji neo™ to: Wygoda - bez wychodzenia z domu co miesiąc otrzymujesz nowe wkłady neo™ Elastyczność – wybierasz taki pakiet, jaki chcesz i rezygnujesz z niego też, kiedy chcesz Oszczędność – masz więcej czasu i pieniędzy w portfelu",
            // "Chcesz poznać szczegóły subskrypcji?",
        ]
    },
    wrong: {
        title: "Mała wpadka",
        content: [
            "Nie martw się. Cały czas masz szansę zdobyć 15 punktów. Wróć do aktywności ponownie i spróbuj raz jeszcze.",
            // "Sprawdź szczegóły subskrypcji Zajrzyj tutaj",
            // "Chcesz skorzystać z małej pomocy?"
        ]
    },
}