import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useWindowSize } from '../../Helpers';
import Arrow from "./img/arrow.svg"
import _ from 'lodash'
import { showErrorPopup, translateError } from '../../components/ErrorHandling';
import "./style.scss"
import { useUserState } from '../../UserContext';
import Limited from "./img/limited.svg"

export const welcomePrizeAwards = ["neo_1+1_nastart", "6_w_cenie_4+2_na_start"]

export const awardAmountMap = new Map([
  [4, {
    copy: "duża",
    copyColor: "#168B5A",
    scale: 3,
    barWidth: "87.5%"
  }],
  [3, {
    copy: "ŚREDNIA",
    copyColor: "#22BA7A",
    scale: 2,
    barWidth: "62.5%"
  }],
  [2, {
    copy: "MAŁA",
    copyColor: "#F5AA04",
    scale: 1,
    barWidth: "37.5%"
  }],
  [1, {
    copy: "BARDZO MAŁA",
    copyColor: "#E86215",
    scale: 0,
    barWidth: "12.5%"
  }],
  [0, {
    copy: "BRAK",
    copyColor: "#FF0000",
    scale: 0,
    barWidth: "0%"
  }],
])

export function calculateProgresPrecentage(pointsEarned, basePrice) {
  if (pointsEarned >= basePrice) {
    return 1
  }

  return (pointsEarned / basePrice)
}

export default function AllAwards() {
  const [awards, setAwards] = useState([])
  const { accountDetails, userDetails } = useUserState()
  const [awardsVisible, setAwardsVisible] = useState(false)

  const getAwards = () => {
    const params = {
      "limit": 100,
      "offset": 0
    }

    fetch(process.env.REACT_APP_API_URL + "/alterzone/prize/catalogue/list", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + JSON.parse(sessionStorage.getItem('token'))?.uuid,
        'X-Mode-Local': sessionStorage.getItem('devParams') ? true : false,
      },
      body: JSON.stringify(params)
    })
      .then((response) => {
        return response.json()
      })
      .then(
        (result) => {
          if (result.status.success) {
            const drops = _.filter(result.data.catalogue, (item) => {
              return item.drop && item.inStock && !item.expired
            })

            const notAvailable = _.filter(result.data.catalogue, (item) => {
              return !item.inStock || item.expired
            })

            const available = _.filter(result.data.catalogue, (aw) => {
              return !((!aw.inStock || aw.expired) && aw.perUserLimit === 1) && !aw.drop && aw.inStock && !(userDetails.welcomePrizeReceived && welcomePrizeAwards.includes(aw.ident))
            })

            let awards = [...drops, ...available, ...notAvailable]

            setAwards(awards.filter(e => e.content !== null))
            setTimeout(() => { setAwardsVisible(true) }, 300)
          } else {
            showErrorPopup(translateError(result.data.error), result.meta.ts)
          }
        },
        (error) => {
          showErrorPopup(translateError("generic"))
          console.log(error)
        }
      )
  }

  useEffect(() => {
    if (JSON.parse(sessionStorage.getItem('token'))?.uuid) {
      getAwards()
    }
    // setTimeout(() => { setAwardsVisible(true) }, 2500)
    //eslint-disable-next-line
  }, [JSON.parse(sessionStorage.getItem('token'))?.uuid])

  const [menuTab, setMenuTab] = useState(1)
  const [selector, setSelector] = useState(0)

  //for all (selector === 0)
  //for hot selector === 1
  //for glo selector === 2

  function filterAwards(award) {
    switch (selector) {
      case 0:
        return award
      case 1:
        return award.hot === true
      case 2:
        return award.glo === true
      default:
        return award
    }
  }

  const awardList = awards.filter(filterAwards).filter(e => e.ident !== "neo_1+1_limitowane_sroda")
  const WednesdayDrop = awards.filter(e => e.ident === "neo_1+1_limitowane_sroda")
  const { width } = useWindowSize();
  const SCREEN_XGA = 1020;

  return (
    <section className={awardsVisible ? "awards-page all-awards visible" : "awards-page all-awards"}>
      {width < SCREEN_XGA ?
        <div className="top-menu">
          {/* <Link className={menuTab === 0 ? "selected" : ""} onClick={() => setMenuTab(0)} to="/nagrody">
            Nagrody wyróżnione
          </Link> */}
          <p className={menuTab === 1 ? "selected" : ""} onClick={() => setMenuTab(1)}>Katalog</p>
          {/* <Link className={menuTab === 2 ? "selected" : ""} onClick={() => {setMenuTab(2); window.sessionStorage.setItem("selectedCity", JSON.stringify(""))}} to="/nagrody/letnie-brzmienia">
            {"Letnie Brzmienia"}
          </Link> */}
          <Link className={menuTab === 2 ? "selected" : ""} onClick={() => setMenuTab(2)} to="/nagrody/za-polecenie">
            Nagrody za polecenie
            { accountDetails?.referral?.available > 0 && <span className='available-badge'>{accountDetails?.referral?.available}</span> }
          </Link>
          <Link className={menuTab === 3 ? "selected" : ""} onClick={() => setMenuTab(3)} to="/nagrody/odebrane">Odebrane</Link>
        </div>
        :
        <div className="top-menu">
          <h2 className="header">Nagrody</h2>
          {/* <Link className={menuTab === 0 ? "selected" : ""} onClick={() => setMenuTab(0)} to="/nagrody">
            Nagrody wyróżnione
          </Link> */}
          <p className={menuTab === 1 ? "selected" : ""} onClick={() => setMenuTab(1)}>Katalog Nagród</p>
          {/* <Link className={menuTab === 2 ? "selected" : ""} onClick={() => {setMenuTab(2); window.sessionStorage.setItem("selectedCity", JSON.stringify(""))}} to="/nagrody/letnie-brzmienia">
            {"Letnie Brzmienia"}
          </Link> */}
          <Link className={menuTab === 2 ? "selected" : ""} onClick={() => setMenuTab(2)} to="/nagrody/za-polecenie">
            Nagrody za polecenie
            { accountDetails?.referral?.available > 0 && <span className='available-badge'>{accountDetails?.referral?.available}</span> }
          </Link>
          <Link className={menuTab === 3 ? "selected" : ""} onClick={() => setMenuTab(3)} to="/nagrody/odebrane">Odebrane nagrody</Link>
        </div>
      }
      <div className="reward-selector">
        <p className={selector === 0 ? "selected" : ""} onClick={() => setSelector(0)} title='Wszystkie'>Wszystkie</p>
        {/* <p className={selector === 1 ? "selected" : ""} onClick={() => setSelector(1)} title="Hot">Hot</p>
        <p className={selector === 2 ? "selected" : ""} onClick={() => setSelector(2)} title='glo™'>glo™</p> */}
      </div>
      <div className={`wrapper ${selector !== 0 ? "border-modifier" : ""}`}>
        {[...WednesdayDrop, ...awardList].sort((a, b) => Number(a.cycleLimitExceeded) - Number(b.cycleLimitExceeded)).map((element, index) => {
          const amount = element.expired || element.cycleLimitExceeded || !element.inStock ? awardAmountMap.get(0) : awardAmountMap.get(element.availability ?? 0)

          
          return (
            <Link to={`/nagrody/odbior/${element.drop ? "drop/" + element.ident : element.ident}`} key={index + element.ident} className={!element.inStock || element.cycleLimitExceeded || element.expired ? "disabled" : ""}>
              <div className={`card ${element.drop ? "drop" : ""}`}>
                <img src={element.thumbnail?.file?.url} alt="" className='image' />
                {element.drop && <div className='limited'>nagroda limitowana <img src={Limited} alt="" /></div>}
                {element.ident === "vusego_1+1zl" && <div className='limited-yellow'>Nowość</div>}
                <p className="title">{element.content?.title}</p>
                <div className="availability">
                  <p>Dostępność: <span style={{ color: amount.copyColor }}>{amount.copy}</span></p>
                  <div className="scale">
                    <div className="bar" style={{ width: amount.barWidth }} />
                    {Array(4).fill(null).map((_, index) => (
                      <div className={`element ${index < amount.scale ? "active" : ""}`} key={index}>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="progress">
                  <div className="cart">
                    <svg className="svg-progress">
                      <circle className="track" />
                      <circle className="indicator" style={{
                        strokeDasharray: `${Math.PI * 2 * 16}px`,
                        strokeDashoffset: `${Math.PI * 2 * 16 * (1 - calculateProgresPrecentage(accountDetails.value, element.basePrice))}px`
                      }} />
                    </svg>
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M5 14.5C5.55228 14.5 6 14.0523 6 13.5C6 12.9477 5.55228 12.5 5 12.5C4.44772 12.5 4 12.9477 4 13.5C4 14.0523 4.44772 14.5 5 14.5Z" fill="#E5451F" />
                      <path d="M11.5 14.5C12.0523 14.5 12.5 14.0523 12.5 13.5C12.5 12.9477 12.0523 12.5 11.5 12.5C10.9477 12.5 10.5 12.9477 10.5 13.5C10.5 14.0523 10.9477 14.5 11.5 14.5Z" fill="#E5451F" />
                      <path d="M2.64375 4.5H13.8562L12.2063 10.275C12.1474 10.4846 12.0213 10.669 11.8474 10.8C11.6735 10.931 11.4614 11.0013 11.2437 11H5.25625C5.03855 11.0013 4.82647 10.931 4.65258 10.8C4.47869 10.669 4.35262 10.4846 4.29375 10.275L2.03125 2.3625C2.00135 2.25797 1.9382 2.16604 1.85136 2.10062C1.76452 2.03521 1.65872 1.99989 1.55 2H0.5" stroke="#E5451F" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                  </div>
                  <div className="price">
                    <p>Koszt: {element.basePrice} pkt</p>
                    <div>
                      {
                        accountDetails.value < element.basePrice ?
                          `Brakuje: ${element.basePrice - accountDetails.value} pkt`
                          :
                          <div className='flex-wrapper'>
                            Możesz odebrać!
                            <svg width="10" height="9" viewBox="0 0 10 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path fillRule="evenodd" clipRule="evenodd" d="M9.51526 0.955017C9.81625 1.23959 9.82956 1.71427 9.54499 2.01526L3.8 8.09169L0.455017 4.55372C0.170447 4.25273 0.183756 3.77805 0.484743 3.49348C0.78573 3.20891 1.26042 3.22222 1.54499 3.5232L3.8 5.90832L8.45502 0.984743C8.73959 0.683756 9.21427 0.670447 9.51526 0.955017Z" fill="#ED8002" />
                            </svg>
                          </div>
                      }
                    </div>
                  </div>
                  <div className="arrow">
                    <img src={Arrow} alt="" />
                  </div>
                </div>
                {/* <div className="points-progres">
                <div className={`points ${element.basePrice === 0 ? "pulse-animation" : ""}`}>
                  {`za ${element.basePrice} pkt`}
                </div>
                <div className={`progres ${accountDetails.value >= element.basePrice ? 'possible' : ""}`}>
                  {accountDetails.value >= element.basePrice ?
                    <>
                      <p>możesz odebrać</p>
                      <img src={Tick} alt="" />
                    </>
                    :
                    <>
                      <p>{`brakuje Ci ${element.basePrice - accountDetails.value} pkt`}</p>
                      <img src={Hourglass} alt="" />
                      <div className="progres-bar" style={{ width: calculateProgresBar(accountDetails.value, element.basePrice) || 0 }}></div>
                    </>
                  }
                </div>
              </div> */}
                {/* <img src={Arrow} alt="" className='arrow-desktop' /> */}
              </div>
            </Link>
          )
        })}
      </div>
    </section>
  )
}
