import Part0 from "./img/part0.png"
import Part1 from "./img/part1.png"
import Part2 from "./img/part2.png"
import Part3 from "./img/part3.png"
import Part4 from "./img/part4.png"
import Part5 from "./img/part5.png"
import Part6 from "./img/part6.png"
import Part7 from "./img/part7.png"
import Part8 from "./img/part8.png"
import img from "./img/img.png"

export const questions = {
    "gameType": "JigsawNew",
    "glo": [
        {
            "ident": "q0",
            "content": "Do jakich obszarów nawiązują 3 nowe wzory glo™ Hyper X2?",
            img: img,
            type: "pickOne",
            "correctAnswers": "d",
            "answers": [
                {
                    "ident": "a",
                    "content": "Technologia, Muzyka, Jedzenie"
                },
                {
                    "ident": "b",
                    "content": "Muzyka, Design, Podróże"
                },
                {
                    "ident": "c",
                    "content": "Podróże, Technologia, Jedzenie"
                },
                {
                    "ident": "d",
                    "content": "Muzyka, Design, Moda"
                },
            ]
        },
        {
            "ident": "q1",
            "content": "Ułóż zdjęcie i poznaj limitowaną edycję glo™ Hyper X2.",
            "sub": "Zaznacz dwa sąsiadujące fragmenty, aby je przesunąć. Kafelki mogą poruszać się tylko w pionie i poziomie",
            type: "jigsaw",
            "correctAnswers": [
                "9ea4e85b-d715-496e-a950-70b7a58fba78",
                "d9e95fb6-b6b0-4de2-be10-2a18b9491a4d",
                "c7e5e6e7-3da3-4494-96fc-b5af6066aa00",
                "da18aacb-dfaf-4f85-9266-9d70133585de",
                "559bdc20-6a3f-4ab5-afb7-244565226937",
                "e0f3b5f1-678e-4c8e-beb5-432c29ddcf64",
                "c70a76c2-58a3-45b5-83e1-36aed2d57b3f",
                "745c4d8f-fd17-4586-a7a9-a1615c26abc3",
                "d40f2a0a-d2d9-4ddb-b596-20b68d90c3a9"
            ],
            "answers": [
                {
                    "ident": "d9e95fb6-b6b0-4de2-be10-2a18b9491a4d",
                    "content": Part1
                },
                {
                    "ident": "9ea4e85b-d715-496e-a950-70b7a58fba78",
                    "content": Part0
                },
                {
                    "ident": "c7e5e6e7-3da3-4494-96fc-b5af6066aa00",
                    "content": Part2
                },
                {
                    "ident": "e0f3b5f1-678e-4c8e-beb5-432c29ddcf64",
                    "content": Part5
                },
                {
                    "ident": "745c4d8f-fd17-4586-a7a9-a1615c26abc3",
                    "content": Part7
                },
                {
                    "ident": "da18aacb-dfaf-4f85-9266-9d70133585de",
                    "content": Part3
                },
                {
                    "ident": "c70a76c2-58a3-45b5-83e1-36aed2d57b3f",
                    "content": Part6
                },
                {
                    "ident": "559bdc20-6a3f-4ab5-afb7-244565226937",
                    "content": Part4
                },
                {
                    "ident": "d40f2a0a-d2d9-4ddb-b596-20b68d90c3a9",
                    "content": Part8
                },
            ]
        },
    ]
}