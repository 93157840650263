import React, { useState, useEffect } from 'react'
import Modal from "../Modal/Modal"
import Button from "../Button/Button"
import "./style.scss"
import { useUserState } from '../../UserContext'
import { showErrorPopup, translateError } from '../ErrorHandling'
import { useNavigate } from 'react-router'

const targetDate = new Date(2023, 0, 4, 23, 59, 59)

const getRemainingTime = () => {
  const currentDate = new Date()
  let deltaInMs = targetDate.getTime() - currentDate.getTime()

  const seconds = Math.floor((deltaInMs / 1000) % 60);
  const minutes = Math.floor((deltaInMs / 1000 / 60) % 60);
  const hours = Math.floor((deltaInMs / (1000 * 60 * 60)));

  return { hours, minutes, seconds }
}

export default function CloudsFestNotify() {

  const [time, setTime] = useState({})
  // const [rewardReceived, setRewardReceived] = useState([])
  // const { userDetails } = useUserState()
  const [notify, setNotify] = useSessionStorage("meetX2", true);
  const navigate = useNavigate()

  function useSessionStorage(key, initialValue) {
    const [storedValue, setStoredValue] = useState(() => {
      if (window)
        try {
          const item = window.localStorage.getItem(key);
          return item ? JSON.parse(item) : initialValue;
        } catch (error) {
          console.log(error);
          return initialValue;
        }
    });
    if (typeof window === "undefined") {
      return initialValue;
    }
    const setValue = (value) => {
      try {
        const valueToStore =
          value instanceof Function ? value(storedValue) : value;
        setStoredValue(valueToStore);
        window.localStorage.setItem(key, JSON.stringify(valueToStore));
      } catch (error) {
        console.log(error);
      }
    };
    return [storedValue, setValue];
  }

  // const usersToNotify = [
  //   "0013z00002pHzrwAAC",
  //   "0013z00002fD6JWAA0",
  //   "0013z00002pIKHVAA4",
  //   "0013z00002hWpmkAAC",
  //   "0013z00002iLrqgAAC",
  //   "0013z00002q2LZ2AAM",
  //   "0013z00002m970zAAA",
  //   "0013z00002nmtxyAAA",
  //   "0013z00002pKdrYAAS",
  //   "0013z00002kw6b6AAA",
  //   "0013z00002hVf1xAAC",
  //   "0013z00002qO8yCAAS",
  //   "0013z00002ftu23AAA",
  //   "0013z00002qNcWaAAK",
  //   "0013z00002elSeQAAU",
  //   "0013z00002mXae8AAC",
  //   "0013z00002mXthpAAC",
  //   "0013z00002oH6a2AAC",
  //   "0013z00002hWKxuAAG",
  //   "0013z00002ekSKQAA2",
  //   "0013z00002oI1cvAAC",
  //   "0013z00002nTzz1AAC",
  //   "0013z00002qO5O9AAK",
  //   "0013z00002ZcpsXAAR",
  //   "0013z00002hPlAqAAK",
  //   "0013z00002fvdxCAAQ",
  //   "0013z00002q2bK7AAI",
  //   "0013z00002oHisGAAS",
  //   "0013z00002lIaJrAAK",
  //   "0013z00002gmglCAAQ",
  //   "0013z00002pjJf5AAE",
  //   "0013z00002ocuy2AAA",
  //   "0013z00002hVWdJAAW",
  //   "0013z00002qNgKkAAK",
  //   "0013z00002ekNjjAAE",
  //   "0013z00002pHexFAAS",
  //   "0013z00002hVl9nAAC",
  //   "0013z00002qNIBbAAO",
  //   "0013z00002ekKOoAAM",
  //   "0013z00002elc11AAA",
  //   "0013z00002q1u1SAAQ",
  //   "0013z00002pKDzHAAW",
  //   "0013z00002nptfjAAA",
  //   "0013z00002Vf0E0AAJ",
  //   "0013z00002lm6QEAAY",
  //   "0013z00002ZcyDfAAJ",
  //   "0013z00002llvpYAAQ",
  //   "0013z00002lKiZPAA0",
  //   "0013z00002n27IzAAI",
  //   "0011q000015ZORbAAO",
  //   "0013z00002pHzrwAAC"
  // ]

  // const getAwards = () => {
  //   const params = {}

  //   fetch(process.env.REACT_APP_API_URL + "/alterzone/prize/list", {
  //     method: "POST",
  //     headers: {
  //       "Content-Type": "application/json",
  //       Authorization: "Bearer " + JSON.parse(sessionStorage.getItem('token')).uuid,
  //       'X-Mode-Local': sessionStorage.getItem('devParams') ? true : false,
  //     },
  //     body: JSON.stringify(params)
  //   })
  //     .then((response) => {
  //       return response.json()
  //     })
  //     .then(
  //       (result) => {
  //         if (result.status.success) {
  //           setRewardReceived(result.data.prize.filter(e => e?.catalogue?.ident === "lot_cf_cho").length > 0 ? true : false)
  //         } else {
  //           showErrorPopup(translateError(result.data.error), result.meta.ts)
  //         }
  //       },
  //       (error) => {
  //         showErrorPopup(translateError("generic"))
  //         console.log(error)
  //       }
  //     )
  // }

  // useEffect(() => {
  //   setTimeout(() => { if ((!rewardReceived || rewardReceived.length === 0) && userDetails.consentAlterzoneParticipate) getAwards() }, 300)
  // }, [userDetails])

  useEffect(() => {
    const interval = setInterval(() => {
      setTime(getRemainingTime())
    }, 1000);
    return () => clearInterval(interval);
  }, [])

  return (
    <>
      {/* {usersToNotify.includes(userDetails.sfId) && time.seconds + time.minutes + time.seconds > 0 && */}
      {time.seconds + time.minutes + time.seconds > 0 &&
        // !rewardReceived &&
        <Modal isOpen={notify} buttonClose={false} modalClass={"wrapper-meetx2"}>
          {/* <div className="clouds-fest-notify">
            <div className="count-down">
              <button onClick={() => setNotify(false)}>
                <div className="exit" />
              </button>
              <p>{time.hours}<sup>godz.</sup>{time.minutes}<sup>min.</sup>{time.seconds}<sup>sek.</sup></p>
            </div>
            <div className="text">
              <h2>Nagroda zaraz odleci!</h2>
              <p>Pamiętaj o swoim locie balonem przed Clouds Fest w Chorzowie.
                Wybierz termin, dokonaj rezerwacji i bujaj w obłokach z bliską Ci osobą.</p>
              <p>Rezerwacja dostępna jest na Twoim mailu. Jeśli jej nie widzisz, sprawdź SPAM.</p>
            </div>
            <div className="button-wrapper">
              <Button action={() => setNotify(false)}>Dzięki</Button>
            </div>
          </div> */}
          <div className="meetx2-modal">
            <button onClick={() => setNotify(false)}>
              <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M9.95147 11.6485C10.4201 12.1172 11.1799 12.1172 11.6485 11.6485C12.1172 11.1799 12.1172 10.4201 11.6485 9.95147L7.69706 5.99999L11.6485 2.04853C12.1171 1.5799 12.1171 0.820101 11.6485 0.351472C11.1799 -0.117156 10.4201 -0.117156 9.95147 0.351472L6 4.30294L2.04853 0.351472C1.57991 -0.117157 0.820108 -0.117157 0.35148 0.351472C-0.11715 0.8201 -0.11715 1.5799 0.35148 2.04853L4.30295 5.99999L0.351472 9.95147C-0.117157 10.4201 -0.117157 11.1799 0.351472 11.6485C0.8201 12.1172 1.5799 12.1172 2.04853 11.6485L6 7.69705L9.95147 11.6485Z" fill="white" />
              </svg>
            </button>
            <h2 className="text">
              Poznaj nowe glo™ hyper X2!
            </h2>
            <Button href="/co-nowego/poznaj-nowe-glo-hyper-x2-new-heating-technology" action={() => setNotify(false)}>Sprawdź</Button>
          </div>
        </Modal>}
    </>
  )
}
