import img from "./img/img.png"


export const questions = {
    "gameType": "TrueFalsePickOneLayout",
    "glo": [
        {
            "ident": "q0",
            "content": "Paczki neo™ kupisz obecnie na 7 lotniskach w Polsce.",
            fontSizeMobile: "15px",
            fontSizeDesktop: "15px",
            img: img,
            "type": "text",
            "answers": [
                {
                    "ident": "a",
                    "content": "Prawda"
                },
                {
                    "ident": "b",
                    "content": "Fałsz"
                }
            ]
        },
        {
            "ident": "q1",
            "content": "Lotnisko Chopina w Warszawie oraz Port Lotniczy Warszawa-Radom to jedyne lotniska w województwie mazowieckim, na którym masz możliwość zakupu paczek neo™.",
            fontSizeMobile: "15px",
            fontSizeDesktop: "15px",
            img: img,
            "type": "text",
            "answers": [
                {
                    "ident": "a",
                    "content": "Prawda"
                },
                {
                    "ident": "b",
                    "content": "Fałsz"
                }
            ]
        },
        {
            "ident": "q2",
            "content": `Port lotniczy „Rzeszów-Jasionka" oraz port lotniczy Bydgoszcz posiadają w sprzedaży paczki neo™ dostępne na polskim rynku..`,
            fontSizeMobile: "15px",
            fontSizeDesktop: "15px",
            img: img,
            "type": "text",
            "answers": [
                {
                    "ident": "a",
                    "content": "Prawda"
                },
                {
                    "ident": "b",
                    "content": "Fałsz"
                }
            ]
        },
    ]
}