import tile1Bad from "./img/1.1.jpg"
import tile2Bad from "./img/2.1.jpg"
import tile3Bad from "./img/3.1.jpg"
import tile4Bad from "./img/4.1.jpg"
import tile5Bad from "./img/5.1.jpg"
import tile1 from "./img/1.jpg"
import tile2 from "./img/2.jpg"
import tile3 from "./img/3.jpg"
import tile4 from "./img/4.jpg"
import tile5 from "./img/5.jpg"
import Blue from "./img/blue.png"
import Gold from "./img/gold.png"
import Khaki from "./img/khaki.png"
import Oragne from "./img/oragne.png"
import Pine from "./img/pine.png"
import Red from "./img/red.png"

export const questions = {
    "gameType": "meetX2",
    "glo": [
        {
            "img": [
                {
                    part: tile1,
                    partBad: tile1Bad,
                    id: "first"
                },
                {
                    part: tile2,
                    partBad: tile2Bad,
                    id: "second"
                },
                {
                    part: tile3,
                    partBad: tile3Bad,
                    id: "third"
                },
                {
                    part: tile4,
                    partBad: tile4Bad,
                    id: "forth"
                },
                {
                    part: tile5,
                    partBad: tile5Bad,
                    id: "fifth"
                }
            ],
            "answers": [
                {
                    ident: "b",
                    copy: "Przycisk BOOST",

                },
                {
                    ident: "a",
                    copy: "Przycisk STANDARD",
                },
                {
                    ident: "c",
                    copy: "Port ładowania USB-C",

                },
                {
                    ident: "d",
                    copy: "Wyświetlacz LED",
                },
                {
                    ident: "e",
                    copy: "Komora grzewcza z nową funkcją zamykania",
                },
            ],
        },
        {
            answers: [
                {
                    img: Blue,
                    name: "Blue",
                },
                {
                    img: Gold,
                    name: "Gold",
                },
                {
                    img: Khaki,
                    name: "Khaki",
                },
                {
                    img: Oragne,
                    name: "Oragne",
                },
                {
                    img: Pine,
                    name: "Pine",
                },
                {
                    img: Red,
                    name: "Red",
                },
            ]
        }
    ]
}