export const data = [
  [
      {
          id: "1",
          name: "Szymon N.",
          img: require("./img/1.jpg")
      },
      {
          id: "2",
          name: "Tomasz Ś.",
          img: require("./img/2.jpg")
      },
      {
          id: "3",
          name: "Katarzyna O.",
          img: require("./img/3.jpg")
      },
  ],
  [
      {
          id: "4",
          name: "Michał D.",
          img: require("./img/4.jpg")
      },
      {
          id: "5",
          name: "Marcin G.",
          img: require("./img/5.jpg")
      },
      {
          id: "6",
          name: "Oliwia P.",
          img: require("./img/6.jpg")
      },
  ],
  [
      {
          id: "7",
          name: "Krystian S",
          img: require("./img/7.jpg")
      },
      {
          id: "8",
          name: "Dominika C.",
          img: require("./img/8.jpg")
      }, 
      {
          id: "9",
          name: "Michal O.",
          img: require("./img/9.jpg")
      },
  ]

]