import outdoor1 from "./img/outdoor1.png"
import outdoor2 from "./img/outdoor2.png"
import outdoor3 from "./img/outdoor3.png"
import portret1 from "./img/portret1.png"
import portret2 from "./img/portret2.png"
import portret3 from "./img/portret3.png"
import lifestyle1 from "./img/lifestyle1.png"
import lifestyle2 from "./img/lifestyle2.png"
import lifestyle3 from "./img/lifestyle3.png"

export const data = {
    outdoor: [
        [
            {
                name: "Maciek R.",
                content: outdoor1,
            },
            {
                name: "Kamila Sz.",
                content: outdoor2,
            },
            {
                name: "Justyna P.",
                content: outdoor3,
            },
        ],
        [
            {
                name: "Małgorzata K.",
            },
            {
                name: "Sandra B.",
            },
            {
                name: "Monika D.",
            },
            {
                name: "Robert Ż.",
            },
            {
                name: "Radosław Łukasz S.",
            },
        ],
    ],
    portret: [
        [
            {
                name: "Wojciech W.",
                content: portret1,
            },
            {
                name: "Yuliia M.",
                content: portret2,
            },
            {
                name: "Lila K.",
                content: portret3,
            },
        ],
        [
            {
                name: "Mateusz D.",
            },
            {
                name: "Klaudia K.",
            },
            {
                name: "Łukasz S.",
            },
            {
                name: "Katarzyna M.",
            },
            {
                name: "Katarzyna Z.",
            },
        ],
    ],
    lifestyle: [
        [
            {
                name: "Joanna S.",
                content: lifestyle1,
            },
            {
                name: "Weronika P.",
                content: lifestyle2,
            },
            {
                name: "Krzysztof O.",
                content: lifestyle3,
            },
        ],
        [
            {
                name: "Robert T.",
            },
            {
                name: "Bartosz R.",
            },
            {
                name: "Dawid W.",
            },
            {
                name: "Michał F.",
            },
            {
                name: "Robert Ż.",
            },
        ],
    ],
}