import Template from "./img/template.svg"
import Source0 from "./img/source0.png"
import Source1 from "./img/source1.png"
import Source2 from "./img/source2.png"
import Source3 from "./img/source3.png"
import Desktop0 from "./img/desktop0.jpg"
import Desktop1 from "./img/desktop1.jpg"
import Desktop2 from "./img/desktop2.jpg"
import Desktop3 from "./img/desktop3.jpg"
// import Glo1Mobile from ""
// import Glo1Desktop from ""

export const questions = {
    "gameType": "PickColor",
    "glo": [
        {
            "ident": "q0",
            "content": "Znajdź i odwzoruj kolor urządzenia",
            "template": Template,
            "img": Source0,
            "desktopImg": Desktop0,
            "panel": false,
            "name": "glo™ hyper+ Uniq Blue",
            "treshold": 23,
            "answers": [
                {
                    "ident": "r",
                    "content": "52"
                },
                {
                    "ident": "g",
                    "content": "81"
                },
                {
                    "ident": "b",
                    "content": "138"
                }
            ]
        },
        {
            "ident": "q1",
            "content": "Znajdź i odwzoruj kolor urządzenia",
            "template": Template,
            "img": Source1,
            "desktopImg": Desktop1,
            "panel": false,
            "name": "glo™ hyper+ Uniq Gold",
            "treshold": 18,
            "answers": [
                {
                    "ident": "r",
                    "content": "182"
                },
                {
                    "ident": "g",
                    "content": "167"
                },
                {
                    "ident": "b",
                    "content": "150"
                }
            ]
        },
        {
            "ident": "q2",
            "content": "Znajdź i odwzoruj kolor panelu bocznego",
            "template": Template,
            "img": Source2,
            "desktopImg": Desktop2,
            "panel": true,
            "name": "Panel MATT Olive Green",
            "treshold": 18,
            "answers": [
                {
                    "ident": "r",
                    "content": "129"
                },
                {
                    "ident": "g",
                    "content": "136"
                },
                {
                    "ident": "b",
                    "content": "122"
                }
            ]
        },
        {
            "ident": "q3",
            "content": "Znajdź i odwzoruj kolor panelu bocznego",
            "template": Template,
            "img": Source3,
            "desktopImg": Desktop3,
            "panel": true,
            "name": "Panel CORE Volt Yellow",
            "treshold": 23,
            "answers": [
                {
                    "ident": "r",
                    "content": "254"
                },
                {
                    "ident": "g",
                    "content": "237"
                },
                {
                    "ident": "b",
                    "content": "138"
                }
            ]
        },
    ]
}