export const data = [
  [
      {
          id: "1",
          name: "Lucyna W.",
          img: require("./img/1.jpg")
      },
      {
          id: "2",
          name: "Agnieszka K.",
          img: require("./img/2.jpg")
      },
      {
          id: "3",
          name: "Jacek K.",
          img: require("./img/3.jpg")
      },
  ],
  [
        {
            id: "4",
            name: "Agnieszka P.",
            img: require("./img/4.jpg")
        },
        {
            id: "5",
            name: "Kamila W.",
            img: require("./img/5.jpg")
        }, 
        {
            id: "6",
            name: "Michał T.",
            img: require("./img/6.jpg")
        },
      {
          id: "7",
          name: "Damian Ch.",
          img: require("./img/7.jpg")
      },
      {
          id: "8",
          name: "Wojciech B.",
          img: require("./img/8.jpg")
      }, 
      {
          id: "9",
          name: "Andzia W.",
          img: require("./img/9.jpg")
      },
      {
        id: "10",
        name: "Jan W.",
        img: require("./img/10.jpg")
    },
  ]

]