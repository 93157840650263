export const questions = {
    "gameType": "PickOne",
    "glo": [
        {
            "ident": "q0",
            "content": "W upalne dni szukasz <span style=\"color:#50B4AF;\">mentolowego chłodu.<span />",
            "type": "text",
            "answers": [
                {
                    "ident": "a",
                    "content": "Violet Click"
                },
                {
                    "ident": "b",
                    "content": "Sunset Click"
                },
                {
                    "ident": "c",
                    "content": "Scarlet Click"
                },
                {
                    "ident": "d",
                    "content": "Ice Click"
                }
            ]
        },
        {
            "ident": "q1",
            "content": "Plaża i żółty parasol nad Twoją głową, a Ty masz ochotę<span style=\"color:#F4B219;\"> na słodkie doświadczenie z nutą ananasa.<span />",
            "type": "text",
            "answers": [
                {
                    "ident": "a",
                    "content": "Gold Tobacco"
                },
                {
                    "ident": "b",
                    "content": "Sunset Click"
                },
                {
                    "ident": "c",
                    "content": "Dark Tobacco"
                },
                {
                    "ident": "d",
                    "content": "Arctic Blue Click"
                }
            ]
        },
        {
            "ident": "q2",
            "content": "<span style=\"color:#F4B219;\">Zaczynasz dzień od nuty słodkiego melona z cytrusami,<span /><span style=\"color:#000000;\"> a kończysz nutą tropikalnych owoców.<span />",
            "type": "text",
            "answers": [
                {
                    "ident": "a",
                    "content": "Terracota Tobacco"
                },
                {
                    "ident": "b",
                    "content": "Ice Click"
                },
                {
                    "ident": "c",
                    "content": "Summer Yellow Click"
                },
                {
                    "ident": "d",
                    "content": "Scarlet Click"
                }
            ]
        }
    ]
}