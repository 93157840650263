export const questions = {
  "gameType": "TrueFalsePickOneLayout",
  "glo": [
    {
      "ident": "q0",
      "content": "Ile jest dostępnych pakietów subskrypcji neo™?",
      "type": "text",
      fontSizeMobile: "32px",
      fontSizeDesktop: "24px",
      "answers": [
        {
          "ident": "a",
          "content": "3"
        },
        {
          "ident": "b",
          "content": "5"
        }
      ]
    },
    {
      "ident": "q1",
      "content": "Który pakiet oferuje najkorzystniejszą cenę za 1 paczkę – 8,49 zł?",
      "type": "text",
      fontSizeMobile: "32px",
      fontSizeDesktop: "24px",
      "answers": [
        {
          "ident": "a",
          "content": "GOLD"
        },
        {
          "ident": "b",
          "content": "DIAMOND i DIAMOND+"
        }
      ]
    },
    {
      "ident": "q2",
      "content": "Ile punktów otrzymasz za pierwszy zakupiony dowolny pakiet neo™?",
      "type": "text",
      fontSizeMobile: "32px",
      fontSizeDesktop: "24px",
      "answers": [
        {
          "ident": "a",
          "content": "50"
        },
        {
          "ident": "b",
          "content": "100"
        }
      ]
    },
    {
      "ident": "q3",
      "content": "Jaka jest cena dostawy zamówionych pakietów neo™ do paczkomatu?",
      "type": "text",
      fontSizeMobile: "32px",
      fontSizeDesktop: "24px",
      "answers": [
        {
          "ident": "a",
          "content": "0 zł"
        },
        {
          "ident": "b",
          "content": "10 zł"
        }
      ]
    },
  ]
}