export const data = {
    sunrise: [
        {
            name: "Patryk S.",
            content: "\"Hejka :) Moim marzeniem jest pojechać na Sunrise Festival, na który zabrałabym moją przyjaciółkę Kasię. Kocham muzykę elektroniczną i chciałabym doświadczyć festiwalowej atmosfery :) Byłabym przeszczęsliwa i mogłabym tym samym zrobic prezent Kasi!\"."
        },
        {
            name: "Piotr Ł.",
            content: `Na sunrise jeszcze nie byłem
Lecz teraz się wybrać postanowiłem
Zabiorę kolegę bo od piaskownicy się znamy
I zawsze wszędzie razem latamy 
Zapisujemy się szybko i czekać będziemy 
Może akurat nagrodę zgarniemy 
Pozdrawiamy serdecznie ❤️`
        },
        {
            name: "Magdalena B.",
            content: "\"Jadę z mężem. W sierpniu mamy 30 rocznicę ślubu. Forever young !!!\"."
        },
        {
            name: "Angelika S.",
            content: `Na festiwal z moją przyjaciółką bym pojechała, 
Bo uważam że z nas dobra imprezowa para! 
Marzymy wspólnie o takich chwili, 
Byśmy świetnie się bawili! 
Wrócić do czasów młodości chociaż na chwilę chcemy, 
By pochować na chwilę nasze problemy!`
        },
        {
            name: "Kamila K.",
            content: "\"Hej! Chciałabym wybrać się na Sunrise Festiwal z moją przyjaciółką, ponieważ 22 lipca wypada pięciolecie naszej przyjaźni, chciałabym to uczcić w fajny sposób, dlatego postanowiłam napisać. Ciao!\"."
        },
        {
            name: "Anita P.",
            content: "\"już jako nastolatka marzyłam o Sunrise Festival. Chciałabym poczuć ten klimat, zatracić się w tańcu do rana i dobrze się bawić. Zabrałabym ze sobą przyjaciółkę, która w sierpniu bierze ślub. Byłby to jej prezent w ramach wieczoru panieńskiego <3\"."
        },
        {
            name: "Aneta P.",
            content: "\"Wyjazd na Sunrise Festival to od zawsze marzenie mojego męża Michała. Jego wujek, z którym mial bardzo bliskie relacje, a niestety nie ma go już z nami był na każdym Festivalu. chciałabym by Michał mógł spełnić swoje marzenia i być tam gdzie wujek\"."
        },
        {
            name: "Anna S.",
            content: "\"Byłam co roku na tych pierwszych festiwalach- dawno dawno temu ? Teraz mam pelnoletnią już córkę i chciałabym żeby ona też zobaczyła jak można się bawić a ja przeżyłabym to jeszcze raz i ożywiła miłe wspomnienia\"."
        },
        {
            name: "Malgorzata M.",
            content: `Na Sunrise Festival zgarnęłabym psiapsię, 
uwielbiam z nią takie atrakcje! 
Będziemy bawić się do białego rana, 
w głowie ciągle nucić na na na na!`
        },
        {
            name: "Elżbieta S.",
            content: "\"Chciałabym pojechać z ukochanym Tatą, byłby to najlepszy prezent na dzień Ojca jaki kiedykolwiek by dostał ?. Zawsze o tym marzyliśmy ale brakuje nam funduszy\"."
        }
    ],
    sun: [
        {
            name: "Lila K.",
            content: "\"Na Sun ruszyłabym z moją kompanką wszelkich przygód - złapałybyśmy autostopa i tańczyły na festiwalu do białego rana! Byłoby to kolejne wyzwanie do spełnienia w tym roku, a mamy ich do podjęcia 12 - tyle ile jest miesięcy\"."
        },
        {
            name: "Jakub L.",
            content: `Wybieram Sun Festiwal w Kołobrzegu, 
Bo rap obecny jest w mym krwiobiegu.
Zabrałbym ze sobą moją narzeczoną,
Bo w świecie mojej ulubionej muzy jest już jedną nogą.
Chciałbym pokazać jej topke z rap rodziny,
I przed ślubem wyprodukować endorfiny.`
        },
        {
            name: "Jakub B. ",
            content: "\"Jeśli wyjazd na Sun Festival to tylko z moim Słoneczkiem, tak nazywam moją Narzeczoną, bo jest Moim Promyczkiem! W blasku słońca na Sun Festivalu, śpiewałabym z Nią, że aż usłyszeliby nas w sąsiednim Rewalu!\"."
        },
        {
            name: "Maciej P.",
            content: "\"Hej! Bardzo chciałbym pojechać na Sun Festiwal z moim przyjacielem Jędrkiem, który będzie moim wsparciem, ponieważ na festiwalu będzie dziewczyna w której się kocham i chce podbić jej serce właśnie tam!\"."
        },
        {
            name: "Maciej J.",
            content: `Jak festival to tylko Sun lub rise.
Na festiwalu wschodzącego słońca zaręczyłem się z moją uwczesną dziewczyną, za tydzień bierzemy ślub. Po wschodzie słońca jest słońce w zenicie.
Był Sunrise z narzeczoną Kingą, czas na Sun Festival z żoną Kingą.`
        },
        {
            name: "Lila K.",
            content: "\"Chciałabym z mężem włączyć Tryb Samolotowy przy Jan-Rapowanie, zrobić Duże Oczy na Smolastym, tańczyć Noc i Dzień z Pezetem, żeby mieć Jetlag po Maliku Montanie!\"."
        },
        {
            name: "Dominika H.",
            content: "\"Na Sun Festival chcę zabrać mojego partnera, bo z nim moje życie jest jak złota płyta dla rapera. Kochamy swoje towarzystwo, muzykę i zabawę, więc chcę go zabrać na tą grubą wrzawę. Proszę, niech te marzenie się spełni, bo nikt tego tak nie doceni!\"."
        },
        {
            name: "Anna Sz.",
            content: "\"Marzę o wyjeździe z najlepszą moją towarzyszką, czyli że sobą samą. W ciągu całego roku troszczę się o dzieci, męża,mamę, swpich uczniów i często ich rodziców, pocieszam koleżanki, doradzam kolegom, dokarmiam koty i jeże. TERAZ MOJA KOLEJ!\"."
        },
        {
            name: "Natalia Sz.",
            content: "\"Marzy mi się wyjazd na SunFestival odkąd on powstał. ❤️❤️❤️Pojechałabym tam z moim narzeczonym, byłby to dla niego prezent z okazji 25 urodzin. Bardzo chciałabym spędzić z nim te chwile na festiwalu❤️❤️\"."
        },
        {
            name: "Artur Ż.",
            content: "\"Marzę o wyjeździe na festiwal z moim szalonym sąsiadem Bobem. Jego taneczne umiejętności są tak niezwykłe, że pewnie zyskalibyśmy status gwiazd festiwalu! Bob jest niezrównany w swoich wyjątkowych ruchach tanecznych\"."
        },
    ],
}