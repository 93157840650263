export const questions = {
    "gameType": "TrueFalse",
    "glo": [
        {
            "ident": "q0",
            "content": "Urządzenie glo™ hyper+ UNIQ różni się od glo™ hyper+ możliwością personalizacji wyglądu dzięki wymiennym panelom bocznym.",
            "type": "text",
            "answers": [
                {
                    "ident": "a",
                    "content": "Prawda"
                },
                {
                    "ident": "b",
                    "content": "Fałsz"
                }
            ]
        },
        {
            "ident": "q1",
            "content": "Do urządzenia glo hyper+ UNIQ pasują obecnie tylko 3 warianty tytoniowe wkładów neo™.",
            "type": "text",
            "answers": [
                {
                    "ident": "a",
                    "content": "Prawda"
                },
                {
                    "ident": "b",
                    "content": "Fałsz"
                }
            ]
        },
        {
            "ident": "q2",
            "content": "Dodatkowe wymienne panele boczne do glo™ hyper+ UNIQ dostępne są w 15 różnych kolorach.",
            "type": "text",
            "answers": [
                {
                    "ident": "a",
                    "content": "Prawda"
                },
                {
                    "ident": "b",
                    "content": "Fałsz"
                }
            ]
        }
    ]
}