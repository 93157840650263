export const questions = {
    "gameType": "WordSearch",
    "glo": [
        {
            "ident": "q0",
            "content": "Znak rozpoznawczy Karli Gruszeckiej:",
            "board": ["m","z","n","j","q","m","b","v","s","o","q","q","a","g","v","y","s","b","f","p","x","p","m","s","f","y","w","k","l","c","f","q","a","t","u","v","b","n","t","o","q","m","n","z","r","e","a","s","d","v","g","t","p","x","v","i","j","h","g","f","a","e","r","q","t","y","b","n","z","z","g","u","d","p","o","w","m","d","n","x","b","u","r","z","a","l","o","k","ó","w","x","q","q","c","a","g","y","u","d","a"]
        },
        {
            "ident": "q1",
            "content": "Jedna rzecz z wybiegu, którą Karla będzie nosić w nowym sezonie to:",
            "board": ["g","c","o","l","r","g","a","h","b","n","g","c","i","k","t","f","y","u","k","s","t","v","u","j","y","d","k","a","o","z","y","b","y","h","u","s","l","q","t","t","u","n","t","g","i","a","d","p","u","u","i","m","t","f","o","z","f","h","r","i","o","a","r","d","p","x","v","n","n","e","p","q","q","s","q","w","n","r","y","w","s","w","c","a","w","c","m","g","z","b","d","e","x","z","e","v","y","e","r","g"]

        },
        {
            "ident": "q2",
            "content": "Wymarzony kierunek wakacyjny Karli Gruszeckiej to:",
            "board": ["m","n","j","a","p","o","n","i","a","f","f","g","c","v","m","n","z","a","a","d","t","y","u","i","p","o","l","k","j","h","z","n","v","a","w","q","d","h","g","f","i","o","p","r","t","v","b","n","m","x","x","v","n","m","l","j","u","p","f","q","c","v","b","n","m","a","s","q","r","t","n","o","p","t","w","q","y","t","u","u","f","h","a","g","j","k","t","y","a","z","j","k","w","e","p","i","r","y","g","g"]
        }
    ]
}