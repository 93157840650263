export const questions = {
  gameType: "Optin",
  glo: [
    {
      ident: "q0",
      content:
        "Wyraź nowe zgody",
      type: "consent",
      answers: [
        {
          ident: "a",
          content: "Zgadzam się",
        },
        {
          ident: "b",
          content: "Nie zgadzam się",
        },
      ],
    },
    {
      ident: "q1",
      content:
        "Zgoda numer 2",
      type: "radio",
      answers: [
        {
          ident: "a",
          content: "Zgadzam się",
        },
        {
          ident: "b",
          content: "Nie zgadzam się",
        },
      ],
    },
    {
      ident: "q2",
      content:
        "Zgoda numer 3",
      type: "radio",
      answers: [
        {
          ident: "a",
          content: "Zgadzam się",
        },
        {
          ident: "b",
          content: "Nie zgadzam się",
        },
      ],
    },
  ],
};
