let articles = sessionStorage.getItem("articles") ? JSON.parse(sessionStorage.getItem("articles")) : [];
 
export const initialState = {
  articles: articles || [],
  loading: false,
  errorMessage: null
};
 
export const ArticleReducer = (initialState, action) => {
  switch (action.type) {
    case "GET_ARTICLES":
      return {
        ...initialState,
        loading: true,
        errorMessage: null
      };
    case "GET_ARTICLES_SUCCESS":
      return {
        ...initialState,
        articles: action.payload,
        loading: false,
        errorMessage: null
      };
  
    case "GET_ARTICLES_ERROR":
      return {
        ...initialState,
        loading: false,
        errorMessage: action.error
      };
 
    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
};