import React, { useEffect, useState, useLayoutEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
  useNavigate
} from "react-router-dom";
import { ProtectedComponent, ProtectedRoute } from "./components/ProtectedComponent";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import Header from "./components/Header";
import Footer from "./components/Footer";
import HealthWarning from "./components/HealthWarning";
import LogoutWarning from "./components/LogoutWarning";
import Maintenance from "./components/Maintenance";
import NotFound from "./components/NotFound";
import HomePage from "./pages/HomePage"
import SubscriptionsPage from "./pages/SubscriptionsPage"
import LoginPage from "./pages/LoginPage"
import ActivitiesPage from "./pages/ActivitiesPage"
// import LoadingScreen from "./pages/LoadingScreen"
import InvitePage from "./pages/InvitePage"
import AllAwards from "./pages/AwardsPage/AllAwards";
import ReceivedAwards from "./pages/AwardsPage/ReceivedAwards";
import CollectAward from "./pages/AwardsPage/CollectAward";
import AboutPage from "./pages/AboutPage";
import FAQ from "./pages/AboutPage/FAQ";
import ContactPage from "./pages/AboutPage/ContactPage";
import ComplaintsPage from "./pages/AboutPage/ComplaintsPage";
import ComplaintsStatusPage from "./pages/AboutPage/ComplaintsStatusPage";
import Menu from "./components/Menu";
import StatutePage from "./pages/AboutPage/StatutePage";
import LogoutPage from "./pages/LogoutPage";
import ProfilePage from "./pages/ProfilePage";
import StoreLocator from "./pages/StoreLocator";
import ChangeData from "./pages/ProfilePage/ChangeData";
import MyRewards from "./pages/ProfilePage/MyRewards";
import IntroTemplate from "./pages/ActivitiesPage/Activities/IntroTemplateNew";
import ArticlePage from "./pages/NewsPage/Article";
import NewsPage from "./pages/NewsPage";
import GameProvider from "./pages/ActivitiesPage/Activities/GameProvider";
import ContestProvider from "./pages/ActivitiesPage/Activities/ContestProvider";
import JoinPage from "./pages/JoinPage";
import JoinVusePage from "./pages/JoinVusePage";
import { getUrlParam, initUrlParams, removeUrlParam } from "./UrlParamsHelper";
import { getUserDetails, loginByToken, useUserDispatch, useUserState } from "./UserContext";
import GloAr from "./pages/ActivitiesPage/GloAr";
import DiscoverGlo from "./pages/DiscoverGlo";
import CookiesModal from "./components/CookiesModal";
import ErrorHandling from "./components/ErrorHandling"
import RegisterPage from "./pages/RegisterPage";
import ResetPage from "./pages/ResetPage";
import ResetPageCustom from "./pages/ResetPageCampaign";
import AgeGate from "./components/AgeGate";
import JoinPage2 from "./pages/JoinPage2";
import CouponPage from "./pages/CouponPage"
import YourCoupon from "./pages/CouponPage/YourCoupon";
import PWAPrompt from 'react-ios-pwa-prompt'
import FloatingButton from "./components/FloatingButton"
import CampaignPage from "./pages/Campaigns";
import SubscriptionInfo from "./pages/ActivitiesPage/Activities/SubscriptionInfo"
import Check from "../img/check.svg"
import EditIcon from "../img/edit-icon.svg"

import DwaSlawy from "./pages/AwardsPage/CustomAwards/DwaSlawy";
import PrevContest from "./pages/ActivitiesPage/PrevContest";
import WinnerPage from "./pages/ActivitiesPage/WinnerPage";
import CloudFest from "./pages/ActivitiesPage/Activities/CloudFest";
import RelaksZKawa from "./pages/ActivitiesPage/Activities/RelaksZKawa";
import FlightReservation from "./pages/ActivitiesPage/Activities/FlightReservation";
import CloudsFestReward from "./pages/AwardsPage/CustomAwards/CloudsFest";
import ZapodajRymem from "./pages/ActivitiesPage/Activities/ZapodajRymem";
import CloudsFestWinners from "./pages/ActivitiesPage/ContestWinners/CloudsFestWinners";
import ZapodajRymemWinners from "./pages/ActivitiesPage/ZapodajRymemWinners/ZapodajRymemWinners";
import TacoWawa from "./pages/AwardsPage/CustomAwards/TacoWawa";
import TacoPoz from "./pages/AwardsPage/CustomAwards/TacoPoz";
import JimekWaw from "./pages/AwardsPage/CustomAwards/JimekWaw";
import CollectDrop from "./pages/AwardsPage/CollectDrop";
import AtrakcyjnyWeekendWinners from "./pages/ActivitiesPage/ContestWinners/AtrakcyjnyWeekendWinners";
import SlowLife from "./pages/ActivitiesPage/Activities/SlowLife";
import SlowLifeWinners from "./pages/ActivitiesPage/ContestWinners/SlowLifeWinners";
import SpringWinners from "./pages/ActivitiesPage/ContestWinners/SpringWinners";
import DzienGloWinners from "./pages/ActivitiesPage/ContestWinners/DzienGloWinners";
import SummerWinners from "./pages/ActivitiesPage/ContestWinners/SummerWinners";
import Slow10Winners from "./pages/ActivitiesPage/ContestWinners/10slowWinners";
import TenWord from "./pages/ActivitiesPage/Activities/TenWord";
import GloBirthday from "./pages/ActivitiesPage/Activities/GloBirthday";
import EasterEggCounter from "./components/EasterEgg/EasterEggCounter";

import LBMain from "./pages/AwardsPage/LetnieBrzmienia/LBMain"

import NewFlavour from "./components/CloudsFestNotify";
import MeetX2Modal from "./components/MeetX2Modal";
import NovelModal from "./components/NovelModal";
import ReferralAwardModal from "./components/ReferralAwardModal";
import CloudsFestNotify from "./components/CloudsFestNotify";
import Modal from "./components/Modal/Modal";
import Button from "./components/Button/Button";
import DoublePointsBar from "./components/DoublePointsBar";
import Hedonisci from "./pages/ActivitiesPage/Activities/Hedonisci";
import PushNotificationModal from "./components/PushNotification";
import CancelNotificationModal from "./components/PushNotification/CancelNotification";
import CloudsFestChoCustom from "./pages/AwardsPage/CloudsFestCustom";
import RelaksZKawaWinners from "./pages/ActivitiesPage/ContestWinners/RelaksZKawaWinners";
import HedonisciWinners from "./pages/ActivitiesPage/ContestWinners/HedonisciWinners";
import CloudsFestCustom from "./pages/AwardsPage/CloudsFestCustom";
import CrazyWednesdayBar from "./components/CrazyWednesdayBar";
import GloSecondBirtday from "./pages/ActivitiesPage/ContestWinners/GloSecondBirthday";
import X3Points from "./components/X3Points";
import ValentinesContest from "./pages/ActivitiesPage/Activities/ValentinesContest";
import ValentineWinners from "./pages/ActivitiesPage/ContestWinners/ValentineWinners";
import MyPoints from "./pages/ProfilePage/MyPoints";
import SpringContent from "./pages/ActivitiesPage/Activities/SpringContent";
import EasterModal from "./components/EasterEgg/EasterModal";
import InviteBonusBar from "./components/InviteBonusBar";
import BarSwitcher from "./components/BarSwitcher";
import MigrationBar from "./components/MigrationBar";
import IntroMonthlyBonus from "./pages/ActivitiesPage/Activities/IntroMonthlyBonus";
import BlacklistPage from "./pages/BlacklistPage";
import ReferralAwards from "./pages/AwardsPage/ReferralAwards";
import CollectReferralAward from "./pages/AwardsPage/CollectReferralAward";
import SummerContent from "./pages/ActivitiesPage/Activities/SummerContent";
import FestivalContent from "./pages/ActivitiesPage/Activities/FestivalContent";
import SubscriptionForm from "./pages/SubscriptionsPage/SubscriptionForm";
import LimitedContent from "./pages/ActivitiesPage/Activities/LimitedContent";
import LimitedEditionWinners from "./pages/ActivitiesPage/ContestWinners/LimitedEditionWinners";
import IcPreregister from "./components/ICPreregister";
import MigrationPage from "./pages/MigrationPage";
import { useSessionStorage } from "./Helpers";
import CoverPage from "./pages/CoverPage";
// import SomethingSpecialModal from "./components/SomethingSpecialModal";

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

function ScrollToTop({ children }) {
  const { pathname } = useLocation();

  useLayoutEffect(() => {
    document.querySelector(".app").scrollTo(0, 0);
  }, [pathname]);

  return children;
}

function Transition() {
  let location = useLocation();
  const dispatch = useUserDispatch()
  const navigate = useNavigate()
  const { userDetails } = useUserState()
  // eslint-disable-next-line
  const params = useQuery()
  const [preloader, setPreloader] = useState(true)
  const [showMenu, setShowMenu] = useState(false)
  const [cookieModal, setCookieModal] = useState(false)
  const [recaptcha, setRecaptcha] = useState(false)
  const [iOSPrompt, setiOSPrompt] = useState(false)
  const [confirmTokenErrorPopup, setConfirmTokenErrorPopup] = useState(false)
  const [confirmEmailPopup, setConfirmEmailPopup] = useState(false)
  const [confirmPhonePopup, setConfirmPhonePopup] = useState(false)
  const [age, setAge] = useState(false)
  const [barsVisible, setBarsVisible] = useState(true)
  const [showCancelNotification, setShowCancelNotification] = useState(false)
  const [notificationState, setNotificationState] = useLocalStorage("pushNotification", {})
  const [IcPreregisterModal, setIcPreregisterModal] = useSessionStorage("preregisterModal", { step: 0, firstHit: true })
  const newX2 = localStorage.getItem("novelColors") && sessionStorage.getItem("novelSession") !== JSON.parse(sessionStorage.getItem("token"))?.uuid ? false : true

  const showNewColors = newX2 && location.pathname === "/" ? true : false

  useEffect(() => {
    // Automatyczne przekierowanie do CoverPage
    navigate('/');
  }, [navigate]);

  const excludedPages = () => {
    // pages without header, footer, and HW
    const exclude = [
      // "/503",
      // "/404",
      // "/login",
      // "/rejestracja",
      // "/haslo",
      // "/haslo/ustaw",
      // "/wylogowano",
      // "/dolacz",
      // "/dolacz-vuse",
      // "/dolacz/",
      // "/dolacz-vuse/",
      // "/aktywacja",
      // "/aktywacja/",
      // "/twoj-kupon/odbior",
      // "/campaign"
      // "/profil/zmien-dane"
    ]

    if (exclude.includes(location.pathname)) {
      return false
    } else {
      return true
    }
  }

  useEffect(() => {
    if (getUrlParam("token") && !Boolean(userDetails.token?.uuid) && !location.pathname.includes("/haslo") && !location.pathname.includes("/twoj-kupon/odbior")) {
      loginByToken(dispatch, getUrlParam("token"))
    }

    initUrlParams()

    window.addEventListener("SW_READY", (e) => { setPreloader(false); document.querySelector(".loading-screen").classList.add("hide") })
    setTimeout(() => {
      window.dispatchEvent(new CustomEvent("SW_READY", {}), true)
      window.loaderC.hide();
    }, 3500)

    //eslint-disable-next-line
  }, [])

  // useEffect(() => {
  //   if (userDetails.firstName) {
  //     if (!userDetails.consentAlterzoneParticipate && !userDetails.consentYourChoice) {
  //       navigate("/dolacz", { replace: true })
  //     } else if (!userDetails.consentAlterzoneParticipate && userDetails.consentMgmParticipate && (userDetails.preferredDevice === "vypeepen3" || userDetails.preferredDevice === "vypeepod")) {
  //       navigate("/dolacz-vuse", { replace: true })
  //     } else if (!userDetails.consentAlterzoneParticipate || userDetails.isPhoneVerificationRequired) {
  //       navigate("/dolacz", { replace: true })
  //     }
  //   }
  //   //eslint-disable-next-line
  // }, [userDetails])

  useEffect(() => {
    const hideMenu = () => {
      setShowMenu(false)
    }

    const closeOnclick = (e) => {
      const menu = document.querySelector("div.main-menu")
      const userData = document.querySelector(".user-data .icon")
      if (!menu.contains(e.target) && !userData.contains(e.target)) {
        e.preventDefault()
        hideMenu()
      }
    }

    if (showMenu) {
      document.querySelector(".app").scrollTo({ top: 0, left: 0, behavior: "smooth" });
      setTimeout(() => { document.querySelector(".app").addEventListener("scroll", hideMenu) }, 300)
      document.querySelector(".app").addEventListener("click", closeOnclick)
    } else {
      document.querySelector(".app").removeEventListener("scroll", hideMenu)
      document.querySelector(".app").removeEventListener("click", closeOnclick)
    }
    return () => {
      document?.querySelector(".app")?.removeEventListener("scroll", hideMenu)
      document?.querySelector(".app")?.removeEventListener("click", closeOnclick)
    }
    //eslint-disable-next-line
  }, [showMenu])

  useEffect(() => {
    const KEY = "6LfhNjcdAAAAACHgw66lt40xZum7Uv9clMgPnAzL"

    const loadRecaptcha = () => {
      const script = document.createElement("script")
      script.src = `https://www.google.com/recaptcha/api.js?render=${KEY}`
      script.id = 'recaptcha-script';
      script.async = true;
      script.addEventListener("load", handleLoaded)
      document.body.appendChild(script)
    }

    const handleLoaded = _ => {
      window.grecaptcha.ready(_ => {
        window.grecaptcha
          .execute(KEY, { action: "homepage" })
          .then(token => {
            setRecaptcha(true)
          })
      })

    }

    const removeRecaptcha = () => {
      const script = document.getElementById('recaptcha-script');
      if (script) {
        script.remove();
      }

      const recaptchaElems = Array.from(document.getElementsByClassName('grecaptcha-badge'));
      if (recaptchaElems.length) {
        recaptchaElems.forEach(e => e.remove())
      }
    }

    if (location.pathname === "/onas/kontakt") {
      loadRecaptcha()
    }

    if (location.pathname !== "/onas/kontakt") {
      removeRecaptcha()
    }

  }, [location.pathname])

  async function confirmPhone() {
    const response = await fetch(process.env.REACT_APP_API_URL + "/consumer/mobile-confirm", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getUrlParam("confirmToken")
      },
      body: null
    })
    const status = await response.json()
    if (status.status.success) {
      setConfirmPhonePopup(true)
      getUserDetails(dispatch)
      removeUrlParam("confirm")
      removeUrlParam("confirmToken")
    } else {
      setConfirmTokenErrorPopup(true)
      removeUrlParam("confirm")
      removeUrlParam("confirmToken")
    }
  }

  async function confirmEmail() {
    const response = await fetch(process.env.REACT_APP_API_URL + "/consumer/email-confirm", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getUrlParam("confirmToken")
      },
      body: null
    })
    const status = await response.json()
    if (status.status.success) {
      setConfirmEmailPopup(true)
      removeUrlParam("confirm")
      removeUrlParam("confirmToken")
    } else {
      setConfirmTokenErrorPopup(true)
      removeUrlParam("confirm")
      removeUrlParam("confirmToken")
    }
  }

  useEffect(() => {
    if (getUrlParam("confirm")) {
      if (getUrlParam("confirm") === "phone") {
        confirmPhone()
      } else if (getUrlParam("confirm") === "email") {
        confirmEmail()
      }
    }
  }, [])

  // useEffect(() => {
  //   if (newX2 && userDetails.firstName) {
  //     localStorage.setItem("novelColors", true)
  //     sessionStorage.setItem("novelSession", JSON.parse(sessionStorage.getItem("token")).uuid)
  //   }

  //   if (!userDetails?.preRegistration && IcPreregisterModal.firstHit && typeof (userDetails?.preRegistration) === "boolean") {
  //     setIcPreregisterModal(prev => ({
  //       ...prev,
  //       open: !userDetails?.preRegistration,
  //       firstHit: false
  //     }))
  //   }
  // }, [userDetails])

  function useLocalStorage(key, initialValue) {
    const [storedValue, setStoredValue] = useState(() => {
      if (window)
        try {
          const item = window.localStorage.getItem(key);
          return item ? JSON.parse(item) : initialValue;
        } catch (error) {
          console.log(error);
          return initialValue;
        }
    });
    if (typeof window === "undefined") {
      return initialValue;
    }
    const setValue = (value) => {
      try {
        const valueToStore =
          value instanceof Function ? value(storedValue) : value;
        setStoredValue(valueToStore);
        window.localStorage.setItem(key, JSON.stringify(valueToStore));
      } catch (error) {
        console.log(error);
      }
    };
    return [storedValue, setValue];
  }

  useEffect(() => {
    const getPermissionData = async () => {
      try {
        let navigatorData = await navigator.permissions.query({ name: 'notifications' })
        if (navigatorData.state === 'granted' || navigatorData.state === 'denied') {
          setNotificationState({ permission: navigatorData.state, showModal: false })
        } else {
          if (Object.keys(notificationState).length === 0) {
            setNotificationState({ permission: "prompt", showModal: false })
          }
        }
        navigatorData.onchange = () => {
          setNotificationState({ permission: navigatorData.state, showModal: false })
        }
      } catch (err) {
        console.log(`Navigator notification error ${err}`)
        setNotificationState({ permission: "prompt", showModal: false, unsupported: true })
      }
    }
    if (userDetails.firstName) (
      setTimeout(getPermissionData, 500)
    )
  }, [userDetails])

  return (
    <div className="app">
      <LogoutWarning />
      {excludedPages() ? <ProtectedComponent><HealthWarning /></ProtectedComponent> : null}
      {excludedPages() ? <ProtectedComponent><MigrationBar /></ProtectedComponent> : null}
      <div className="page-holder">
        <TransitionGroup>
          <CSSTransition
            key={location.key}
            classNames="page"
            className="page-with-bg"
            timeout={{ enter: 250, exit: 250 }}
          >
            <div>
              <AgeGate setAge={setAge} />
              {/* {excludedPages() ? <ProtectedComponent><BarSwitcher setBarsVisible={setBarsVisible} barsVisible={barsVisible}></BarSwitcher></ProtectedComponent> : null} */}
              {/* {excludedPages() ? <ProtectedComponent><Header newX2={showNewColors} showMenu={showMenu} setShowMenu={setShowMenu} headerClass={barsVisible ? "double-bar-open" : ""} /></ProtectedComponent> : null}
              <ProtectedComponent><Menu closeMenu={() => setShowMenu(false)} isOpen={showMenu} setiOSPrompt={(pr) => setiOSPrompt(pr)} setShowCancelNotification={setShowCancelNotification}
                notificationState={notificationState} setNotificationState={setNotificationState} />
              </ProtectedComponent> */}
              <Routes location={location}>
                <Route path={`${process.env.PUBLIC_URL}/`} exact element={<CoverPage />} />
                {/* <Route path={`${process.env.PUBLIC_URL}/r/:referral`} exact element={<RegisterPage />} /> */}
                {/* <Route path={`${process.env.PUBLIC_URL}/`} exact element={<ProtectedRoute><HomePage newX2={showNewColors} /></ProtectedRoute>} /> */}
                {/* <Route path={`${process.env.PUBLIC_URL}/`} exact element={<ProtectedRoute><MigrationPage /></ProtectedRoute>} /> */}
                {/* <Route path={`${process.env.PUBLIC_URL}/subskrybcje`} exact element={<ProtectedRoute><SubscriptionsPage /></ProtectedRoute>} /> */}
                {/* <Route path={`${process.env.PUBLIC_URL}/subskrybcje/formularz`} exact element={<ProtectedRoute><SubscriptionForm /></ProtectedRoute>} /> */}
                {/* <Route path={`${process.env.PUBLIC_URL}/login`} exact element={<LoginPage showCookieModal={() => setCookieModal(true)} />} />
                <Route path={`${process.env.PUBLIC_URL}/login/reset`} exact element={<LoginPage showCookieModal={() => setCookieModal(true)} />} /> */}
                {/* <Route path={`${process.env.PUBLIC_URL}/campaign/:template/`} exact element={<CampaignPage/>}/>  */}
                {/* <Route path={`${process.env.PUBLIC_URL}/campaign/:template/:ident`} exact element={<CampaignPage />} /> */}
                {/* <Route path={`${process.env.PUBLIC_URL}/rejestracja`} exact element={<RegisterPage />} /> */}
                {/* <Route path={`${process.env.PUBLIC_URL}/haslo/reset`} exact element={<ResetPage />} />
                <Route path={`${process.env.PUBLIC_URL}/haslo/ustaw`} exact element={<ResetPage />} />
                <Route path={`${process.env.PUBLIC_URL}/custom/haslo/ustaw/:ident`} exact element={<ResetPageCustom />} />
                <Route path={`${process.env.PUBLIC_URL}/custom/haslo/reset/:ident`} exact element={<ResetPageCustom />} />
                <Route path={`${process.env.PUBLIC_URL}/aktywacja`} exact element={<ProtectedRoute><JoinPage2 /></ProtectedRoute>} />
                <Route path={`${process.env.PUBLIC_URL}/dolacz`} exact element={<ProtectedRoute><JoinPage /></ProtectedRoute>} />
                <Route path={`${process.env.PUBLIC_URL}/dolacz-vuse`} exact element={<ProtectedRoute><JoinVusePage /></ProtectedRoute>} />
                <Route path={`${process.env.PUBLIC_URL}/wylogowano`} exact element={<LogoutPage />} /> */}
                {/* <Route path={`${process.env.PUBLIC_URL}/aktywnosci`} exact element={<ProtectedRoute><ActivitiesPage /></ProtectedRoute>} />
                <Route path={`${process.env.PUBLIC_URL}/aktywnosci/zone-plus`} exact element={<ProtectedRoute><GloAr /></ProtectedRoute>} />
                <Route path={`${process.env.PUBLIC_URL}/aktywnosci/sub_neo_2022`} exact element={<ProtectedRoute><SubscriptionInfo /></ProtectedRoute>} />
                <Route path={`${process.env.PUBLIC_URL}/aktywnosci/:ident`} exact element={<ProtectedRoute><IntroTemplate /></ProtectedRoute>} />
                <Route path={`${process.env.PUBLIC_URL}/aktywnosci/:ident/gra`} exact element={<ProtectedRoute><GameProvider /></ProtectedRoute>} />
                <Route path={`${process.env.PUBLIC_URL}/aktywnosci/:ident/konkurs`} exact element={<ProtectedRoute><GloBirthday /></ProtectedRoute>} />
                <Route path={`${process.env.PUBLIC_URL}/aktywnosci/clouds-fest/konkurs`} exact element={<ProtectedRoute><CloudFest /></ProtectedRoute>} />
                <Route path={`${process.env.PUBLIC_URL}/aktywnosci/konkurs6_sierpien/konkurs`} exact element={<ProtectedRoute><Hedonisci /></ProtectedRoute>} />
                <Route path={`${process.env.PUBLIC_URL}/aktywnosci/konkurs7_wrzesien/konkurs`} exact element={<ProtectedRoute><SlowLife /></ProtectedRoute>} />
                <Route path={`${process.env.PUBLIC_URL}/aktywnosci/konkurs8_pazdziernik/konkurs`} exact element={<ProtectedRoute><TenWord /></ProtectedRoute>} /> */}
                {/* <Route path={`${process.env.PUBLIC_URL}/aktywnosci/relaks-z-kawa/konkurs`} exact element={<ProtectedRoute><RelaksZKawa/></ProtectedRoute>}/> */}
                {/* <Route path={`${process.env.PUBLIC_URL}/aktywnosci/slow-life/konkurs`} exact element={<ProtectedRoute><SlowLife /></ProtectedRoute>} />
                <Route path={`${process.env.PUBLIC_URL}/aktywnosci/zapodaj-rymem/konkurs`} exact element={<ProtectedRoute><ZapodajRymem /></ProtectedRoute>} />
                <Route path={`${process.env.PUBLIC_URL}/aktywnosci/poprzednie-konkursy`} exact element={<ProtectedRoute><PrevContest /></ProtectedRoute>} />
                <Route path={`${process.env.PUBLIC_URL}/aktywnosci/wyniki-konkursu/konkurs1_luty`} exact element={<ProtectedRoute><WinnerPage /></ProtectedRoute>} />
                <Route path={`${process.env.PUBLIC_URL}/aktywnosci/wyniki-konkursu/lec-wysoko`} exact element={<ProtectedRoute><CloudsFestWinners /></ProtectedRoute>} />
                <Route path={`${process.env.PUBLIC_URL}/aktywnosci/wyniki-konkursu/zapodaj-rymem`} exact element={<ProtectedRoute><ZapodajRymemWinners /></ProtectedRoute>} />
                <Route path={`${process.env.PUBLIC_URL}/aktywnosci/wyniki-konkursu/konkurs2_kwiecien`} exact element={<ProtectedRoute><AtrakcyjnyWeekendWinners /></ProtectedRoute>} />
                <Route path={`${process.env.PUBLIC_URL}/aktywnosci/wyniki-konkursu/konkurs4_lipiec`} exact element={<ProtectedRoute><RelaksZKawaWinners /></ProtectedRoute>} />
                <Route path={`${process.env.PUBLIC_URL}/aktywnosci/wyniki-konkursu/konkurs5_lipiec_2023_design`} exact element={<ProtectedRoute><LimitedEditionWinners /></ProtectedRoute>} />
                <Route path={`${process.env.PUBLIC_URL}/aktywnosci/wyniki-konkursu/konkurs6_sierpien`} exact element={<ProtectedRoute><HedonisciWinners /></ProtectedRoute>} />
                <Route path={`${process.env.PUBLIC_URL}/aktywnosci/wyniki-konkursu/konkurs7_wrzesien`} exact element={<ProtectedRoute><SlowLifeWinners /></ProtectedRoute>} />
                <Route path={`${process.env.PUBLIC_URL}/aktywnosci/wyniki-konkursu/konkurs8_pazdziernik`} exact element={<ProtectedRoute><Slow10Winners /></ProtectedRoute>} />
                <Route path={`${process.env.PUBLIC_URL}/aktywnosci/wyniki-konkursu/konkurs9_grudzien`} exact element={<ProtectedRoute><GloSecondBirtday /></ProtectedRoute>} />
                <Route path={`${process.env.PUBLIC_URL}/aktywnosci/wyniki-konkursu/konkurs1_luty_2023`} exact element={<ProtectedRoute><ValentineWinners /></ProtectedRoute>} />
                <Route path={`${process.env.PUBLIC_URL}/aktywnosci/wyniki-konkursu/konkurs2_kwiecien_2023`} exact element={<ProtectedRoute><SpringWinners /></ProtectedRoute>} />
                <Route path={`${process.env.PUBLIC_URL}/aktywnosci/wyniki-konkursu/konkurs3_czerwiec_2023_outdoor`} exact element={<ProtectedRoute><DzienGloWinners /></ProtectedRoute>} />
                <Route path={`${process.env.PUBLIC_URL}/aktywnosci/wyniki-konkursu/konkurs4_czerwiec_2023_sun`} exact element={<ProtectedRoute><SummerWinners /></ProtectedRoute>} />
                <Route path={`${process.env.PUBLIC_URL}/aktywnosci/konkurs1_luty_2023/konkurs`} exact element={<ProtectedRoute><ValentinesContest /></ProtectedRoute>} />
                <Route path={`${process.env.PUBLIC_URL}/aktywnosci/konkurs2_kwiecien_2023/konkurs`} exact element={<ProtectedRoute><SpringContent /></ProtectedRoute>} />
                <Route path={`${process.env.PUBLIC_URL}/aktywnosci/konkurs3_czerwiec_2023_outdoor/konkurs`} exact element={<ProtectedRoute><SummerContent /></ProtectedRoute>} />
                <Route path={`${process.env.PUBLIC_URL}/aktywnosci/konkurs4_czerwiec_2023/konkurs`} exact element={<ProtectedRoute><FestivalContent /></ProtectedRoute>} />
                <Route path={`${process.env.PUBLIC_URL}/aktywnosci/konkurs5_lipiec_2023_design/konkurs`} exact element={<ProtectedRoute><LimitedContent /></ProtectedRoute>} />
                <Route path={`${process.env.PUBLIC_URL}/aktywnosci/konkurs5_lipiec_2023/konkurs`} exact element={<ProtectedRoute><LimitedContent /></ProtectedRoute>} />
                <Route path={`${process.env.PUBLIC_URL}/aktywnosci/monthly-bonus/`} exact element={<ProtectedRoute><IntroMonthlyBonus /></ProtectedRoute>} />
                <Route path={`${process.env.PUBLIC_URL}/aktywnosci/rezerwacja-lotu/`} exact element={<ProtectedRoute><FlightReservation /></ProtectedRoute>} /> */}
                {/* <Route path={`${process.env.PUBLIC_URL}/profil`} exact element={<ProtectedRoute><ProfilePage /></ProtectedRoute>} />
                <Route path={`${process.env.PUBLIC_URL}/profil/zmien-dane`} exact element={<ProtectedRoute><ChangeData /></ProtectedRoute>} />
                <Route path={`${process.env.PUBLIC_URL}/profil/moje-nagrody`} exact element={<ProtectedRoute><MyRewards /></ProtectedRoute>} />
                <Route path={`${process.env.PUBLIC_URL}/profil/moje-punkty`} exact element={<ProtectedRoute><MyPoints /></ProtectedRoute>} /> */}
                {/* <Route path={`${process.env.PUBLIC_URL}/nagrody`} exact element={<ProtectedRoute><AwardsPage/></ProtectedRoute>}/> */}
                {/* <Route path={`${process.env.PUBLIC_URL}/nagrody/katalog`} exact element={<ProtectedRoute><AllAwards /></ProtectedRoute>} /> */}
                {/* <Route path={`${process.env.PUBLIC_URL}/nagrody/odebrane`} exact element={<ProtectedRoute><ReceivedAwards /></ProtectedRoute>} /> */}
                {/* <Route path={`${process.env.PUBLIC_URL}/nagrody/za-polecenie`} exact element={<ProtectedRoute><ReferralAwards /></ProtectedRoute>} /> */}
                {/* <Route path={`${process.env.PUBLIC_URL}/nagrody/odbior/za-polecenie/:refuuid`} exact element={<ProtectedRoute><CollectReferralAward /></ProtectedRoute>} />
                <Route path={`${process.env.PUBLIC_URL}/nagrody/odbior/drop_plyta_cd_dwaslawy`} exact element={<ProtectedRoute><DwaSlawy /></ProtectedRoute>} />
                <Route path={`${process.env.PUBLIC_URL}/nagrody/odbior/cloudsfest_gnd_220618`} exact element={<ProtectedRoute><CloudsFestReward /></ProtectedRoute>} />
                <Route path={`${process.env.PUBLIC_URL}/nagrody/odbior/drop_bilet_taco_waw`} exact element={<ProtectedRoute><TacoWawa /></ProtectedRoute>} />
                <Route path={`${process.env.PUBLIC_URL}/nagrody/odbior/drop_bilet_taco_poz`} exact element={<ProtectedRoute><TacoPoz /></ProtectedRoute>} />
                <Route path={`${process.env.PUBLIC_URL}/nagrody/odbior/drop_bilet_jimek_waw`} exact element={<ProtectedRoute><JimekWaw /></ProtectedRoute>} />
                <Route path={`${process.env.PUBLIC_URL}/nagrody/odbior/:ident`} exact element={<ProtectedRoute><CollectAward /></ProtectedRoute>} />
                <Route path={`${process.env.PUBLIC_URL}/nagrody/odbior/drop/:ident`} exact element={<ProtectedRoute><CollectDrop /></ProtectedRoute>} />
                <Route path={`${process.env.PUBLIC_URL}/nagrody/odbior/campaign/:ident`} exact element={<ProtectedRoute><CloudsFestChoCustom /></ProtectedRoute>} /> */}
                {/* <Route path={`${process.env.PUBLIC_URL}/nagrody/odbior/campaign/:ident`} exact element={<ProtectedRoute><CloudsFestChoCustom/></ProtectedRoute>}/> */}
                {/* <Route path={`${process.env.PUBLIC_URL}/zaproszenie`} exact element={<ProtectedRoute><InvitePage /></ProtectedRoute>} /> */}
                {/* <Route path={`${process.env.PUBLIC_URL}/onas`} exact element={<ProtectedRoute><AboutPage /></ProtectedRoute>} />
                <Route path={`${process.env.PUBLIC_URL}/onas/faq`} exact element={<ProtectedRoute><FAQ /></ProtectedRoute>} />
                <Route path={`${process.env.PUBLIC_URL}/onas/kontakt`} exact element={<ProtectedRoute><ContactPage recaptcha={recaptcha} /></ProtectedRoute>} />
                <Route path={`${process.env.PUBLIC_URL}/onas/wymiana-urzadzenia`} exact element={<ProtectedRoute><ComplaintsPage recaptcha={recaptcha} /></ProtectedRoute>} />
                <Route path={`${process.env.PUBLIC_URL}/onas/status-reklamacji`} exact element={<ProtectedRoute><ComplaintsStatusPage /></ProtectedRoute>} />
                <Route path={`${process.env.PUBLIC_URL}/onas/regulaminy`} exact element={<ProtectedRoute><StatutePage /></ProtectedRoute>} /> */}
                {/* <Route path={`${process.env.PUBLIC_URL}/znajdz-sklep`} exact element={<ProtectedRoute><StoreLocator /></ProtectedRoute>} /> */}
                {/* <Route path={`${process.env.PUBLIC_URL}/poznaj-glo`} exact element={<ProtectedRoute><DiscoverGlo /></ProtectedRoute>} /> */}
                {/* <Route path={`${process.env.PUBLIC_URL}/co-nowego/:slug`} exact element={<ProtectedRoute><ArticlePage setIcPreregisterModal={setIcPreregisterModal} /></ProtectedRoute>} />
                <Route path={`${process.env.PUBLIC_URL}/co-nowego`} exact element={<ProtectedRoute><NewsPage /></ProtectedRoute>} /> */}
                {/* <Route path={`${process.env.PUBLIC_URL}/odbior-kuponu`} exact element={<ProtectedRoute><CouponPage /></ProtectedRoute>} /> */}
                {/* <Route path={`${process.env.PUBLIC_URL}/twoj-kupon`} exact element={<ProtectedRoute><YourCoupon /></ProtectedRoute>} />
                <Route path={`${process.env.PUBLIC_URL}/twoj-kupon/odbior`} exact element={<YourCoupon />} />
                <Route path={`${process.env.PUBLIC_URL}/dbl/latest`} exact element={<BlacklistPage />} /> */}
                {/* <Route path={`${process.env.PUBLIC_URL}/nagrody/letnie-brzmienia`} exact element={<ProtectedRoute><LBMain/></ProtectedRoute>}/> */}
                {/* <Route path={`${process.env.PUBLIC_URL}/503`} exact element={<Maintenance />} />
                <Route path={`${process.env.PUBLIC_URL}/404`} exact element={<NotFound />} />
                <Route path={`${process.env.PUBLIC_URL}/*`} exact element={<NotFound />} /> */}
              </Routes>
              <Footer showCookieModal={() => { setCookieModal(true) }} logged={Object.keys(userDetails).length !== 0} />
            </div>
          </CSSTransition>
        </TransitionGroup>
        {(age || sessionStorage.getItem("overEighteen")) && <CookiesModal showCookieModal={cookieModal} hideCookieModal={() => { setCookieModal(false) }} />}
        <ErrorHandling />
      </div>
      {/* <CloudsFestNotify/> */}
      <ProtectedComponent><PushNotificationModal notify={notificationState} setNotify={setNotificationState} /></ProtectedComponent>
      <ProtectedComponent><CancelNotificationModal showModal={showCancelNotification} setShowModal={setShowCancelNotification} /></ProtectedComponent>
      {/* <ProtectedComponent><X3Points /></ProtectedComponent> */}
      {/* <ProtectedComponent><MeetX2Modal/></ProtectedComponent> */}
      {/* <ProtectedComponent><NovelModal /></ProtectedComponent> */}
      {/* <ProtectedComponent><ReferralAwardModal /></ProtectedComponent> */}
      {/* {excludedPages() ? <ProtectedComponent><IcPreregister IcPreregisterModal={IcPreregisterModal} setIcPreregister={setIcPreregisterModal} /></ProtectedComponent> : null} */}
      {/* {excludedPages() ? <ProtectedComponent><SomethingSpecialModal /></ProtectedComponent> : null } */}
      {/* <FloatingButton newX2={showNewColors} /> */}
      {iOSPrompt && <PWAPrompt
        promptOnVisit={0}
        timesToShow={999}
        copyClosePrompt="Zamknij"
        permanentlyHideOnDismiss={false}
        onClose={() => { setTimeout(() => { setiOSPrompt(false) }, 500) }}
        copyTitle="Dodaj do ekranu początkowego"
        copyBody="Wrzuć ikonę na pulpit i odpalaj Alter Zone jednym ruchem - szybciej niż kiedykolwiek."
        copyShareButtonLabel="1) Naciśnij przycisk udostępniania widoczny na środku dolnego paska nawigacyjnego."
        copyAddHomeButtonLabel="2) Naciśnij „Do ekranu początkowego”"
      />}
      <Modal isOpen={confirmTokenErrorPopup} closeModal={() => setConfirmTokenErrorPopup(false)} modalClass="confirm-modal">
        <div className="confirm-modal-inner">
          <div className="confirm-top">
            <img src={EditIcon} alt="" />
          </div>
          <div className='confirm-content'>
            <h3>Przepraszamy, ale link przestał być aktywny.</h3>
            <p>Jeśli chcesz dokonać zmiany, prosimy spróbować ponownie.</p>
            <div className='confirm-buttons'>
              <Button action={() => { setConfirmTokenErrorPopup(false) }} btnClass="orange">Ok</Button>
            </div>
          </div>
        </div>
      </Modal>
      <Modal isOpen={confirmEmailPopup} closeModal={() => setConfirmEmailPopup(false)} modalClass="confirm-modal">
        <div className="confirm-modal-inner">
          <div className="confirm-top">
            <img src={Check} alt="" />
          </div>
          <div className='confirm-content'>
            <h3>Twój adres e-mail został pomyślnie zmieniony.</h3>
            <p>Potwierdzenie wysłaliśmy na stary, jak i nowy adres. <br />Zmiana obejmuje również sklep internetowy glo™ oraz platformę Masz Wybór.</p>
            <p><strong>Zaloguj się do Alter Zone za pomocą nowego adresu e-mail.</strong></p>
            <div className='confirm-buttons'>
              <Button action={() => { setConfirmEmailPopup(false) }} btnClass="orange">Zaloguj się</Button>
            </div>
          </div>
        </div>
      </Modal>
      <Modal isOpen={confirmPhonePopup} closeModal={() => setConfirmPhonePopup(false)} modalClass="confirm-modal">
        <div className="confirm-modal-inner">
          <div className="confirm-top">
            <img src={Check} alt="" />
          </div>
          <div className='confirm-content'>
            <h3>Twój numer telefonu został pomyślnie zmieniony. </h3>
            <p>Potwierdzenie wysłaliśmy na stary, jak i nowy numer. Zmiana obejmuje również sklep internetowy glo™ oraz oraz platformę Masz Wybór.</p>
            <div className='confirm-buttons'>
              <Button action={() => { setConfirmPhonePopup(false) }} btnClass="orange">Dzięki</Button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default function MainView() {
  return (
    <Router>
      <ScrollToTop >
        <Transition />
      </ScrollToTop>
    </Router>
  );
}
