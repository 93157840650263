import React from "react";
import { useUserState } from '../UserContext'
import LoginPage from "../pages/LoginPage";
 
export const ProtectedRoute = ({ children }) => {
    const userDetails = useUserState()

    return (
        !Boolean(userDetails.token?.uuid) ? (
            <LoginPage/>
        ) : (
            children
        )
    )
}

export const ProtectedComponent = ({ children }) => {
    const userDetails = useUserState()

    return (
        !Boolean(userDetails.token?.uuid) ? (
            null
        ) : (
            children
        )
    )
}