import jura from "./img/ic_jura.svg"
import krups from "./img/ic_krups.svg"
import delonghi from "./img/ic_delonghi.svg"

export const konkurs2_kwiecien = {
    rewards: [
        {
            img: jura,
            place: "1-3",
            content: "Ekspres JURA ENA 8",
        },
        {
            img: delonghi,
            place: "4-6",
            content: "Ekspres DELONGHI Nespresso Lattissima One EN510.B"
        },
        {
            img: krups,
            place: "7-9",
            content: "Ekspres KRUPS Dolce Gusto Mini Me KP123B",
        },
    ],
}