export const questions = {
    "gameType": "Poll",
    "glo": [
        {
            "ident": "q1",
            "content": "Z ilu urządzeń glo™ korzystasz:",
            "type": "Poll",
            "answers": [
                {
                    "ident": "0",
                    "content": "1"
                },
                {
                    "ident": "1",
                    "content": "2"
                },
                {
                    "ident": "2",
                    "content": "Więcej niż 3"
                },
                {
                    "ident": "3",
                    "content": "Nie korzystam z urządzenia glo™"
                }
            ]
        },
        {
            "ident": "q2",
            "content": "Ile czasu upłynęło między zakupem pierwszego a drugiego podgrzewacza glo™?",
            "type": "text",
            "answers": [
                {
                    "ident": "0",
                    "content": "Nie kupiłem/am drugiego urządzenia"
                },
                {
                    "ident": "1",
                    "content": "Do 30 dni"
                },
                {
                    "ident": "2",
                    "content": "1-3 miesięcy"
                },
                {
                    "ident": "3",
                    "content": "3-6 miesięcy"
                },
                {
                    "ident": "4",
                    "content": "6-12 miesięcy"
                },
                {
                    "ident": "5",
                    "content": "Ponad 12 miesięcy"
                },
            ]
        },
        {
            "ident": "q3",
            "content": "Ile wkładów neo™ do glo™ używasz średnio dziennie?",
            "type": "text",
            "answers": [
                {
                    "ident": "0",
                    "content": "Do 8"
                },
                {
                    "ident": "1",
                    "content": "8-12"
                },
                {
                    "ident": "2",
                    "content": "13-20"
                },
                {
                    "ident": "3",
                    "content": "Ponad 20"
                }
            ]
        },
        {
            "ident": "q4",
            "content": "Czy w ciągu ostatnich 14 dni korzystałeś/aś z któregoś z niżej wymienionych wyrobów tytoniowych (poza glo™)? Wybierz z listy: ",
            "type": "text",
            "multiSelect": "true",
            "clearAnswersOnIndex": "6",
            "answers": [
                {
                    "ident": "0",
                    "content": "Tradycyjne papierosy"
                },
                {
                    "ident": "1",
                    "content": "E-papieros napełniany liquidami"
                },
                {
                    "ident": "2",
                    "content": "E-papieros z wymiennymi wkladami"
                },
                {
                    "ident": "3",
                    "content": "IQOS"
                },
                {
                    "ident": "4",
                    "content": "Saszetki nikotynowe"
                },
                {
                    "ident": "5",
                    "content": "Tytoń do skręcania/nabijania"
                },
                {
                    "ident": "6",
                    "content": "Nie korzystałem/am z żadnych z powyższych"
                },
            ]
        }
    ]
}