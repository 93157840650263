import img1 from "./img/1.png"
import img2 from "./img/2.png"
import img3 from "./img/3.png"
import img4 from "./img/4.png"
import img5 from "./img/5.png"
import img6 from "./img/6.png"
import img7 from "./img/7.png"
import img8 from "./img/8.png"
import img9 from "./img/9.png"
import img10 from "./img/10.png"
import img11 from "./img/11.png"
import main from "./img/main.png"
import main2 from "./img/main2.jpg"

export const questions = {
    "gameType": "PickOne",
    "glo": [
        {
            "ident": "q0",
            "content": "Który kolor nie pasuje?",
            "type": "img",
            img: main,
            img2: main2,
            popupCopy: "glo™ hyper X2 Air to 7 wyjątkowych kolorów inspirowanych hipnotyzującymi odcieniami nieba.",
            "correctAnswer": "c",
            "answers": [
                {
                    "ident": "a",
                    content: img5
                },
                {
                    "ident": "b",
                    content: img6
                },
                {
                    "ident": "c",
                    content: img1
                },
                {
                    "ident": "d",
                    content: img7
                },
            ]
        },
        {
            "ident": "q1",
            "content": "Które urządzenie nie pasuje?",
            "type": "img",
            img: main,
            popupCopy: "glo™ Hyper X2 Air to następca glo™ Hyper X2, przeznaczony dla użytkowników ceniących dyskrecję.",
            "correctAnswer": "b",
            "answers": [
                {
                    "ident": "a",
                    content: img8
                },
                {
                    "ident": "b",
                    content: img2
                },
                {
                    "ident": "c",
                    content: img6
                },
                {
                    "ident": "d",
                    content: img9
                },
            ]
        },
        {
            "ident": "q2",
            "content": "Które urządzenie wygląda inaczej?",
            "type": "img",
            img: main,
            popupCopy: "glo™ Hyper X2 Air to najbardziej kompaktowe urządzenie z linii hyper.",
            "correctAnswer": "c",
            "answers": [
                {
                    "ident": "a",
                    content: img10
                },
                {
                    "ident": "b",
                    content: img5
                },
                {
                    "ident": "c",
                    content: img3
                },
                {
                    "ident": "d",
                    content: img7
                },
            ]
        },
        {
            "ident": "q3",
            "content": "Które urządzenie wygląda inaczej?",
            "type": "img",
            img: main,
            popupCopy: "W urządzeniu umieszczono kolorowy wskaźnik LED - sygnalizujący sesję oraz stan naładowania baterii",
            "correctAnswer": "a",
            "answers": [
                {
                    "ident": "a",
                    content: img4
                },
                {
                    "ident": "b",
                    content: img11
                },
                {
                    "ident": "c",
                    content: img8
                },
                {
                    "ident": "d",
                    content: img7
                },
            ]
        },
    ]
}