import bg from "./img/bg.jpg"
import img from "./img/img.png"
import img2 from "./img/img2.png"
import imgM from "./img/imgM.png"
import img2M from "./img/img2M.png"

export const questions = {
    "gameType": "FindPacks",
    "glo": [
        {
            "ident": "q0",
            "content": "Które z podanych urządzeń glo™ jest najlżejsze?",
            "sub": "",
            "type": "question",
            img: bg,
            correctAnswer: 2,
            "answers": [
                {
                    "ident": "a",
                    "content": "glo™ Hyper+ UNIQ"
                },
                {
                    "ident": "b",
                    "content": "glo™ Hyper X2"
                },
                {
                    "ident": "c",
                    "content": "glo™ Hyper X2 Air"
                },
            ]
        },
        {
            "ident": "q1",
            "content": "Znajdź wszystkie glo™ Hyper Air ",
            "type": "find",
            img: bg,
            imgDesktop: img,
            imgMobile: imgM,
            "answers": [
                {
                    "ident": "3a47f1be-b2dd-4a44-afd8-fc5810288faa",
                    "clipPathMobile": "circle(5.5% at 24% 6%)",
                    "clipPathDesktop": "circle(4.2% at 39% 6%)",
                    "copy": "glo™ Hyper Air to najnowszy, stylowy model podgrzewacza tytoniu dla użytkowników ceniących dyskrecję",
                },
                {
                    "ident": "89618cd4-07a6-4544-be9f-5ed5f38f6261",
                    "clipPathMobile": "circle(5.5% at 18% 32%)",
                    "clipPathDesktop": "circle(4.2% at 36% 32%)",
                    "copy": "Najbardziej kompaktowe urządzenie z linii hyper. Waży zaledwie 75 gramów , a jego grubość to 12,5 mm",
                },
                {
                    "ident": "bbce7752-bf44-407b-8c83-e588efd69919",
                    "clipPathMobile": "circle(5.5% at 73% 72%)",
                    "clipPathDesktop": "circle(4.2% at 60% 72%)",
                    "copy": "Z boku podgrzewacza znajdują się osobne przyciski STANDARD i BOOST, za pomocą których można dostosować intensywność podgrzewania.",
                },
            ]
        },
        {
            "ident": "q2",
            "content": "Znajdź wszystkie glo™ Hyper Air ",
            "type": "find",
            img: bg,
            imgDesktop: img2,
            imgMobile: img2M,
            "answers": [
                {
                    "ident": "fa6da3ba-aab4-455d-8577-ec1eec353e36",
                    "clipPathMobile": "circle(5.9% at 56% 43%)",
                    "clipPathDesktop": "circle(4.2% at 53% 43%)",
                    "copy": "Do wyboru jest aż 7 wyjątkowych kolorów inspirowanych hipnotyzującymi odcieniami nieba.",
                },
                {
                    "ident": "6adaef9a-0f1d-4eba-b758-c7f413c125d8",
                    "clipPathMobile": "circle(5.9% at 69% 65%)",
                    "clipPathDesktop": "circle(4.2% at 58% 66%)",
                    "copy": "W urządzeniu umieszczono kolorowy wskaźnik LED - sygnalizujący sesję oraz stan naładowania baterii",
                },
            ]
        }
    ]
}