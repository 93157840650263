export const data = [
  [
      {
          id: "1",
          name: "Monika",
          img: require("./img/1.png")
      },
  ],
  [
      {
          id: "2",
          name: "Katarzyna",
          img: require("./img/2.png")
      },
      {
          id: "3",
          name: "Ewelina",
          img: require("./img/3.png")
      },
  ],
  [
        {
            id: "4",
            name: "Matian",
            img: require("./img/4.png")
        },
        {
            id: "5",
            name: "Filip",
            img: require("./img/5.png")
        }, 
        {
            id: "6",
            name: "Agata",
            img: require("./img/6.png")
        },
      {
          id: "7",
          name: "Kuba",
          img: require("./img/7.png")
      },
      {
          id: "8",
          name: "Małgorzata",
          img: require("./img/8.png")
      }, 
      {
          id: "9",
          name: "Wojciech",
          img: require("./img/9.png")
      },
      {
        id: "10",
        name: "Marcin",
        img: require("./img/10.png")
    },
  ]

]