export const questions = {
    "gameType": "TrueFalsePickOneLayout",
    "glo": [
        {
            "ident": "q0",
            "content": "Skuteczne Polecenie to zaproszenie pełnoletniej palącej osoby poprzez Twój link do udziału w programie Alter Zone, a następnie odbieranie i zrealizowanie przez nią kuponu rabatowego na zakup urządzenia glo™.",
            fontSizeMobile: "",
            fontSizeDesktop: "24px",
            "type": "text",
            "answers": [
                {
                    "ident": "a",
                    "content": "Prawda"
                },
                {
                    "ident": "b",
                    "content": "Fałsz"
                }
            ]
        },
        {
            "ident": "q1",
            "content": "Korzyści przewidziane dla osoby zaproszonej to:",
            fontSizeMobile: "24px",
            fontSizeDesktop: "24px",
            "sub": "Kupon 10 zł na glo™\n45 punktów za realizację kuponu\nPakiet powitalny: 2 paczki neo™ w&nbsp;cenie&nbsp;1&nbsp;+&nbsp;1&nbsp;zł",
            subStylingMobile: "",
            subStylingDesktop: "",
            "type": "text",
            "answers": [
                {
                    "ident": "a",
                    "content": "Prawda"
                },
                {
                    "ident": "b",
                    "content": "Fałsz"
                }
            ]
        },
        {
            "ident": "q2",
            "content": "Za 1 skuteczne polecenie otrzymasz 100 punktów oraz kupon na 4 paczki neo™ w cenie 1 do zrealizowania w punktach eSmoking World oraz w sklepach Żabka.",
            fontSizeMobile: "",
            fontSizeDesktop: "24px",
            "type": "text",
            "answers": [
                {
                    "ident": "a",
                    "content": "Prawda"
                },
                {
                    "ident": "b",
                    "content": "Fałsz"
                }
            ]
        },
        {
            "ident": "q3",
            "content": "Nagroda za skuteczne polecenie naliczana jest na Twoje konto w terminie do 14 dni od dnia zakupu przez osobę zaproszoną urządzenia glo™ przy użyciu rabatu.",
            fontSizeMobile: "",
            fontSizeDesktop: "24px",
            "type": "text",
            "answers": [
                {
                    "ident": "a",
                    "content": "Prawda"
                },
                {
                    "ident": "b",
                    "content": "Fałsz"
                }
            ]
        }
    ]
}