export const questions = {
    "gameType": "PickOne",
    "glo": [
        {
            "ident": "q0",
            "content": "„Hardkorowo pada deszcz”",
            "type": "text",
            "answers": [
                {
                    "ident": "a",
                    "content": "Nosowska"
                },
                {
                    "ident": "b",
                    "content": "Zalewski"
                },
                {
                    "ident": "c",
                    "content": "Kwiat Jabłoni"
                },
                {
                    "ident": "d",
                    "content": "Sanah"
                }
            ]
        },
        {
            "ident": "q1",
            "content": "„I nawet kiedy będę sam”",
            "type": "text",
            "answers": [
                {
                    "ident": "a",
                    "content": "Artur Rojek"
                },
                {
                    "ident": "b",
                    "content": "IGO"
                },
                {
                    "ident": "c",
                    "content": "Brodka"
                },
                {
                    "ident": "d",
                    "content": "Daria Zawiałow"
                }
            ]
        },
        {
            "ident": "q2",
            "content": "„Zemsta jest słodka jak bigotka”",
            "type": "text",
            "answers": [
                {
                    "ident": "a",
                    "content": "Natalia Przybysz"
                },
                {
                    "ident": "b",
                    "content": "Mrozu"
                },
                {
                    "ident": "c",
                    "content": "Merry Spolsky"
                },
                {
                    "ident": "d",
                    "content": "Ralph Kaminski"
                }
            ]
        },
        {
            "ident": "q3",
            "content": "„Ja nie chcę iść pod wiatr, gdy wieje w dobrą stronę”",
            "type": "text",
            "answers": [
                {
                    "ident": "a",
                    "content": "Sobel"
                },
                {
                    "ident": "b",
                    "content": "Zalewski"
                },
                {
                    "ident": "c",
                    "content": "Karaś/Rogucki"
                },
                {
                    "ident": "d",
                    "content": "Ania Dąbrowska"
                }
            ]
        },
        {
            "ident": "q4",
            "content": "Jaki wariant smakowy neo™ możesz kupić u hostessy glo™ podczas eventu z cyklu Letnie Brzmienia?",
            "type": "text",
            "answers": [
                {
                    "ident": "a",
                    "content": "Peanut Butter Tobacco"
                },
                {
                    "ident": "b",
                    "content": "Sunset Click"
                },
                {
                    "ident": "c",
                    "content": "Blackberry Lemon Click"
                },
                {
                    "ident": "d",
                    "content": "Eucalyptus Click"
                }
            ]
        }
    ]
}