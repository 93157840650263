import React from "react"
import "./style.scss"

export default function HealthWarning() {

  return (
    <div className="upper-bars">
      <div className="health-warning">
        <p>Tylko dla pełnoletnich. Ten produkt tytoniowy szkodzi zdrowiu <br />i&nbsp;powoduje uzależnienie</p>
      </div>
    </div>
  );
}
