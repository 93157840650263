import React, { useState, useEffect } from 'react'
import Modal from "../Modal/Modal"
import Button from "../Button/Button"
import "./style.scss"
import { useNavigate } from 'react-router'

const targetDate = new Date(2023, 1, 3, 13, 0, 0)

const getRemainingTime = () => {
  const currentDate = new Date()
  let deltaInMs = targetDate.getTime() - currentDate.getTime()

  const seconds = Math.floor((deltaInMs / 1000) % 60);
  const minutes = Math.floor((deltaInMs / 1000 / 60) % 60);
  const hours = Math.floor((deltaInMs / (1000 * 60 * 60)));

  return { hours, minutes, seconds }
}

export default function CloudsFestNotify() {
  const [time, setTime] = useState({})
  const [notify, setNotify] = useSessionStorage("x3-points", true);

  function useSessionStorage(key, initialValue) {
    const [storedValue, setStoredValue] = useState(() => {
      if (window)
        try {
          const item = window.localStorage.getItem(key);
          return item ? JSON.parse(item) : initialValue;
        } catch (error) {
          console.log(error);
          return initialValue;
        }
    });
    if (typeof window === "undefined") {
      return initialValue;
    }
    const setValue = (value) => {
      try {
        const valueToStore =
          value instanceof Function ? value(storedValue) : value;
        setStoredValue(valueToStore);
        window.localStorage.setItem(key, JSON.stringify(valueToStore));
      } catch (error) {
        console.log(error);
      }
    };
    return [storedValue, setValue];
  }

  useEffect(() => {
    const interval = setInterval(() => {
      setTime(getRemainingTime())
    }, 1000);
    return () => clearInterval(interval);
  }, [])


  return (
    <>
      {time.seconds + time.minutes + time.seconds > 0 &&
        <Modal isOpen={notify} buttonClose={false} modalClass={"wrapper-x3points"}>
          <div className="x3points-modal">
            <div className="top-bar">
              <button onClick={() => setNotify(false)}>
                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fillRule="evenodd" clipRule="evenodd" d="M9.95147 11.6485C10.4201 12.1172 11.1799 12.1172 11.6485 11.6485C12.1172 11.1799 12.1172 10.4201 11.6485 9.95147L7.69706 5.99999L11.6485 2.04853C12.1171 1.5799 12.1171 0.820101 11.6485 0.351472C11.1799 -0.117156 10.4201 -0.117156 9.95147 0.351472L6 4.30294L2.04853 0.351472C1.57991 -0.117157 0.820108 -0.117157 0.35148 0.351472C-0.11715 0.8201 -0.11715 1.5799 0.35148 2.04853L4.30295 5.99999L0.351472 9.95147C-0.117157 10.4201 -0.117157 11.1799 0.351472 11.6485C0.8201 12.1172 1.5799 12.1172 2.04853 11.6485L6 7.69705L9.95147 11.6485Z" fill="white" />
                </svg>
              </button>
            </div>
            <div className="content">
              <h2 className="title">
                Wjeżdża polecanie za 3 razy więcej!
              </h2>
              <p><strong>Dla Ciebie</strong> za każde skuteczne polecenie: <span>300pkt</span></p>
              <p><strong>Dla Zaproszonego</strong> za realizację <br className='br-mobile' />kuponu w internetowym sklepie glo™:&nbsp;<span>135pkt</span></p>
              <div className="line"></div>
              <p>Akcja trwa od 26 stycznia do 3 lutego. Poznaj szczegóły czytając najnowszy news i podziel się promocją.</p>
              <Button href="/co-nowego/zgarnij-potrojne-punkty-za-zapraszanie" action={() => setNotify(false)}>Dowiedz się więcej</Button>
            </div>
          </div>
        </Modal>}
    </>
  )
}
