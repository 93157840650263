import device0 from "./img/device0.png"
import device2 from "./img/device2.png"
import device3 from "./img/device3.png"
import device4 from "./img/device4.png"
import device5 from "./img/device5.png"
import device6 from "./img/device6.png"
import device7 from "./img/device7.png"


export const questions = {
    "gameType": "RevealColor",
    "glo": [
        {
            "ident": "q0",
            "tile": 0,
            "correct": "7e5d279a-33c7-4fdd-ae46-499ea0b4029a",
            "device": device0,
            "name": "Light Teal"
        },
        {
            "ident": "q0",
            "tile": 1,
            "correct": "93e8879c-167c-11ee-be56-0242ac120002",
            "device": null,
            "name": "Niestety glo™ hyper X2 Air nie występuję w tym kolorze"
        },
        {
            "ident": "q0",
            "tile": 2,
            "correct": "3be492d1-a308-4691-849e-45c6271ac029",
            "device": device2,
            "name": "Crisp Purple"
        },
        {
            "ident": "q0",
            "tile": 3,
            "correct": "c601cb1b-1b3c-403d-abac-c1565b48b3e4",
            "device": device3,
            "name": "Moonless Black"
        },
        {
            "ident": "q0",
            "tile": 4,
            "correct": "32d4429d-243e-456c-a81c-6b5e9d1e4192",
            "device": device4,
            "name": "Ocean Blue"
        },
        {
            "ident": "q0",
            "tile": 5,
            "correct": "4e19dce9-e7a4-46cf-81fb-c7936608dd68",
            "device": device5,
            "name": "Rosey Gold"
        },
        {
            "ident": "q0",
            "tile": 6,
            "correct": "344ef567-2507-4e77-8e47-bc4d47e7d3c9",
            "device": device6,
            "name": "Velvet Pink"
        },
        {
            "ident": "q0",
            "tile": 7,
            "correct": "e52440b6-e30f-4e1e-bfb8-4976c1377962",
            "device": device7,
            "name": "Space Navy"
        },
        {
            "ident": "q0",
            "tile": 8,
            "correct": "93e8809e-167c-11ee-be56-0242ac120002",
            "device": null,
            "name": "Niestety glo™ hyper X2 Air nie występuję w tym kolorze"
        },
    ]
}