import React, { useState, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { useWindowSize } from '../../Helpers';
import { Link } from "react-router-dom";
import { ReactComponent as Arrow } from "./img/arrow.svg"
import { ReactComponent as ArrowWhite } from "./img/arrowWhite.svg"
// import Button from "../../components/Button/Button.js";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { getArticles, useArticleState, useArticleDispatch } from '../../ArticlesContext'
import { useUserState } from '../../UserContext'
import Tick from "./img/tick.svg"
import Hourglass from "./img/hourglass.svg"
import _ from "lodash"
import { activitiesDone } from "../../data/activities"
import Limited from "./img/limited.svg"
import HyperX2 from "./img/x2-logo.svg"
import MoreAwards from "./img/more-awards.svg"

import "swiper/css";

import "swiper/css/pagination"
import "./style.scss"

import SwiperCore, {
  Mousewheel, Pagination, Navigation
} from 'swiper';
import LikeButton from "../../components/LikeButton";
import { awardAmountMap, calculateProgresPrecentage, welcomePrizeAwards } from "../AwardsPage/AllAwards";
import EasterEgg from "../../components/EasterEgg";
import { hiddenActivities, monthlyBonsuContent } from "../ActivitiesPage";

SwiperCore.use([Mousewheel, Pagination, Navigation]);

export default function HomePage({ newX2 }) {
  const { width } = useWindowSize();
  const SCREEN_XGA = 1020;
  const [selectedNews, setSelectedNews] = useState(0)
  const dispatch = useArticleDispatch()
  const { articles } = useArticleState()
  const [activities, setActivities] = useState([])
  const [awards, setAwards] = useState([])
  const { userDetails, accountDetails } = useUserState()
  const [awardsVisible, setAwardsVisible] = useState(false)
  const [awardIndex, setAwardIndex] = useState(0)
  const [activityIndex, setActivityIndex] = useState(0)

  const getArt = async () => {
    try {
      let response = await getArticles(dispatch)
      if (!response) return
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    const getActivities = async () => {
      const response = await fetch(process.env.REACT_APP_API_URL + "/alterzone/activation/list", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + JSON.parse(sessionStorage.getItem('token')).uuid,
          'X-Mode-Local': sessionStorage.getItem('devParams') ? true : false,
        },
        body: JSON.stringify({
          "ident": "migration_debug",
          "withSolutions": false
        })
      })
      let activities = await response.json()
      let newActivites = [monthlyBonsuContent, ...activities?.data.activations]
      setActivities(newActivites.filter(e => !activitiesDone.includes(e.ident) && !hiddenActivities.includes(e.ident) && e.content && !e.expired))
    }

    const getAwards = async () => {
      const params = {
        "limit": 100,
        "offset": 0
      }

      const response = await fetch(process.env.REACT_APP_API_URL + "/alterzone/prize/catalogue/list-dashboard", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + JSON.parse(sessionStorage.getItem('token')).uuid,
          'X-Mode-Local': sessionStorage.getItem('devParams') ? true : false,
        },
        body: JSON.stringify(params)
      })
      const awards = await response.json()
      const newAwards = awards.data.catalogue.filter(e => {
        if (userDetails.welcomePrizeReceived && welcomePrizeAwards.includes(e.ident)) {
          return false
        }
        return true
      })
      // const newAwards = awards.data.catalogue.filter(e => e.available && e.inStock)
      setAwards(newAwards.filter(e => !e.cycleLimitExceeded))
      setTimeout(() => { setAwardsVisible(true) }, 300)
    }

    setTimeout(() => { if ((!activities || activities.length === 0) && userDetails.consentAlterzoneParticipate) getActivities() }, 300)
    setTimeout(() => { if ((!awards || awards.length === 0) && userDetails.consentAlterzoneParticipate) getAwards() }, 300)
    //eslint-disable-next-line
  }, [userDetails])

  function calculateProgresBar(pointsEarned, basePrice) {
    const objects = document.querySelectorAll(".progres")
    if (!objects || objects.length === 0) {
      return 0
    }
    let widthOfDiv = objects.item(0)?.offsetWidth - 10
    let widthOfBar = widthOfDiv * (pointsEarned / basePrice)
    return widthOfBar > widthOfDiv ? widthOfDiv : widthOfBar
  }

  useEffect(() => {
    setTimeout(() => { if ((!articles || articles.length === 0) && userDetails.consentAlterzoneParticipate) getArt() }, 300)
    //eslint-disable-next-line
  }, [userDetails])

  let sortedArticles = [...(!userDetails?.preRegistration ? articles.filter(e => e.slug.toLowerCase() === "wstepna-rejestracja-otwarta-inspiration-club-jest-juz-blisko") : []), ...articles.filter(e => e.slug.toLowerCase() === "kulisy-nowej-platformy-inspiration-club"), ...articles.filter(e => e.slug.toLowerCase() === "limitowana_edycja_glo_hyper_x2"), ...articles.filter(e => e.slug.toLowerCase() === "podroz-po-letnich-doznaniach-z-neo"), ..._.orderBy(articles.filter(e => !(["wstepna-rejestracja-otwarta-inspiration-club-jest-juz-blisko", "kulisy-nowej-platformy-inspiration-club", "Limitowana_edycja_glo_Hyper_X2", "podroz-po-letnich-doznaniach-z-neo"].includes(e.slug.toLowerCase()))), ['hero'], 'desc')]

  sortedArticles.splice(5)

  return (
    <section className={newX2 ? "home-page new-variants" : "home-page"}>
      <div className="news">
        <h2>Co nowego?</h2>
        {/* {newX2 ? <img className="hyper-x2-logo" src={HyperX2} alt="glo hyper X2 logo" /> : null} */}
        {width < SCREEN_XGA ? <Swiper pagination={true} className="swiper-news"
          width={320} spaceBetween={16} slidesPerView={"auto"}>
          {sortedArticles.map((element, index) => {
            const isExternal = element.external_url ? true : false

            return (<SwiperSlide width={320} key={index}>
              {isExternal ? element.external_url?.custom.includes("https://") || element.external_url?.custom.includes("http://") ?
                <a href={element.external_url?.custom} target="_blank" rel="noopener noreferrer" className="slide-link">
                  <div className="img" style={{ backgroundImage: "url(" + (element?.thumbnail?.file?.url ? element?.thumbnail?.file?.url : element?.hero_image?.file?.url) + ")" }}>
                    <div className="category-like-wrapper">
                      <p className="category">{element.categoryName}</p>
                      <LikeButton article={element} />
                    </div>
                  </div>
                  <p className="title" dangerouslySetInnerHTML={{ __html: element.content?.title }}></p>
                  <p className="subtitle">{element.content?.description}</p>
                  {/* <element.logo className="logo"/> */}
                </a>
                :
                <Link to={element.external_url?.custom} className="slide-link">
                  <div className="img" style={{ backgroundImage: "url(" + (element?.thumbnail?.file?.url ? element?.thumbnail?.file?.url : element?.hero_image?.file?.url) + ")" }}>
                    <div className="category-like-wrapper">
                      <p className="category">{element.categoryName}</p>
                      <LikeButton article={element} />
                    </div>
                  </div>
                  <p className="title" dangerouslySetInnerHTML={{ __html: element.content?.title }}></p>
                  <p className="subtitle">{element.content?.description}</p>
                  {/* <element.logo className="logo"/> */}
                </Link>
                : <Link to={"/co-nowego/" + element.slug} className="slide-link">
                  <div className="img" style={{ backgroundImage: "url(" + (element?.thumbnail?.file?.url ? element?.thumbnail?.file?.url : element?.hero_image?.file?.url) + ")" }}>
                    <div className="category-like-wrapper">
                      <p className="category">{element.categoryName}</p>
                      <LikeButton article={element} />
                    </div>
                  </div>
                  <p className="title" dangerouslySetInnerHTML={{ __html: element.content?.title }}></p>
                  <p className="subtitle">{element.content?.description}</p>
                  {/* <element.logo className="logo"/> */}
                </Link>}
            </SwiperSlide>
            )
          })}
        </Swiper> : <div className="news-desktop">
          <TransitionGroup className="img-wrapper">
            <CSSTransition
              key={selectedNews}
              classNames="news-img"
              timeout={{ enter: 250, exit: 250 }}
            >
              {sortedArticles[selectedNews]?.external_url != null ?
                sortedArticles[selectedNews]?.external_url?.custom.includes("https://") || sortedArticles[selectedNews]?.external_url?.custom.includes("http://") ? <a href={sortedArticles[selectedNews]?.external_url?.custom} target="_blank" rel="noopener noreferrer" className="img" style={{ backgroundImage: "url(" + (sortedArticles[selectedNews]?.thumbnail?.file?.url ? sortedArticles[selectedNews]?.thumbnail?.file?.url : sortedArticles[selectedNews]?.hero_image?.file?.url) + ")" }} >
                  <p className="category">{sortedArticles[selectedNews]?.categoryName}</p>
                  <LikeButton article={sortedArticles[selectedNews]} setArticle={getArt} />
                </a>
                  :
                  <Link to={sortedArticles[selectedNews]?.external_url?.custom} className="img" style={{ backgroundImage: "url(" + (sortedArticles[selectedNews]?.thumbnail?.file?.url ? sortedArticles[selectedNews]?.thumbnail?.file?.url : sortedArticles[selectedNews]?.hero_image?.file?.url) + ")" }} >
                    <p className="category">{sortedArticles[selectedNews]?.categoryName}</p>
                    <LikeButton article={sortedArticles[selectedNews]} setArticle={getArt} />
                  </Link>
                :
                <Link className="img" style={{ backgroundImage: "url(" + (sortedArticles[selectedNews]?.thumbnail?.file?.url ? sortedArticles[selectedNews]?.thumbnail?.file?.url : sortedArticles[selectedNews]?.hero_image?.file?.url) + ")" }} to={"/co-nowego/" + sortedArticles[selectedNews]?.slug}>
                  {/* <div className="category-like-wrapper"> */}
                  <p className="category">{sortedArticles[selectedNews]?.categoryName}</p>
                  <LikeButton article={sortedArticles[selectedNews]} setArticle={getArt} />
                  {/* </div> */}
                </Link>}
            </CSSTransition>
          </TransitionGroup>
          <Swiper pagination={true} direction={'vertical'} navigation={true} mousewheel={true} className="swiper-news" // change naviation={true} mousewheel={true} later
            width={320} slidesPerView={3}>
            {sortedArticles.map((element, index) => {
              const isExternal = element.external_url ? true : false

              return (<SwiperSlide className={index === selectedNews ? "selected" : ""} width={320} key={index} onMouseOver={() => { setSelectedNews(index) }}>
                {isExternal ? element.external_url?.custom.includes("https://") || element.external_url?.custom.includes("http://") ?
                  <a href={element.external_url?.custom} target="_blank" rel="noopener noreferrer" className="slide-link">
                    <div className="content-wrapper">
                      <p className="title" dangerouslySetInnerHTML={{ __html: element.content?.title }}></p>
                      <p className="desc" dangerouslySetInnerHTML={{ __html: element.content?.description }}></p>
                    </div>
                    <div className="arrow">
                      <Arrow style={{ fill: "#fff" }} />
                    </div>
                  </a>
                  :
                  <Link to={element.external_url?.custom} className="slide-link">
                    <div className="content-wrapper">
                      <p className="title" dangerouslySetInnerHTML={{ __html: element.content?.title }}></p>
                      <p className="desc" dangerouslySetInnerHTML={{ __html: element.content?.description }}></p>
                    </div>
                    <div className="arrow">
                      <Arrow style={{ fill: "#fff" }} />
                    </div>
                  </Link>
                  : <Link to={"/co-nowego/" + element.slug} className="slide-link">
                    <div className="content-wrapper">
                      <p className="title" dangerouslySetInnerHTML={{ __html: element.content?.title }}></p>
                      <p className="desc" dangerouslySetInnerHTML={{ __html: element.content?.description }}></p>
                    </div>
                    <div className="arrow">
                      <Arrow style={{ fill: "#fff" }} />
                    </div>
                  </Link>}
              </SwiperSlide>
              )
            })}
          </Swiper>
        </div>}
      </div>
      <div className="activities">
        <h2>Aktywności</h2>
        {activities.length >= 4 && width >= SCREEN_XGA ? <ul className="dots">
          <li className={activityIndex === 0 ? "active" : ""}></li>
          <li className={activityIndex === 1 ? "active" : ""}></li>
          <li className={activityIndex === 2 ? "active" : ""}></li>
        </ul> : null}
        {/* { width < SCREEN_XGA ?  */}
        <Swiper pagination={width < SCREEN_XGA ? true : false} navigation={activities.length >= 4 ? true : false} onSlideChange={(swiper) => {
          if (swiper.isEnd) {
            setActivityIndex(2)
          } else if (swiper.isBeginning) {
            setActivityIndex(0)
          } else {
            setActivityIndex(1)
          }
        }} className="swiper-activities"
          breakpoints={{
            0: {
              slidesPerView: "auto",
              spaceBetween: 16
            },
            1280: {
              slidesPerView: 3
            }
          }}
        >
          {activities.length > 0 && activities?.map((element, index) => {
            if (element.ident === "sub_neo_2022") {
              element.payout = 100
            }

            return (
              <SwiperSlide width={320} key={index}>
                <Link key={index} className={`activity ${element.ident === "monthly-bonus" ? "highlighted" : ""}`} to={`/aktywnosci/${element.ident.includes("konkurs") ? element.ident === "konkurs5_cf_chorzow" ? "clouds-fest/konkurs" : element.ident + "/konkurs" : element.ident.includes("cloudsfest_cho_vip_220827") ? "zapodaj-rymem/konkurs" : element.ident}`} state={{ ident: element.ident }}> {/*element.contest ? "/aktywnosci/poprzednie-konkursy" : */}
                  <div className="img" style={{ backgroundImage: `url( ${element.thumbnail.file.url} )` }}>
                    {element.payout ? <div className="points">
                      {`${element.payout} pkt`}
                    </div> : null}
                  </div>
                  <div className="content">
                    <div className="type">
                      {element.type}
                      {/* {element.contest ? "Konkurs Zakończony" : element.type */}
                    </div>
                    <div className="title">
                      {element.content.title}
                    </div>
                    <div className="sub">
                      {element.content.description}
                    </div>
                    <div className="arrow">
                      {element.ident === "monthly-bonus" ? <ArrowWhite /> : <Arrow />}
                    </div>
                  </div>
                </Link>
              </SwiperSlide>
            )
          })}
        </Swiper>
        {/* : <Swiper pagination={true} className="swiper-activities" spaceBetween={16} navigation={true} slidesPerView={3}>
          {activities.length && activities?.map((element, index) => (
            <SwiperSlide key={index}>
              <Link key={index} className="activity" to={`/aktywnosci/${element.ident}`} state={{ident: element.ident}}>
                <div className="img" style={{backgroundImage: `url( ${element.hero_image.file.url} )`}}>
                  <div className="points">
                    {`${element.payout} pkt`}
                  </div>
                </div>
                <div className="content">
                  <div className="type">
                    {element.type}
                  </div>
                  <div className="title">
                    {element.content.title}
                  </div>
                  <div className="sub">
                    {element.content.description}
                  </div>
                  <div className="arrow">
                    <Arrow/> 
                  </div>
                </div>
              </Link>
            </SwiperSlide>
          ))}
        </Swiper> } */}
      </div>
      <div className="awards">
        {/* <Waypoint
          onEnter={() => { setAwardsVisible(true) }}
        /> */}
        <h2>Nagrody</h2>
        {activities.length >= 4 && width >= SCREEN_XGA ? <ul className="dots">
          <li className={awardIndex === 0 ? "active" : ""}></li>
          <li className={awardIndex === 1 ? "active" : ""}></li>
          <li className={awardIndex === 2 ? "active" : ""}></li>
        </ul> : null}
        <Swiper pagination={width < SCREEN_XGA ? true : false} navigation={awards.length < 5 ? false : true} onSlideChange={(swiper) => {
          if (swiper.isEnd) {
            setAwardIndex(2)
          } else if (swiper.isBeginning) {
            setAwardIndex(0)
          } else {
            setAwardIndex(1)
          }
        }} className={awardsVisible ? "swiper-awards visible" : "swiper-awards"}
          breakpoints={{
            0: {
              spaceBetween: 16,
              slidesPerView: "auto"
            },
            1280: {
              spaceBetween: 16,
              slidesPerView: 4
            }
          }}
        >
          {/* {console.log(awards)} */}
          {awards.length > 0 && awards?.map((element, index) => {
            const amount = element.cycleLimitExceeded ? awardAmountMap.get(0) : awardAmountMap.get(element.availability ?? 0)

            return (<SwiperSlide width={320} key={index}>
              <Link key={index} className={`award ${element.drop ? "drop" : ""}`} to={`/nagrody/odbior/${element.drop ? "drop/" + element.ident : element.ident}`}>
                <img src={element.thumbnail?.file?.url} alt="" className='image' />
                {element.drop && <div className='limited'>nagroda limitowana <img src={Limited} alt="" /></div>}
                <p className="title">{element.content?.title}</p>
                <div className="availability">
                  <p>Dostępność: <span style={{ color: amount.copyColor }}>{amount.copy}</span></p>
                  <div className="scale">
                    <div className="bar" style={{ width: amount.barWidth }} />
                    {Array(4).fill(null).map((_, index) => (
                      <div className={`element ${index < amount.scale ? "active" : ""}`} key={index}>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="progress">
                  <div className="cart">
                    <svg className="svg-progress">
                      <circle className="track" />
                      <circle className="indicator" style={{
                        strokeDasharray: `${Math.PI * 2 * 16}px`,
                        strokeDashoffset: `${Math.PI * 2 * 16 * (1 - calculateProgresPrecentage(accountDetails.value, element.basePrice))}px`
                      }} />
                    </svg>
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M5 14.5C5.55228 14.5 6 14.0523 6 13.5C6 12.9477 5.55228 12.5 5 12.5C4.44772 12.5 4 12.9477 4 13.5C4 14.0523 4.44772 14.5 5 14.5Z" fill="#E5451F" />
                      <path d="M11.5 14.5C12.0523 14.5 12.5 14.0523 12.5 13.5C12.5 12.9477 12.0523 12.5 11.5 12.5C10.9477 12.5 10.5 12.9477 10.5 13.5C10.5 14.0523 10.9477 14.5 11.5 14.5Z" fill="#E5451F" />
                      <path d="M2.64375 4.5H13.8562L12.2063 10.275C12.1474 10.4846 12.0213 10.669 11.8474 10.8C11.6735 10.931 11.4614 11.0013 11.2437 11H5.25625C5.03855 11.0013 4.82647 10.931 4.65258 10.8C4.47869 10.669 4.35262 10.4846 4.29375 10.275L2.03125 2.3625C2.00135 2.25797 1.9382 2.16604 1.85136 2.10062C1.76452 2.03521 1.65872 1.99989 1.55 2H0.5" stroke="#E5451F" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                  </div>
                  <div className="price">
                    <p>Koszt: {element.basePrice} pkt</p>
                    <div>
                      {
                        accountDetails.value < element.basePrice ?
                          `Brakuje: ${element.basePrice - accountDetails.value} pkt`
                          :
                          <div className='flex-wrapper'>
                            Możesz odebrać!
                            <svg width="10" height="9" viewBox="0 0 10 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path fillRule="evenodd" clipRule="evenodd" d="M9.51526 0.955017C9.81625 1.23959 9.82956 1.71427 9.54499 2.01526L3.8 8.09169L0.455017 4.55372C0.170447 4.25273 0.183756 3.77805 0.484743 3.49348C0.78573 3.20891 1.26042 3.22222 1.54499 3.5232L3.8 5.90832L8.45502 0.984743C8.73959 0.683756 9.21427 0.670447 9.51526 0.955017Z" fill="#ED8002" />
                            </svg>
                          </div>
                      }
                    </div>
                  </div>
                  <div className="arrow">
                    <img src={Arrow} alt="" />
                  </div>
                </div>
                {/* <div className="points-progres">
                  <div className={`points ${element.basePrice === 0 ? "pulse-animation" : ""}`}>
                    {`${element.basePrice} pkt`}
                  </div>
                  <div className={`progres ${accountDetails.value >= element.basePrice ? 'possible' : ""}`}>
                    {accountDetails.value >= element.basePrice ?
                      <>
                        <p>możesz odebrać</p>
                        <img src={Tick} alt="" />
                      </>
                      :
                      <>
                        <p>{`brakuje Ci ${element.basePrice - accountDetails.value} pkt`}</p>
                        <img src={Hourglass} alt="" />
                        <div className="progres-bar" style={{ width: calculateProgresBar(accountDetails.value, element.basePrice) || 0 }}></div>
                      </>
                    }
                  </div>
                </div>
                {element.drop && <div className='limited'>nagroda limitowana <img src={Limited} alt="" /></div>}
                <div className="title">
                  {element.content?.title}
                </div>
                <div className="arrow">
                  <Arrow />
                </div> */}
              </Link>
            </SwiperSlide>
            )
          })}
          {awards.length !== 0 && <SwiperSlide width={320}>
            <Link className="more-awards-wrapper" to="/nagrody/katalog">
              <div className="content">
                <p>Zobacz<br />wszystkie</p>
                <Arrow />
              </div>
              <img src={MoreAwards} alt="" />
            </Link>
          </SwiperSlide>}
        </Swiper>
      </div>
      {/* <div className="ar-section">
        <div className="img"></div>
        <div className="text">
          <div>
            <h2>Skaner glo™ AR</h2>
            <p>Chcesz zeskanować kod na punkty <br/>i darmowy drink przy barze?</p>
            <Button>Uruchom skaner</Button>
          </div>
        </div>
      </div> */}
    </section >
  );
}
