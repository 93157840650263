import React, { useLayoutEffect, useState } from 'react'
import Modal from 'react-modal';
import Exit from "./img/exit.svg"
import ExitCode from "./img/exit-code.svg"
import Success from "./img/modal-success.svg"
import { showErrorPopup, translateError } from '../../components/ErrorHandling';
import "./style.scss"

export default function ModalComponent({ isOpen = false, closeModal, children, code = false, modalClass = "", buttonClose = true, ident = "", topSuccess = false }) {

  Modal.setAppElement('#root')

  const [partnerImg, setPartnerImg] = useState("")
  const [partnerAlt, setPartnerAlt] = useState("")
  const [partnerText, setPartnerText] = useState("")

  useLayoutEffect(() => {
    async function fetchData() {
      const response = await fetch(process.env.REACT_APP_API_URL + "/alterzone/prize/list", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + JSON.parse(sessionStorage.getItem('token')).uuid,
          'X-Mode-Local': sessionStorage.getItem('devParams') ? true : false,
        },
        body: JSON.stringify({})
      })
      const result = await response.json()
      if (result.status.success) {
        const awardObj = result.data.prize.filter(e => e.catalogue.ident === ident)[0]?.catalogue
        console.log(awardObj)
        if(awardObj.glo) {
          setPartnerImg(awardObj?.partner_info?.file?.url ?? "")
          setPartnerAlt(awardObj?.partner_info?.meatadata?.alt ?? "")
          setPartnerText(parseHTMLToText(awardObj?.partner_info?.custom) ?? "")
        } else {
          setPartnerImg("")
          setPartnerAlt("")
          setPartnerText("")
        }
      } else {
        showErrorPopup(translateError(result.data.error), result.meta.ts)
      }
    }
    if (ident !== "")
      fetchData()
  }, [ident])

  function getModuleValue(object, type, field) {
    if (Object.keys(object).length === 0) {
      return
    }
    let fieldSplit = field.split(".")
    let data = object?.data?.content?.modules.filter(obj => obj.type === type)[0]
    for (let i = 0; i < fieldSplit.length; i++) {
      data = data?.[fieldSplit[i]]
    }
    return data
  }

  function parseHTMLToText(html) {
    let text = ""
    let dummy = document.createElement("html")
    dummy.innerHTML = html
    for (let i = 0; i < dummy.getElementsByTagName('p').length; i++) {
      text += (dummy.getElementsByTagName('p').item(i).innerHTML)
      text += " "
    }
    return (text)
  }

  return (
    <Modal isOpen={isOpen} className={`modal ${code ? "modal-code " + modalClass : " " + modalClass}`} overlayClassName={`overlay ${code ? "overlay-code " + modalClass : " " + modalClass}`}>
      {code ?
        <div className="code-wrapper">
          <div className="button-wrapper">
            <button className='close-button'>
              <img src={ExitCode} alt="" onClick={closeModal} />
            </button>
          </div>
          <div className="content">
            <p>Kod</p>
            <div className="value">
              <h2>{children}</h2>
            </div>
            <div className="partner-info">
              {partnerText !== "" && partnerImg !== "" &&
                <>
                  <div className="text" dangerouslySetInnerHTML={{ __html: partnerText }}></div>
                  <img src={partnerImg} alt={partnerAlt} />
                </>}
            </div>
          </div>
        </div>
        :
        <>
          {topSuccess && <div className='modal-top-success'>
            <img src={Success} alt=""/>
          </div>}
          {buttonClose && <div className="button-wrapper">
            <button className='close-button'>
              <img src={Exit} alt="" onClick={closeModal} />
            </button>
          </div>}
          <div className="content">
            {children}
          </div>
        </>
      }
    </Modal>
  )
}
