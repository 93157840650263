import React, { useRef, useState } from 'react'
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, {
  Pagination, Autoplay
} from 'swiper';
import "swiper/css";
import "swiper/css/pagination"
import "./style.scss"
import CrazyWednesdayBar from '../CrazyWednesdayBar';
import InviteBonusBar from '../InviteBonusBar';
import DoublePointsBar from '../DoublePointsBar';
import { useSessionStorage } from '../../Helpers';

SwiperCore.use([Pagination, Autoplay]);

export default function BarSwitcher({ children, setBarsVisible, barsVisible }) {
  const swiperRef = useRef(null)
  const [bars, setBars] = useSessionStorage("bars", [true, true, true])
  
  const handleChagne = (val, index) => {
    let temp = [...bars] 
    temp[index] = val
    setBars(temp)

    if(bars.every(e => e === false)) {
      setBarsVisible(false)
    }
  }

  return (
    barsVisible && <div className="bar-switcher">
      <Swiper
        ref={swiperRef}
        autoplay={{
          delay: 5000,
          disableOnInteraction: false,
        }}
        pagination={{
          type: "progressbar",
        }}
        navigation={true}
        modules={[Autoplay, Pagination]}
        className={"swiper-bar"}
      >
        {/* {React.Children.map(children, child => */}
        {bars[0] && <SwiperSlide>
          <DoublePointsBar setDoubleBar={val => handleChagne(val, 0)} />
        </SwiperSlide>}
        {/* {bars[1] && <SwiperSlide>
          <InviteBonusBar setInviteBonusBar={val => handleChagne(val, 1)}/>
        </SwiperSlide>} */}
        {bars[2] && <SwiperSlide>
          <CrazyWednesdayBar setWednesdayBar={val => handleChagne(val, 2)}/>
        </SwiperSlide>}
        {/* )} */}
      </Swiper>
    </div >
  )
}
