import Mobile from "./img/aside.png"
import Desktop from "./img/aside.png"
import { questions } from "./questions"


export const zostan_ekspertem_neo = {
    header: "Ekspert neo™",
    backgroundMobile: Mobile,
    backgroundDesktop: Desktop,
    title: "Odkryj aromaty nowych limitowanych wariantów neo™ Lounge Click & Terrazza Click",
    subtitle: "Sprawdź je w najnowszej aktywności, a później wybierz się do jednego z punktów eSmoking World.",
    message: "Dopasuj opis aromatu do paczki wkładów neo™",
    points: 15,
    statute: "/assets/Regulamin Aktywności_Ekspert_neo_14.02.pdf",

    ident: "luty3_2022",
    gameType: "TrueFalse",
    questions: questions,

    correct: {
        title: "Brawo!",
        content: [
            "Jesteśmy zaskoczeni! To było bezbłędne oraz imponujące. Masz swój ulubiony smak wkładów neo™?",
            // "Wkłady tytoniowe neo™ to aż 12 różnych smaków. 3 warianty tytoniowe, 9 wariantów z kapsułą. W tym:  2 warianty mentolowe, 2 warianty z podwójną kapsułą 5 wariantów owocowych",
            "Szukaj swoich ulubionych smaków \nw najbliższych punktach sprzedaży. "
        ]
    },
    wrong: {
        title: "Było ok!",
        content: [
            "Jednak to za mało by zostać Ekspertem neo™. Pamiętaj, że praktyka czyni mistrza. 15 punktów będzie na Ciebie czekać.",
            "Sprawdź wszystkie warianty neo™",
            // "Chcesz skorzystać z małej pomocy?"
        ]
    },
}