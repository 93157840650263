export const data = [
    [
        {
            id: "1",
            name: "Maria S.",
            title: "Neonowy weekend",
            first: "Neonowa impreza w bacówce, połaczenie tradycji z nowoczesnoscią, mush-up muzyki elektrycznej z góralską, lasery",
            second: "Neonowa impreza part II na basenie, połączenie wody, dobrej muzyki, leserów masy swiateł.",
            third: "Festiwal kolorów u podnurza gór, piękne widoki, elektryzująca muzyka i neonowy proszek",
        },
        {
            id: "2",
            name: "Neo(TM)nowe morze",
            title: "Piotr B.",
            first: "Morską przygodę zaczynamy lunchem z owoców morza w SŁODKIM MELONIE, a smak podkreśla szklanka soku TROPIKALNYCH OWOCóW",
            second: "Kontynuujemy SŁODKIE chillowanie pływając na ANANASOWYM materacu, a morska bryza zapewnia ORZEŻWIAJĄCE DOŚWIADCZENIE",
            third: "SŁODKI POSMAK weekendu dopełnia TRUSKAWKOWY zachód słońca, odbijający się w TYTONIOWO-KREMOWYCH falach",
        },
        {
            id: "3",
            name: "Piotr N.",
            title: "Dance&Chill w Pafos na Cyprze",
            first: "Warsztaty yogi, medytacji i relaksacji na plaży, w odpowiedni nastrój pomogą się wprowadzić Neo Dark Tobacco.",
            second: "Warsztaty taneczne na piasku i w wodzie, orzeżawienie przyniosą Neo Ice Click. ",
            third: "Relaks, odpoczynek, plażowanie i siatkówka wodna, oglądanie zachodu słońca z Neo Sunset Click.",
        },
    ],
    [
        {
            id: "4",
            name: "Weronika A.",
            title: "Morska karuzela! Od latania w chmurach przez dzikie tańce na plaży do skakania po falach zakończone błogim relaxem..",
            first: "Mocny początek! Pierwsza atrakcja to lot balonem po polskim wybrzeżu puszczając dymka z podwójnie klikanego wkładu neo.",
            second: "Huczna impreza na plaży! śawietna muzyka, przyjaciele i słodkie wkłady malinowe neo idealnie pasujące do margarity.",
            third: "Na koniec kitesurfing a potem plaża, kocyk, słońce, szum fal, zimne piwko i miętowe wkłady Ice Click.",
        },
        {
            id: "5",
            name: "Marek N.",
            title: "Magiczna Lizbona z Glo",
            first: "Weekend w Lizbonie rozpoczynamy od zwiedzania urokliwych uliczek i zabytków , a ochłodę w tym przyniosą nam Neo Arctic. ",
            second: "Tego dnia oddajemy się zabawie w klubach, barach i restauracjach, a to wszystko z Neo Club Click. ",
            third: "Ostatni dzień spędzamy na jednej z okolicznych plaż, a czas ten osładzamy Neo Summer Yellow.",
        },
        {
            id: "6",
            name: "Zbigniew S.",
            title: "Smakuj Bieszczady",
            first: "Wejść na piękną połoninę Caryńską i skosztować Ice Click ",
            second: "W upalny dzień posiedzieć nad wodospadem Szepit mając w ustach orzeżawiający i słodki smak Arctic Blue",
            third: "Wieczorem zaprosic moją Panią na zakapiorska kolacje do Siekierezady w Cisnej, tam smakowac Bieszczady i Yellow Sumer.",
        },
        {
            id: "7",
            name: "Festiwal - Rozkręcamy Lato",
            title: "Festiwal - Rozkręcamy Lato",
            first: "Od rana na scenie same najlepsze gwiazdy a w międzyczasie grill i miejsca wyznaczone do Glo",
            second: "Nowe gwiazdy a wieczorem ognisko i śpiewanie przy gitarze i Glo",
            third: "Karaoke związane z latem i opowieści o najlepszych przeżyciach z wakacji.",
        },
        {
            id: "8",
            name: "Dominika D.",
            title: "Bliżej nieba ",
            first: "Energylandia w rytmach orzeżawiającego arbuza neo, wycisnę z zabawy wszystkie soki i poczuję wiatr we włosach.",
            second: "Odwiedzę Smoka w Krakowie i namówię na super miętę z mentolem neo. Może odlecimy razem do gwiazd.",
            third: "Babia góra i odpoczynek w chmurach, z neo każda pora roku może być obfita w jagody.",
        },
        {
            id: "9",
            name: "Simona A.",
            title: "NeoMelo na bulwarach ",
            first: "Wszyscy zaproszeni goście ubierają się w kolory wygranych przez siebie wkładów Neo, następnie duże ognicho!",
            second: "Gra w dwa ognie - nagrodami są wkłady Neo",
            third: "Wymienianie się smakami wkładów Neo z wszystkimi gośćmi.",
        },
        {
            id: "10",
            name: "Paulina C.",
            title: "Weekend na czterech łapach ",
            first: "Spacer z mężem oraz naszą kochaną czworonożną Tini. Podróż będzie długa z wywieszonym jęzorem",
            second: "Cudowne spotkanie ze znajomymi i ich pieskami. Nasza Tini będzie bardzo zadowolona. Gill przygotowany, kiełbaski kupione",
            third: "Odpoczynek nad wodą. Co prawda jeszcze zimno na moczenie, ale jaki Pies będzie zadowolony gdy zrobi nura. Majówka 100%.",
        },
    ],
    [
        {
            id: "11",
            name: "Bartłomiej N.",
            title: "Grill w górach ",
            first: "Piwko w plenerze oczywiście w dobrym towarzystwie podgrzewając mentolowe wkłady do piwka",
            second: "Grill w górach w pięknym klimacie, głód nikotynowy trzeba ugasić więc podgrzejemy atmosferę glo ",
            third: "Na koniec udanego wypadu pójdziemy coś zjeść dobre miesko,piwko a po jedzonku czas na glo . Udane 3 dni.",
        },
        {
            id: "12",
            name: "Kacper S.",
            title: "non stop uśmiech",
            first: "Wspólne ognisko w gronie najbliższych,pyszne jedzenie i podzielenia się opiniami na temat ulubionych wkładów neo! ",
            second: "Wspólna wycieczka w góry, rozłożenie biwaku, wspólny wieczór z glo i spadającymi gwiazdami! ",
            third: "Wynajęcie domku i rozpoczęcie ostrej pożegnalnej imprezy, oczywiście w towarzystwie swoich ulubionych wkładów neo!",
        }, 
        {
            id: "13",
            name: "Wiktoria K.",
            title: "Food & Chill",
            first: "Dom w górach - Przy delektowaniu się moim ulubionym smakiem neo - czerwone owoce, zajadamy pyszny grill.",
            second: "Jezioro - Pływanie rowerkiem wodnym\/kajakiem ze znajomymi, nie zapominając o łagodnej mięcie.",
            third: "Góry - Nasza tradycja - spacer po górach w parze z orzeżawiającą pomarańczą.",
        },
        {
            id: "14",
            name: "Kamil K.",
            title: "Forty Party (wydarzenie odbywało by się na Forcie Bema w Warszawie stąd nazwa)",
            first: "Pierwszego dnia, odbyło by się oficjalne otwarcie wydarzenia, koncert rapera Kizo oraz innych znanych twórców. ",
            second: "Drugiego dnia perełką wydarzenia byłyby darmowe loty balonem nad tą częśćią stolicy oraz wieczorna impreza z DJem.",
            third: "Trzeci dzień to wielki piknik rodzinny, atrakcje dla dzieci, oraz zakończenie imprezy koncertem i pokazem fajerwerków.",
        }, 
        {
            id: "15",
            name: "Wiktor M.",
            title: "Wielki Nadmorski Zlot Miłośników Glo Hyper+",
            first: "Wspólne zdjęcia i impreza przez całe popołudnie, wieczorem ognisko i smażone kiełbaski ",
            second: "Wszyscy wspólnie będziemy wymieniać się doświadczeniami i donzaniami korzystania z produktów Glo wybierzemy najlepszy sm",
            third: "Impreza i kąpiel nad morzem ciągle trwa w przerwach od pływania używamy glo robimy zdjęcia i tańczymy do muzyki.",
        },
        {
            id: "16",
            name: "Michał  N.",
            title: "Trójmiasto summer fest",
            first: "Skutery wodne, flyboard, wakeboard, parasailing lub... spokojne plażowanie z GLO, drinkami i chilloutową muzyką",
            second: "Dzień ze stand-upem na plaży, a po zmroku kino na leżakach. Wcześniej plażowe atrakcje",
            third: "Wielki koncert gwiazd muzyki, nie tylko polskiej (kilka godzin). Poza tym lody, piwo, GLO, foodtrucki.",
        },
        {
            id: "17",
            name: "Paweł W.",
            title: "Mokre oko",
            first: "Dojazd do Zakopanego i wejście na morskie oko z koszem piknikowym. Chillout na zielonej trawie wśród piękna gór",
            second: "Kąpiel nad Morskim okiem w miejscu niedostępnym dla ludzi czyli na nielegalu ;)",
            third: "Wejście na rysy z namiotem na plecach z GLO w dłoniach puszczając dymka.",
        },
        {
            id: "18",
            name: "Szymon J.",
            title: "City tour/grilowanie/domówka/ryby",
            first: "Zwiedzanie wspólne ze znajomymi Warszawy, wyjście do zoo galerii luster,fontanny wieczorne i bary  nad Wisła ",
            second: "Dzień drugi to domówka na 25 osób atrakcje lampiony No i oczywiście Glo przy grilu ",
            third: "3 dzień szybka pobudka elektrolity  piłka ze znajomymi i od razu na rybki piwko a potem powrót do rzeczywistości.",
        }, 
        {
            id: "19",
            name: "Paweł P.",
            title: "Summer Party & Chill",
            first: "Nad morzem, na plazy pełno leżaków, beach bar w formie food trucka, klimatyczna muzyka i zabawa",
            second: "Drugiego dnia pojawia się stoisko neo na którym każdy z uczestników może spróbować jakiegoś nowego smaku",
            third: "Ostatniego dnia koncert pożegnalny. Grają znani artyści, jest ognisko, muzyka i zabawa.",
        },
        {
            id: "20",
            name: "Paweł S.",
            title: "City break w celtyckiej Irlandii",
            first: "Kocham delektować się moim glo po długim locie! Najlepiej smakuje wtedy neo mocno tytoniowe! Ach!",
            second: "Po pierwszym dniu przychodzi czas na odpoczynek. Nie ma nic piękniejszego od celtyckiej architektury z Neo Summer Yellow.",
            third: "Zostawmy centrum Dublina na moment. Tym razem wybieram się na dzikie wybrzeże z Arctic BLUE Click! Tak smakuje raj.",
        }
    ]

]