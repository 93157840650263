import React from "react"
import { NavLink } from 'react-router-dom'
import "./style.scss"
import Logo from "../Header/img/alterzone-logo-white.svg"
import { ReactComponent as Mail } from "./img/mail.svg"
import { ReactComponent as FB } from "./img/facebook.svg"
import { ReactComponent as Insta } from "./img/instagram.svg"
import BatLogo from "./img/bat-logo.svg"
import AgeMobile from "../../pages/RegisterPage/img/consents/age-verified-mobile.svg"
import AgeDesktop from "../../pages/RegisterPage/img/consents/age-verified-desktop.svg"
import AgeLaptop from "../../pages/RegisterPage/img/consents/age-verified-laptop.svg"
import CopyMobile from "../../pages/RegisterPage/img/consents/copyrights-mobile.svg"
import CopyDesktop from "../../pages/RegisterPage/img/consents/copyrights-desktop.svg"
import CreatedMobile from "../../pages/RegisterPage/img/consents/created-by-mobile.svg"
import CreatedLaptop from "../../pages/RegisterPage/img/consents/created-by-laptop.svg"
import CreatedDesktop from "../../pages/RegisterPage/img/consents/created-by-desktop.svg"

export default function Footer({ showCookieModal, logged }) {

  return (
    <footer className={logged ? "logged" : ""}>
      <div className="footer-top">
        <div className="over-18">
          <img src={Logo} alt="" className="logo" />
          <p>Strona przeznaczona dla pełnoletnich konsumentów wyrobów nikotynowych BAT Polska Trading sp.&nbsp;z&nbsp;o.o. oraz wyrobów powiązanych.</p>
          {/* <picture>
            <source
              media="(min-width: 1320px)"
              srcSet={AgeDesktop}
            />

            <source
              media="(min-width: 720px)"
              srcSet={AgeLaptop}
            />

            <img
              srcSet={AgeMobile}
              alt="legal"
            />
          </picture> */}
        </div>
        <hr className="line" />
        <div className="footer-left">
          <div className="footer-contact">
            <div className="social">
              <h4>Tu nas znajdziesz:</h4>
              <div className="wrapper">
                <div><a href="https://www.facebook.com/Masz-wyb%C3%B3r-101292994825277/" target="_blank" rel="noopener noreferrer"><FB /></a></div>
                <div><a href="https://www.instagram.com/maszwybor_/" target="_blank" rel="noopener noreferrer"><Insta /></a></div>
                <div><a href="mailto:kontakt@alterzone.pl" target="_blank" rel="noopener noreferrer"><Mail /></a></div>
                <div><a href="mailto:kontakt@alterzone.pl" rel="noopener noreferrer" className="email-link"><h4 className="email">kontakt@alterzone.pl</h4></a></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-nav">
        <ul>
          <li>
            {logged ?
              <NavLink to="/onas/regulaminy">Regulaminy</NavLink>
              :
              <a href={process.env.REACT_APP_API_URL + "/alterzone/terms/latest"} target="_blank" rel="noopener noreferrer">Regulamin</a>
            }
          </li>
          {logged && <li><NavLink to={logged ? "/onas/faq" : ""}>FAQ</NavLink></li>}
          <li><a href="/assets/polityka_prywatnosci_AZ.pdf" target="_blank" rel="noopener noreferrer">Polityka prywatności</a></li>
          <li><span onClick={() => showCookieModal()}>Preferencje Cookies</span></li>
          <li><a href="/assets/polityka_cookies_AZ.pdf" target="_blank" rel="noopener noreferrer">Polityka Cookies</a></li>
        </ul>
        <div className="flex-bat-logo desktop">
          <img src={BatLogo} alt="" />
          <p className="copyrights">© 2023 BAT Polska Trading. All rights reserved</p>

          {/* <picture>
            <source
              media="(min-width: 1100px)"
              srcSet={CopyDesktop}
            />

            <img
              className="copyrights"
              style={{ width: "auto" }}
              src={CopyMobile}
              alt="legal"
            />
          </picture> */}
        </div>
      </div>
      <div className="footer-bottom">
        <div className="flex-bat-logo mobile">
          <img src={BatLogo} alt="" />
          <p className="copyrights">© 2023 BAT Polska Trading. All rights reserved.</p>
          {/* <img
            className="copyrights"
            style={{ width: "auto" }}
            src={CopyMobile}
            alt="legal"
          /> */}
        </div>
        <p>Serwis został stworzony przez BAT Polska Trading Sp.&nbsp;z&nbsp;o.&nbsp;o. z&nbsp;siedzibą w&nbsp;Warszawie. Strona przeznaczona jest wyłącznie dla pełnoletnich użytkowników wyrobów tytoniowych, nikotynowych lub powiązanych zainteresowanych ofertą wyrobów nikotynowych BAT Polska Trading sp.&nbsp;z&nbsp;o.&nbsp;o.</p>
        {/* <picture>
          <source
            media="(min-width: 1320px)"
            srcSet={CreatedDesktop}
          />

          <source
            media="(min-width: 720px)"
            srcSet={CreatedLaptop}
          />

          <img
            srcSet={CreatedMobile}
            alt="legal"
          />
        </picture> */}
      </div>
    </footer>
  );
}
