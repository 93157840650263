export const data = [
    [
        {
            name: "Paulina B.",
            content: "\"Idealnym zakończeniem tego intensywnego roku byłby chillout z przyjaciółką na totalnym odludziu. Może rzućmy wszystko i wyjedźmy w BIESZCZADY! Domek w górach, kominek, w ręku gorący trunek, a za oknem mnóstwo śniegu... A w głowie tylko spokój\"."
        },
        {
            name: "Arkadiusz S.",
            content: "\"Romantyczny rejs z Gdańska promem do Szwecji wycieczka jednodniowa. Wspomienie zareczyn które miały miejsce 20 lat temu właśnie na tym promie dzięki którym do dziś jestemy kochającym się małżeństwem\"."
        },
        {
            name: "Monika Ch.",
            content: "\"Zawsze marzyłam o spędzeniu ostatniego dnia roku w innym Państwie. Inna kultura, strefa czasowa, ciekawe doświadczenie i niezapomniane chwile. Sylwester na plaży, przy prawie 30 stopniach z butelką dobrego wina. Zamiast głośnej muzyki, szum fal\"."
        }
    ],
    [
        {
            name: "Przemysław P.",
            content: "\"Marzy mi się uciekanie przed wybiciem północy. Jestem w danym miejscu, 5 minut po północy wsiadam w samolot, lecę do innej strefy czasowej i znowu 23.55?\"."
        },
        {
            name: "Oliwia Sz.",
            content: "\"Chciałabym podziękować mojej przyjaciółce za cały rok. Może jakiś apartament? Sylwester nad morzem? Nie ważne gdzie i nie ważne jak, najważniejsze, że z nią! Liczę, że słowa: „Z kim spędzisz Sylwestra, z tym spędzisz przyszły rok”, się spełnią\"."
        },
        {
            name: "Klaudia R.",
            content: "\"Szalone pomysły ja mam, więc bungee urządzę nam. Ja to cykor, on odważny, będzie blind date, strach tu jest mało ważny. Po tej wielkiej adrenalinie, pójdziemy skakać na trampolinie. Na sam koniec tej randeczki, wypijemy piwko prosto z beczki\"."
        }
    ],
    [
        {
            name: "Wojciech K.",
            content: "\"W teatrze nie bywamy często, ale zdarza nam się. Jest to fajne rozwiązanie na wieczór, szczególnie ten zimowy. Potem wspólne gotowanie (kolacja), butelka dobrego wina i gry planszowe. Pozdrawiam\"."
        },
        {
            name: "Natalia P.",
            content: "\"Ostatnie chwile 2022 roku chciałabym spędzić z przyjacielem oraz moim psem, oglądając fajerwerki z miasta z oddali, przy ognisku, odpoczywając w domku w górach, pijąc grzane wino, zajadając się czekoladą i wspominając jak szalony był ten rok\"."
        },
        {
            name: "Sandra P.",
            content: "\"Chciałabym przede wszystkim odpocząć, najlepiej na bezludnej wyspie na której miałabym obok siebie najważniejszą osobę w życiu\"."
        },
        {
            name: "Mirek W.",
            content: "\"Ostatnie chwile tego roku spędził bym w przytulnym miejscu z ukochaną w ramionach patrząc na ogień w kominku nie myśląc o problemach dnia codziennego byłby to wyjątkowy czas dla nas\"."
        }
    ]
]