export const questions = {
    "gameType": "Video",
    "glo": [
        {
            "ident": "q0",
            "content": "Piotr, jako nastolatek podczas wielu podróży po Europie z rodzicami często nalegał, aby zatrzymywać się na kawę.",
            video: require("./img/1.mp4"),
            "answers": [
                {
                    "ident": "a",
                    "content": "Prawda"
                },
                {
                    "ident": "b",
                    "content": "Fałsz"
                },
            ]
        },
        {
            "ident": "q1",
            "content": "Piotra Czaykowskiego można określić jako człowieka wielu talentów, którzy odnajdzie się w każdej sytuacji.",
            video: require("./img/2.mp4"),
            "answers": [
                {
                    "ident": "a",
                    "content": "Prawda"
                },
                {
                    "ident": "b",
                    "content": "Fałsz"
                },
            ]
        },
        {
            "ident": "q2",
            "content": "Piotr został mężem sławnej chińskiej projektantki.",
            video: require("./img/3.mp4"),
            "answers": [
                {
                    "ident": "a",
                    "content": "Prawda"
                },
                {
                    "ident": "b",
                    "content": "Fałsz"
                },
            ]
        }
    ]
}