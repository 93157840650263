import glo0 from "./img/glo0.png"
import glo1 from "./img/glo1.png"
import glo2 from "./img/glo2.png"
import glo3 from "./img/glo3.png"
import glo4 from "./img/glo4.png"
import glo5 from "./img/glo5.png"
import glo6 from "./img/glo6.png"
import glo7 from "./img/glo7.png"
import glo8 from "./img/glo8.png"
import glo9 from "./img/glo9.png"

export const questions = {
    "gameType": "PickOne",
    "glo": [
        {
            "ident": "q0",
            "content": "Melon",
            "type": "img",
            "answers": [
                {
                    "ident": "a",
                    "content": glo0,
                    "name": "Summer Yellow Click"
                },
                {
                    "ident": "b",
                    "content": glo1,
                    "name": "Sunset Click"
                },
                {
                    "ident": "c",
                    "content": glo2,
                    "name": "Scarlet Click"
                },
                {
                    "ident": "d",
                    "content": glo3,
                    "name": "Arctic Blue Click"
                },
            ]
        },
        {
            "ident": "q1",
            "content": "Kapsułka",
            "type": "img",
            "answers": [
                {
                    "ident": "a",
                    "content": glo4,
                    "name": "Gold Tobacco"
                },
                {
                    "ident": "b",
                    "content": glo5,
                    "name": "Dark Tobacco"
                },
                {
                    "ident": "c",
                    "content": glo6,
                    "name": "Terracota Tobacco"
                },
                {
                    "ident": "d",
                    "content": glo3,
                    "name": "Arctic Blue Click"
                },
            ]
        },
        {
            "ident": "q2",
            "content": "Orzeźwienie",
            "type": "img",
            "answers": [
                {
                    "ident": "a",
                    "content": glo2,
                    "name": "Scarlet Click"
                },
                {
                    "ident": "b",
                    "content": glo8,
                    "name": "Green Click"
                },
                {
                    "ident": "c",
                    "content": glo7,
                    "name": "Violet Click"
                },
                {
                    "ident": "d",
                    "content": glo0,
                    "name": "Summer Yellow Click"
                },
            ]
        },
        {
            "ident": "q3",
            "content": "Tropiki",
            "type": "img",
            "answers": [
                {
                    "ident": "a",
                    "content": glo6,
                    "name": "Terracota Tobacco"
                },
                {
                    "ident": "b",
                    "content": glo9,
                    "name": "Ice Click"
                },
                {
                    "ident": "c",
                    "content": glo1,
                    "name": "Sunset Click"
                },
                {
                    "ident": "d",
                    "content": glo4,
                    "name": "Gold Tobacco"
                },
            ]
        }
    ]
}