import glo0 from "./img/item.png"
import glo1 from "./img/item2.png"

export const questions = {
    "gameType": "MomentRange",
    "glo": [
        {
            "ident": "q0",
            "content": "Doświadczenie aromatu słodko-gorzkiej pomarańczy i nut cytrusowych uzupełnione aromatycznymi ziołami.",
            "type": "image",
            "correctAnswer": "b",
            "answers": [
                {
                    "ident": "a",
                    "content": "neo™ Terrazza Click",
                    img: glo0
                },
                {
                    "ident": "b",
                    "content": "neo™ Lounge Click",
                    img: glo1
                },
            ]
        },
        {
            "ident": "q1",
            "content": "Doświadczenie aromatu soczystych winogron",
            "correctAnswer": "a",
            "type": "image",
            "answers": [
                {
                    "ident": "a",
                    "content": "neo™ Terrazza Click",
                    img: glo0
                },
                {
                    "ident": "b",
                    "content": "neo™ Lounge Click",
                    img: glo1
                },
            ]
        },

    ]
}