export const data = [
    {
        name: "Jakub S.",
        content: "To osoba, która korzysta z życia pełnymi garściami. Słowem: wie co dobre i jeszcze Ci o tym opowie. To osoba, która wie czego chce, co ją kręci, czego pragnie, co sprawia jej przyjemność. Lub nie wie, ale szuka, próbuje, podejmuje ryzyko. Jest otwarta na nowe, jest głodna życia. Poszukiwania też potrafią dać rozkosz. Kto wie może nawet większą niż to, co już odkryte. Smacznego! "
    },
    {
        name: "Damian S.",
        content: "Jest nikim innym jak wyzwoloną osoba pełną wiedzy i pasji która nie boi się życia i czerpie z niego garściami. Nie każdy jest taki sam gdyż każdy ma zbudowany swój własny model życia. Patrzenie na świat w tego typu sposób otwiera na nowe i zamyka stare schematy tworząc tym nowoczesny model oświecenia."
    },
    {
        name: "Mateusz D.",
        content: "Osoba która nie ma sobie równych. Jest to poeta w świecie dekadentów."
    }
]