import Part0 from "./img/part0.jpg"
import Part1 from "./img/part1.jpg"
import Part2 from "./img/part2.jpg"
import Part3 from "./img/part3.jpg"
import Part4 from "./img/part4.jpg"
import Part5 from "./img/part5.jpg"
import Part6 from "./img/part6.jpg"
import Part7 from "./img/part7.jpg"
import Part8 from "./img/part8.jpg"

export const questions = {
    "gameType": "JigsawNew",
    "glo": [
        {
            "ident": "q0",
            "content": "Dopasuj opis do wkładu neo™ Rounded Tobacco",
            type: "dropdowns",
            "copys": [
                "Łagodna i słodka miętowa nuta",
                "Intensywne miętowe doświadczenie",
                "Doświadczenie intensywnego mentolu oraz słodka miętowa nuta"
            ],
            "correctAnswers": [
                "a", "b", "c"
            ],
            "answers": [
                {
                    ident: "a",
                    copy: "neo™ Green Click",
                },
                {
                    ident: "b",
                    copy: "neo™ Ice Click",
                },
                {
                    ident: "c",
                    copy: "neo™ Arctic Blue Click",
                },
            ]
        },
        {
            "ident": "q3",
            "content": "Ułóż zdjęcie",
            type: "jigsaw",
            "correctAnswers": [
                "6fb0a2da-5517-4d18-b9ea-3c19cfa77bf0",
                "69a1c551-7a2a-4905-b2df-e73c53fe0ae3",
                "306950de-ec8b-4c60-882e-b8fad77ddc75",
                "04aeb694-b763-4619-b474-92c2da8b26e5",
                "d1b44f54-6cdb-4a6d-a34e-df45a8aaad6c",
                "561cd08c-3813-4c58-bbf9-0bcae3aa604f",
                "35ed4ecb-fe39-4af5-a476-8819b58e8908",
                "fa76790a-4822-4f1d-ab20-04e99eb1a311",
                "44a9bc44-2e6d-4029-ab44-65a6b3985240"
            ],
            "answers": [
                {
                    "ident": "306950de-ec8b-4c60-882e-b8fad77ddc75",
                    "content": Part0
                },
                {
                    "ident": "d1b44f54-6cdb-4a6d-a34e-df45a8aaad6c",
                    "content": Part1
                },
                {
                    "ident": "6fb0a2da-5517-4d18-b9ea-3c19cfa77bf0",
                    "content": Part2
                },
                {
                    "ident": "35ed4ecb-fe39-4af5-a476-8819b58e8908",
                    "content": Part3
                },
                {
                    "ident": "69a1c551-7a2a-4905-b2df-e73c53fe0ae3",
                    "content": Part4
                },
                {
                    "ident": "44a9bc44-2e6d-4029-ab44-65a6b3985240",
                    "content": Part5
                },
                {
                    "ident": "fa76790a-4822-4f1d-ab20-04e99eb1a311",
                    "content": Part6
                },
                {
                    "ident": "04aeb694-b763-4619-b474-92c2da8b26e5",
                    "content": Part7
                },
                {
                    "ident": "561cd08c-3813-4c58-bbf9-0bcae3aa604f",
                    "content": Part8
                },
            ]
        },
    ]
}