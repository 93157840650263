import jura from "./img/ic_jura.svg"
import krups from "./img/ic_krups.svg"
import delonghi from "./img/ic_delonghi.svg"
import Hoodie from "./img/hoodie.svg"

export const konkurs2_kwiecien = {
    rewards: [
        {
            img: Hoodie,
            place: "1-3",
            content: "Ekspres JURA ENA 8",
        },
    ],
}