import smak1 from "./img/smak1.png"
import smak2 from "./img/smak2.png"
import smak3 from "./img/smak3.png"
import smak4 from "./img/smak4.png"
import smak5 from "./img/smak5.png"
import smak6 from "./img/smak6.png"
import smak7 from "./img/smak7.png"
import smak8 from "./img/smak8.png"
import smak9 from "./img/smak9.png"
import smak10 from "./img/smak10.png"
import smak11 from "./img/smak11.png"
import smak12 from "./img/smak12.png"



export const questions = {
    "gameType": "WordSearch",
    "glo": [
        {
            "ident": "q0",
            "content": "Który wariant wkładów neo™ jest Twoim ulubionym?",
            "type": "pick",
            "board": [{ img: smak1, copy: "Terrazza Click" }, { img: smak2, copy: "Lounge Click" }, { img: smak3, copy: "Terracotta Tobacco" }, { img: smak4, copy: "Rounded Tobacco" }, { img: smak5, copy: "Gold Tobacco" }, { img: smak6, copy: "Summer Yellow Click" }, { img: smak7, copy: "Arctic Blue Click" }, { img: smak8, copy: "Green Click" }, { img: smak9, copy: "Ice Click" }, { img: smak10, copy: "Scarlet Click" }, { img: smak11, copy: "Sunset Click" }, { img: smak12, copy: "Violet Click" }],
            "answers": [
                { "uuid": "Terrazza Click", "count": "0" },
                { "uuid": "Lounge Click", "count": "1" },
                { "uuid": "Terracotta Tobacco", "count": "2" },
                { "uuid": "Rounded Tobacco", "count": "3" },
                { "uuid": "Gold Tobacco", "count": "4" },
                { "uuid": "Summer Yellow Click", "count": "5" },
                { "uuid": "Arctic Blue Click", "count": "6" },
                { "uuid": "Green Click", "count": "7" },
                { "uuid": "Ice Click", "count": "8" },
                { "uuid": "Scarlet Click", "count": "9" },
                { "uuid": "Sunset Click", "count": "10" },
                { "uuid": "Violet Click", "count": "11" },
            ],
        },
        {
            "ident": "q1",
            "content": "Znajdź 1 dowolne słowo i dowiedz się, co Cię czeka w majówkę:",
            "type": "board",
            "board": ["m", "d", "o", "m", "ó", "w", "k", "a", "r", "y", "o", "d", "j", "e", "z", "i", "o", "r", "o", "p", "r", "p", "o", "s", "f", "y", "w", "k", "l", "c", "z", "q", "a", "t", "u", "v", "b", "n", "t", "o", "e", "m", "n", "g", "ó", "r", "y", "s", "d", "l", "g", "t", "p", "x", "v", "i", "j", "h", "g", "a", "a", "e", "r", "q", "t", "y", "b", "n", "z", "s", "g", "u", "d", "p", "o", "w", "m", "d", "n", "x", "b", "u", "r", "z", "a", "l", "o", "k", "ó", "w", "c", "i", "t", "y", "b", "r", "e", "a", "k", "z"],
            "correctAnswers": ["morze", "las", "domówka", "jezioro", "góry", "citybreak"]
        },
        {
            "ident": "q7",
            "content": "Wybierz, ile paczek zabierzesz na majówkę:",
            "sub": "Pakowanie",
            "type": "counter",
            "answers": [
                { "uuid": "0", "count": "0" },
                { "uuid": "1", "count": "1" },
                { "uuid": "2", "count": "2" },
                { "uuid": "3", "count": "3" },
                { "uuid": "4", "count": "4" },
                { "uuid": "5", "count": "5" },
                { "uuid": "6", "count": "6" },
                { "uuid": "7", "count": "7" },
                { "uuid": "8", "count": "8" },
                { "uuid": "9", "count": "9" },
                { "uuid": "10", "count": "10" },
            ],
        },
    ]
}