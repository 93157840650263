export const questions = {
    "gameType": "PickOne",
    "glo": [
        {
            "ident": "q0",
            "content": "Tytoń podgrzewany jest do temperatury około ________ stopni Celsjusza. W porównaniu z tradycyjnymi papierosami jest to 4 razy mniej. Przy spalaniu standardowego papierosa temperatura osiąga nawet 800 stopni.",
            "fontSizeMobile": "15px",
            "fontSizeDesktop": "15px",
            "type": "text",
            "correctAnswer": "a",
            "answers": [
                {
                    "ident": "a",
                    "content": "200"
                },
                {
                    "ident": "b",
                    "content": "400"
                },
                {
                    "ident": "c",
                    "content": "600"
                },
            ]
        },
        {
            "ident": "q1",
            "content": "W urządzeniach glo™ umieszczono indukcyjny system grzewczy, który podgrzewa ___________ . Wystarczy umieścić go w komorze grzewczej, a zostanie on poddany obróbce termicznej, produkując przy okazji charakterystyczną mgiełkę – aerozol tytoniowy zawierający znacznie mniej substancji toksycznych niż dym papierosowy.",
            "fontSizeMobile": "15px",
            "fontSizeDesktop": "15px",
            "type": "text",
            "correctAnswer": "c",
            "answers": [
                {
                    "ident": "a",
                    "content": "papierosa"
                },
                {
                    "ident": "b",
                    "content": "liquid"
                },
                {
                    "ident": "c",
                    "content": "wkład"
                },
            ]
        },
        {
            "ident": "q2",
            "content": "Podgrzewacz tytoniu glo™ stanowi alternatywę dla tradycyjnych papierosów. Bez dymu, popiołu, przykrego zapachu, a także do 90-95% mniej szkodliwych substancji w porównaniu do _____________________ .",
            "fontSizeMobile": "15px",
            "fontSizeDesktop": "15px",
            "type": "text",
            "correctAnswer": "b",
            "answers": [
                {
                    "ident": "a",
                    "content": "e-papierosów"
                },
                {
                    "ident": "b",
                    "content": "tradycyjnych papierosów"
                },
                {
                    "ident": "c",
                    "content": "saszetek nikotynowych"
                },
            ]
        },
    ]
}