import Blue from "./img/blue.png"
import Gold from "./img/gold.png"
import Black from "./img/black.png"
import Navy from "./img/navy.png"
import Teal from "./img/teal.png"
import Pink from "./img/pink.png"
import Purple from "./img/purple.png"

export const questions = {
    "gameType": "meetX2",
    "glo": [
        {
            "answers": [
                {
                    ident: "a",
                    copy: "Przycisk STANDARD",
                },
                {
                    ident: "b",
                    copy: "Przycisk BOOST",
                },
                {
                    ident: "c",
                    copy: "Port ładowania USB-C",

                },
                {
                    ident: "d",
                    copy: "Kolorowy wskaźnik LED",
                },
                {
                    ident: "e",
                    copy: "Komora grzewcza ",
                },
                {
                    ident: "f",
                    copy: "Zasuwana przesłona",
                },
                {
                    ident: "g",
                    copy: "Dolne zamknięcie podgrzewacza",
                },
            ],
            correctAnswers: [
                "f", "e", "a", "b", "d", "g", "c"
            ]
        },
        {
            answers: [
                {
                    img: Blue,
                    name: "Ocean Blue",
                },
                {
                    img: Gold,
                    name: "Rosey Gold",
                },
                {
                    img: Black,
                    name: "Moonless Black",
                },
                {
                    img: Teal,
                    name: "Light Teal",
                },
                {
                    img: Navy,
                    name: "Space Navy",
                },
                {
                    img: Pink,
                    name: "Velvet Pink",
                },
                {
                    img: Purple,
                    name: "Crisp Purple",
                },
            ]
        }
    ]
}